import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import ModalAreYouSureCoinEdit from "./ModalAreYouSureCoinEdit";
import SelectTrend from "./SelectTrend";
import AnalyseInput from "./AnalyseInput";




import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import ConsumerPost from "./ConsumerPost";


import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory, useParams } from "react-router-dom";
import { Divider } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

//let open = false;

export default function RouteDashboardCoinEdit(props) {

  const [staticText] = useContext(ConstantsContext);

  let { _id } = useParams();

  if(_id == undefined) {
    _id = 0;
  }
  //const [myPortfolioListArr, setMyPortfolioListArr] = useState(['ADA-USD', 'BCH-USD', 'BTC-USD', 'DOGE-USD', 'ETH-USD', 'LTC-USD', 'SHIB-USD', 'XRP-USD'])

  const classes = useStyles();
  const theme = useTheme();

  /////////////////////////////////////////////param _id
  const [coinPairId, setCoinPairId] = useState(_id);
  const [coinPairName, setCoinPairName] = useState('');
  const [changeBool, setChangeBool] = useState(false);

  ///////////////////////////////////Modal Stuff
    const [open, setOpen] = useState(props.open != undefined ? props.open : false);

    const handleOpen = () => {
        //console.log(setOpen);
        setOpen(true);
        //open = true;
    };

    const handleClose = () => {
        setOpen(false);
        //open = false;
    };

    const handleCloseAgree = () => {

      handleClose();
      if(tempCoinPairId != 0) {

        setChangeBool(false);
        setCoinPairId(tempCoinPairId);
        setCoinPairName(tempCoinPairName);
        changeHistory('/DashboardCoinEdit/' + _id, '/DashboardCoinEdit/' + tempCoinPairId)
      }
      
      //props.doFnc();
    };

    const setChangeBoolFnc = (bool) => {

      setChangeBool(bool)
      //props.setChangeBool(bool);
    }

    const [tempCoinPairId, setTempCoinPairId] = useState(0);
    const [tempCoinPairName, setTempCoinPairName] = useState('');
    
    
    /////////////////////////////////////////////

    const [h1, setH1] = React.useState(-100);
    const [h4, setH4] = React.useState(-100);
    const [d1, setD1] = React.useState(-100);
    const [w1, setW1] = React.useState(-100);
    const [m1, setM1] = React.useState(-100);

    const [low, setLow] = React.useState(0);
    const [high, setHigh] = React.useState(0);

    const [comment, setComment] = React.useState('');

    const [chartLink, setChartLink] = React.useState('');
    const [videoLink, setVideoLink] = React.useState('');
    /////////////////////////////////////////////

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
        changeHistory('/DashboardCoinEdit', '/')
       
      } else {
        //changeHistory('/ReferAFriend', '/SignUp')
        //console.log('_path = ', props._path)
        //console.log('loggined_token = ', props.userDetails.loggined_token)
        if(props._path != undefined) {
          //_api_contributor_get_coin_pair()
          _api_get_portfolio()
          //console.log('_id = ' + _id);
          setCoinPairId(_id);

          //_api_get_analyse(_id);

        } else {
          
        }
      }
  }, []);

  const [myPortfolioListArr, setMyPortfolioListArr] = useState([]);
  //const [myPortfolioListArr, setMyPortfolioListArr] = useState([])
  const [myPortfolioListArrLength, setMyPortfolioListArrLength] = useState(myPortfolioListArr.length)

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_portfolio_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_portfolio error = ' + _data.msg);
      //console.log('get_portfolio code = ' + _data.status_code);
      
    } else {
      
      //console.log('get_portfolio Dashboard Coin Edit was successfully sent');
      //console.log('_data = ' + _data.data[0]);
      

      let coin_pair_idsArr_int = [];
      //_data.data.map((value, key) => {

        //coin_pair_idsArr_int.push(parseInt(value["id"]));
      //})
      //console.log('ZZZZZZZZZZZdata = ', _data.data);
      _data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      _data.data.map((value, key) => {

        if(parseInt(coinPairId) == parseInt(value["id"])) {
          setCoinPairName(value["left_side"]+'-'+value["right_side"])
        }
        
      })

      setMyPortfolioListArr(_data.data)
      setMyPortfolioListArrLength(_data.data.length)

      //props.setMyPortfolioListArr(_data.data);
      ///////////////////////////////////////////////////////////////////////
      
      
      
      /*let coin_pair_idsArr = [];

      if(_data.data[0].coin_pair_ids != '') {
        coin_pair_idsArr = _data.data[0].coin_pair_ids.split(',');
      }
      
      var coin_pair_idsArr_int = coin_pair_idsArr.map(function (x) { 
        return parseInt(x, 10); 
      });
      setMyPortfolioListArr(coin_pair_idsArr_int)
      setMyPortfolioListArrLength(coin_pair_idsArr_int.length)
      */
    }
    
  }

  const _api_get_portfolio = () => {

    let data = [
      {'key':'action', 'value': 'get_portfolio'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_portfolio_callback)
  }
  //////////////////////////////////////



  ///////////////////////////////////////////////////////////////////////////////coin pairs buttons
  
  const chooseCoinPairFnc = (id, name) => {
    
    if(changeBool) {

      setTempCoinPairId(id);
      setTempCoinPairName(name);
      
      handleOpen();
      //onClick={() => chooseCoinPairFnc(value["id"])}
    } else {
      setCoinPairId(id);
      setCoinPairName(name);
      changeHistory('/DashboardCoinEdit/' + _id, '/DashboardCoinEdit/' + id)
    }
    
    
    
    //_api_get_analyse(id);
  }
  /////////////////////////////////////////////////////////////////////////

  

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
      
      <DashboardTopMenu changeBool={changeBool} userProfileType={props.userDetails.usertype} _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{textAlign:'center', maxWidth:600, fontSize:18, fontWeight:'bold', paddingBottom:10}}>Analyst’s Portfolio: Update Price Estimate and Trend Indicators</div>
        </Grid>


        <Grid item xs={12} sm={4} md={2} lg={2} align="center" justify="center">
            <div style={{display: 'flex', textAlign:'center', justifyContent:'space-between', width:'100%', maxWidth:120}}>
              <div style={{textAlign:'center'}}>Coins</div>
              <div style={{paddingLeft:10}}>
                  <ControlPointIcon onClick={() => changeHistory('/', '/BuildPortfolio')} style={{width:30, height:30, cursor:'pointer'}} />
              </div>
            </div>
            <div style={{paddingTop:1, width:'100%', maxWidth:120, alignItems:'flex-start'}}>
                <div 
                    style={{
                        height: 250,
                        width: 120,
                        overflow: 'auto',
                        border: '1px solid #0070C0', borderRadius: 5,
                        alignItems:'flex-start',
                        paddingLeft:10
                    }}
                >
                {
                    myPortfolioListArr.map((value, key) => {

                        //if(parseInt(coinPairId) == parseInt(value["id"])) { 
                          //setCoinPairName(value["left_side"]+'-'+value["right_side"]);
                        //}
                        return (
                            <div key={key} style={{padding:5, textAlign:'left', cursor:'pointer'}}>
                                
                                <Typography key={key} onClick={() => chooseCoinPairFnc(value["id"], (value["left_side"]+'-'+value["right_side"]))} style={{fontSize:13, backgroundColor:parseInt(coinPairId) == parseInt(value["id"]) ? '#3FAAEB' : theme.background}}>{(value["left_side"]+'-'+value["right_side"])}</Typography>
                            </div>
                        );
                    })
                }
                </div>
            </div>
        </Grid>
        <AnalyseInput key={coinPairId} changeBool={changeBool} setChangeBoolFnc={setChangeBoolFnc} coinPairId={coinPairId} coinPairName={coinPairName} userDetails={props.userDetails} _apiConnect={props._apiConnect} _path={props._path} />
        {/*<AnalyseInput key={coinPairId} coinPairId={coinPairId} saveCoinEditFnc={saveCoinEditFnc} h1={h1} h4={h4} d1={d1} w1={w1} m1={m1} low={low} high={high} comment={comment} chartLink={chartLink} videoLink={videoLink} />*/}
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{height:30}}></div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{fontSize:10, padding:20, textAlign:'left'}}>
                DISCLAIMER: The material presented on CryptoMarketDarts.com platform has no regard to any specific investment objectives, financial situation or needs of 
                any of its users. This information is presented solely for informational purposes and is not to be construed as a recommendation, solicitation or 
                an offer to buy or sell (long or short) any asset classes covered by CryptoMarketDarts.com platform. None of the content on this platform should 
                be taken as investment advice. CryptoMarketDarts.com or its subsidiary or holding entities or any of the contributing analysts or users should not 
                be seen, treated or otherwise assumed to be investment or financial advisors. The views and opinion expressed in the platform are speculative 
                and do not guarantee any specific result or profit. Trading and investing is extremely high risk and can result in the loss of all of your capital. Any 
                opinions expressed in this platform are subject to change without notice. CryptoMarketDarts.com is not under any obligation to update or keep 
                current the information contained herein. CryptoMarketDarts.com or its subsidiary or holding entities may have an interest in the securities, 
                commodities, cryptocurrencies and or derivatives of any entities referred to in this platform. CryptoMarketDarts.com or its subsidiary or holding 
                entities accept no liability whatsoever for any loss or damage of any kind arising out of the use of all or any part of materials presented on 
                CryptoMarketDarts.com. CryptoMarketDarts.com recommends that you consult with a licensed and qualified professional before making any 
                investment or trading decisions.
            </div>
        </Grid>
      </>
      <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle>You have unsaved data</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to leave?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => handleClose()}>No</Button>
          <Button onClick={() => handleCloseAgree()}>I'm sure</Button>
          </DialogActions>
      </Dialog>
    </Grid>
  );
}