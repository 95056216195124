import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import { ConstantsContext, ThemeContext } from '..';
import { roundNumberIfNeeded } from './functions';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalOrderConditions(props) {

  const [staticText] = useContext(ConstantsContext);
  const [theme] = useContext(ThemeContext);
  const alertMessage = props.inputAction === 'buy_stop_limit' || props.inputAction === 'buy_limit' ? 'Sell Order' : 'Buy Order';
  const [takeProfit, setTakeProfit] = useState(10);
  const [stopLoss, setStopLoss] = useState(3);
  const [trailingStop, setTrailingStop] = useState(false);
  const [activation, setActivation] = useState(1);
  const [trailingDelta, setTrailingDelta] = useState(2);
  const [errors, setErrors] = useState({});

  const [takeProfitValue, setTakeProfitValue] = useState(10);
  const [stopLossValue, setStopLossValue] = useState(3);
  const [activationValue, setActivationValue] = useState(1);
  const [trailingDeltaValue, setTrailingDeltaValue] = useState(2);

  const [takeProfitSellStopValue, setTakeProfitSellStopValue] = useState(9.89); // Initialize properly (10 - 1.01%)
  const [stopLossSellStopValue, setStopLossSellStopValue] = useState(2.97); // Initialize properly (3 - 1.01%)

  const priceAtFilledMoment = props.inputAction === 'buy_limit' ? props.inputPrice : props.inputAction === 'buy_stop_limit' ? props.inputLimitPrice : 0;

  const [portfolioOrderCreateSuccessMessage, setPortfolioOrderCreateSuccessMessage] = useState('');
  const [portfolioOrderCreateValidationMessage, setPortfolioOrderCreateValidationMessage] = useState('');

  const calculateSellStopValue = (value) => {
    return value * (1 - 0.0101); // Decrease by 1.01%
  };

   // Effect to reset messages and errors when the modal opens or closes
   useEffect(() => {
    if (props.open) {
      // Reset messages when modal is opened
      setPortfolioOrderCreateSuccessMessage('');
      setPortfolioOrderCreateValidationMessage('');
      setErrors({});
    }
  }, [props.open]);

  useEffect(() => {
    validateFields();

    let _takeProfitValue = 0;
    let _stopLossValue = 0;
    let _activationValue = 0;
    let _trailingDeltaValue = 0;

    const takeProfitPercent = parseFloat(takeProfit);
    const stopLossPercent = parseFloat(stopLoss);
    const activationPercent = parseFloat(activation);
    const trailingDeltaPercent = parseFloat(trailingDelta);

    if (props.inputAction === 'buy_limit') {
      _takeProfitValue = !isNaN(takeProfitPercent) ? props.inputPrice + (props.inputPrice * takeProfitPercent / 100) : props.inputPrice;
      _stopLossValue = !isNaN(stopLossPercent) ? props.inputPrice - (props.inputPrice * stopLossPercent / 100) : props.inputPrice;
      _activationValue = !isNaN(activationPercent) ? props.inputPrice + (props.inputPrice * activationPercent / 100) : props.inputPrice;
    } else if (props.inputAction === 'buy_stop_limit') {
      _takeProfitValue = !isNaN(takeProfitPercent) ? props.inputLimitPrice + (props.inputLimitPrice * takeProfitPercent / 100) : props.inputLimitPrice;
      _stopLossValue = !isNaN(stopLossPercent) ? props.inputLimitPrice - (props.inputLimitPrice * stopLossPercent / 100) : props.inputLimitPrice;
      _activationValue = !isNaN(activationPercent) ? props.inputLimitPrice + (props.inputLimitPrice * activationPercent / 100) : props.inputLimitPrice;
    }

    _trailingDeltaValue = _activationValue * (100 - trailingDeltaPercent) / 100;

    setTakeProfitValue(_takeProfitValue);
    setStopLossValue(_stopLossValue);
    setActivationValue(_activationValue);
    setTrailingDeltaValue(_trailingDeltaValue);

    // Update sell stop values using the new calculated values
    setTakeProfitSellStopValue(calculateSellStopValue(_takeProfitValue));
    setStopLossSellStopValue(calculateSellStopValue(_stopLossValue));
  }, [takeProfit, stopLoss, trailingStop, activation, trailingDelta, props.inputAction, props.inputPrice, props.inputLimitPrice]);

  const validateFields = () => {
    let newErrors = {};

    // Convert stopLoss to negative for validation purposes
    const realStopLoss = -stopLoss;

    if (takeProfit <= 0 || isNaN(takeProfit)) {
      newErrors.takeProfit = 'Take Profit must be a positive number';
    }

    if (stopLoss <= 0 || isNaN(stopLoss)) {
      newErrors.stopLoss = 'Stop Loss must be a positive number';
    }

    if (trailingStop) {
      if (activation !== '' && (parseFloat(activation) < 0 || isNaN(parseFloat(activation)))) {
        newErrors.activation = 'Activation must be empty or a positive number';
      }

      if (trailingDelta <= 0 || isNaN(trailingDelta)) {
        newErrors.trailingDelta = 'Trailing Delta must be a positive number';
      }
    }

    setErrors(newErrors);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (setter) => (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setter(event.target.type === 'checkbox' ? value : value === '' ? '' : parseFloat(value));
  };

  const handleSubmit = () => {

    _api_set_portfolio_order_options()
    //props.setOpen(false);
  }

  
  const handleDelete = () => {

    _api_delete_portfolio_order_options()
    //props.setOpen(false);
  }

  ////////////////////////////////////////_api_set_portfolio_order_options
  function _api_set_portfolio_order_options_callback(_data) {
    //console.log('_api_set_portfolio_order_callback _data', _data)
    if(_data.status_code != 2) {
      
      setPortfolioOrderCreateSuccessMessage('');
      setPortfolioOrderCreateValidationMessage(_data.msg);
    } else {

      setPortfolioOrderCreateSuccessMessage('Your order options has been submitted successfully');
      setPortfolioOrderCreateValidationMessage('');
      props.updateOrdersList()
      //props.setOpen(false);

      /*if(inputAction == 'buy_stop_limit' || inputAction == 'buy_limit') {

        console.log('open modal')
        setOpenOrderConditions(true);
      }*/
    }    
  }

  const _api_set_portfolio_order_options = () => {

    
    let data = [
      {'key':'action', 'value': 'set_portfolio_order_options'},
      {'key':'order_id', 'value': props.order_id},
      {'key':'order_key', 'value': props.order_key},
      {'key':'stop_loss', 'value': stopLoss},
      {'key':'stop_loss_action', 'value': 'sell_stop_limit'},
      {'key':'stop_loss_price_limit_percent', 'value': 1},
      {'key':'trailing_stop', 'value': trailingStop === true ? 1 : 0},
      {'key':'trailing_stop_activation', 'value': activation},
      {'key':'trailing_stop_delta', 'value': trailingDelta},
      {'key':'take_profit', 'value': takeProfit},
      {'key':'take_profit_action', 'value': 'sell_stop_limit'},
      {'key':'take_profit_price_limit_percent', 'value': 1},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('_api_set_portfolio_order_options = ', data)

    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_set_portfolio_order_options_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_delete_portfolio_order_options
  function _api_delete_portfolio_order_options_callback(_data) {
    //console.log('_api_set_portfolio_order_callback _data', _data)
    if(_data.status_code != 2) {
      
      setPortfolioOrderCreateSuccessMessage('');
      setPortfolioOrderCreateValidationMessage(_data.msg);
    } else {

      setPortfolioOrderCreateSuccessMessage('Your order options has been deleted successfully');
      setPortfolioOrderCreateValidationMessage('');
      props.updateOrdersList()
      //props.setOpen(false);

      /*if(inputAction == 'buy_stop_limit' || inputAction == 'buy_limit') {

        console.log('open modal')
        setOpenOrderConditions(true);
      }*/
    }    
  }

  const _api_delete_portfolio_order_options = () => {

    
    let data = [
      {'key':'action', 'value': 'delete_portfolio_order_options'},
      {'key':'order_id', 'value': props.order_id},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('_api_delete_portfolio_order_options = ', data)

    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_delete_portfolio_order_options_callback)
  }
  //////////////////////////////////////

  const isSubmitDisabled = Object.keys(errors).length > 0 || portfolioOrderCreateSuccessMessage != '';
  const isDeleteDisabled = portfolioOrderCreateSuccessMessage != '';

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{props.alertHeader}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {alertMessage}
        </DialogContentText>
        {
        portfolioOrderCreateSuccessMessage !== '' ?
          <div style={{paddingTop:40}}>
            <Alert
              severity="success"
              sx={{ mb: 2 }}
            >
              {portfolioOrderCreateSuccessMessage}
            </Alert>
          </div>
        :
          props.openOrderConditionsAddDelete == 'add' ?
            
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption" color="green" display="block">
                    Will trigger on ORDER({props.order_key}) filled at price: ${roundNumberIfNeeded(priceAtFilledMoment, 8)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Exit (%)"
                    value={takeProfit}
                    onChange={handleChange(setTakeProfit)}
                    type="number"
                    variant="outlined"
                    margin="normal"
                    error={!!errors.takeProfit}
                    helperText={errors.takeProfit}
                  />
                  <Typography variant="caption" color="green" display="block">
                    Trigger Condition: Market Value is more or equal than ${roundNumberIfNeeded(takeProfitValue, 8)}
                  </Typography>
                  <Typography variant="caption" color="black" display="block">
                    Actions after trigger
                  </Typography>
                  <Typography variant="caption" color="green" display="block">
                    Cancel Stop Loss Order
                  </Typography>
                  <Typography variant="caption" color="green" display="block">
                    Open Exit Order -> Sell Stop Limit(1.01%) at price ${roundNumberIfNeeded(takeProfitSellStopValue, 8)}
                  </Typography>
                  <Typography variant="caption" color="green" display="block">
                    If Market Price is less than ${roundNumberIfNeeded(takeProfitSellStopValue, 8)} Cancel Exit Order and Reopen Stop Loss order again
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Stop Loss (%)"
                    value={stopLoss}
                    onChange={handleChange(setStopLoss)}
                    type="number"
                    variant="outlined"
                    margin="normal"
                    error={!!errors.stopLoss}
                    helperText={errors.stopLoss}
                  />
                  <Typography variant="caption" color="orange" display="block">
                    Trigger Condition: Market Value is less or equal than ${roundNumberIfNeeded(stopLossValue, 8)}
                  </Typography>
                  <Typography variant="caption" color="black" display="block">
                    Actions after trigger
                  </Typography>
                  <Typography variant="caption" color="orange" display="block">
                    Open Stop Loss Order -> Sell Stop Limit(1.01%) at price ${roundNumberIfNeeded(stopLossSellStopValue, 8)}
                  </Typography>
                  <Typography variant="caption" color="orange" display="block">
                    Will be ignored if Trailing Stop Order is set and activated (Market Price is more or equal than ${roundNumberIfNeeded(activationValue, 8)})
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={trailingStop} onChange={handleChange(setTrailingStop)} />}
                    label="Trailing Stop"
                  />
                </Grid>
                {trailingStop && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Activation (%)"
                        value={activation}
                        onChange={handleChange(setActivation)}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.activation}
                        helperText={errors.activation}
                      />
                      <Typography variant="caption" color="blue" display="block">
                        Trailing Stop Order Activation Condition: Market Value &gt;= ${roundNumberIfNeeded(activationValue, 8)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Trailing Delta (%)"
                        value={trailingDelta}
                        onChange={handleChange(setTrailingDelta)}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.trailingDelta}
                        helperText={errors.trailingDelta}
                      />
                      <Typography variant="caption" color="gray" display="block">
                        Trailing Delta Price: ${roundNumberIfNeeded(trailingDeltaValue, 8)}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          :
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
              <Typography
                variant="caption"
                color="error"
                component="h1"
                sx={{ fontSize: 'inherit', margin: 0 }}
              >
                Are you sure you want to delete Stop Loss & Exit for ORDER ({props.order_key})?
              </Typography>
            </Box>
        }
      </DialogContent>
      <>
        {/* Alert messages container */}
        <Box sx={{ width: '100%', mb: 2 }}>
          
          <Collapse in={portfolioOrderCreateValidationMessage !== ''}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setPortfolioOrderCreateValidationMessage('');
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {portfolioOrderCreateValidationMessage}
            </Alert>
          </Collapse>
        </Box>

        {/* Buttons container */}
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button onClick={handleClose} style={{ paddingLeft:20, color: '#666666' }}>Close</Button>
            {props.openOrderConditionsAddDelete == 'add' ?
              <Button onClick={handleSubmit} disabled={isSubmitDisabled} style={{paddingRight:35, color: isSubmitDisabled ? '#cccccc' : '#00cc00'}}>Submit</Button>
            :
              <Button onClick={handleDelete} disabled={isDeleteDisabled} style={{paddingRight:35, color: isDeleteDisabled ? '#cccccc' : '#cc0000'}}>Delete</Button>
            }
            
          </Box>
        </DialogActions>
      </>
    </Dialog>
  );
}
