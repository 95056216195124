import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";
import ConsumerHistoryGrid2 from "./ConsumerHistoryGrid2";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import LinearProgress from '@mui/material/LinearProgress';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import Conditions_image from '../assets/img/conditions.jpeg';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import GridView from "./GridView";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ModalAreYouSureWithParam from "./ModalAreYouSureWithParam";
import ControlledSwitches from './ControlledSwitches';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
  }),
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

let currentCommentToEdit = '';
let currentCommentId = -1;

export default function RouteAdminUserScenarioList(props) {

    const [theme] = useContext(ThemeContext);

    const [scenarioList, setScenarioList] = useState([]);
    const [userScenarioList, setUserScenarioList] = useState([]);
    
    const [errorMessage, setErrorMessage] = useState('');



  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();

  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_edit_user_scenario_list_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_edit_user_scenario_list error = ' + _data.msg);
      console.log('admin_edit_user_scenario_list code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      
    } else {
      
      setErrorMessage('')
      console.log('admin_edit_user_scenario_list was successfully sent');
      _api_admin_get_user_scenario_list()
    }
    
  }

  const _api_admin_edit_user_scenario_list = (id, scenario_id) => {

    let data = [
      {'key':'action', 'value': 'admin_edit_user_scenario_list'},
      {'key':'id', 'value': id},
      {'key':'scenario_id', 'value': scenario_id},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('LIVE: ', data)

    props._apiConnect(staticText.path_darts, data, _api_admin_edit_user_scenario_list_callback)
  }
  //////////////////////////////////////
  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_get_user_scenario_list_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_get_user_scenario_list error = ' + _data.msg);
      console.log('admin_get_user_scenario_list code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage(_data.msg)
      }
      
    } else {
      
        setErrorMessage('')
        setUserScenarioList(_data.data)
        setScenarioList(_data.scenarios)
      console.log('admin_get_user_scenario_list was successfully sent');
      //console.log('_data = ' + _data.data[0]);
      //setPortfolioListArr(_data.data)
      //_api_admin_get_coin_pair();
    }
    
  }

  const _api_admin_get_user_scenario_list = () => {

    let data = [
      {'key':'action', 'value': 'admin_get_user_scenario_list'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_admin_get_user_scenario_list_callback)
  }
  //////////////////////////////////////

  
  //////////////////////////////////////

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/AdminGridView', '/')
    } else {
      
        _api_admin_get_user_scenario_list()
    }
  }, []);

  const ScenarioSelecte = ({id, scenario_id}) => {

    const [scenario_id_local, setScenario_id_local] = React.useState(scenario_id)

    const handleChange = (event) => {
        //const newDataTmp = { ...dataTmp, timeframe_outcome: event.target.value };
        //setDataTmp(newDataTmp);
        setScenario_id_local(event.target.value)
        _api_admin_edit_user_scenario_list(id, event.target.value)
        console.log('id = ', id)
        console.log('event.target.value = ', event.target.value)
    };

    let is_deleted = true;
    let scenario_id_deleted = scenario_id_local
    scenarioList.map((val, key) => {

        if(scenario_id_local == val.id) {

            is_deleted = false
        }
    })
    if(is_deleted) {

        scenario_id_deleted = 0
    }
    // Function to map the numeric values to colors
    return (
        
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Scenario</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={scenario_id_deleted}
                label="Scenario"
                onChange={handleChange}
            >
                <MenuItem key={`selaaabbb_0`} value={0}>Not Subscribed</MenuItem>
                {scenarioList.map((val, key) => (
                    <MenuItem key={`selaaabbb_${key}`} value={val.id}>{val.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };


  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />

        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
          <div>User Scenario List</div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
            <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0, padding: 10}} >
                
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{paddingTop: 10}}>
                    {
                    userScenarioList.length > 0 ?
                            
                        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                            <Table sx={{ width:'100%' }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>User</StyledTableCell>
                                        <StyledTableCell align="right">Scenario</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                userScenarioList.map((value, key) => (
                                    <StyledTableRow 
                                        key={`scenarioList${key}`}
                                    >
                                        <StyledTableCell align="left">{value.username}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <ScenarioSelecte id={value.id} scenario_id={value.scenario_id} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                                }                        
                                </TableBody>
                            </Table>
                        </TableContainer>
                    :
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  );
}