import React, { useState, useContext, useEffect}  from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NativeSelect from '@mui/material/NativeSelect';
import { NumericFormat } from 'react-number-format';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {numberToAmericanFormat, roundNumberIfNeeded} from './functions';
import ButtonCustomStyle from "./ButtonCustomStyle";
import ModalWarning from "./ModalWarning";
import Alert from '@mui/material/Alert';

import { useHistory } from "react-router-dom";
import ControlledSwitches from './ControlledSwitches';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(data_type, data_id, name, month, pnl) {
  return { data_type, data_id, name, month, pnl };
}

export default function WatchListTable(props) {

  const [staticText] = useContext(ConstantsContext);

  const [theme] = useContext(ThemeContext);
  const [pnlList, setPnlList] = useState([])
  const [filterMonthsArr, setFilterMonthsArr] = useState([])
  const [filterMonth, setFilterMonth] = useState('')
  
  const [checked, setChecked] = React.useState(false);

  
  
  function formatDate(inputDate) {
    const [year, month] = inputDate.split('-');
    const date = new Date(year, month - 1);
    const monthName = date.toLocaleString('default', { month: 'short' });
    const formattedDate = `${monthName} ${year}`;
  
    return formattedDate;
  }

  useEffect(() => {

    console.log(props.watchListArr)
  }, []);

  let rows = [];
  let btcPnl = -1000;

  pnlList.map((val, key) => {
    if(checked || val.type == 'user' || (val.type == 'coin' && (val.id == 1 || val.id == 2))) {

      if(val.id == 1) {

        btcPnl = val.pnl
      }
      rows.push(
        createData(val.type, val.id, val.name, val.month, val.pnl),
      )
    }
  })

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const Indicator = ({ 
    low_average,
    high_average,
    current_price,
    support,
    resistance,
  }) => {

    low_average = Number(low_average) 
    high_average = Number(high_average) 
    current_price = Number(current_price) 
    support = Number(support) 
    resistance = Number(resistance) 
    // Calculate percentages
    const bottomPercentage = ((support - low_average) / (high_average - low_average)) * 100;
    const topPercentage = ((high_average - resistance) / (high_average - low_average)) * 100;
    const bluePercentage = ((current_price - low_average) / (high_average - low_average)) * 100;
  
    // Inline styles for the lines
    const bottomLineStyle = {
        width: '10px',
      height: `${bottomPercentage}%`,
      backgroundColor: '#F8B8B6',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      right:'50%'
    };
  
    const topLineStyle = {
        width: '10px',
      height: `${topPercentage}%`,
      backgroundColor: '#A9D08E',
      position: 'absolute',
      top: 0,
      left: '50%',
      right:'50%'
    };
  
    const blueLineStyle = {
      width: '10px',
      height: `3px`,
      backgroundColor: 'blue',
      position: 'absolute',
      bottom: `${(bluePercentage)}%`,
      left: '50%',
      right:'50%'
    };

    // Inline styles for the lines
    const LeftbottomLineStyle = {
        width: '10px',
      position: 'absolute',
      bottom: '-4px',
      left: 0,
      fontSize: 10,
      fontWeight: 'bold'
    };
  
    const LefttopLineStyle = {
        width: '10px',
      position: 'absolute',
      top: 0,
      left: 0,
      fontSize: 10,
      fontWeight: 'bold'
    };
  
    const LeftblueLineStyle = {
      width: '10px',
      position: 'absolute',
      bottom: `${(bluePercentage - 6)}%`,
      left: '25%',
      fontSize: 10,
      fontWeight: 'bold'
    };

    // Inline styles for the lines
    const RightbottomLineStyle = {
        width: '10px',
      position: 'absolute',
      bottom: '-4px',
      right: 0,
      fontSize: 10,
      fontWeight: 'bold'
    };
  
    const RighttopLineStyle = {
        width: '10px',
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: 10,
      fontWeight: 'bold'
    };
  
    const RightblueLineStyle = {
      width: '10px',
      position: 'absolute',
      bottom: `${(bluePercentage - 6)}%`,
      right: '25%',
      fontSize: 10,
      fontWeight: 'bold'
    };
  
    return (
      <div style={{ width: '100px', height: '100px', position: 'relative' }}>
        <div style={bottomLineStyle}></div>
        <div style={topLineStyle}></div>
        <div style={blueLineStyle}></div>
        <div style={LeftbottomLineStyle}>Low</div>
        <div style={LefttopLineStyle}>High</div>
        <div style={LeftblueLineStyle}>Now</div>
        <div style={RightbottomLineStyle}>${numberToAmericanFormat(low_average, 4)}</div>
        <div style={RighttopLineStyle}>${numberToAmericanFormat(high_average, 4)}</div>
        <div style={RightblueLineStyle}>${numberToAmericanFormat(current_price, 4)}</div>
      </div>
    );
  };

  const data = {
    low_average: 100,
    high_average: 200,
    current_price: 170,
    support: 140,
    resistance: 190,
  };

  const TimeFrameSelector = ({ color1, color2, color3, color4, color5 }) => {
    // Function to map the numeric values to colors
    const getColor = (value) => {
        value = Number(value)
      switch (value) {
        case -2:
          return '#FF0000';
        case -1:
          return '#FFA6A0';
        case 0:
          return '#808080';
        case 1:
          return '#25FF88';
        case 2:
          return '#00B050';
        default:
          return '#808080';
      }
    };
  
    return (
      <div style={{ width: '50px', textAlign: 'center', fontWeight: 'bold', fontSize: '10px' }}>
        <div style={{ backgroundColor: getColor(color1), width: '50px', height: '15px' }}>1M</div>
        <div style={{ backgroundColor: getColor(color2), width: '50px', height: '15px' }}>1W</div>
        <div style={{ backgroundColor: getColor(color3), width: '50px', height: '15px' }}>1D</div>
        <div style={{ backgroundColor: getColor(color4), width: '50px', height: '15px' }}>4H</div>
        <div style={{ backgroundColor: getColor(color5), width: '50px', height: '15px' }}>1H</div>
      </div>
    );
  };

  const SupportResistance = ({ is_support, is_resistance, is_support_missed, is_resistance_missed }) => {
    // Function to map the numeric values to colors
    return (
      
        is_support ? 
            <div style={{color:'#cc0000', fontWeight: 'bold'}}>Support</div>
        :
            is_resistance ? 
                <div style={{color:'#00cc00', fontWeight: 'bold'}}>Resistance</div>
            :
              is_support_missed ? 
                  <div style={{color:'#cc0000', fontWeight: 'bold'}}>Under Support</div>
              :
                is_resistance_missed ? 
                      <div style={{color:'#00cc00', fontWeight: 'bold'}}>Over Resistance</div>
                  :
                      <div style={{color:'#333333'}}>-</div>
    );
  };

  const SupportResistanceLevels = ({ support, resistance }) => {
    // Function to map the numeric values to colors
    return (
      
        <div style={{fontSize: 10, fontWeight: 'bold'}}>
            <div style={{color:'#666666'}}>Resistance Level: ${numberToAmericanFormat(resistance, 4)}</div>
            <div style={{color:'#666666'}}>Support Level: ${numberToAmericanFormat(support, 4)}</div>
        </div>
    );
  };

  

  
  const SupportResistanceTiming = ({ is_support, is_resistance }) => {
    // Function to map the numeric values to colors
    return (
      
        is_support ? 
            <div>1H/4H <div style={{color:'#cc0000'}}>LE</div></div>
        :
            is_resistance ? 
                <div>1H/4H <div style={{color:'#00cc00'}}>HE</div></div>
            :
                <div style={{color:'#333333'}}>-</div>
    );
  };

  const AveragePriceValue = ({ is_support, is_resistance, low_average, high_average }) => {
    // Function to map the numeric values to colors
    return (
      
        is_support ? 
            <div style={{color:'#cc0000'}}>${numberToAmericanFormat(low_average, 4)}</div>
        :
            is_resistance ? 
            <div style={{color:'#00cc00'}}>${numberToAmericanFormat(high_average, 4)}</div>
            :
                <div style={{color:'#333333'}}>-</div>
    );
  };

  const okChange = (_coin_pair_id, _coin_pair_name) => {

    //props.setCurrentHistoricData('current')
    changeHistory('/', '/DashboardCoin/' + _coin_pair_id + '/' + _coin_pair_name)
  }

  
  return (
    props.watchListArr.length > 0 ? 
      <div>
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table sx={{ maxWidth: 1200 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Coin</StyledTableCell>
                <StyledTableCell align="right">Dart</StyledTableCell>
                <StyledTableCell align="right">Level</StyledTableCell>
                <StyledTableCell align="right">High/Low Visual</StyledTableCell>
                <StyledTableCell align="right">High/Low Estimate</StyledTableCell>
                <StyledTableCell align="right">Average Price</StyledTableCell>
                <StyledTableCell align="right">Current Price</StyledTableCell>
                <StyledTableCell align="right">Range</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {props.watchListArr.map((row, key) => (
                    <StyledTableRow 
                        key={`watchListArr${key}`}
                    >
                        <StyledTableCell align="right"><div style={{cursor:'pointer', textDecoration: 'underline'}} onClick={() => okChange(row.coin_pair_id, row.coin_pair_name)}>{row.coin_pair_name}</div></StyledTableCell>
                        <StyledTableCell align="right">
                            <TimeFrameSelector color1={row._1m} color2={row._1w} color3={row._1d} color4={row._4h} color5={row._1h} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <div>
                                <SupportResistance is_support={row.is_support} is_resistance={row.is_resistance} is_support_missed={row.is_support_missed} is_resistance_missed={row.is_resistance_missed} />
                            </div>
                            <div>
                                <SupportResistanceLevels support={row.support} resistance={row.resistance} />
                            </div>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Indicator {...{
                                low_average: row.low_average,
                                high_average: row.high_average,
                                current_price: row.current_price,
                                support: row.support,
                                resistance:  row.resistance,
                            }} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <SupportResistanceTiming is_support={row.is_support} is_resistance={row.is_resistance} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <AveragePriceValue is_support={row.is_support} is_resistance={row.is_resistance} low_average={row.low_average} high_average={row.high_average} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            ${numberToAmericanFormat(row.current_price, 4)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            {roundNumberIfNeeded(row.range_percent, 2)}%
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
                
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    :
                            
      <div>
        <Alert severity="warning">No data to show</Alert>
      </div>
  );
}