import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import DashboardTopMenu from "./DashboardTopMenu";


export default function RouteAbout(props) {

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (

    <Grid container spacing={2} columns={12} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', margin: 0}} >
      <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      <div style={{alignItems: 'center', padding:20}}>
        <div style={{textAlign:'center'}}>
          <Typography variant="h3" gutterBottom component="div">
            About Us
          </Typography>
        </div>
        <div style={{maxWidth:900, alignItems: 'center', margin:'auto'}}>
          <div style={{fontWeight:'bold'}}>© CryptoMarketDart LLC 2023, Patent Pending, All rights reserved</div>
        <div style={{height:30}} />
        Welcome to CryptoMarketDarts, a unique community crypto research platform covering top crypto coins. Our platform brings together enthusiast analysts and professional analysts from all over the world who provide daily updates and opinions on CryptoMarketDarts.com. 
  <div style={{height:20}} />
  We are proud to offer "just-in-time" research that reflects and forecasts price trends for various crypto coins. Our platform is designed to service both retail and institutional investors, providing valuable insights and analysis that can help them make informed decisions in the fast-paced and ever-changing world of cryptocurrency.
  <div style={{height:20}} />
  At CryptoMarketDarts, we offer a unique opportunity for all types of investors to compare our sentiment to their own research. While we are not financial advisors and do not aim or claim to be, we have thousands of users worldwide who have found our research to be fundamental to their assessment of crypto market conditions.
  <div style={{height:20}} />
  CryptoMarketDarts is based in New Jersey, NJ, USA. For any inquiries, please email us at info@CryptoMarketDarts.com. Thank you for choosing CryptoMarketDarts for your crypto coin research needs.
        </div>
      </div>
    </Grid>
  );
}