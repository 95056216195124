import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "./index";
import styles from './st.css';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import axios from "axios";

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import RouteSignUp from "./components/RouteSignUp";
import RouteSignIn from "./components/RouteSignIn";
import RouteDashboardUser from "./components/RouteDashboardUser";
//import RouteDashboardConsumer from "./components/RouteDashboardConsumer";
import RouteDashboardConsumer2 from "./components/RouteDashboardConsumer2";
import RouteForgotPassword from "./components/RouteForgotPassword";
import RouteForgotPasswordConfirm from "./components/RouteForgotPasswordConfirm";

import RouteBuildPortfolio from "./components/RouteBuildPortfolio";
import RouteMyInfo from "./components/RouteMyInfo";
import RouteReferAFriend from "./components/RouteReferAFriend";
import RouteDashboardCoin from "./components/RouteDashboardCoin";
import RouteDashboardCoinEdit from "./components/RouteDashboardCoinEdit";
import RoutePortfolio from "./components/RoutePortfolio";
import RoutePortfolioUser from "./components/RoutePortfolioUser";


import RouteConsumerHistory from "./components/RouteConsumerHistory";

import RouteAbout from "./components/RouteAbout";
import RouteCareer from "./components/RouteCareer";
import RouteTerms from "./components/RouteTerms";
import RoutePrivacy from "./components/RoutePrivacy";
import RouteFeedback from "./components/RouteFeedback";
import RouteContact from "./components/RouteContact";
import RouteEarnMoney from "./components/RouteEarnMoney";
import RouteSiteTutorial from "./components/RouteSiteTutorial";
import RouteColumnsHelp from "./components/RouteColumnsHelp";

import FfffImg from './assets/img/ethereum-gold.png';

//////////ADMIN pages imports
import RouteAdminGridView from "./components/RouteAdminGridView";
//import RouteAdminScenarioBuilder from "./components/RouteAdminScenarioBuilder";
import RouteAdminScenarioBuilderAccordion from "./components/RouteAdminScenarioBuilderAccordion";


import RouteAdminContributorActivity from "./components/RouteAdminContributorActivity";
import RouteAdminUserGrowth from "./components/RouteAdminUserGrowth";
import RouteAdminSitesVisits from "./components/RouteAdminSitesVisits";
import RouteAdminViewsPerCoin from "./components/RouteAdminViewsPerCoin";
import RouteAdminInviteAFriendStats from "./components/RouteAdminInviteAFriendStats";
import RouteAdminCreateCoinPair from "./components/RouteAdminCreateCoinPair";
import RouteMySubscriptions from "./components/RouteMySubscriptions";

import BottomPart from "./components/BottomPart";
import LoadingAnimation from "./components/LoadingAnimation";
import LiveChatSupport from "./components/LiveChatSupport";
import RouteAdminUserScenarioList from './components/RouteAdminUserScenarioList';
import RouteAdminWatchlistSettings from './components/RouteAdminWatchlistSettings';



function App() {

  const [theme] = useContext(ThemeContext);
  //const [accountStage, setAccountStage, bc, rightNetworkId, connectWallet, signMessage, currentPath, loadSmartContract, _contract, user_serviceFnc] = useContext(ConnectWalletContext);
  const [staticText] = useContext(ConstantsContext);
  console.log("theme", theme);

  const [userEmail, setUserEmail] = useState('');
  const [userProfileType, setUserProfileType] = useState(1);
  
  //const [userPassword, setUserPassword] = useState('');

  const [signInErrorMessage, setSignInErrorMessage] = useState('');
  const [signUpErrorMessage, setSignUpErrorMessage] = useState('');
  const [forgotPasswordResetErrorMessage, setForgotPasswordResetErrorMessage] = useState('');
  const [forgotPasswordResetConfirmErrorMessage, setForgotPasswordResetConfirmErrorMessage] = useState('');
  const [forgotPasswordConfirm, setForgotPasswordConfirm] = useState(false);
  const [forgotPasswordConfirmChangePassword, setForgotPasswordConfirmChangePassword] = useState(false);
  
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  
  //////////////for DashboardCoinEdit default value
  const [myPortfolioListArr, setMyPortfolioListArr] = useState([]);
  

  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [userType, setUserType] = useState(0);
  const [userLogedIn, setUserLogedIn] = useState(false);

  const [loggined_token, setLoggined_token] = useState(localStorage.getItem('loggined_token') != null ? localStorage.getItem('loggined_token') : '');
  const [homepageAlertMessage, setHomepageAlertMessage] = useState('');

  const [myInfoSuccessMessage, setMyInfoSuccessMessage] = useState(false);

  //TEST
  const [_path, set_path] = useState(staticText.path + '/api/data_api.php');

  

  const [IP, setIP] = useState('');

  //setHomepageAlertMessage('You are blocked, please contact support@cyptodarts.com');
  //const [loggined_token, setLoggined_token] = useState(localStorage.getItem('loggined_token'));
  
  const [userDetails, setUserDetails] = useState(
    null
  );

  useEffect(() => {
    
    //console.log('useEffect doings...')
    //localStorage.setItem('myValueInLocalStorage', event.target.value);

    //localStorage.getItem('loggined_token') == null || localStorage.getItem('loggined_token') == '' ? setLoggined_token('') : setLoggined_token(localStorage.getItem('loggined_token'));
    
    //console.log('loggined_token = ' + localStorage.getItem('loggined_token'));
    //getIPData();
    _api_chech_user_token(loggined_token);
    
  }, []);

  /*const getIPData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setIP(res.data.IPv4)
  }*/

  

  function doSomeCoolStuff(...args) {
    //console.log('doSomeCoolStuff = ' + args[0].userProfileType);
    setUserType(args[0].userProfileType);
    if(args[0].userProfileType == 0) {
      <Redirect to='/DashboardUser' />
      //changeHistory('/', '/Privacy')
    } else {
      <Redirect to='/Dashboard' />
      //changeHistory('/', '/About')
    }
    
    //setUserLogedIn(true);
    //console.log(JSON.stringify(args[0]));
  }

  

  

  

  const _apiSignIn = (_data) => {
    //console.log('_apiSignIn');

    _api_login(_data);
  }

  const _apiLogOff = () => {
    //console.log('_apiLogOff');

    _api_logout();
  }

  const _apiForgotPasswordConfirm = (_data) => {
    //console.log('_apiForgotPasswordConfirm');
    _api_forgotpassword_newpassword(_data);
  }

  const _apiForgotPassword = (_data) => {
    //console.log('_apiForgotPassword');
    _api_forgotpassword_activationcode_request(_data);
  }

  const _apiSignUp = (_data) => {
    //console.log('_apiSignUp');
    _api_registration(_data);
  }

  const setMyInfoDeleteAccountFnc = () => {
    //console.log('do setMyInfoDeleteAccountFnc');
    _api_removeuser();
  }


  ////////////////////////////////////////chech_user_token
  function _api_chech_user_token_callback(_data) {

    if(_data.status_code != 2) {
      setUserDetails(
        {
          'loggined_token': '',
          'user_loged_in': false,
          'user_id': 0,
          'username': '',
          'usertype': 0,
          'is_admin': 0,
          'role': '',
          'email': '',
          'telegram': '',
          'youtube': '',
          'twitter': '',
          'is_blocked': 0,
        }
      )
      setLoggined_token('');
      //setUserLogedIn(false);
      //console.log('_api_chech_user_token_callback error = ' + _data.msg);
      <Redirect to="/" />
    } else {
      //console.log('signed in');
      //console.log('user_id = ' + _data.data.user_id);

      
      _api_get_my_user(_data.data.loggined_token);
    }
    
  }

  const _api_chech_user_token = (_loggined_token) => {
    //console.log('_api_chech_user_token data = ' + _data);
    //console.log('userProfileType = ' + _data['userProfileType']);
    //var data = {
    //  'email': 'sdsgsdsds'
    //};
    //data['action'] = 'vs_currencies';
    //let data = _data;

    let data = [
      {'key':'action', 'value': 'chech_user_token'},
      {'key':'loggined_token', 'value': _loggined_token}
    ]

    //console.log('_loggined_token = ' + _loggined_token);

    _apiConnect(staticText.path_user, data, _api_chech_user_token_callback);
  }

  ////////////////////////////////////////get_my_user
  function _api_get_my_user_callback(_data) {

    if(_data.status_code != 2) {
      setUserDetails(
        {
          'loggined_token': '',
          'user_loged_in': false,
          'user_id': 0,
          'username': '',
          'usertype': 0,
          'is_admin': 0,
          'role': '',
          'email': '',
          'telegram': '',
          'youtube': '',
          'twitter': '',
          'is_blocked': 0,
        }
      )

      if(_data.status_code == 5) {
        setHomepageAlertMessage('Your account has been locked, please contact us at info@ddd.com');
      } else {
        //setHomepageAlertMessage('_api_get_my_user_callback error = ' + _data.msg);
      }
      
      //console.log('_api_get_my_user_callback error = ' + _data.msg);
      //setHomepageAlertMessage(_data.msg);
      
      <Redirect  to="/" />
    } else {

      if(_data.data.is_blocked == 1) {
        setHomepageAlertMessage('Your account has been locked, please contact us at info@ddd.com');
        //console.log('Your account has been locked, please contact us at info@ddd.com')
      } else {

        setHomepageAlertMessage('');

        //if(userDetails.user_id == 0) {
          localStorage.setItem('loggined_token', _data.data.loggined_token);
          setLoggined_token(_data.data.loggined_token);
          setUserDetails(
            {
              'loggined_token': _data.data.loggined_token,
              'user_loged_in': true,
              'user_id': parseInt(_data.data.user_id),
              'username': _data.data.username,
              'usertype': parseInt(_data.data.usertype),
              'is_admin': parseInt(_data.data.is_admin),
              'role': _data.data.role,
              'email': _data.data.email,
              'telegram': _data.data.telegram,
              'youtube': _data.data.youtube,
              'twitter': _data.data.twitter,
              'is_blocked': parseInt(_data.data.is_blocked),
            }

          )
        //}
        //setUserLogedIn(true);
        
      }
      

    }
    
  }

  const _api_get_my_user = (_loggined_token) => {
    //console.log('_api_chech_user_token data = ' + _data);
    //console.log('userProfileType = ' + _data['userProfileType']);
    //var data = {
    //  'email': 'sdsgsdsds'
    //};
    //data['action'] = 'vs_currencies';
    //let data = _data;

    let data = [
      {'key':'action', 'value': 'get_my_user'},
      {'key':'loggined_token', 'value': _loggined_token}
    ]


    //console.log('_api_get_my_user _loggined_token = ' + _loggined_token);
    _apiConnect(staticText.path_user, data, _api_get_my_user_callback);
  }

  const _api_get_my_userFnc = (_loggined_token) => {
    _api_get_my_user(_loggined_token)
    setMyInfoSuccessMessage(true);
  }

  ////////////////////////////////////////login
  function _api_registration_callback(_data) {

    if(_data.status_code != 2) {
      setUserDetails(
        {
          'loggined_token': '',
          'user_loged_in': false,
          'user_id': 0,
          'username': '',
          'usertype': 0,
          'is_admin': 0,
          'role': '',
          'email': '',
          'telegram': '',
          'youtube': '',
          'twitter': '',
          'is_blocked': 0,
        }
      )
      if(_data.status_code == 5) {
        
        setSignUpErrorMessage(_data.msg);
        //setHomepageAlertMessage(_data.msg);
      } else {
        setSignUpErrorMessage('Technical error');
      }
      //console.log('_api_registration_callback error = ' + _data.msg);
      //console.log('_api_registration_callback status_code = ' + _data.status_code);
      <Redirect  to="/" />
    } else {

      
      setSignUpErrorMessage('');
      //if(_data.data.is_blocked == 1) {
        //console.log('you are blocked, please contact support@cyptodarts.com')
      //} else {

        //setUserType(_data.data.usertype);
        

        //console.log('user_id = ' + _data.data.user_id);
        //console.log('username = ' + _data.data.username);
        //console.log('loggined_token = ' + _data.data.loggined_token);

        //localStorage.setItem('loggined_token', _data.data.loggined_token);

        setLoggined_token(_data.data.loggined_token);
        _api_chech_user_token(_data.data.loggined_token);
        //setUserLogedIn(true);        
      //}
    }
  }

  const _api_registration = (_data) => {

    setUserEmail(_data.userEmail);
    setUserProfileType(_data.userProfileType);
    
    let data = [
      {'key':'action', 'value': 'registration'},
      {'key':'email', 'value': _data.userEmail},
      {'key':'password', 'value': _data.userPassword},
      {'key':'usertype', 'value': _data.userProfileType},
      {'key':'username', 'value': _data.userNickname},
      {'key':'telegram', 'value': _data.userTelegram},
      {'key':'youtube', 'value': _data.userYoutube},
      {'key':'twitter', 'value': _data.userTwitter}/*,
      {'key':'ip', 'value': IP}*/
    ]

    _apiConnect(staticText.path_user, data, _api_registration_callback);
  }

  ////////////////////////////////////////login
  function _api_login_callback(_data) {

    if(_data.status_code != 2) {
      setUserDetails(
        {
          'loggined_token': '',
          'user_loged_in': false,
          'user_id': 0,
          'username': '',
          'usertype': 0,
          'is_admin': 0,
          'role': '',
          'email': '',
          'telegram': '',
          'youtube': '',
          'twitter': '',
          'is_blocked': 0,
        }
      )
      //console.log('_api_login_callback error = ' + _data.msg);
      //console.log('_api_login_callback code = ' + _data.status_code);
      if(_data.status_code == 3) {
        
        setSignInErrorMessage(_data.msg);
        //setHomepageAlertMessage(_data.msg);
      } else if(_data.status_code == 101) {
        
        setSignInErrorMessage('Please complete your registration by choosing a plan');
        //setHomepageAlertMessage(_data.msg);
      } else {
        setSignInErrorMessage('Technical error');
      }
      
      
      <Redirect  to="/" />
    } else {

      localStorage.setItem('loggined_token', _data.data.loggined_token);

      setSignInErrorMessage('');
      if(_data.data.is_blocked == 1) {
        //setHomepageAlertMessage(_data.msg);
        //console.log('You are blocked, please contact support@cyptodarts.com')
      } else {



        setHomepageAlertMessage('');
        //setUserType(_data.data.usertype);
        

        //console.log('user_id = ' + _data.data.user_id);
        //console.log('username = ' + _data.data.username);
        //console.log('loggined_token = ' + _data.data.loggined_token);

        //localStorage.setItem('loggined_token', _data.data.loggined_token);

        setLoggined_token(_data.data.loggined_token);
        _api_chech_user_token(_data.data.loggined_token);
        //setUserLogedIn(true);   
      }
    }
  }

  const _api_login = (_data) => {

    let data = [
      {'key':'action', 'value': 'login'},
      {'key':'email', 'value': _data.userEmail},
      {'key':'password', 'value': _data.userPassword}
    ]

    setUserEmail(_data.userEmail);
    ///setUserPassword(_data.userPassword);

    _apiConnect(staticText.path_user, data, _api_login_callback);
  }

  ////////////////////////////////////////forgotpassword_activationcode_request 
  function _api_forgotpassword_activationcode_request_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('_api_forgotpassword_activationcode_request error = ' + _data.msg);
      //console.log('_api_forgotpassword_activationcode_request code = ' + _data.status_code);
      if(_data.status_code == 5) {
        
        setForgotPasswordResetErrorMessage(_data.msg);
      } else {
        setForgotPasswordResetErrorMessage('Technical error');
      }
      
      
      <Redirect  to="/" />
    } else {
      setForgotPasswordResetErrorMessage('');
      setForgotPasswordConfirm(true);
      //('_api_forgotpassword_activationcode_request email was successfully sent');


    }
    
  }

  const _api_forgotpassword_activationcode_request = (_data) => {

    setForgotPasswordEmail(_data.userEmail);
    let data = [
      {'key':'action', 'value': 'forgotpassword_activationcode_request'},
      {'key':'email', 'value': _data.userEmail}
    ]

    _apiConnect(staticText.path_user, data, _api_forgotpassword_activationcode_request_callback);
  }

  ////////////////////////////////////////forgotpassword_newpassword 
  function _api_forgotpassword_newpassword_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('_api_forgotpassword_newpassword error = ' + _data.msg);
      //console.log('_api_forgotpassword_newpassword code = ' + _data.status_code);
      if(_data.status_code == 5) {
        
        setForgotPasswordResetConfirmErrorMessage(_data.msg);
      } else {
        setForgotPasswordResetConfirmErrorMessage('Technical error');
      }
      
      
      <Redirect  to="/" />
    } else {
      setForgotPasswordResetConfirmErrorMessage('');
      setForgotPasswordConfirmChangePassword(true);
      //console.log('_api_forgotpassword_newpassword was successfull');


    }
    
  }

  const _api_forgotpassword_newpassword = (_data) => {

    let data = [
      {'key':'action', 'value': 'forgotpassword_newpassword'},
      {'key':'email', 'value': forgotPasswordEmail},
      {'key':'activation_code', 'value': _data.userConfirm},
      {'key':'password', 'value': _data.userPassword},
      {'key':'confirm_password', 'value': _data.userPasswordConfirm}
    ]

    _apiConnect(staticText.path_user, data, _api_forgotpassword_newpassword_callback);
  }
  ////////////////////////////////////////

  ////////////////////////////////////////logout
  function _api_logout_callback(_data) {

    if(_data.status_code != 2) {

      //console.log('_api_logout_callback error = ' + _data.msg);
    } else {

      setUserDetails(
        {
          'loggined_token': '',
          'user_loged_in': false,
          'user_id': 0,
          'username': '',
          'usertype': 0,
          'is_admin': 0,
          'role': '',
          'email': '',
          'telegram': '',
          'youtube': '',
          'twitter': '',
          'is_blocked': 0,
        }
      )
      setLoggined_token('');
      //console.log('_api_logout_callback success = ' + _data.msg);
      //localStorage.clear();
      _api_chech_user_token('');

    }
    
  }

  const _api_logout = () => {

    let data = [
      {'key':'action', 'value': 'logout'},
      {'key':'loggined_token', 'value': loggined_token}
    ]

    _apiConnect(staticText.path_user, data, _api_logout_callback);
  }

  ////////////////////////////////////////removeuser
  function _api_removeuser_callback(_data) {

    if(_data.status_code != 2) {

      //console.log('_api_removeuser_callback error = ' + _data.msg);
    } else {

      setUserDetails(
        {
          'loggined_token': '',
          'user_loged_in': false,
          'user_id': 0,
          'username': '',
          'usertype': 0,
          'is_admin': 0,
          'role': '',
          'email': '',
          'telegram': '',
          'youtube': '',
          'twitter': '',
          'is_blocked': 0,
        }
      )
      setLoggined_token('');
      //console.log('_api_removeuser_callback success = ' + _data.msg);
      //localStorage.clear();
      _api_chech_user_token('');
      setHomepageAlertMessage('Your account has been successfully removed.');

    }
    
  }

  const _api_removeuser = () => {

    let data = [
      {'key':'action', 'value': 'removeuser'},
      {'key':'loggined_token', 'value': loggined_token}
    ]

    _apiConnect(staticText.path_user, data, _api_removeuser_callback);
  }

  const _apiConnect = async (__path, data, callBackFnc) => {

    //const output = data.map(user);
    //console.log(output);
    let getStr = '';
    data.map((value, key) => {

      if(key == 0) {
        
        getStr = getStr + value.key + '=' + value.value;
      } else {

        getStr = getStr + '&' + value.key + '=' + value.value;
      }
    })

    //console.log('getStr = ' + getStr);

    //setLoadingAnimation(true);
    
    const request_str = __path + '?' + getStr;
    console.log('request_str = ', request_str);

    //console.log('ggggggggggggggggg', data[0].value);
    //if(data[0].value != 'set_analyse') {


    //


    
    await axios.get(request_str)
    .then(res => {

      callBackFnc(res.data);
      //setLoadingAnimation(false);
    })
    .catch(error => console.log(error));
    //}
  }

  return (
    <Router>
      <Grid container spacing={2} columns={12} style={{minHeight: '100vh', /* will cover the 100% of viewport */
 overflow: 'hidden',
 display: 'block',
 position: 'relative',
 justifyContent: 'center', alignItems: 'center',
 paddingBottom: 150, 
 backgroundColor:theme.background,
 color:theme.foreground}} direction="column" justifyContent="center" alignItems="center" >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {userDetails != null ? 
          <>
            {homepageAlertMessage != '' ? <Alert severity="error">{homepageAlertMessage}</Alert> : null}
            <Route path="/" exact component={() => <RouteSignIn userDetails={userDetails} _apiSignIn={_apiSignIn} signInErrorMessage={signInErrorMessage} userEmail={userEmail} _apiConnect={_apiConnect} />}>
              {userDetails.user_loged_in ? (<Redirect to='/Dashboard' />) : null}
            </Route>
              
              <Route path="/SignUp/:_promo"  component={() => <RouteSignUp bbb="withpromo" _apiSignUp={_apiSignUp} userDetails={userDetails} signUpErrorMessage={signUpErrorMessage} userEmail={userEmail} userProfileType={userProfileType} _apiConnect={_apiConnect} _path={_path} setUserDetails={setUserDetails} setLoggined_token={setLoggined_token} _api_chech_user_token={_api_chech_user_token} />}>
                {userDetails.user_loged_in ? (<Redirect to='/Dashboard' />) : null}
              </Route> 
              <Route path="/SignUp/"  component={() => <RouteSignUp bbb="nopromo" _apiSignUp={_apiSignUp} userDetails={userDetails} signUpErrorMessage={signUpErrorMessage} userEmail={userEmail} userProfileType={userProfileType} _apiConnect={_apiConnect} _path={_path} setUserDetails={setUserDetails} setLoggined_token={setLoggined_token} _api_chech_user_token={_api_chech_user_token} />}>
                {userDetails.user_loged_in ? (<Redirect to='/Dashboard' />) : null}
              </Route>         
            <Route path="/ForgotPassword"  component={() => <RouteForgotPassword _apiForgotPassword={_apiForgotPassword} forgotPasswordResetErrorMessage={forgotPasswordResetErrorMessage} />}>
              {userDetails.user_loged_in ? (<Redirect to='/Dashboard' />) : forgotPasswordConfirm ? <Redirect to='/ForgotPasswordConfirm' /> : null}
            </Route>
            <Route path="/ForgotPasswordConfirm"  component={() => <RouteForgotPasswordConfirm forgotPasswordEmail={forgotPasswordEmail} _apiForgotPasswordConfirm={_apiForgotPasswordConfirm} forgotPasswordResetConfirmErrorMessage={forgotPasswordResetConfirmErrorMessage} forgotPasswordConfirmChangePassword={forgotPasswordConfirmChangePassword} _apiConnect={_apiConnect} _path={_path}  />}>
              {userDetails.user_loged_in ? (<Redirect to='/Dashboard' />) : null}
            </Route>
            <Route path="/Dashboard"  component={() => <RouteDashboardConsumer2 userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} >
              {userDetails.user_loged_in ? null : <Redirect to='/' />}
            </Route>
            <Route path="/MySubscriptions"  component={() => <RouteMySubscriptions userDetails={userDetails} _api_get_my_user={_api_get_my_user} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} >
              {userDetails.user_loged_in ? null : <Redirect to='/' />}
            </Route>
            
            <Route path="/Portfolio" component={() => <RoutePortfolio userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/PortfolioUser/:_month/:_user_id" component={() => <RoutePortfolioUser userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/BuildPortfolio" component={() => <RouteBuildPortfolio userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/MyInfo"  component={() => <RouteMyInfo myInfoSuccessMessage={myInfoSuccessMessage} _api_get_my_userFnc={_api_get_my_userFnc} userDetails={userDetails} _apiLogOff={_apiLogOff} setMyInfoDeleteAccountFnc={setMyInfoDeleteAccountFnc} _apiConnect={_apiConnect} _path={_path} setUserDetails={setUserDetails} setLoggined_token={setLoggined_token} _api_chech_user_token={_api_chech_user_token} />} />
            <Route path="/ReferAFriend"  component={() => <RouteReferAFriend userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/DashboardCoin/:_id/:_cryptopairname"  component={() => <RouteDashboardCoin isIframe={false} userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/DashboardCoinEdit/:_id"  component={() => <RouteDashboardCoinEdit userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />

            <Route path="/ConsumerHistory/:_user_id/:_id/:_username/:_coinpairname"  component={() => <RouteConsumerHistory userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />


            
            <Route path="/AdminWatchlistSettings"  component={() => <RouteAdminWatchlistSettings userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminGridView"  component={() => <RouteAdminGridView userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminScenarioBuilder"  component={() => <RouteAdminScenarioBuilderAccordion userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminUserScenarioList"  component={() => <RouteAdminUserScenarioList userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminContributorActivity"  component={() => <RouteAdminContributorActivity userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminUserGrowth"  component={() => <RouteAdminUserGrowth userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminSitesVisits"  component={() => <RouteAdminSitesVisits userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminViewsPerCoin"  component={() => <RouteAdminViewsPerCoin userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminInviteAFriendStats"  component={() => <RouteAdminInviteAFriendStats userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
            <Route path="/AdminCreateCoinPair"  component={() => <RouteAdminCreateCoinPair userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />} />
          </>
          : null}
          
          

          <Route path="/About"  component={() => <RouteAbout userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />
          <Route path="/Career"  component={() => <RouteCareer userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />
          <Route path="/Terms"  component={() => <RouteTerms userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />
          <Route path="/Privacy"  component={() => <RoutePrivacy userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />
          <Route path="/Feedback"  component={() => <RouteFeedback userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />
          <Route path="/Contact"  component={() => <RouteContact userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />
          <Route path="/EarnMoney"  component={() => <RouteEarnMoney userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />
          <Route path="/SiteTutorial"  component={() => <RouteSiteTutorial userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />
          <Route path="/EarnMoneyHelp"  component={() => <RouteColumnsHelp userDetails={userDetails} _apiLogOff={_apiLogOff} _apiConnect={_apiConnect} _path={_path} />}  />

          
        </Grid>
        {userDetails != null ? 
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{paddingTop:150}}>
              <BottomPart user_loged_in={userDetails.user_loged_in} ddd="fff" />
              {/*userDetails.email != '' ? <LiveChatSupport name={userDetails.user_id} /> : null*/}
            </Grid>
            
        :
          null
        }
        
      </Grid>
      {/*{userDetails != null ? <div>usertype: {userDetails.usertype}</div> : null}
      {userDetails != null ? <div style={{fontSize:10}}>
      <div>userLogedIn: {userDetails.user_loged_in == true ? 'true' : 'false'}</div>
      <div>localStorage loggined_token: {localStorage.getItem('loggined_token')}</div>
      <div>state loggined_token: {loggined_token}</div>
          </div> : null}*/}
      <LoadingAnimation loadingAnimation={loadingAnimation} />
    </Router>
  );
}

export default App;
