import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import FormContactUs from "./FormContactUs";
import DashboardTopMenu from "./DashboardTopMenu";


export default function RouteContact(props) {


  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', margin: 0}} >
      <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      <div style={{alignItems: 'center', padding:20}}>
        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{fontSize:24, paddingTop:20}}>
                    <div style={{fontSize:25, fontStyle: 'italic'}}>
                        Contact Us
                    </div>
                </div>
                <div style={{paddingTop:20}}>
                    <FormContactUs userDetails={props.userDetails} _apiLogOff={props._apiLogOff} _apiConnect={props._apiConnect} _path={props._path} />
                </div>

            </Grid>
        </Grid>
      </div>
    </Grid>
  );
}