import React, { useState, useEffect } from 'react';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

export default function RouteReferAFriend(props) {

  

  const classes = useStyles();
  const theme = useTheme();

  const [portfolioListArr, setPortfolioListArr] = useState(['ADA-USD', 'BCH-USD', 'BTC-USD', 'DOGE-USD', 'ETH-USD', 'LINK-USD', 'LTC-USD', 'SHIB-USD', 'XLM-USD', 'XRP-USD'])
  const [myPortfolioListArr, setMyPortfolioListArr] = useState(['BTC-USD', 'ETH-USD'])
  const [buildYourPortfolioPressed, setBuildYourPortfolioPressed] = useState(false);
  const [referAFriendPressed, setReferAFriendPressed] = useState(false);
  const [myInfoPressed, setMyInfoPressed] = useState(false);

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/ReferAFriend', '/')
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')
    }
  }, []);
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{fontSize:24, paddingTop:20}}>
                <div style={{fontSize:25, fontStyle: 'italic'}}>
                    Share the news, invite others...
                </div>
            </div>
            <div>
                <FormReferAFriend userDetails={props.userDetails} _apiLogOff={props._apiLogOff} _apiConnect={props._apiConnect} _path={props._path} />
            </div>
            
        </Grid>
    </Grid>
  );
}