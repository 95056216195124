import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import ButtonCustomStyle from "./ButtonCustomStyle";
import {isMobile} from 'react-device-detect';

const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding:20,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1976D2',
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {

  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();

  const [userEmail, setUserEmail] = useState(props.userEmail);
  const [userPassword, setUserPassword] = useState('');

  const userEmailRef = useRef(null);
  const userPasswordRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(props.signInErrorMessage != null ? props.signInErrorMessage : '');

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const _apiSignIn = () => {

    let data = {};
    data['userEmail'] = userEmail;
    data['userPassword'] = userPassword;

    props._apiSignIn(data);
  }

  const keyPress = (e) => {
    
    if(e.keyCode == 13){
      e.preventDefault();
      _handleSubmit();
    }
  }

  

  const handleSubmit = (e) => {
    e.preventDefault();

    //console.log('value', e.target.value);
    _handleSubmit();
  };

  const _handleSubmit = () => {

    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(userEmail == '') {
      setErrorMessage("Email can't be empty");
    } else if ( !re.test(userEmail) ) {
      
      setErrorMessage("Email is invalid");
        // invalid email, maybe show an error to the user.
    } else if(userPassword == '') {
      setErrorMessage("Password can't be empty");
    } else if(userPassword.length < 6) {
      setErrorMessage("Password must be minimum 6 symbols");
    }

    if(userEmail != '' && re.test(userEmail) && userPassword != '' && userPassword.length >= 6) {

      //sendContactRequest();
      _apiSignIn();
    }
    

    
    //console.log('userEmail = ' + userEmail + ', userPassword = ' + userPassword);
  }


  return (
        <form className={classes.form}>
        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:400}}>
                    
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item>Email</Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          sx={theme.inputTextSx}
                          id="userEmail_id"
                          name="userEmail"
                          ref={userEmailRef}
                          placeholder="e.g. your email"
                          size="small"
                          style={{width:'100%'}}
                          onChange={event => setUserEmail(event.target.value)}
                          inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                          defaultValue={props.userEmail}
                          onKeyDown={keyPress}
                        />
                    </Grid>                    
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item>Password</Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          sx={theme.inputTextSx}
                          id="userPassword_id"
                          name="userPassword"
                          ref={userPasswordRef}
                          size="small"
                          style={{width:'100%'}}
                          type="password"
                          onChange={event => setUserPassword(event.target.value)}
                          inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                          onKeyDown={keyPress}
                        />
                    </Grid>
                    {errorMessage != '' ? <Grid item xs={12}>
                      <Alert severity="error">{errorMessage}</Alert>
                    </Grid> : (<div></div>)}
                    <Grid item xs={4} sm={4} md={4}>

                    </Grid>

                    <Grid container item xs={8} sm={8} md={8} justifyContent="flex-end">
                      <div>
                        <ButtonCustomStyle
                            changeHistory={null}
                            doFnc={handleSubmit}
                            changeHistoryFrom=''
                            changeHistoryTo=''
                            btnName='Sign In'                          
                        />
                      </div>
                    </Grid>
                    
                    <Grid item xs={4} sm={4} md={4}>

                    </Grid>
                    <Grid container item xs={8} sm={8} md={8} justifyContent="flex-end">
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div>
                          Don't have an account?
                        </div>
                        <div style={{paddingLeft:5, paddingTop:3}}>
                                {isMobile ? 
                                  typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask ? 
                                    <ButtonCustomStyle
                                        changeHistory={true}
                                        doFnc={null}
                                        changeHistoryFrom='/'
                                        changeHistoryTo='/SignUp'
                                        btnName='Register'                          
                                    />
                                  :
                                    <>
                                      {/*for mobile regular user sign up when user has Metamask...<a href={`https://metamask.app.link/dapp/${staticText.path}/SignUp`}>
                                        <ButtonCustomStyle
                                            changeHistory={null}
                                            doFnc={null}
                                            changeHistoryFrom=''
                                            changeHistoryTo=''
                                            btnName='Register'                          
                                        />
                                      </a>*/}
                                      <ButtonCustomStyle
                                          changeHistory={true}
                                          doFnc={null}
                                          changeHistoryFrom='/'
                                          changeHistoryTo='/SignUp'
                                          btnName='Register'                          
                                      />
                                    </>
                                :
                                  <ButtonCustomStyle
                                      changeHistory={true}
                                      doFnc={null}
                                      changeHistoryFrom='/'
                                      changeHistoryTo='/SignUp'
                                      btnName='Register'                          
                                  />
                                }
                          
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>

                    </Grid>
                    
                    <Grid container item xs={8} sm={8} md={8} justifyContent="flex-end">
                      <div style={{paddingTop:20}}>
                        <ButtonCustomStyle
                                changeHistory={true}
                                doFnc={null}
                                changeHistoryFrom='/'
                                changeHistoryTo='/ForgotPassword'
                                btnName='Forgot Password?'                          
                            />
                      </div>
                    </Grid>
                

        </Grid></form>
  );
}