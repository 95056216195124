import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ButtonCustomStyle from "./ButtonCustomStyle";

const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

export default function FormReferAFriend(props) {

  
  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };
  const [emailAddress, setEmailAddress] = useState('');
  const [allEmailAddress, setAllEmailAddress] = useState([]);
  const [emailSubject, setEmailSubject] = useState('Crypto Market Darts Invitation');
  const [emailMessage, setEmailMessage] = useState('Please join me on www.CryptoMarketDarts.com');
  const [ok, setOk] = useState(true);

  const [emailAddressErrorMessage, setEmailAddressErrorMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const addToBBCList = (_emailAddress) => {

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let validation_email = true;
    if(_emailAddress == '') {
      validation_email = false
      setEmailAddressErrorMessage("Email can't be empty");
    } 
    else if(!re.test(_emailAddress) ) {
      
      validation_email = false
      setEmailAddressErrorMessage("Email is invalid");
        // invalid email, maybe show an error to the user.
    }

    let idx = allEmailAddress.indexOf(_emailAddress);
    if (idx != -1) {
      validation_email = false
      setEmailAddressErrorMessage("Email already exists");
    }

    if(validation_email) {
      setErrorMessage("");
      setEmailAddressErrorMessage("");
      let arr = allEmailAddress;
      arr.push(_emailAddress);
      setAllEmailAddress(arr);
      setEmailAddress('');
      setOk(!ok);
    }
  }

  const deleteMe = (key) => {

    let arr = allEmailAddress;
    let ggg = arr.splice(key, 1)
    //console.log('arr.lenght = ' + arr.length)
    //console.log(ggg)
    setAllEmailAddress(arr);
    setOk(!ok);
  }
  const setReferAFriendPressedFnc = () => {
    
    let validation_email = true;
    //console.log('llllllllllllll = ' + allEmailAddress)
    if(allEmailAddress.length == 0) {
      validation_email = false;
      setErrorMessage("BCC list can't be empty");
    } else if(emailSubject == '') {
      validation_email = false;
      setErrorMessage("Subject can't be empty");
    } else if(emailMessage == '') {
      validation_email = false;
      setErrorMessage("Message can't be empty");
    }

    if(validation_email) {
      setErrorMessage("");
      setEmailAddressErrorMessage("");
      
      setOk(!ok);

      //console.log('fffff')

      _api_send_refer_a_friend();
    }
  }

  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_send_refer_a_friend_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('_api_send_refer_a_friend error = ' + _data.msg);
      //console.log('_api_send_refer_a_friend code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        
      }
      
    } else {
      
      setErrorMessage('')
      setEmailAddressErrorMessage("");
      setSuccessMessage('Successfully Sent');

      ///console.log('_api_send_refer_a_friend was successfully sent');
    }
  }

  const _api_send_refer_a_friend = () => {

    let email_list = allEmailAddress.toString();

    //console.log('loggined_token _api_admin_get_coin_pair' + props.userDetails.loggined_token);
    let data = [
      {'key':'action', 'value': 'refer_a_friend'},
      {'key':'email_list', 'value': email_list},
      {'key':'subject', 'value': emailSubject},
      {'key':'message', 'value': emailMessage},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_send_refer_a_friend_callback)
  }
  //////////////////////////////////////
  

  return (

        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:350}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:15, width:'100%', textAlign:'left'}}>
                        This is Your BCC List of People
                        </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <TextField
                        sx={theme.inputTextSx}
                        id="outlined-textarea"
                        placeholder="Type email address here"
                        size="small"
                        style={{width:'100%'}}
                        inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                        onChange={event => setEmailAddress(event.target.value)}
                        value={emailAddress}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item style={{fontSize:11, paddingTop:5, paddingBottom:5, backgroundColor:theme.infoPanelBackground, borderRadius: 10, cursor:'pointer'}} onClick={() => addToBBCList(emailAddress)}>Add to BCC List</Item>
                      </div>
                    </Grid> 
                    {
                    emailAddressErrorMessage != '' ? 
                      <Grid item xs={12}>
                        <Alert severity="warning">{emailAddressErrorMessage}</Alert>
                      </Grid> 
                    : null
                    }
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{height:160, overflowY: 'auto', border: '1px solid #cccccc', borderRadius: 5, padding:12, fontSize:14}}>
                        {
                        allEmailAddress.map((value, key) => {
                          return (
                            <div key={`allEmailAddress_${key}`} style={{display:'flex', paddingTop:5, textAlign:'left'}}>
                              <div style={{width:'100%'}}>
                                {value}
                              </div>
                              <div style={{textAlign:'right'}}>
                                <div style={{cursor:'pointer'}} onClick={() => deleteMe(key)}><DeleteForeverIcon /></div>
                              </div>
                            </div>
                          );
                        })
                        }
                      </div>
                    </Grid>                  
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                            Subject
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            sx={theme.inputTextSx}
                            id="outlined-textarea"
                            placeholder="Crypto Market Darts Invitation"
                            defaultValue="Crypto Market Darts Invitation"
                            size="small"
                            style={{width:'100%'}}
                            inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                            onChange={event => setEmailSubject(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                            Custom Message
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            sx={theme.inputTextSx}
                            id="outlined-textarea"
                            multiline
                            maxRows={3}
                            rows={3}
                            placeholder="Type your custom message here, otherwise 
                            generic message below will be sent.
                            Please join me on 	www.cryptomarketdarts.com"
                            defaultValue="Please join me on www.CryptoMarketDarts.com"
                            size="small"
                            style={{width:'100%'}}
                            inputProps={{style: {fontSize: 13, color:theme.foreground}}}
                            onChange={event => setEmailMessage(event.target.value)}
                        />
                    </Grid>
                    {errorMessage != '' ? <Grid item xs={12}>
                      <Alert severity="error">{errorMessage}</Alert>
                    </Grid> : (<div></div>)}
                    {successMessage != '' ? <Grid item xs={12}>
                      <Alert severity="success">Successfully Sent</Alert>
                    </Grid> : (<div></div>)}
                    <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
                      <div style={{width:350, textAlign:'right'}}>
                        <ButtonCustomStyle
                            changeHistory={null}
                            doFnc={setReferAFriendPressedFnc}
                            changeHistoryFrom=''
                            changeHistoryTo=''
                            btnName='Send'                          
                        />
                      </div>
                    </Grid>
                    
  
        </Grid>
  );
}