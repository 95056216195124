import React, { useState, useContext, useEffect}  from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NativeSelect from '@mui/material/NativeSelect';
import { NumericFormat } from 'react-number-format';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {roundNumberIfNeeded} from './functions';
import ButtonCustomStyle from "./ButtonCustomStyle";
import ModalWarning from "./ModalWarning";

import { useHistory } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(data_type, data_id, name, month, pnl, burned) {
  return { data_type, data_id, name, month, pnl, burned };
}

export default function PnlListView(props) {

  const [staticText] = useContext(ConstantsContext);

  const [theme] = useContext(ThemeContext);
  const [pnlList, setPnlList] = useState([])
  const [filterMonthsArr, setFilterMonthsArr] = useState([])
  const [filterMonth, setFilterMonth] = useState('')
  
  const [checked, setChecked] = React.useState(false);
  const [warningMessage, setWarningMessage] = useState('')
  const [openWarning, setOpenWarning] = useState(false);
  

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  

  const doPnlClick = (_month, _data_type, _data_id) => {

    if(_data_type == 'user') {
      //console.log('props.noSignedIn = ', props.noSignedIn)
      if(props.noSignedIn == null) {
        changeHistory('/', '/PortfolioUser/' + _month + '/' + _data_id)
      } else {
        //console.log('doit')
        setOpenWarning(true)
      }
      
      //console.log('doPnlClick: ', _month, _data_type, _data_id)
    }
    
  }

  const chooseMonthFncSelect = (e) => {

    setFilterMonth(e.target.value);
    _api_get_pnl_public(e.target.value)
    //console.log('chooseMonthFncSelect = ', e.target.value) 
  }

  ////////////////////////////////////////forgotpassword_activationcode_request 
  function _api_get_pnl_public_callback(_data) {

    //console.log('_api_get_pnl_public_callback _data = ', _data)
    if(_data.status_code != 2) {
      
      
    } else {
      
      
      setFilterMonthsArr(_data.monthsArray)
      if(filterMonth == '') {
        setFilterMonth(_data.monthsArray[0])
      }
      
      setPnlList(_data.data)
    }
    
  }

  const _api_get_pnl_public = (_month) => {

    let data = [
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'action', 'value': 'get_pnl_public'},
      {'key':'filterByMonthValue', 'value': _month}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_pnl_public_callback);
  }

  function formatDate(inputDate) {
    const [year, month] = inputDate.split('-');
    const date = new Date(year, month - 1);
    const monthName = date.toLocaleString('default', { month: 'short' });
    const formattedDate = `${monthName} ${year}`;
  
    return formattedDate;
  }

  useEffect(() => {
    
    //console.log('useEffect doings...')
    //localStorage.setItem('myValueInLocalStorage', event.target.value);

    //localStorage.getItem('loggined_token') == null || localStorage.getItem('loggined_token') == '' ? setLoggined_token('') : setLoggined_token(localStorage.getItem('loggined_token'));
    
    //console.log('loggined_token = ' + localStorage.getItem('loggined_token'));
    //getIPData();
    
    _api_get_pnl_public('');
    
  }, []);

  let rows = [];
  let btcPnl = -1000;

  pnlList.map((val, key) => {
    if(checked || val.type == 'user' || (val.type == 'coin' && (val.id == 1 || val.id == 2))) {

      if(val.id == 1) {

        btcPnl = val.pnl
      }
      rows.push(
        createData(val.type, val.id, val.name, val.month, val.pnl, val.burned != null ? val.burned : 0),
      )
    }
    
  })

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  
  return (
      filterMonthsArr.length > 0 ? 
      <>
      <div style={{paddingTop:10, paddingBottom:10, maxWidth: 400}}>
          <div style={{paddingTop:10, paddingBottom:10}}>
            {formatDate(filterMonth)} Analyst Virtual Portfolio Performance Dashboard
          </div> 
          <div>
            <span>
            <NativeSelect
              value={filterMonth}
              inputProps={{
                  name: 'month_name',
                  id: 'monthselect',
              }}
              style={{width:150, paddingLeft:10, backgroundColor:'#FFFFFF'}}
              onChange={chooseMonthFncSelect}
            >
              {
                  filterMonthsArr.map((value, key) => {
                      return (
                          <option key={`myMonth_${key}`} value={value}>{key == 0 ? 'Current Month' : key == 1 ? 'Last Month' : value}</option>
                      );
                  })
              }
            </NativeSelect>
            </span>
            <span style={{paddingLeft:10}}>
              <FormControlLabel control={<Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                style={{color:'#23A9F2'}}
              />} label="Show more coins" labelPlacement="start" />
              
            </span>
          </div>
      </div>
      {pnlList.length > 0 ? 
        <div>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table sx={{ maxWidth: 400 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Analyst / Benchmark</StyledTableCell>
                  <StyledTableCell align="right">Month</StyledTableCell>
                  <StyledTableCell align="right">MTD&nbsp;P&L %</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => (
                  <StyledTableRow 
                    key={`pnllist_${key}`} 
                    onClick={() => doPnlClick(row.month, row.data_type, row.data_id)} 
                    style={{cursor:row.data_type == 'user' ? 'pointer': 'default', textDecoration:row.data_type == 'user' ? 'underline': 'none', color:(row.data_type == 'user' && (row.pnl - btcPnl) > 10 && row.pnl >= 1 ? '#00cc00' : '#000000')}}
                  >
                    <StyledTableCell component="th" scope="row" style={{color:(row.data_type == 'user' && (row.pnl - btcPnl) > 10 && row.pnl >= 1 ? '#00cc00' : '#000000')}}>
                      {row.name} {(row.data_type == 'user' && (row.pnl - btcPnl) > 10 && row.pnl >= 1 && filterMonthsArr[0] != filterMonth ? <div style={{background:'#130C02', padding:3, color:'#EABE7B', width:65, textAlign:'center'}}>Reward Paid</div> : null)}
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{color:(row.data_type == 'user' && (row.pnl - btcPnl) > 10 && row.pnl >= 1 ? '#00cc00' : '#000000')}}>{row.month}</StyledTableCell>
                    <StyledTableCell align="right" style={{color:(row.data_type == 'user' && (row.pnl - btcPnl) > 10 && row.pnl >= 1 ? '#00cc00' : '#000000')}}>{row.burned == 1 ? '-99.9999' : roundNumberIfNeeded(row.pnl, 4)}%</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      :
        null
      }
      <ModalWarning open={openWarning} setOpenWarning={setOpenWarning} alertHeader="Info" alertMessage="Log in to see Analyst Virtual Portfolio details" />
    </>
    :
    null
  );
}