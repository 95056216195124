import * as React from 'react';
import Grid from '@mui/material/Grid';
import ForgotPasswordConfirm from "./ForgotPasswordConfirm";
import Typography from '@mui/material/Typography';
import homepage_image from '../assets/img/homepage_image.png';


export default function RouteForgotPasswordConfirm(props) {
  return (
    <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%'}} direction="row" justifyContent="center" alignItems="center" >
      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:90}} align="center" justify="center">
        <Typography variant="h5" gutterBottom component="div">
          We sent you an email with code. Please enter this code in the top box.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:60}}>
        <ForgotPasswordConfirm _apiConnect={props._apiConnect} _path={props._path} forgotPasswordEmail={props.forgotPasswordEmail} _apiForgotPasswordConfirm={props._apiForgotPasswordConfirm} forgotPasswordResetConfirmErrorMessage={props.forgotPasswordResetConfirmErrorMessage} forgotPasswordConfirmChangePassword={props.forgotPasswordConfirmChangePassword} />
      </Grid>
    </Grid>
  );
}