import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from '@mui/x-data-grid-generator';
const useStyles = makeStyles({
    root: {
      '& .super-app-theme--cell': {
        backgroundColor: 'rgba(224, 183, 60, 0.55)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._m2v': {
        backgroundColor: '#FF0000',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._m1v': {
        backgroundColor: '#FFA6A0',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._0v': {
        backgroundColor: '#808080',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._p1v': {
        backgroundColor: '#25FF88',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._p2v': {
        backgroundColor: '#00B050',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._c10v': {
        backgroundColor: '#cc0000',
        color: '#1a3e72',
        fontWeight: '600',
        fontSize:10,
      },
      '& .super-app._c20v': {
        backgroundColor: '#00cccc',
        color: '#1a3e72',
        fontWeight: '600',
        fontSize:10,

      },
    },
  });

  

export default function ConsumerHistoryGrid(props) {
    const classes = useStyles();

  return (
    <div style={{ height: 700, width: '100%' }} className={classes.root}>
      <DataGrid 
        wrap="wrap"
        rows={props.rows} 
        columns={props.columns}
        rowsPerPageOptions={[]}
        defaultCurrentPage={0}
        defaultPageSize={0}
        disableSelectionOnClick
        getCellClassName={(params) => {
            if (params.field === 'age') {
            return '';
            }
            return params.value >= 30 ? 'hot' : 'cold';
        }} 
      />
    </div>
  );
}