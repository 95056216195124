import React, { useState, useEffect, useContext } from 'react';
import { ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import GridView from "./GridView";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

export default function RouteAdminViewsPerCoin(props) {

  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();
  const theme = useTheme();

  const [adminViewsPerCoin, setAdminViewsPerCoin] = useState([]);

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/AdminViewsPerCoin', '/')
    } else {
      _api_get_consumer_coin_pair_click()
    }
  }, []);

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_consumer_coin_pair_click_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('get_consumer_coin_pair_click error = ' + _data.msg);
      console.log('get_consumer_coin_pair_click code = ' + _data.status_code);
      
    } else {
      
      console.log('get_consumer_coin_pair_click was successfully sent, data = ' + _data.data.length);

      let _rows = [];

  
      _data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1)
      _data.data.map((value, key) => {


          _rows.push({

              id: value.coin_pair_name,
              coin: value.coin_pair_name,
              _6hour: value.hour6,
              _12hour: value.hour12,
              _1day: value.day1,
              _7days: value.day7,
              _15days: value.day15,
              _30days: value.day30,
              _30dayaverage: value.day30/30,
          });
      })

    //setGetAnalyseAll(_data.data);
      //setRows(_rows);
      setAdminViewsPerCoin(_rows);

      //setGetAnalyseAll(_data.data);
      //setGetAnalyseAllCount(_data.count);
      //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      //setMyPortfolioListArr(_data.data)
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_get_consumer_coin_pair_click = (_coinPairId, _coinPairName) => {

    let data = [
      {'key':'action', 'value': 'get_consumer_coin_pair_click'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_like, data, _api_get_consumer_coin_pair_click_callback)
  }
  //////////////////////////////////////
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const columns = [
    { field: 'coin', headerName: 'Coin', width: 110, editable: false },
    {
      field: '_6hour',
      headerName: '6 Hours',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_12hour',
      headerName: '12 Hours',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_1day',
      headerName: '1 Day',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_7days',
      headerName: '7 Days',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_15days',
      headerName: '15 Days',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_30days',
      headerName: '30 Days',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_30dayaverage',
      headerName: '30 Day Average',
      type:'number',
      width: 130,
      editable: false,
    },
  ];

  
  

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <GridView columns={columns} rows={adminViewsPerCoin} />
        </Grid>
    </Grid>
  );
}