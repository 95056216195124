import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";
import ConsumerHistoryGrid2 from "./ConsumerHistoryGrid2";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import LinearProgress from '@mui/material/LinearProgress';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import Conditions_image from '../assets/img/conditions.jpeg';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import GridView from "./GridView";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ModalAreYouSureWithParam from "./ModalAreYouSureWithParam";
import ControlledSwitches from './ControlledSwitches';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
  }),
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

let currentCommentToEdit = '';
let currentCommentId = -1;

export default function RouteAdminWatchlistSettings(props) {

    const [theme] = useContext(ThemeContext);

    const [scenarioList, setScenarioList] = useState([]);
    const [userScenarioList, setUserScenarioList] = useState([]);
    
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    

    ////////////////////////////////////////////////////////////////Minutes
    const [dataTmp, setDataTmp] = React.useState({})

    /*
    {
        "id": "1",
        "support_minute": "240",
        "resistance_minute": "240",
        "under_support_minute": "240",
        "over_resistance_minute": "240"
    }
    */

    const [support_minute, setSupport_minute] = useState(240);
    const [resistance_minute, setResistance_minute] = useState(240);
    const [under_support_minute, setUnder_support_minute] = useState(240);
    const [over_resistance_minute, setOver_resistance_minute] = useState(240);

    const handleChange_support_minute = (event) => {
        const newDataTmp = { ...dataTmp, support_minute: event.target.value };
        setDataTmp(newDataTmp);
        setSupport_minute(event.target.value)
    };
    const handleChange_resistance_minute = (event) => {
        const newDataTmp = { ...dataTmp, resistance_minute: event.target.value };
        setDataTmp(newDataTmp);
        setResistance_minute(event.target.value)
    };
    const handleChange_under_support_minute = (event) => {
        const newDataTmp = { ...dataTmp, under_support_minute: event.target.value };
        setDataTmp(newDataTmp);
        setUnder_support_minute(event.target.value)
    };
    const handleChange_over_resistance_minute = (event) => {
        const newDataTmp = { ...dataTmp, over_resistance_minute: event.target.value };
        setDataTmp(newDataTmp);
        setOver_resistance_minute(event.target.value)
    };

    const doSave = () => {

        const newDataTmp = { 
            ...dataTmp, 
            support_minute: support_minute,
            resistance_minute: resistance_minute,
            under_support_minute: under_support_minute,
            over_resistance_minute: over_resistance_minute,
        };
        setDataTmp(newDataTmp);

        console.log('dataTmp = ', newDataTmp)
        _api_admin_edit_watchlist_settings(newDataTmp)
    }

    ////////////////////////////////////////////////////
////////////////////////////////////////_api_admin_set_coin_pair
function _api_admin_edit_watchlist_settings_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('_api_admin_edit_watchlist_settings error = ' + _data.msg);
      console.log('_api_admin_edit_watchlist_settings code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Watchlist Saved Successfully')
      console.log('_api_admin_edit_watchlist_settings was successfully sent');
      _api_admin_get_watchlist_settings()
    }
  }

  const _api_admin_edit_watchlist_settings = (_data) => {

    let data = [
      {'key':'action', 'value': 'admin_edit_watchlist_settings'},
      {'key':'data', 'value': btoa(JSON.stringify(_data))},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('LIVE: ', data)

    props._apiConnect(staticText.path_darts, data, _api_admin_edit_watchlist_settings_callback)
  }
  //////////////////////////////////////
  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_get_watchlist_settings_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_get_watchlist_settings error = ' + _data.msg);
      console.log('admin_get_watchlist_settings code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      
    } else {
      
        setDataTmp(_data.data)
        setSupport_minute(_data.data.support_minute)
        setResistance_minute(_data.data.resistance_minute)
        setUnder_support_minute(_data.data.under_support_minute)
        setOver_resistance_minute(_data.data.over_resistance_minute)
        
        console.log('admin_get_watchlist_settings was successfully sent = ', _data.data);
      //console.log('_data = ' + _data.data[0]);
      //setPortfolioListArr(_data.data)
      //_api_admin_get_coin_pair();
    }
    
  }

  const _api_admin_get_watchlist_settings = () => {

    let data = [
      {'key':'action', 'value': 'admin_get_watchlist_settings'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_admin_get_watchlist_settings_callback)
  }
  //////////////////////////////////////
    ////////////////////////////////////////////////////


  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();

  //////////////////////////////////////

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/AdminGridView', '/')
    } else {
      
        _api_admin_get_watchlist_settings()
    }
  }, []);
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };


  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />

        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
          <div>Watchlist Settings</div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
            <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0, padding: 10}} >
                
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{paddingTop: 10}}>

                            
                        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                            <Table sx={{ width:'100%' }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Support Minute</StyledTableCell>
                                        <StyledTableCell align="right">Resistance Minute</StyledTableCell>
                                        <StyledTableCell align="right">Under Support Minute</StyledTableCell>
                                        <StyledTableCell align="right">Over Resistance Minute</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow 
                                        key={`watchlist_settings`}
                                    >
                                        <StyledTableCell align="left">
                                            <TextField
                                                label="Support Minute"
                                                id="outlined-size-small"
                                                defaultValue={support_minute}
                                                size="small"
                                                value={support_minute}
                                                onChange={handleChange_support_minute}
                                            />    
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <TextField
                                                label="Resistance Minute"
                                                id="outlined-size-small"
                                                defaultValue={resistance_minute}
                                                size="small"
                                                value={resistance_minute}
                                                onChange={handleChange_resistance_minute}
                                            />    
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <TextField
                                                label="Under Support Minute"
                                                id="outlined-size-small"
                                                defaultValue={under_support_minute}
                                                size="small"
                                                value={under_support_minute}
                                                onChange={handleChange_under_support_minute}
                                            />    
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <TextField
                                                label="Over Resistance Minute"
                                                id="outlined-size-small"
                                                defaultValue={over_resistance_minute}
                                                size="small"
                                                value={over_resistance_minute}
                                                onChange={handleChange_over_resistance_minute}
                                            />    
                                        </StyledTableCell>
                                    </StyledTableRow>                      
                                </TableBody>
                            </Table>
                        </TableContainer>
                </Grid>
                {errorMessage != '' ? 
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{paddingTop: 10}}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                :
                    null
                }
                {successMessage != '' ? 
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{paddingTop: 10}}>
                        <Alert severity="success">{successMessage}</Alert>
                    </Grid>
                :
                    null
                }
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{paddingTop: 10}}>
                    <Button variant="contained" size="small" style={{width:'70%'}} onClick={() => doSave()}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  );
}