import React, { useState, useEffect } from 'react';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

export default function RouteDashboardUser(props) {

  

  const classes = useStyles();
  const theme = useTheme();

  const [myPortfolioListArr, setMyPortfolioListArr] = useState(['ADA-USD', 'BCH-USD', 'BTC-USD', 'DOGE-USD', 'ETH-USD', 'LTC-USD', 'SHIB-USD', 'XRP-USD'])



  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
      
      <DashboardTopMenu isDashboard="yes" _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', maxWidth:900, paddingBottom:10, backgroundColor:'#F2F2F2', borderRadius: 10}} >
            <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
              <div style={{fontSize:20}}>Averaged Trend Darts</div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
              <div style={{display: 'flex', alignItems: 'center', width:360, height:'100%'}}>
              
                <SelectLabels color="#00B050" name="Bullish - Strong" />
                <SelectLabels color="#25FF88" name="Bullish" />
                <SelectLabels color="#808080" name="Neutral" />
                <SelectLabels color="#FFA6A0" name="Bearish" />
                <SelectLabels color="#FF0000" name="Bearish - Strong" />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', maxWidth:900}} >
            
          
          {
            myPortfolioListArr.map((value, key) => {

              return (

                <Grid item xs={12} sm={6} md={4} lg={3} align="center" justify="center">
                  <div onClick={() => changeHistory('/', '/DashboardCoin')}>
                    <DartsDesign key={key} name={value} userDetails={props.userDetails} _apiConnect={props._apiConnect} _path={props._path} />
                  </div>
                </Grid>
                
              );
            })
          }
          </Grid>
        </Grid>
      </>
    </Grid>
  );
}