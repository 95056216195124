import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PortfolioList from "./PortfolioList";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import ModalAreYouSure from "./ModalAreYouSure";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonCustomStyle from "./ButtonCustomStyle";
import DashboardTopMenu from "./DashboardTopMenu";
import moment from 'moment';
import PaidIcon from '@mui/icons-material/Paid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import AreYouSureModal from "./AreYouSureModal";
const Web3 = require("web3");


//import '../App.css';




const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

const checkbox_label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding:20,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1976D2',
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {

  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  //let _refund_invoice_key = -1;
  //let _refund__userPlanType = -1;
  //let _refund__userPromotionalCode = '';
  //let _refund__pay = -1;

  const [_refund_invoice_key, set_refund_invoice_key] = useState(-1);
  const [_refund__userPlanType, set_refund__userPlanType] = useState(-1);
  const [_refund__userPromotionalCode, set_refund__userPromotionalCode] = useState('');
  const [_refund__pay, set_refund__pay] = useState(-1);
  

  let loadSmartContract_bool = true;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  let Subscribejson = require('../abis/Subscribe' + staticText.rightNetworkId + '.json');
  let plansCosts = [];
  plansCosts[30] = 20000000000000000;
  plansCosts[180] = 90000000000000000;
  plansCosts[360] = 120000000000000000;
  //const rightNetworkId = 4;
  const rightNetworkId = staticText.rightNetworkId;
  const [accountStage, setAccountStage] = useState('connect_to_wallet');
  const [connectedChanged, setConnectedChanged] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');
  const [bc, setBc] = useState({
    'account': '',
    'accountShort': '',
    'networkId': 0,
    'connectedWalletBool': false,
    'web3': '',
    'isSigned': false,
    'signature': '',
    'message': ''
  });

  const [_contract, setContract] = useState({
    'name': '',
    'owner': '',
    'refund_period': '',
    'contract_connection' : '',
    'user_service': {},
    'user_subscriptions': {},
    'expire_date': 0
  });
  //const [activeTheme, setActiveTheme] = useState("dark");

  const [payLoading, setPayLoading] = useState(false)
  const [loadingNFTMintTransactionHash, setLoadingNFTMintTransactionHash] = useState(false)
  const [refundLoading, setRefundLoading] = useState(false)
  const [loadingNFTRefundTransactionHash, setLoadingNFTRefundTransactionHash] = useState(false)
  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [loadingNFTWithdrawTransactionHash, setLoadingNFTWithdrawTransactionHash] = useState(false)
  
  const [prontoPerSignUp, setProntoPerSignUp] = useState(false)
  const [changeBool, setChangeBool] = useState(false);
  const [user_subscriptions, setUser_subscriptions] = useState({})
  const [userServices, setUserServices] = useState(0)
  
  const [influencerPromos, setInfluencerPromos] = useState({})
  const [influencerInvoicesInfo, setInfluencerInvoicesInfo] = useState({})
  const [copyToClipboardStr, setCopyToClipboardStr] = useState('Copy to clipboard');
  const [influencerWithdraws, setInfluencerWithdraws] = useState({})


  const connectWallet = async () => {
    console.log('connectWallet');
    if (window.ethereum) {

        console.log('connectWallet inside window.ethereum');
        const eth_requestAccounts = await window.ethereum.send('eth_requestAccounts').then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('eth_requestAccounts result = ', result)
            //_user_service = result;
          }
        )
        .catch(
          err => {
            console.log("eth_requestAccounts error occured", err)
            //setBalanceOf_res(String(err))
          }
        )

        window.ethereum.on('accountsChanged', handleAccountsChanged)
        window.ethereum.on('chainChanged', handleChainChanged)

        window.web3 = new Web3(window.ethereum);

        /////////////////////////////////////////////////////
        const accounts = await window.web3.eth.getAccounts()
        // Network ID
        const networkId = await window.web3.eth.net.getId()
        ///let _balance = 0;

        Promise.all(
          [ 
            eth_requestAccounts,
            accounts,
            networkId
          ])
          .then((values) => {

            if(accounts[0] != undefined) {

              console.log('account = ', accounts[0]);
              console.log('networkId = ', networkId);
    
              let _bc = {
                'account': accounts[0],
                'accountShort': accounts[0].substr(0, 5) + '...' + accounts[0].substr(39, 3),
                'networkId': networkId,
                'connectedWalletBool': true,
                'web3': window.web3
              }
    
              
    
              setBc(_bc);
    
              if(networkId != rightNetworkId) {
    
                setAccountStage('connect_to_mainnet')
                setErrorMessage(staticText.connect_to_mainnet);
              } else {
    
                console.log('blablabaaaaaaaa = ' + _bc.account)
                setErrorMessage("");
                if(loadSmartContract_bool) {

                  loadSmartContract_bool = false;
                  loadSmartContract(_bc);
                }
                
                //loadMyNFTsContract();
    
                //if(accountStage != 'logined') {
                if((localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '')) {
    
                  console.log('set logined')
                  setAccountStage('logined')
                } else {
    
                  setAccountStage('login')
                  
                }
                //}
                
                //signMessage();
                
              }
    
    
              
            } else {
    
              console.log('connect_to_wallet accounts[0] == undefined')
              setAccountStage('connect_to_wallet')
              setErrorMessage(staticText.connect_to_wallet);
            }
          }
        );
        
        

      return true;
    } else {
      setErrorMessage(staticText.connect_to_wallet);
      console.log('Please install MetaMask.')

      /*let _bc = bc;
      _bc.account = '';
      _bc.accountShort = '';
      _bc.networkId = 0;
      _bc.connectedWalletBool = false;
      _bc.isSigned = false;
      _bc.signature = '';
      _bc.message = '';
      setBc(_bc);*/
      setAccountStage('connect_to_wallet')
      setErrorMessage(staticText.connect_to_wallet);

      //setMetmaskError('Please install MetaMask.')
    }
    
    return false;
  }

  // For now, 'eth_accounts' will continue to always return an array
  const handleAccountsChanged = (accounts) => {

    console.log('handleAccountsChanged = ' + accounts)
    if (accounts.length === 0) {

      /*let _bc = bc;
      _bc.account = '';
      _bc.connectedWalletBool = false;
      setBc(_bc);*/
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.')
      setAccountStage('connect_to_wallet')
      //setMetmaskError('Please connect to MetaMask.')

    } else {

      localStorage.setItem('access_token', '');
      localStorage.setItem('refresh_token', '');
      //setAccountStage('login')

      console.log('handleAccountsChanged = ' + accounts[0])
      let _bc = bc;
      _bc.connectedWalletBool = true;
      _bc.account = accounts[0];
      _bc.accountShort = accounts[0].substr(0, 5) + '...' + accounts[0].substr(39, 3);
      setBc(_bc);
      console.log('_bc handleAccountsChanged', _bc)

      
    }
    connectWallet()
  }

  function handleChainChanged (chainId) {
    
      console.log('handleChainChanged = ' + chainId.substr(2))
      if (Number(bc.networkId) !== Number(chainId.substr(2))) {

        localStorage.setItem('access_token', '');
        localStorage.setItem('refresh_token', '');
    
        console.log('handleChainChanged different')
        let _bc = bc;
        _bc.networkId = Number(chainId.substr(2));
        setBc(_bc);
        console.log('_bc handleChainChanged', _bc)

        connectWallet()
      }
    }

    /////////////////////////////////////////load NFT contract and stuff
    const loadSmartContract = async (_bc) => {

      setContract({
        'name': '',
        'owner': '',
        'refund_period': '',
        'contract_connection' : '',
        'user_service': {},
        'user_subscriptions': {},
        'expire_date': 0
      });

      console.log(Subscribejson)
      console.log('bc.networkId', _bc.networkId)
        
      const networkData = Subscribejson.networks[_bc.networkId];

      console.log('networkData = ', networkData)
      if (networkData) {

        const NFT_par = new _bc.web3.eth.Contract(
          Subscribejson.abi,
          networkData.address
        );
        
        let _name = await NFT_par.methods.name().call();
        let _owner = await NFT_par.methods.owner().call();
        let _refund_period = await NFT_par.methods.refundPeriod().call();
        
        console.log(_name);
        Promise.all(
          [ 
            _name,
            _owner,
            _refund_period
          ])
          .then((values) => {

            

            let _nft_temp = _contract;

            _nft_temp.contract_connection = NFT_par;
            _nft_temp.name = _name.toString();
            _nft_temp.owner = _owner.toString();
            _nft_temp.refund_period = _refund_period.toString();
            setContract(_nft_temp);

            loadSmartContract_bool = true

            console.log('_nft_temp = ', _nft_temp);

            ///if(prontoPerSignUp) {

              //_apiSignUp();
            //}

            getUserSubscribesByAddressFnc();

            getInfluencerPromosFnc(_bc.account);
          }
        );
      } else {
        //this.setState({ contractDetected: false });
      }
    }

  const payCheckDiscountDo = async (pay, user_unique_id, _loginned_token) => {

    console.log(pay);
    console.log('userPromotionalCode = ', userPromotionalCode)
    if(userPromotionalCode != '' && userPromotionalCode != null) {

      await _contract.contract_connection.methods
        .discounts(userPromotionalCode.toLowerCase())
        .call()
        .then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('result = ', result)
            //_user_subscriptions = result;
            if(result.name == '' || result.deleted) {

              console.log('wrong discount')
              setErrorMessage(staticText.invalid_promo_code);
            } else {

              //setErrorMessage("");
              console.log('discount name: ' + result.name + ', discount percent: ' + result.percent)
              payDo(Math.floor(plansCosts[userPlanType] * (100 - result.percent) / 100), user_unique_id, _loginned_token)
            }
            //setUser_subscriptions(result)
          }
        )
        .catch(
          err => {
            //console.log("An error occured", err)
            //setBalanceOf_res(String(err))
          }
        )
    } else {

      payDo(plansCosts[userPlanType], user_unique_id, _loginned_token)
    }

    
  }

  const payDo = async (pay, user_unique_id, _loginned_token) => {

    let _transHash = '';
    //let user_unique_id = Math.floor(Math.random() * 1000000) + 1 ;

    setPayLoading(true)
    console.log('pay = ', pay);
    await _contract.contract_connection.methods
    .subscribeDeposit(userPlanType, userPromotionalCode.toLowerCase(), user_unique_id)
    .send({ from: bc.account, value: pay }).on('transactionHash', (hash) => {

      /////////////////////https://api-rinkeby.etherscan.io/api?module=proxy&action=eth_getTransactionByHash&txhash=0xb9d232f9a6bf0f7ef9ee21da468f737c8b06811e952515792f393049676d6320&apikey=HE4DBV8VK9VH27981I6BJQXZYAWWTS893F
        //setLoadingAnimation(true);
        console.log('subscribeDeposit transactionHash hash = ' + hash);
        _transHash = hash;
        setTransHash(hash);
        setLoadingNFTMintTransactionHash(true);
      }).once('error', (err) =>{
        //window.alert('Error')
        //setLoading(false);
        //handleLoadingClose();
        setErrorMessage(staticText.payment_denied);
        setPayLoading(false)
        console.log('Hohohohohohohohoho = ', bc.account)
        console.log('subscribeDeposit error' + err.message);
        setLoadingNFTMintTransactionHash(false);
        //setMint_res(String(err))
      }).once('receipt', () => { 
        //setLoading(false);
        console.log('subscribeDeposit receipt');
        setLoadingNFTMintTransactionHash(false);
      }).once("confirmation", () => {

        console.log('subscribeDeposit confirmation loggined_token = ' + _loginned_token);
        console.log('subscribeDeposit confirmation txhash = ' + _transHash);
        console.log('subscribeDeposit confirmation address = ' + bc.account);
        console.log('subscribeDeposit confirmation plan = ' + userPlanType);
        console.log('subscribeDeposit confirmation discount = ' + userPromotionalCode);
        console.log('subscribeDeposit confirmation price = ' + pay);
        setPayLoading(false)

        ///////////////////////////SIGNUP DOING AFTER PAYMENT CONFIRMS
        _contract.contract_connection.methods
          .user_service(user_unique_id)
          .call()
          .then(
            result => {
              
              //setBalanceOf_res(result)
              console.log('result = ', result)
              _api_payments(pay, _transHash, result.expire_date, _loginned_token);

              localStorage.setItem('_promo', '');
              setUserPromotionalCode('')
              setUserServices(result.expire_date)
              //_user_service = result;
              //_apiSignUp();


            }
          )
          .catch(
            err => {
              console.log("An error occured", err)
              //setBalanceOf_res(String(err))
            }
          )
        
        //getUserSubscribesByAddressFnc();
        //stopNFTMintFnc = true;
        console.log('subscribeDeposit confirmation');
        //props._api_get_project(props.projectId)
        setLoadingNFTMintTransactionHash(false);
      }).then(tx => {
        //do whatever you want with tx
        setPayLoading(false)
        setLoadingNFTMintTransactionHash(false);
      })
      .catch(e => {
          if (e.code === 4001){
            setPayLoading(false)
            setLoadingNFTMintTransactionHash(false);
              //user rejected the transaction
          } 
      });
  }

  ////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const classes = useStyles();

  const [loadProccess, setLoadProccess] = useState(false);

  const [userEmail, setUserEmail] = useState(props.myInfo == true ? props.userDetails.email : '');
  const [userPassword, setUserPassword] = useState('');
  const [userProfileType, setUserProfileType] = useState(props.myInfo == true ? props.userDetails.usertype : props.userProfileType);
  const [userPlanType, setUserPlanType] = useState(30);
  const [userNickname, setUserNickname] = useState(props.myInfo == true ? props.userDetails.username : '');
  const [userTelegram, setUserTelegram] = useState(props.myInfo == true ? props.userDetails.telegram : '');
  const [userYoutube, setUserYoutube] = useState(props.myInfo == true ? props.userDetails.youtube : '');
  const [userTwitter, setUserTwitter] = useState(props.myInfo == true ? props.userDetails.twitter : '');

  const [userPromotionalCode, setUserPromotionalCode] = useState(localStorage.getItem('_promo') != '' && localStorage.getItem('_promo') != null ? localStorage.getItem('_promo') : '');

  

  const [userAgree, setUserAgree] = useState(false);
  const [userRefundAgree, setUserRefundAgree] = useState(false);
  const [userWithdrawAgree, setUserWithdrawAgree] = useState(false);
  
  

  const userEmailRef = useRef(null);
  const userPasswordRef = useRef(null);
  
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessageRefund, setErrorMessageRefund] = useState('');
  const [errorMessageWithdraw, setErrorMessageWithdraw] = useState('');
  
  
  
  //const [signUpErrorMessage, setSignUpErrorMessage] = useState('');

  const [data, setData] = useState({
    'action': 'vs_currencies'
  });

  const [openBtnPressed, setOpenBtnPressed] = useState(false);

  const [transHash, setTransHash] = useState('');
  const [logginedTokenTemporary, setLogginedTokenTemporary] = useState('')

  const [op_cl, setOp_cl] = useState(false);
  const [op_cl_refund, setOp_cl_refund] = useState(false);
  const [op_cl_withdraw, setOp_cl_withdraw] = useState(false);
  

  useEffect(() => {

    let isSubscribed = true

    setOpenBtnPressed(openBtnPressed);
    if(!props.myInfo) {

      if(isSubscribed) {
        connectWallet();
      }      
      //connectWallet()
    }

    
    return () => isSubscribed = false
  }, []);


  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const changeUserProfileType = (val) => {
    //console.log(val);
    setUserProfileType(val);
  }

  const changeUserPlanType = (val) => {
    console.log(val);
    setUserPlanType(val);
  }

  const _apiSignUp = () => {

    setOp_cl(true)
    //payCheckDiscountDo(userPlanType, props.userDetails.user_id, props.userDetails.loggined_token)
  }

  const doAfterAreYouSure = () => {

    payCheckDiscountDo(userPlanType, props.userDetails.user_id, props.userDetails.loggined_token)
  }
  //
  

  function _api_payments_callback(_data) {

    //setLoadProccess(false)

    if(_data.status_code != 2) {

      setErrorMessage(_data.msg);
    } else {

      setErrorMessage('');
      console.log('_api_payments_callback success');

      getUserSubscribesByAddressFnc();
      props._api_get_my_user(_data.data.loggined_token)
    }
  }

  const _api_payments = (pay, _transHash, expire_date, _loginned_token) => {
    //setLoadProccess(true)
    
    let data = [
      {'key':'action', 'value': 'payment_add'},
      {'key':'loggined_token', 'value': _loginned_token},
      {'key':'txhash', 'value': _transHash},
      {'key':'address', 'value': bc.account},
      {'key':'plan', 'value': userPlanType},
      {'key':'discount', 'value': userPromotionalCode},
      {'key':'price', 'value': pay},
      {'key':'expire_date', 'value': expire_date}
    ]

    props._apiConnect(staticText.path_user, data, _api_payments_callback);
  }
  /////////////////////////

  

  const handleSubmit = (e) => {
    e.preventDefault();

      // don't remember from where i copied this code, but this works.
      if(!payLoading) {

        _handleSubmit();
      }
  };

  const _handleSubmit = () => {

    //_connectWallet();
    console.log('_handleSubmit')

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!userAgree) {
      setErrorMessage(staticText.agree);
    } else if(accountStage == 'connect_to_wallet' && userProfileType != 1) {
      setErrorMessage(staticText.connect_to_wallet);
      console.log('iharke Please connect to wallet')
      //connectWallet()
    } else if(accountStage == 'connect_to_mainnet' && userProfileType != 1) {
      setErrorMessage(staticText.connect_to_mainnet);
    } else if(accountStage == 'login' && userProfileType != 1) {
      //setErrorMessage("logined");
      setErrorMessage("");
    } else {
      console.log('xuy chgitem')
    }

    if(userAgree) {

      
      //sendContactRequest();
      if(userProfileType == 1) {
        //setProntoPerSignUp(false)
        setErrorMessage("");
        _apiSignUp();
        
      } else {

        //setProntoPerSignUp(true)
        if(accountStage == 'login') {
          setErrorMessage("");

          _apiSignUp();

          
          //setErrorMessage("check Smart Contract now and pay: " + accountStage);
          /////////////check Smart Contract now and pay
        } else {

          connectWallet()
          console.log('Booooooooooooooooooooooooooo not login yet????')
        }
        
      }
      
    } else {

      //setProntoPerSignUp(false)
    }

    //console.log('userEmail = ' + userEmail + ', userPassword = ' + userPassword);
  }

  /////////////////////////////////////////////<REFUND>//////////////////////////////////////////////////////////<REFUND>

  const _handleRefund = (invoice_key, _userPlanType, _userPromotionalCode, _pay) => {

    if(!refundLoading) {

      //_connectWallet();
      console.log('_handleRefund')

      if(!userRefundAgree) {
        setErrorMessageRefund(staticText.agree_refund);
      }  else {
        console.log('xuy chgitem')
      }

      if(userRefundAgree) {

          if(accountStage == 'login') {
            setErrorMessageRefund("");

            set_refund_invoice_key(invoice_key)
            set_refund__userPlanType(_userPlanType)
            set_refund__userPromotionalCode(_userPromotionalCode)
            set_refund__pay(_pay)

            //_refund_invoice_key = invoice_key;
            //_refund__userPlanType = _userPlanType;
            //_refund__userPromotionalCode = _userPromotionalCode;
            //_refund__pay = _pay;

            //console.log('_refund_invoice_key = ', _refund_invoice_key)
            //console.log('_refund__userPlanType = ', _refund__userPlanType)
            //console.log('_refund__userPromotionalCode = ', _refund__userPromotionalCode)
            //console.log('_refund__pay = ', _refund__pay)
            setOp_cl_refund(true);
            //refundDo(invoice_key, _userPlanType, _userPromotionalCode, _pay)
            
          } else {

            connectWallet()
            console.log('Booooooooooooooooooooooooooo not login yet????')
          }
      }

      //console.log('userEmail = ' + userEmail + ', userPassword = ' + userPassword);
    }
    
  }

  function _api_refund_callback(_data) {

    //setLoadProccess(false)

    if(_data.status_code != 2) {

      setErrorMessageRefund(_data.msg);
    } else {

      setErrorMessageRefund('');
      console.log('_api_refund_callback success, time = ' + _data.time + ', sql = ' + _data.sql);

      getUserSubscribesByAddressFnc();

      props._api_get_my_user(_data.data.loggined_token)
    }
  }
  
  const _api_refund = (invoice_key, _transHash, expire_date, _loginned_token, _userPlanType, _userPromotionalCode, _pay) => {
    //setLoadProccess(true)
    
    let data = [
      {'key':'action', 'value': 'payment_refund'},
      {'key':'loggined_token', 'value': _loginned_token},
      {'key':'txhash', 'value': _transHash},
      {'key':'address', 'value': bc.account},
      {'key':'plan', 'value': _userPlanType},
      {'key':'discount', 'value': _userPromotionalCode},
      {'key':'price', 'value': _pay},
      {'key':'invoice_key', 'value': invoice_key},
      {'key':'expire_date', 'value': expire_date}
    ]

    console.log('_api_refund data = ', data)

    props._apiConnect(staticText.path_user, data, _api_refund_callback);
  }
  /////////////////////////

  const refundDo = async (invoice_key, _userPlanType, _userPromotionalCode, _pay) => {

    let _transHash = '';

    console.log('refundDo invoice_key = ', invoice_key);
    setRefundLoading(true)
    await _contract.contract_connection.methods
    .refund(invoice_key, props.userDetails.user_id)
    .send({ from: bc.account }).on('transactionHash', (hash) => {
        _transHash = hash;
        //setLoadingAnimation(true);
        console.log('subscribeDeposit transactionHash hash = ' + hash);
        setLoadingNFTRefundTransactionHash(true);
      }).once('error', (err) =>{
        //window.alert('Error')
        //setLoading(false);
        //handleLoadingClose();
        setErrorMessageRefund(staticText.refund_denied);
        setRefundLoading(false)
        console.log('subscribeDeposit error' + err.message);
        setLoadingNFTRefundTransactionHash(false);
        //setMint_res(String(err))
      }).once('receipt', () => { 
        //setLoading(false);
        console.log('subscribeDeposit receipt');
        setLoadingNFTRefundTransactionHash(false);
      }).once("confirmation", () => {



        setRefundLoading(false)
        getUserSubscribesByAddressFnc();

        
        ////////////////////////////////////////////////////////////////////////////////////////////
        console.log('subscribeDeposit confirmation loggined_token = ' + props.userDetails.loggined_token);
        console.log('subscribeDeposit confirmation txhash = ' + _transHash);
        console.log('subscribeDeposit confirmation address = ' + bc.account);
        console.log('subscribeDeposit confirmation invoice_key = ' + invoice_key);

        ///////////////////////////SIGNUP DOING AFTER PAYMENT CONFIRMS
        _contract.contract_connection.methods
          .user_service(props.userDetails.user_id)
          .call()
          .then(
            result => {
              
              //setBalanceOf_res(result)
              console.log('result = ', result)
              
              _api_refund(invoice_key, _transHash, result.expire_date, props.userDetails.loggined_token, _userPlanType, _userPromotionalCode, _pay)
              //_api_refund(invoice_key, _transHash, result.expire_date, props.userDetails.loggined_token);
              
              //_user_service = result;
              //_apiSignUp();
              setUserServices(result.expire_date)

            }
          )
          .catch(
            err => {
              console.log("An error occured", err)
              //setBalanceOf_res(String(err))
            }
          )
          ///////////////////////////////////////////////////////////////////////////////////////////////////



        //stopNFTMintFnc = true;
        console.log('subscribeDeposit confirmation');
        //props._api_get_project(props.projectId)
        setLoadingNFTRefundTransactionHash(false);
      }).then(tx => {
        //do whatever you want with tx
        setRefundLoading(false)
        setLoadingNFTRefundTransactionHash(false);
      })
      .catch(e => {
          if (e.code === 4001){
            setRefundLoading(false)
            setLoadingNFTRefundTransactionHash(false);
              //user rejected the transaction
          } 
      });
  }

  /////////////////////////////////////////////</REFUND>//////////////////////////////////////////////////////////</REFUND>

  const getUserSubscribesByAddressFnc = async () => {

    await _contract.contract_connection.methods
        .getUserSubscribesByAddress(props.userDetails.user_id)
        .call()
        .then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('result getUserSubscribesByAddress = ', result)
            //_user_subscriptions = result;
            setUser_subscriptions(result)

            /////////////////////////load user services
            getUserServiceFnc()
          }
        )
        .catch(
          err => {
            //console.log("An error occured", err)
            //setBalanceOf_res(String(err))
          }
        )
  }

  const getUserServiceFnc = async () => {

    await _contract.contract_connection.methods
        .user_service(props.userDetails.user_id)
        .call()
        .then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('result getUserServiceFnc = ', result)
            //_user_subscriptions = result;
            setUserServices(result.expire_date)

          }
        )
        .catch(
          err => {
            //console.log("An error occured", err)
            //setBalanceOf_res(String(err))
          }
        )
  }

  const getInfluencerPromosFnc = async (_account) => {

    console.log('_account getInfluencerPromosFnc = ', _account)
    await _contract.contract_connection.methods
        .getInfluencerPromos(_account)
        .call({from:_account})
        .then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('result getInfluencerPromos = ', result)
            //_user_subscriptions = result;
            setInfluencerPromos(result)

            /////////////////////////load user services
            //getUserServiceFnc()
            getInfluencerInvoicesInfoFnc(_account)
          }
        )
        .catch(
          err => {
            console.log("An error occured getInfluencerPromosFnc", err)
            //setBalanceOf_res(String(err))
          }
        )
  }

  const getInfluencerInvoicesInfoFnc = async (_account) => {

    console.log('_account getInfluencerPromosFnc = ', _account)
    await _contract.contract_connection.methods
        .getInfluencerInvoicesInfo(_account)
        .call({from:_account})
        .then(
          result => {
            
            //setBalanceOf_res(result)
            setInfluencerInvoicesInfo(result)
            console.log('result getInfluencerInvoicesInfo = ', result)
            //_user_subscriptions = result;
            //setInfluencerPromos(result)

            /////////////////////////load user services
            //getUserServiceFnc()
            //getInfluencerInvoicesInfoFnc(_account)
            getInfluencerWithdrawsFnc(_account);
          }
        )
        .catch(
          err => {
            console.log("An error occured getInfluencerPromosFnc", err)
            //setBalanceOf_res(String(err))
          }
        )
  }

  ///////////////////////////////////<Withdraw>

  const getInfluencerWithdrawsFnc = async (_account) => {

    console.log('_account getInfluencerWithdrawsFnc = ', _account)
    await _contract.contract_connection.methods
        .getInfluencerWithdraws(_account)
        .call({from:_account})
        .then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('result getInfluencerWithdraws = ', result)
            //_user_subscriptions = result;
            setInfluencerWithdraws(result)
          }
        )
        .catch(
          err => {
            console.log("An error occured getInfluencerWithdrawsFnc", err)
            //setBalanceOf_res(String(err))
          }
        )
  }

  const _handleWithdraw = () => {

    if(!withdrawLoading) {

      //_connectWallet();
      console.log('_handleWithdraw')

      if(!userWithdrawAgree) {
        setErrorMessageWithdraw(staticText.agree);
      }  else {
        console.log('xuy chgitem')
      }

      console.log('_handleWithdraw bheeee = ' + userWithdrawAgree + ', staticText.agree = ' + staticText.agree)
      if(userWithdrawAgree) {

          if(accountStage == 'login') {
            setErrorMessageWithdraw("");
            setOp_cl_withdraw(true)
            //withdrawDo()
            
          } else {

            connectWallet()
            console.log('_handleWithdraw Booooooooooooooooooooooooooo not login yet????')
          }
      }

      //console.log('userEmail = ' + userEmail + ', userPassword = ' + userPassword);
    }
    
  }
  
  const withdrawDo = async () => {

    let _transHash = '';

    setWithdrawLoading(true)
    await _contract.contract_connection.methods
    .withdrawByInfluencer()
    .send({ from: bc.account }).on('transactionHash', (hash) => {
        _transHash = hash;
        //setLoadingAnimation(true);
        console.log('withdrawByInfluencer transactionHash hash = ' + hash);
        setLoadingNFTWithdrawTransactionHash(true);
      }).once('error', (err) =>{
        //window.alert('Error')
        //setLoading(false);
        //handleLoadingClose();
        setErrorMessageWithdraw(staticText.withdraw_denied);
        setWithdrawLoading(false)
        console.log('subscribeDeposit error' + err.message);
        setLoadingNFTWithdrawTransactionHash(false);
        //setMint_res(String(err))
      }).once('receipt', () => { 
        //setLoading(false);
        console.log('subscribeDeposit receipt');
        setLoadingNFTWithdrawTransactionHash(false);
      }).once("confirmation", () => {

        getInfluencerInvoicesInfoFnc(bc.account)

        setWithdrawLoading(false)
        
        console.log('withdrawByInfluencer confirmation');
        //props._api_get_project(props.projectId)
        setLoadingNFTWithdrawTransactionHash(false);
      }).then(tx => {
        //do whatever you want with tx
        setWithdrawLoading(false)
        setLoadingNFTWithdrawTransactionHash(false);
      })
      .catch(e => {
          if (e.code === 4001){
            setWithdrawLoading(false)
            setLoadingNFTWithdrawTransactionHash(false);
              //user rejected the transaction
          } 
      });
  }

  const setCopyToClipboardStrTimeout = (txt) => {

    navigator.clipboard.writeText(txt)
    setCopyToClipboardStr('Copied!')

    const timer = setTimeout(() => {
      setCopyToClipboardStr('Copy to clipboard')
    }, 2000);
    return () => clearTimeout(timer);
  }

  const doAfterAreYouSureRefund = () => {

    refundDo(_refund_invoice_key, _refund__userPlanType, _refund__userPromotionalCode, _refund__pay)
  }

  return (
        
          <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
      
            <DashboardTopMenu changeBool={changeBool} userProfileType={props.userDetails.usertype} _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
            
            <>
              {bc.web3 != '' && bc.web3 != null ?
                <div style={{color:'#FCCF3E', textAlign:'center', width:'100%'}}>(connected: {bc.account.substr(0, 5) + '...' + bc.account.substr(39, 3)})</div> : <div>(not connected)</div>
              }
              {props.userDetails.usertype != 1 ? 
              <div style={{padding:20, textAlign:'center', width:'100%', justifyContent:'center', alignItems:'center'}}>

                <div style={{border:`1px solid ${theme.foreground}`, padding:10}}>

                  <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                      <div style={{textAlign:'center', maxWidth:600, fontSize:25, fontWeight:'bold', paddingBottom:10}}>Purchase New Subscription</div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                      <div style={{textAlign:'center', maxWidth:600, fontSize:20, fontWeight:'bold', paddingBottom:10}}>(7 days full refund net of gas fees)</div>
                  </Grid>
                  
                  
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:10, paddingBottom:10}} align="center" justify="center">
                    {Number(userServices) > 0 ? userServices < moment().unix() ? <div style={{color:'#FE4A4A'}}>{staticText.subscription_expired}</div> : <div style={{color:'#FCCF3E'}}>Expiration date: {moment.unix(Number(userServices)).format('MMMM DD, YYYY hh:mm A')}</div> : <div style={{color:'#FE4A4A'}}>{staticText.subscription_expired}</div>}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                      <form className={classes.form}>
                            <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:400, textAlign: 'center'}}>
                              <Grid item xs={4} sm={4} md={4}>
                                <div style={{paddingTop:2}}>
                                  <Item>Promotional Code (opt)</Item>
                                </div>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8}>
                                  <TextField
                                    sx={theme.inputTextSx}
                                    id="user_promotional_code_form"
                                    placeholder="ABC123"
                                    size="small"
                                    style={{width:'100%'}}
                                    onChange={event => setUserPromotionalCode(event.target.value)}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    defaultValue={localStorage.getItem('_promo')}
                                  />
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <div style={{paddingTop:2}}>
                                  <Item>Select Plan</Item>
                                </div>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8}>
                                <RadioGroup row aria-label="plan_type" value={userPlanType} name="row-radio-buttons-group_plan" onChange={ (e, val) => changeUserPlanType(val) }>
                                  {plansCosts.map((val, key) => 
                                    <FormControlLabel key={key} value={key} control={<Radio style={{color:theme.foreground}} />} label={<Typography style={{fontSize:13, color:theme.foreground, whiteSpace: 'nowrap'}}>{val/1000000000000000000} ETH for {key} days{key == 180 ? ' (25% discount)' : key == 360 ? ' (50% discount)' : ''}</Typography>} />
                                  )}
                                </RadioGroup>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                      <Grid container direction={'row'} spacing={2} justify="space-between">
                                        <Grid item xl={12} md={12} sm={12} xs={12}>
                                          <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{fontSize: 13, paddingLeft:6}}>
                                              <FormControlLabel 
                                                control={
                                                  <Checkbox 
                                                    onChange={e => {
                                                      //console.log(e.target.checked);
                                                      setUserAgree(e.target.checked)
                                                    }} 
                                                    style={{color:theme.foreground}}
                                                  />
                                                } 
                                                label={
                                                  <Typography style={{fontSize:13}}>Agree to <a style={{textDecoration: 'underline', color:theme.foreground}} href="/Terms" target="_blank">Terms and Conditions</a>
                                                  </Typography>
                                                }
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                              </Grid>
                              {errorMessage != '' ? <Grid item xs={12}>
                                <Alert severity="error">{errorMessage}</Alert>
                              </Grid> : (<div></div>)}
                              {payLoading 
                              ?
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20}} align="center" justify="center">
                                  <div>
                                    <CircularProgress />
                                  </div>
                                  <div style={{paddingTop:5}}>
                                    <Alert severity="info">{loadingNFTMintTransactionHash ? staticText.payment_processing : staticText.payment_confirm}</Alert>
                                  </div>
                                </Grid>
                              : 
                                null
                              }
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid container direction={'row'} spacing={2} justifyContent="flex-end">
                                  <Grid item xl={9} md={9} sm={9} xs={9}>

                                  </Grid>
                                  <Grid item xl={3} md={3} sm={3} xs={3}>
                                    <div>
                                        <ButtonCustomStyle
                                            changeHistory={null}
                                            doFnc={handleSubmit}
                                            changeHistoryFrom=''
                                            changeHistoryTo=''
                                            btnName='Pay'  
                                            variant='outlined'                        
                                        />
                                        <AreYouSureModal type="pay" op_cl={op_cl} setOp_cl={setOp_cl} doAfterAreYouSure={doAfterAreYouSure} />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>   
                            </Grid>
                      </form>
                  </Grid>
                </div>
              </div>
              : null }
              {props.userDetails.usertype != 1 ? 
              <div style={{padding:20, textAlign:'center', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <div style={{border:`1px solid ${theme.foreground}`, padding:10}}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:30}} align="center" justify="center">
                    <Typography variant="h6" gutterBottom component="div">
                        My Subscriptions
                    </Typography>
                  </Grid>
                  {user_subscriptions.length > 0 && bc.web3 != '' && bc.web3 != null ? 
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:10, overflowX:'auto', width:'100%', whiteSpace: 'nowrap'}} align="center" justify="center">
                        
                        <div style={{width:'100%', paddingBottom:20}}>
                          <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>Paid (ETH)</div>
                          <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>Plan</div>
                          <div style={{width:'15%', paddingLeft:15, minWidth:90, maxWidth:120, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>Date Paid</div>
                          <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:90, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>Refund Status</div>
                          <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:100, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>Refund Date</div>
                          <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:100, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>Wallet</div>
                          <div style={{width:'10%', paddingLeft:15, minWidth:100, maxWidth:130, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>Expire Date</div>
                        </div>
                        {user_subscriptions.map((val, key) =>  
                          <div key={`subscriptions_${key}`} style={{width:'100%', paddingTop:10}}>
                            <div style={{backgroundColor:theme.infoPanelBackground, paddingTop:10, paddingBottom:10}}>
                              <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>{bc.web3.utils.fromWei(val["refund_price"])}</div>
                              <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>{val["plan"]} DAYS</div>
                              <div style={{width:'15%', paddingLeft:15, minWidth:90, maxWidth:120, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>{moment.unix(Number(val["date"])).format('MMMM DD, YYYY hh:mm A')}</div>
                              <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:90, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>
                                {val["refunded"] == false && moment().unix() - Number(val["date"]) < Number(_contract.refund_period) ? 
                                  <>
                                    <div style={{paddingTop:10, paddingRight:10}}>
                                      {val["user"].toLowerCase() == bc.account.toLowerCase() ? 
                                        <div onClick={() => _handleRefund(key, val["plan"], val["discount_name"], val["refund_price"])} style={{cursor:'pointer', color:'#FE4A4A', padding:5, borderRadius:4, border: '2px solid #FE4A4A', textAlign:'center'}}>
                                          REFUND
                                        </div>
                                      :
                                        <div style={{color:'#FFBBBB', padding:5, borderRadius:4, border: '2px solid #FFBBBB', textAlign:'center'}}>
                                          REFUND
                                        </div>
                                      }
                                    </div>
                                    <AreYouSureModal type="refund" op_cl={op_cl_refund} setOp_cl={setOp_cl_refund} doAfterAreYouSure={doAfterAreYouSureRefund} />
                                    
                                  </>
                                : 
                                      <>
                                        {val["refunded"] == true ? 
                                          <div style={{color:'#FE4A4A'}}>Refunded</div> 
                                        : 
                                          <div>Not Refundable</div>
                                        }
                                      </>
                                  
                                }
                              </div>
                              <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:100, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>
                                {val["refunded"] == true ? 
                                  moment.unix(Number(val["refund_date"])).format('MMMM DD, YYYY hh:mm A')
                                :
                                  moment().unix() - Number(val["date"]) < Number(_contract.refund_period) ?
                                    <div>Refund available before <b style={{color:'#FCCF3E'}}>{moment.unix(Number(val["date"]) + Number(_contract.refund_period)).format('MMMM DD, YYYY hh:mm A')}</b></div>
                                  :
                                    null
                                }
                              </div>
                              <div style={{width:'15%', paddingLeft:15, minWidth:50, maxWidth:100, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>
                                <b style={{color:'#FCCF3E'}}>{val["user"].substr(0, 5) + '...' + val["user"].substr(39, 3)}</b>
                              </div>
                              <div style={{width:'10%', paddingLeft:15, minWidth:100, maxWidth:130, display: 'inline-block', verticalAlign: 'middle', textAlign:'left', whiteSpace: 'normal'}}>
                                {Number(userServices) > 0 && val["refunded"] == false ? userServices < moment().unix() ? null : moment.unix(Number(userServices)).format('MMMM DD, YYYY hh:mm A') : null}
                              </div>
                            </div>
                          </div>
                        )
                        }
                      </Grid> 
                      <Grid item xs={12} sm={12} md={12} style={{paddingTop:20, textAlign:'center'}} align="center" justify="center">
                              <Grid container direction={'row'} spacing={2} align="center" justify="center">
                                <Grid item xl={12} md={12} sm={12} xs={12}>
                                  <div style={{display: 'flex', alignItems: 'center', width:'100%', textAlign:'center'}}>
                                    <div style={{fontSize: 13, paddingLeft:6, alignItems: 'center', width:'100%', textAlign:'center'}}>
                                      <FormControlLabel 
                                        control={
                                          <Checkbox 
                                            onChange={e => {
                                              //console.log(e.target.checked);
                                              setUserRefundAgree(e.target.checked)
                                            }} 
                                            style={{color:theme.foreground}}
                                          />
                                        } 
                                        label={
                                          <Typography style={{fontSize:13}}>Agree to <a style={{textDecoration: 'underline', color:theme.foreground}} href="/Terms" target="_blank">Terms and Conditions</a>
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                      </Grid>
                      {errorMessageRefund != '' ? 
                      <Grid item xs={12} style={{textAlign:'center'}} align="center" justify="center">
                        <Alert severity="error">{errorMessageRefund}</Alert>
                      </Grid> : (<div></div>)}
                      {refundLoading ?
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20, textAlign:'center'}} align="center" justify="center">
                          <CircularProgress />
                          <Alert severity="info">{loadingNFTRefundTransactionHash ? staticText.refund_processing : staticText.refund_confirm}</Alert>
                        </Grid>
                      : 
                        null}
                    </>
                    
                  : 
                    user_subscriptions.length == 0 ? null : 
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:90}} align="center" justify="center">
                      <Typography variant="h6" gutterBottom component="div">
                          Loading Subscriptions...
                      </Typography>
                      <CircularProgress />
                    </Grid>
                  }
                  {Number(userServices) > 0 && userServices > moment().unix() ? 
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:10}} align="center" justify="center">
                      <div style={{paddingTop:5}}>
                        <ButtonCustomStyle
                          changeHistory={true}
                          doFnc={null}
                          changeHistoryFrom='/MySubscriptions'
                          changeHistoryTo='/'
                          btnName='Proceed to Dashboard' 
                          variant='outlined'                         
                        />
                      </div>
                    </Grid>
                  :
                    null
                  }
                </div>
              </div>
              : null }
              <div style={{padding:20, textAlign:'center', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <div style={{border:`1px solid ${theme.foreground}`, padding:10}}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:30}} align="center" justify="center">
                    <Typography variant="h6" gutterBottom component="div">
                      My Revenue Center <span onClick={() => changeHistory('/EarnMoney', '/EarnMoneyHelp')} style={{cursor:'pointer', textDecoration: 'underline'}}>(explanation of columns)</span>
                    </Typography>
                  </Grid>
                  {influencerPromos.length > 0 && bc.web3 != '' && bc.web3 != null ? 
                    <>
                      
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:10, overflowX:'auto', width:'100%', whiteSpace: 'nowrap'}} align="center" justify="center">
                        
                        <div style={{paddingBottom:20, width:'100%'}}>
                          <div style={{width:'25%', minWidth:150, maxWidth:200, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>My Link</div>
                          <div style={{width:'10%', minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Copy Link</div>
                          <div style={{width:'10%', minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Promo Code</div>
                          <div style={{width:'10%', minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>User Discount %</div>
                          <div style={{width:'10%', minWidth:80, maxWidth:110, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>My (Influencer) Commission %</div>
                          <div style={{width:'10%', minWidth:150, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Number of times the link was used</div>
                          <div style={{width:'15%', minWidth:150, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Total Earned (Vested + non-Vested) ETH</div>
                          <div style={{width:'10%', minWidth:70, maxWidth:100, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Link Status</div>
                        </div>
                        {influencerPromos.map((val, key) =>  
                          <div key={`promos_${key}`} style={{paddingTop:10, width:'100%'}}>
                            <div style={{backgroundColor:theme.infoPanelBackground, paddingTop:10, paddingBottom:10}}>
                              <div style={{width:'25%', minWidth:150, maxWidth:200, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left', whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{`${staticText.path}/SignUp/${val["name"]}`}</div>
                              <div style={{width:'10%', minWidth:50, maxWidth:80, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left', cursor:'pointer'}}>
                                <Tooltip title={copyToClipboardStr} arrow>
                                  <ContentCopyIcon onClick={() => {setCopyToClipboardStrTimeout(staticText.path + '/SignUp/' + val["name"])}} />
                                </Tooltip>
                              </div>
                              <div style={{width:'10%', minWidth:50, maxWidth:80, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["name"]}</div>
                              <div style={{width:'10%', minWidth:50, maxWidth:80, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["percent"]}%</div>
                              <div style={{width:'10%', minWidth:80, maxWidth:110, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["influencer_percent"]}%</div>
                              <div style={{width:'10%', minWidth:150, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["used_count"]}</div>
                              <div style={{width:'15%', minWidth:150, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{bc.web3.utils.fromWei(String(Math.floor(Number(val["total_eth_sent"]) * Number(val["influencer_percent"]) / 100)))} ETH</div>
                              <div style={{width:'10%', minWidth:70, maxWidth:100, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["deleted"] ? <div style={{color:'#cccccc'}}>Deleted</div> : 'Active'}</div>
                            </div>
                              
                          </div>
                        )
                        }
                      </Grid>
                      {influencerInvoicesInfo[0] != null ? 
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:30}} align="center" justify="center">
                            <Typography variant="h6" gutterBottom component="div">
                              Total Remaining Vested Balance: {influencerInvoicesInfo[1] != 0 ? bc.web3.utils.fromWei(String(Math.floor(Number(influencerInvoicesInfo[1])))) : 0} ETH
                            </Typography>
                            <Typography variant="h6" gutterBottom component="div">
                              Refundable balance: {influencerInvoicesInfo[2] != 0 ? Number(influencerInvoicesInfo[2]) == 0 ? 0 : bc.web3.utils.fromWei(String(Math.floor(Number(influencerInvoicesInfo[2])))) : 0} ETH
                            </Typography>
                          </Grid>
                          {influencerInvoicesInfo[1] != 0 ?
                          <>
                            <Grid item xs={12} sm={12} md={12} style={{paddingTop:20, textAlign:'center'}} align="center" justify="center">
                                <Grid container direction={'row'} spacing={2} align="center" justify="center">
                                  <Grid item xl={12} md={12} sm={12} xs={12}>
                                    <div style={{display: 'flex', alignItems: 'center', width:'100%', textAlign:'center'}}>
                                      <div style={{fontSize: 13, paddingLeft:6, alignItems: 'center', width:'100%', textAlign:'center'}}>
                                        <FormControlLabel 
                                          control={
                                            <Checkbox 
                                              onChange={e => {
                                                setUserWithdrawAgree(e.target.checked)
                                              }} 
                                              style={{color:theme.foreground}}
                                            />
                                          } 
                                          label={
                                            <Typography style={{fontSize:13}}>
                                              Agree to <a style={{textDecoration: 'underline', color:theme.foreground}} href="/Terms" target="_blank">Terms and Conditions</a>
                                            </Typography>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                            </Grid>
                            {errorMessageWithdraw != '' ? 
                              <Grid item xs={12}>
                                <Alert severity="error">{errorMessageWithdraw}</Alert>
                              </Grid> 
                            : 
                              null
                            }
                            {withdrawLoading 
                            ?
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20}} align="center" justify="center">
                                <div>
                                  <CircularProgress />
                                </div>
                                <div style={{paddingTop:5}}>
                                  <Alert severity="info">{loadingNFTWithdrawTransactionHash ? staticText.withdraw_processing : staticText.withdraw_confirm}</Alert>
                                </div>
                              </Grid>
                            : 
                              null
                            }
                            <Grid item xs={12} sm={12} md={12} style={{paddingTop:20, textAlign:'center'}} align="center" justify="center">
                                
                                  <div>
                                    <ButtonCustomStyle
                                      changeHistory={null}
                                      doFnc={_handleWithdraw}
                                      changeHistoryFrom=''
                                      changeHistoryTo=''
                                      btnName={'WITHDRAW ' + bc.web3.utils.fromWei(String(Math.floor(Number(influencerInvoicesInfo[1])))) + ' ETH'}
                                      variant='outlined'                         
                                    />
                                  </div>
                                  <AreYouSureModal type="withdraw" op_cl={op_cl_withdraw} setOp_cl={setOp_cl_withdraw} doAfterAreYouSure={withdrawDo} />
                            </Grid>
                          </> 
                          : 
                          null
                          }
                          {influencerWithdraws.length > 0 && bc.web3 != '' && bc.web3 != null ? 
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:30}} align="center" justify="center">
                                <Typography variant="h6" gutterBottom component="div">
                                  Withdraw History
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:10, overflowX:'auto', width:'100%', whiteSpace: 'nowrap'}} align="center" justify="center">
                                
                                <div style={{paddingBottom:20, width:'100%'}}>
                                  <div style={{width:'50%', minWidth:150, maxWidth:200, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Withdrawed by</div>
                                  <div style={{width:'25%', minWidth:100, maxWidth:150, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Amount</div>
                                  <div style={{width:'25%', minWidth:100, maxWidth:150, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Date</div>
                                </div>
                                {influencerWithdraws.map((val, key) =>  
                                  <div key={`withdraws_${key}`} style={{paddingTop:10, width:'100%'}}>
                                    <div style={{backgroundColor:theme.infoPanelBackground, paddingTop:10, paddingBottom:10}}>
                                      <div style={{width:'50%', minWidth:150, maxWidth:200, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["caller_address"].substr(0, 5) + '...' + val["caller_address"].substr(39, 3)} ({val["caller_address"] == bc.account ? 'Me' : 'Admin'})</div>
                                      <div style={{width:'25%', minWidth:100, maxWidth:150, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{bc.web3.utils.fromWei(String(Number(val["amount"])))} ETH</div>
                                      <div style={{width:'25%', minWidth:100, maxWidth:150, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{moment.unix(Number(val["date"])).format('MMMM DD, YYYY hh:mm A')}</div>
                                    </div>
                                  </div>
                                )
                                }
                              </Grid>
                            </>
                          :
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20}} align="center" justify="center">
                              <div style={{fontSize:16, color:'#FCCF3E'}}>
                                You have no withdrawals yet
                              </div>
                            </Grid>
                          }
                        </>
                      : 
                        null
                      }
                    </>
                  :
                  influencerInvoicesInfo[0] == null || influencerInvoicesInfo[0] == 0 ? 
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20}} align="center" justify="center">
                        <div style={{fontSize:16, color:'#FCCF3E'}}>
                          Have no promo code yet?
                        </div>
                        <div style={{paddingTop:16}}>
                          <ButtonCustomStyle
                              changeHistory={true}
                              doFnc={null}
                              changeHistoryFrom='/'
                              changeHistoryTo='/EarnMoney'
                              btnName='Submit Earn Money Application Form'
                              variant='outlined'
                              endIcon={<PaidIcon />}
                          />
                        </div>
                      </Grid>
                    </>
                  : 
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:90}} align="center" justify="center">
                      <Typography variant="h6" gutterBottom component="div">
                          Loading Promos...
                      </Typography>
                      <CircularProgress />
                    </Grid>
                  }
                </div>
              </div>
            </>
          </Grid>
        
  );
}