import React from "react";

import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import moment from 'moment'
Chart.register(...registerables);



export default function LowHighPercentChart(props) {

    const data = {
        labels: [props._values[3] != undefined ? '3 Days Ago' : '-', props._values[2] != undefined ? '2 Days Ago' : '-', props._values[1] != undefined ? 'Yesterday' : '-', 'Today'],
        datasets: [
          {
            label: "High / Low % Range Change",
            data: [props._values[3] != undefined ? Math.floor(100 * props._values[3].high_average / props._values[3].low_average - 100) : 0, props._values[2] != undefined ? Math.floor(100 * props._values[2].high_average / props._values[2].low_average - 100) : 0, props._values[1] != undefined ? Math.floor(100 * props._values[1].high_average / props._values[1].low_average - 100) : 0, props._values[0] != undefined ? Math.floor(100 * props._values[0].high_average / props._values[0].low_average - 100) : 0],
            fill: true,
            borderColor: "rgba(75,192,192,1)"
          }
        ]
      };

  return (
    <div style={{width:250, height:150}}>
      <Line data={data} />
    </div>
  );
}
