import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PortfolioList from "./PortfolioList";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import ModalAreYouSure from "./ModalAreYouSure";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonCustomStyle from "./ButtonCustomStyle";
import AreYouSureModal from "./AreYouSureModal";
const Web3 = require("web3");

//import '../App.css';




const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

const checkbox_label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding:20,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1976D2',
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {

  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  let Subscribejson = require('../abis/Subscribe' + staticText.rightNetworkId + '.json');
  let plansCosts = [];
  plansCosts[30] = 40000000000000000;
  plansCosts[180] = 180000000000000000;
  plansCosts[360] = 240000000000000000;
  //const rightNetworkId = 4;
  const rightNetworkId = staticText.rightNetworkId;
  const [accountStage, setAccountStage] = useState('login');
  const [connectedChanged, setConnectedChanged] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');
  const [bc, setBc] = useState({
    'account': '',
    'accountShort': '',
    'networkId': 0,
    'connectedWalletBool': false,
    'web3': '',
    'isSigned': false,
    'signature': '',
    'message': ''
  });

  const [_contract, setContract] = useState({
    'name': '',
    'owner': '',
    'contract_connection' : '',
    'user_service': {},
    'user_subscriptions': {},
    'expire_date': 0
  });
  //const [activeTheme, setActiveTheme] = useState("dark");

  const [payLoading, setPayLoading] = useState(false)
  const [loadingNFTMintTransactionHash, setLoadingNFTMintTransactionHash] = useState(false)
  const [prontoPerSignUp, setProntoPerSignUp] = useState(false)

  const connectWallet = async () => {
    console.log('connectWallet');
    if (window.ethereum) {


        const eth_requestAccounts = await window.ethereum.send('eth_requestAccounts').then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('eth_requestAccounts result = ', result)
            //_user_service = result;
          }
        )
        .catch(
          err => {
            console.log("eth_requestAccounts error occured", err)
            //setBalanceOf_res(String(err))
          }
        )

        window.ethereum.on('accountsChanged', handleAccountsChanged)
        window.ethereum.on('chainChanged', handleChainChanged)

        window.web3 = new Web3(window.ethereum);

        /////////////////////////////////////////////////////
        const accounts = await window.web3.eth.getAccounts()
        // Network ID
        const networkId = await window.web3.eth.net.getId()
        ///let _balance = 0;

        Promise.all(
          [ 
            eth_requestAccounts,
            accounts,
            networkId
          ])
          .then((values) => {

            if(accounts[0] != undefined) {

              console.log('account = ', accounts[0]);
              console.log('networkId = ', networkId);
    
              let _bc = {
                'account': accounts[0],
                'accountShort': accounts[0].substr(0, 5) + '...' + accounts[0].substr(39, 3),
                'networkId': networkId,
                'connectedWalletBool': true,
                'web3': window.web3
              }
    
              
    
              setBc(_bc);
    
              if(networkId != rightNetworkId) {
    
                setAccountStage('connect_to_mainnet')
                if(userProfileType != 1) {
                  setErrorMessage(staticText.connect_to_mainnet);
                }
                
              } else {
    
                console.log('blablabaaaaaaaa = ' + _bc.account)
                loadSmartContract(_bc);
                //loadMyNFTsContract();
    
                //if(accountStage != 'logined') {
                if((localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '')) {
    
                  console.log('set logined')
                  setAccountStage('logined')
                } else {
    
                  setAccountStage('login')
                  
                }
                //}
                
                //signMessage();
                setErrorMessage("");
              }
    
    
              
            } else {
    
              console.log('connect_to_wallet accounts[0] == undefined')
              setAccountStage('connect_to_wallet')
              if(userProfileType != 1) {
                setErrorMessage(staticText.connect_to_wallet);
              }
            }
          }
        );
        
        

      return true;
    } else {
      if(userProfileType != 1) {
      setErrorMessage(staticText.connect_to_wallet);
      }
      console.log('Please install MetaMask.')
      setAccountStage('connect_to_wallet')
    }
    
    return false;
  }

  // For now, 'eth_accounts' will continue to always return an array
  const handleAccountsChanged = (accounts) => {

    console.log('handleAccountsChanged accounts = ', accounts)
    console.log('handleAccountsChanged accounts.length = ', accounts.length)
    if (accounts.length === 0) {

      /*let _bc = bc;
      _bc.account = '';
      _bc.connectedWalletBool = false;
      setBc(_bc);*/
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.')
      setAccountStage('connect_to_wallet')
      //setMetmaskError('Please connect to MetaMask.')

    } else {

      localStorage.setItem('access_token', '');
      localStorage.setItem('refresh_token', '');
      //setAccountStage('login')

      console.log('handleAccountsChanged = ' + accounts[0])
      let _bc = bc;
      _bc.connectedWalletBool = true;
      _bc.account = accounts[0];
      _bc.accountShort = accounts[0].substr(0, 5) + '...' + accounts[0].substr(39, 3);
      setBc(_bc);
      console.log('_bc handleAccountsChanged', _bc)

      
    }
    //connectWallet()
  }

  function handleChainChanged (chainId) {
    
      console.log('handleChainChanged = ' + chainId.substr(2))
      if (Number(bc.networkId) !== Number(chainId.substr(2))) {

        localStorage.setItem('access_token', '');
        localStorage.setItem('refresh_token', '');
    
        console.log('handleChainChanged different')
        let _bc = bc;
        _bc.networkId = Number(chainId.substr(2));
        setBc(_bc);
        console.log('_bc handleChainChanged', _bc)

        //connectWallet()
      }
    }

    /////////////////////////////////////////load NFT contract and stuff
    const loadSmartContract = async (_bc) => {

      setContract({
        'name': '',
        'owner': '',
        'contract_connection' : '',
        'user_service': {},
        'user_subscriptions': {},
        'expire_date': 0
      });

      console.log(Subscribejson)
      console.log('bc.networkId', _bc.networkId)
        
      const networkData = Subscribejson.networks[_bc.networkId];

      console.log('networkData = ', networkData)
      if (networkData) {

        const NFT_par = new _bc.web3.eth.Contract(
          Subscribejson.abi,
          networkData.address
        );
        
        let _name = await NFT_par.methods.name().call();
        let _owner = await NFT_par.methods.owner().call();
        /*let _user_service = {};
        let _user_subscriptions = {};
        await NFT_par.methods
          .user_service(_bc.account)
          .call()
          .then(
            result => {
              
              //setBalanceOf_res(result)
              console.log('result = ', result)
              _user_service = result;
            }
          )
          .catch(
            err => {
              //console.log("An error occured", err)
              //setBalanceOf_res(String(err))
            }
          )
        
          await NFT_par.methods
          .getUserSubscribesByAddress(_bc.account)
          .call()
          .then(
            result => {
              
              //setBalanceOf_res(result)
              console.log('result = ', result)
              _user_subscriptions = result;
            }
          )
          .catch(
            err => {
              //console.log("An error occured", err)
              //setBalanceOf_res(String(err))
            }
          )
        */
          
        
        console.log(_name);
        Promise.all(
          [ 
            _name,
            _owner
          ])
          .then((values) => {

            let _nft_temp = _contract;

            _nft_temp.contract_connection = NFT_par;
            _nft_temp.name = _name.toString();
            _nft_temp.owner = _owner.toString();
            //_nft_temp.user_service = _user_service;
            //_nft_temp.expire_date = _user_service.expire_date;
            //_nft_temp.user_subscriptions = _user_subscriptions;
            setContract(_nft_temp);

            console.log('_nft_temp = ', _nft_temp);

            if(prontoPerSignUp) {

              _apiSignUp();
            }
          }
        );
      } else {
        //this.setState({ contractDetected: false });
      }
  }

  const payCheckDiscountDo = async (pay, user_unique_id, _loginned_token) => {

    console.log('userPromotionalCode = ' + userPromotionalCode);
    console.log('plansCosts[userPlanType] = '  +plansCosts[userPlanType] + ', user_unique_id = ' + user_unique_id + ', _loginned_token = ' + _loginned_token)
    if(userPromotionalCode != '' && userPromotionalCode != null) {

      await _contract.contract_connection.methods
        .discounts(userPromotionalCode.toLowerCase())
        .call()
        .then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('result = ', result)
            //_user_subscriptions = result;
            if(result.name == '' || result.deleted) {

              console.log('wrong discount')
              if(userProfileType != 1) {
              setErrorMessage(staticText.invalid_promo_code);
              }
            } else {

              //setErrorMessage("");
              console.log('discount name: ' + result.name + ', discount percent: ' + result.percent)
              payDo(Math.floor(plansCosts[userPlanType] * (100 - result.percent) / 100), user_unique_id, _loginned_token)
            }
            //setUser_subscriptions(result)
          }
        )
        .catch(
          err => {
            //console.log("An error occured", err)
            //setBalanceOf_res(String(err))
          }
        )
    } else {

      
      payDo(plansCosts[userPlanType], user_unique_id, _loginned_token)
    }

    
  }

  const payDo = async (pay, user_unique_id, _loginned_token) => {

    let _transHash = '';
    //let user_unique_id = Math.floor(Math.random() * 1000000) + 1 ;

    setPayLoading(true)
    console.log('pay = ', pay);
    await _contract.contract_connection.methods
    .subscribeDeposit(userPlanType, userPromotionalCode.toLowerCase(), user_unique_id)
    .send({ from: bc.account, value: pay }).on('transactionHash', (hash) => {

      /////////////////////https://api-rinkeby.etherscan.io/api?module=proxy&action=eth_getTransactionByHash&txhash=0xb9d232f9a6bf0f7ef9ee21da468f737c8b06811e952515792f393049676d6320&apikey=HE4DBV8VK9VH27981I6BJQXZYAWWTS893F
        //setLoadingAnimation(true);
        console.log('subscribeDeposit transactionHash hash = ' + hash);
        _transHash = hash;
        setTransHash(hash);
        setLoadingNFTMintTransactionHash(true);
      }).once('error', (err) =>{
        //window.alert('Error')
        //setLoading(false);
        //handleLoadingClose();
        if(userProfileType != 1) {
        setErrorMessage(staticText.payment_denied);
        }
        setPayLoading(false)
        console.log('Hohohohohohohohoho = ', bc.account)
        console.log('subscribeDeposit error' + err.message);
        setLoadingNFTMintTransactionHash(false);
        //setMint_res(String(err))
      }).once('receipt', () => { 
        //setLoading(false);
        console.log('subscribeDeposit receipt');
        setLoadingNFTMintTransactionHash(false);
      }).once("confirmation", () => {

        console.log('subscribeDeposit confirmation loggined_token = ' + _loginned_token);
        console.log('subscribeDeposit confirmation txhash = ' + _transHash);
        console.log('subscribeDeposit confirmation address = ' + bc.account);
        console.log('subscribeDeposit confirmation plan = ' + userPlanType);
        console.log('subscribeDeposit confirmation discount = ' + userPromotionalCode);
        console.log('subscribeDeposit confirmation price = ' + pay);
        //setPayLoading(false)

        ///////////////////////////SIGNUP DOING AFTER PAYMENT CONFIRMS
        _contract.contract_connection.methods
          .user_service(user_unique_id)
          .call()
          .then(
            result => {
              
              //setBalanceOf_res(result)
              console.log('result = ', result)
              _api_payments(pay, _transHash, result.expire_date, _loginned_token);
              localStorage.setItem('_promo', '');
              setUserPromotionalCode('')
              //_user_service = result;
              //_apiSignUp();


            }
          )
          .catch(
            err => {
              console.log("An error occured", err)
              //setBalanceOf_res(String(err))
            }
          )
        
        //getUserSubscribesByAddressFnc();
        //stopNFTMintFnc = true;
        console.log('subscribeDeposit confirmation');
        //props._api_get_project(props.projectId)
        setLoadingNFTMintTransactionHash(false);
      }).then(tx => {
        //do whatever you want with tx
        //setPayLoading(false)
        setLoadingNFTMintTransactionHash(false);
      })
      .catch(e => {
          if (e.code === 4001){
            //setPayLoading(false)
            setLoadingNFTMintTransactionHash(false);
              //user rejected the transaction
          } 
      });
  }

  ////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const classes = useStyles();

  const [loadProccess, setLoadProccess] = useState(false);

  const [userEmail, setUserEmail] = useState(props.myInfo == true ? props.userDetails.email : '');
  const [userPassword, setUserPassword] = useState('');
  const [userProfileType, setUserProfileType] = useState(props.myInfo == true ? props.userDetails.usertype : props.userProfileType);
  const [userPlanType, setUserPlanType] = useState(30);
  const [userNickname, setUserNickname] = useState(props.myInfo == true ? props.userDetails.username : '');
  const [userTelegram, setUserTelegram] = useState(props.myInfo == true ? props.userDetails.telegram : '');
  const [userYoutube, setUserYoutube] = useState(props.myInfo == true ? props.userDetails.youtube : '');
  const [userTwitter, setUserTwitter] = useState(props.myInfo == true ? props.userDetails.twitter : '');

  const [userPromotionalCode, setUserPromotionalCode] = useState(localStorage.getItem('_promo') != '' && localStorage.getItem('_promo') != null ? localStorage.getItem('_promo') : '');

  

  const [userAgree, setUserAgree] = useState(false);

  const userEmailRef = useRef(null);
  const userPasswordRef = useRef(null);
  
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  //const [signUpErrorMessage, setSignUpErrorMessage] = useState('');

  const [data, setData] = useState({
    'action': 'vs_currencies'
  });

  const [openBtnPressed, setOpenBtnPressed] = useState(false);

  const [transHash, setTransHash] = useState('');
  const [logginedTokenTemporary, setLogginedTokenTemporary] = useState('')

  const [op_cl, setOp_cl] = useState(false);

  useEffect(() => {

    let isSubscribed = true

    setOpenBtnPressed(openBtnPressed);
    if(!props.myInfo) {

      if(isSubscribed) {
        if(props.userProfileType == 0) {
          
          //connectWallet();
        }
      }      
    }

    
    return () => isSubscribed = false
  }, []);


  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const changeUserProfileType = (val) => {
    //console.log(val);
    if(val == 1) {
      setErrorMessage('')
    }    
    setUserProfileType(val);
  }

  const changeUserPlanType = (val) => {
    console.log(val);
    setUserPlanType(val);
  }

  const _apiSignUp = () => {

    if(props.userProfileType == 0) {
      setOp_cl(true)
      console.log(op_cl)
    } else {

      doAfterAreYouSure()
    }
    
    /*let data = {
        //'action': 'vs_currencies'
    };
    data['userEmail'] = userEmail;
    data['userPassword'] = userPassword;
    data['userProfileType'] = userProfileType;
    data['userNickname'] = userNickname;
    data['userTelegram'] = userTelegram;
    data['userYoutube'] = userYoutube;
    data['userTwitter'] = userTwitter;

    data['userPromotionalCode'] = userPromotionalCode;
    

    //props._apiSignUp(data);
    _api_registration(data);*/
  }

  const doAfterAreYouSure = () => {

    let data = {
        //'action': 'vs_currencies'
    };
    data['userEmail'] = userEmail;
    data['userPassword'] = userPassword;
    data['userProfileType'] = userProfileType;
    data['userNickname'] = userNickname;
    data['userTelegram'] = userTelegram;
    data['userYoutube'] = userYoutube;
    data['userTwitter'] = userTwitter;

    data['userPromotionalCode'] = userPromotionalCode;
    

    //props._apiSignUp(data);
    _api_registration(data);
  }

  //
  ////////////////////////////////////////login
  function _api_registration_callback(_data) {

    console.log('_api_registration_callback = ' + _data.status_code);
    //
    if(_data.status_code != 2) {

      if(_data.status_code == 5 || _data.status_code == 9) {
        
        setErrorMessage(_data.msg);
        //setHomepageAlertMessage(_data.msg);
      } else {
        //setErrorMessage('Technical error');

      }

      if(_data.status_code == 101) {

        setLogginedTokenTemporary(_data.data.loggined_token)
        payCheckDiscountDo(userPlanType, _data.data.user_id, _data.data.loggined_token)
        
        console.log('userPlanType = ' + userPlanType + ', _data.data.user_id = ' + _data.data.user_id + ', logginedTokenTemporary = ', _data.data.loggined_token);
        //props._api_chech_user_token(_data.data.loggined_token);
        //console.log('dddddddddddddddd user data id = ', _data.data.user_id)
        
      }
      //console.log('_api_registration_callback error = ' + _data.msg);
      //console.log('_api_registration_callback status_code = ' + _data.status_code);
      
    } else {

      setErrorMessage('');
      //if(_data.data.is_blocked == 1) {
        //console.log('you are blocked, please contact support@cyptodarts.com')
      //} else {

        //setUserType(_data.data.usertype);
        

        //console.log('user_id = ' + _data.data.user_id);
        //console.log('username = ' + _data.data.username);
        //console.log('loggined_token = ' + _data.data.loggined_token);

        //localStorage.setItem('loggined_token', _data.data.loggined_token);

        //props.setLoggined_token(_data.data.loggined_token);
        props._api_chech_user_token(_data.data.loggined_token);
        //setUserLogedIn(true);        
      //}
      

    }
    
  }

  const _api_registration = (_data) => {

    //setUserEmail(_data.userEmail);
    //setUserProfileType(_data.userProfileType);
    
    let data = [
      {'key':'action', 'value': 'registration'},
      {'key':'email', 'value': _data.userEmail},
      {'key':'password', 'value': _data.userPassword},
      {'key':'usertype', 'value': _data.userProfileType},
      {'key':'username', 'value': _data.userNickname},
      {'key':'telegram', 'value': _data.userTelegram},
      {'key':'youtube', 'value': _data.userYoutube},
      {'key':'twitter', 'value': _data.userTwitter},
      {'key':'referral_code', 'value': _data.userPromotionalCode}
    ]

    props._apiConnect(staticText.path_user, data, _api_registration_callback);
  }
  /////////////////////////

  //
  ////////////////////////////////////////edituser

  const _apiEditUser = () => {

    let data = {
        //'action': 'vs_currencies'
    };
    data['loggined_token'] = props.userDetails.loggined_token;
    data['userEmail'] = userEmail;
    data['userPassword'] = userPassword;
    data['userNickname'] = userNickname;
    data['userTelegram'] = userTelegram;
    data['userYoutube'] = userYoutube;
    data['userTwitter'] = userTwitter;

    //props._apiSignUp(data);
    _api_edituser(data);
  }

  function _api_edituser_callback(_data) {

    setLoadProccess(false)

    if(_data.status_code != 2) {

      setErrorMessage(_data.msg);
      setSuccessMessage('')
      if(_data.status_code == 5) {
        
        setErrorMessage(_data.msg);
      } else {
        //setErrorMessage('Technical error');
      }
      //console.log('_api_edituser_callback error = ' + _data.msg);
      //console.log('_api_edituser_callback status_code = ' + _data.status_code);
  
    } else {

      setErrorMessage('');
      setSuccessMessage('Changes has been successfully saved')

      //console.log('_api_edituser_callback success');

      props._api_get_my_userFnc(props.userDetails.loggined_token);
      //console.log('user_id = ' + _data.data.user_id);
      //console.log('username = ' + _data.data.username);
      //console.log('loggined_token = ' + _data.data.loggined_token);

      //localStorage.setItem('loggined_token', _data.data.loggined_token);

      //props.setLoggined_token(_data.data.loggined_token);
      //props._api_chech_user_token(_data.data.loggined_token);
      //setUserLogedIn(true);        

    }
    
  }

  const _api_edituser = (_data) => {
    setLoadProccess(true)

    //setUserEmail(_data.userEmail);
    //setUserProfileType(_data.userProfileType);
    
    let data = [
      {'key':'action', 'value': 'edituser'},
      {'key':'loggined_token', 'value': _data.loggined_token},
      {'key':'email', 'value': _data.userEmail},
      {'key':'password', 'value': _data.userPassword},
      {'key':'username', 'value': _data.userNickname},
      {'key':'telegram', 'value': _data.userTelegram},
      {'key':'youtube', 'value': _data.userYoutube},
      {'key':'twitter', 'value': _data.userTwitter}
    ]

    props._apiConnect(staticText.path_user, data, _api_edituser_callback);
  }
  /////////////////////////

  function _api_payments_callback(_data) {

    //setLoadProccess(false)

    if(_data.status_code != 2) {

      setErrorMessage(_data.msg);
    } else {

      setErrorMessage('');
      console.log('_api_payments_callback success');
      //props._api_get_my_userFnc(props.userDetails.loggined_token);
      props._api_chech_user_token(_data.data.loggined_token);
    }
  }

  const _api_payments = (pay, _transHash, expire_date, _loginned_token) => {
    //setLoadProccess(true)
    
    let data = [
      {'key':'action', 'value': 'payment_add'},
      {'key':'loggined_token', 'value': _loginned_token},
      {'key':'txhash', 'value': _transHash},
      {'key':'address', 'value': bc.account},
      {'key':'plan', 'value': userPlanType},
      {'key':'discount', 'value': userPromotionalCode},
      {'key':'price', 'value': pay},
      {'key':'expire_date', 'value': expire_date}
    ]

    props._apiConnect(staticText.path_user, data, _api_payments_callback);
  }
  /////////////////////////

  const handleSubmit = (e) => {
    e.preventDefault();

      // don't remember from where i copied this code, but this works.
      if(!payLoading) {

        _handleSubmit();
      }
  };

  const _handleSubmit = () => {

    console.log('_handleSubmit')

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(userEmail == '') {
      setErrorMessage("Email can't be empty");
    } else if ( !re.test(userEmail) ) {
      
      setErrorMessage("Email is invalid");
        // invalid email, maybe show an error to the user.
    } else if(userPassword == '') {
      setErrorMessage("Password can't be empty");
    } else if(userPassword.length < 6) {
      setErrorMessage("Password must be minimum 6 symbols");
    } else if(userProfileType == 1 && userNickname == '') {
      setErrorMessage("Nickname can't be empty");
    } else if(userProfileType == 1 && userNickname.length < 3) {
      setErrorMessage("Nickname must be minimum 3 symbols");
    } else if(!userAgree) {
      setErrorMessage(staticText.agree);
    /*} else if(accountStage == 'connect_to_wallet' && userProfileType != 1) {
      setErrorMessage(staticText.connect_to_wallet);
      console.log('iharke Please connect to wallet')
    } else if(accountStage == 'connect_to_mainnet' && userProfileType != 1) {
      setErrorMessage(staticText.connect_to_mainnet);*/
    } else if(accountStage == 'login' && userProfileType != 1) {
      setErrorMessage("");
    } else {
      console.log('xuy chgitem')
    }

    if(userEmail != '' && re.test(userEmail) && userPassword != '' && userPassword.length >= 6 && userAgree) {

      
      //sendContactRequest();
      if(userProfileType == 1) {
        setProntoPerSignUp(false)
        //setErrorMessage("");
        _apiSignUp();
        
      } else {

        setProntoPerSignUp(true)
        if(accountStage == 'login') {
          setErrorMessage("");

          _apiSignUp();

          
          //setErrorMessage("check Smart Contract now and pay: " + accountStage);
          /////////////check Smart Contract now and pay
        } else {

          //connectWallet()
          console.log('Booooooooooooooooooooooooooo not login yet???? accountStage = ' + accountStage)
        }
        
      }
      
    } else {

      setProntoPerSignUp(false)
    }

    //console.log('userEmail = ' + userEmail + ', userPassword = ' + userPassword);
  }

  const saveHandleSubmit = (e) => {
    e.preventDefault();

      // don't remember from where i copied this code, but this works.
    _saveHandleSubmit();
  };

  const _saveHandleSubmit = () => {

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let validation_passed = true;
    if(userEmail == '') {
      setErrorMessage("Email can't be empty");
      validation_passed = false;
    } else if ( !re.test(userEmail) ) {
      setErrorMessage("Email is invalid");
      validation_passed = false;
    } else if(userPassword.length > 0 && userPassword.length < 6) {
      setErrorMessage("Password must be minimum 6 symbols");
      validation_passed = false;
    } else if(userProfileType == 1 && userNickname == '') {
      setErrorMessage("Nickname can't be empty");
      validation_passed = false;
    } else if(userProfileType == 1 && userNickname.length < 6) {
      setErrorMessage("Nickname must be minimum 6 symbols");
      validation_passed = false;
    }

    if(validation_passed) {

      //sendContactRequest();
      _apiEditUser();
    }

    //console.log('userEmail = ' + userEmail + ', userPassword = ' + userPassword);
  }

  const _cancelBtnPressed = () => {
    setOpenBtnPressed(false);
  }


  return (
        <form className={classes.form}>
        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:400, textAlign: 'center'}}>
                    
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item>Email</Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          sx={theme.inputTextSx}
                          id="userEmail_id"
                          name="userEmail"
                          ref={userEmailRef}
                          placeholder="e.g. your email"
                          size="small"
                          autoFocus
                          style={{width:'100%'}}
                          onChange={event => setUserEmail(event.target.value)}
                          inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                          defaultValue={props.myInfo ? props.userDetails.email : ''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item>Password</Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          sx={theme.inputTextSx}
                          id="userPassword_id"
                          name="userPassword"
                          ref={userPasswordRef}
                          placeholder={props.myInfo == true ? "******" : ""}
                          size="small"
                          type="password"
                          style={{width:'100%'}}
                          onChange={event => setUserPassword(event.target.value)}
                          inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                        />
                    </Grid>
                    {!props.myInfo
                    ?
                      <>
                        <Grid item xs={4} sm={4} md={4}>
                          <div style={{paddingTop:2}}>
                            <Item>Promotional Code (opt)</Item>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            <TextField
                              sx={theme.inputTextSx}
                              id="user_promotional_code_form"
                              placeholder="ABC123"
                              size="small"
                              style={{width:'100%'}}
                              onChange={event => setUserPromotionalCode(event.target.value)}
                              inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                              defaultValue={localStorage.getItem('_promo')}
                            />
                        </Grid>
                      </>
                    :
                      null
                    }
                    
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:4}}>
                        <Item>Profile Type <a href="/SiteTutorial" target="_blank" style={{color:theme.foreground}}><HelpOutlineIcon style={{width:15, height:15, cursor:'pointer'}} /></a></Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <RadioGroup row aria-label="profile_type" defaultValue={props.userProfileType == 0 ? 0 : 1} name="row-radio-buttons-group" onChange={ (e, val) => changeUserProfileType(val) }>

                          <FormControlLabel disabled={props.myInfo == true ? true : false} value={0} control={<Radio style={{color:theme.foreground}} />} label={<Typography style={{fontSize:13, color:((props.myInfo == true && props.userDetails.usertype == 0) || props.myInfo == undefined) ? theme.foreground : '#cccccc'}}>Regular User</Typography>} />
                          <FormControlLabel disabled={props.myInfo == true ? true : false} value={1} control={<Radio style={{color:theme.foreground}} />} label={<Typography style={{fontSize:13, color:((props.myInfo == true && props.userDetails.usertype == 1) || props.myInfo == undefined) ? theme.foreground : '#cccccc'}}>Contributor</Typography>} />

                        </RadioGroup>
                    </Grid>
                    
                    {userProfileType == 0 ? 
                    /*(!props.myInfo
                    ?
                      <>
                        <Grid item xs={4} sm={4} md={4}>
                          <div style={{paddingTop:2}}>
                            <Item>Select Plan</Item>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                          <RadioGroup row aria-label="plan_type" value={userPlanType} name="row-radio-buttons-group_plan" onChange={ (e, val) => changeUserPlanType(val) }>
                            {plansCosts.map((val, key) => 
                              <FormControlLabel key={key} value={key} control={<Radio style={{color:theme.foreground}} />} label={<Typography style={{fontSize:13, color:theme.foreground, whiteSpace: 'nowrap'}}>{val/1000000000000000000} ETH for {key} days{key == 180 ? ' (25% discount)' : key == 360 ? ' (50% discount)' : ''}</Typography>} />
                            )}
                          </RadioGroup>
                        </Grid>
                      </>
                    :
                      null
                    )*/
                      null
                    :
                      <>
                        <Grid item xs={4} sm={4} md={4}>
                          <div style={{paddingTop:2}}>
                            <Item>Nickname</Item>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            <TextField
                              sx={theme.inputTextSx}
                              id="user_nickname_form"
                              placeholder="your unique nickname that people will know you as"
                              size="small"
                              style={{width:'100%'}}
                              onChange={event => setUserNickname(event.target.value)}
                              inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                              defaultValue={props.myInfo ? props.userDetails.username : ''}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <div style={{paddingTop:2}}>
                            <Item>Telegram (opt)</Item>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            <TextField
                              sx={theme.inputTextSx}
                              id="user_telegram_form"
                              placeholder="t.me/myname"
                              size="small"
                              style={{width:'100%'}}
                              onChange={event => setUserTelegram(event.target.value)}
                              inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                              defaultValue={props.myInfo ? props.userDetails.telegram : ''}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <div style={{paddingTop:2}}>
                            <Item>Youtube (opt)</Item>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            <TextField
                              sx={theme.inputTextSx}
                              id="user_youtube_form"
                              placeholder="youtube.com/myname"
                              size="small"
                              style={{width:'100%'}}
                              onChange={event => setUserYoutube(event.target.value)}
                              inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                              defaultValue={props.myInfo ? props.userDetails.youtube : ''}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <div style={{paddingTop:2}}>
                            <Item>Twitter (opt)</Item>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            <TextField
                              sx={theme.inputTextSx}
                              id="user_twitter_form"
                              placeholder="twitter.com/myname"
                              size="small"
                              style={{width:'100%'}}
                              onChange={event => setUserTwitter(event.target.value)}
                              inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                              defaultValue={props.myInfo ? props.userDetails.twitter : ''}
                            />
                        </Grid>
                      </>
                    }
                    {props.myInfo == null ? 
                      <>
                        <Grid item xs={12} sm={12} md={12}>
                                <Grid container direction={'row'} spacing={2} justify="space-between">
                                  <Grid item xl={12} md={12} sm={12} xs={12}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                      <div style={{fontSize: 13, paddingLeft:6}}>
                                        <FormControlLabel 
                                          control={
                                            <Checkbox 
                                              onChange={e => {
                                                //console.log(e.target.checked);
                                                setUserAgree(e.target.checked)
                                              }} 
                                              style={{color:theme.foreground}}
                                            />
                                          } 
                                          label={
                                            <Typography style={{fontSize:13}}>Agree to <a style={{textDecoration: 'underline', color:theme.foreground}} href="/Terms" target="_blank">Terms and Conditions</a>
                                            </Typography>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                        </Grid>
                        {errorMessage != '' ? <Grid item xs={12}>
                          <Alert severity="error">{errorMessage}</Alert>
                        </Grid> : (<div></div>)}
                        {payLoading 
                        ?
                          <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20}} align="center" justify="center">
                            <div>
                              <CircularProgress />
                            </div>
                            <div style={{paddingTop:5}}>
                              <Alert severity="info">{loadingNFTMintTransactionHash ? staticText.payment_processing : staticText.payment_confirm}</Alert>
                            </div>
                          </Grid>
                        : 
                          null
                        }
                        {
                        /*userProfileType != 1 && typeof window.ethereum === 'undefined' ? 
                          <Grid item xs={12} sm={12} md={12} lg={12} justifyContent="flex-start">
                            <a href="https://metamask.io" target="_blank" style={{color:'#EC7D21'}}>Install Metamask</a>
                          </Grid>
                        :
                          null
                        */}
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container direction={'row'} spacing={2} justifyContent="flex-end">
                            <Grid item xl={9} md={9} sm={9} xs={9}>

                            </Grid>
                            <Grid item xl={3} md={3} sm={3} xs={3}>
                              <div>
                                  <ButtonCustomStyle
                                      changeHistory={null}
                                      doFnc={handleSubmit}
                                      changeHistoryFrom=''
                                      changeHistoryTo=''
                                      btnName='Register'                          
                                  />
                              </div>
                              <AreYouSureModal type="pay" op_cl={op_cl} setOp_cl={setOp_cl} doAfterAreYouSure={doAfterAreYouSure} />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/*userProfileType != 1 && props.isMobileAndNoMetamask ? 
                          <Grid item xs={12} sm={12} md={12}>
                            <div style={{color:'#FCCF3E'}}>You are on mobile</div>
                            <div style={{paddingTop:20}}>
                              <a href={props.metamaskLink}>
                                <ButtonCustomStyle
                                    changeHistory={null}
                                    doFnc={null}
                                    changeHistoryFrom=''
                                    changeHistoryTo=''
                                    btnName='Open Metamask to Sign Up'    
                                    fontSize={20}                      
                                />
                              </a>
                            </div>
                          </Grid>
                        : 
                          null
                        */}
                      </>
                    : 
                      <>
                        {errorMessage != '' ? <Grid item xs={12}>
                          <Alert severity="error">{errorMessage}</Alert>
                        </Grid> : (<div></div>)}
                        {successMessage != '' ? <Grid item xs={12}>
                          <Alert severity="success">{successMessage}</Alert>
                        </Grid> : (<div></div>)}
                        {loadProccess ? <Box sx={{ display: 'flex', width:'100%', maxWidth:400, textAlign:'center' }}>
                          <CircularProgress />
                        </Box> : null}
                        <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
                          <div style={{textAlign:'right', maxWidth:400}}>
                            <ButtonCustomStyle
                                changeHistory={null}
                                doFnc={saveHandleSubmit}
                                changeHistoryFrom=''
                                changeHistoryTo=''
                                btnName='Save'                          
                            />
                          </div>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
                          <div style={{paddingTop:30, textAlign:'right'}}>
                            {/*<Button
                              onClick={() => setOpenBtnPressed(true)}
                              style={{
                                cursor:'pointer',
                                textTransform: 'none',
                                color:'#FF1F1F',
                                fontSize:20
                              }}
                            >
                              Delete Account
                            </Button>*/}
                            <ModalAreYouSure open={openBtnPressed} alertHeader="Delete Account" alertMessage="Are you sure you want to delete your account?" cancelFnc={() => _cancelBtnPressed()} doFnc={props.setMyInfoDeleteAccountFnc} />
                            
                          </div>
                        </Grid>
                      </>
                    }
        </Grid>
        </form>
  );
}