import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PortfolioList from "./PortfolioList";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import ModalAreYouSure from "./ModalAreYouSure";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonCustomStyle from "./ButtonCustomStyle";
import DashboardTopMenu from "./DashboardTopMenu";
import moment from 'moment';
import PaidIcon from '@mui/icons-material/Paid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormEarnMoney from "./FormEarnMoney";
import Tooltip from '@mui/material/Tooltip';
import AreYouSureModal from "./AreYouSureModal";
const Web3 = require("web3");



export default function RouteEarnMoney(props) {

  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  let Subscribejson = require('../abis/Subscribe' + staticText.rightNetworkId + '.json');

  let loadSmartContract_bool = true;

  const [openBtnPressed, setOpenBtnPressed] = useState(false);
  //const rightNetworkId = 4;
  const rightNetworkId = staticText.rightNetworkId;
  const [accountStage, setAccountStage] = useState('connect_to_wallet');
  const [bc, setBc] = useState({
    'account': '',
    'accountShort': '',
    'networkId': 0,
    'connectedWalletBool': false,
    'web3': '',
    'isSigned': false,
    'signature': '',
    'message': ''
  });

  const [_contract, setContract] = useState({
    'name': '',
    'owner': '',
    'contract_connection' : '',
    'user_service': {},
    'user_subscriptions': {},
    'expire_date': 0
  });
  const [errorMessageWithdraw, setErrorMessageWithdraw] = useState('');
  const [influencerPromos, setInfluencerPromos] = useState({})
  const [influencerInvoicesInfo, setInfluencerInvoicesInfo] = useState({})
  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [loadingNFTWithdrawTransactionHash, setLoadingNFTWithdrawTransactionHash] = useState(false)
  const [userWithdrawAgree, setUserWithdrawAgree] = useState(false);
  const [copyToClipboardStr, setCopyToClipboardStr] = useState('Copy to clipboard');
  const [influencerWithdraws, setInfluencerWithdraws] = useState({})

  const [op_cl_withdraw, setOp_cl_withdraw] = useState(false);

  useEffect(() => {

    let isSubscribed = true

    setOpenBtnPressed(openBtnPressed);
    if(!props.myInfo) {

      if(isSubscribed) {
        connectWallet();
      }      
      //connectWallet()
    }

    
    return () => isSubscribed = false
  }, []);

  const connectWallet = async () => {
    console.log('connectWallet');
    if (window.ethereum) {

        console.log('connectWallet inside window.ethereum');
        const eth_requestAccounts = await window.ethereum.send('eth_requestAccounts').then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('eth_requestAccounts result = ', result)
            //_user_service = result;
          }
        )
        .catch(
          err => {
            console.log("eth_requestAccounts error occured", err)
            //setBalanceOf_res(String(err))
          }
        )

        window.ethereum.on('accountsChanged', handleAccountsChanged)
        window.ethereum.on('chainChanged', handleChainChanged)

        window.web3 = new Web3(window.ethereum);

        /////////////////////////////////////////////////////
        const accounts = await window.web3.eth.getAccounts()
        // Network ID
        const networkId = await window.web3.eth.net.getId()
        ///let _balance = 0;

        Promise.all(
          [ 
            eth_requestAccounts,
            accounts,
            networkId
          ])
          .then((values) => {

            if(accounts[0] != undefined) {

              console.log('account = ', accounts[0]);
              console.log('networkId = ', networkId);
    
              let _bc = {
                'account': accounts[0],
                'accountShort': accounts[0].substr(0, 5) + '...' + accounts[0].substr(39, 3),
                'networkId': networkId,
                'connectedWalletBool': true,
                'web3': window.web3
              }
    
              
    
              setBc(_bc);
    
              if(networkId != rightNetworkId) {
    
                setAccountStage('connect_to_mainnet')
                setErrorMessageWithdraw(staticText.connect_to_mainnet);
              } else {
    
                console.log('blablabaaaaaaaa = ' + _bc.account)
                setErrorMessageWithdraw("");
                if(loadSmartContract_bool) {

                  loadSmartContract_bool = false;
                  loadSmartContract(_bc);
                }
                
                //loadMyNFTsContract();
    
                //if(accountStage != 'logined') {
                if((localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '')) {
    
                  console.log('set logined')
                  setAccountStage('logined')
                } else {
    
                  setAccountStage('login')
                  
                }
                //}
                
                //signMessage();
                
              }
    
    
              
            } else {
    
              console.log('connect_to_wallet accounts[0] == undefined')
              setAccountStage('connect_to_wallet')
              setErrorMessageWithdraw(staticText.connect_to_wallet);
            }
          }
        );
        
        

      return true;
    } else {
      setErrorMessageWithdraw(staticText.connect_to_wallet);
      console.log('Please install MetaMask.')

      /*let _bc = bc;
      _bc.account = '';
      _bc.accountShort = '';
      _bc.networkId = 0;
      _bc.connectedWalletBool = false;
      _bc.isSigned = false;
      _bc.signature = '';
      _bc.message = '';
      setBc(_bc);*/
      setAccountStage('connect_to_wallet')
      setErrorMessageWithdraw(staticText.connect_to_wallet);

      //setMetmaskError('Please install MetaMask.')
    }
    
    return false;
  }

  // For now, 'eth_accounts' will continue to always return an array
  const handleAccountsChanged = (accounts) => {

    console.log('handleAccountsChanged = ' + accounts)
    if (accounts.length === 0) {

      /*let _bc = bc;
      _bc.account = '';
      _bc.connectedWalletBool = false;
      setBc(_bc);*/
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.')
      setAccountStage('connect_to_wallet')
      //setMetmaskError('Please connect to MetaMask.')

    } else {

      localStorage.setItem('access_token', '');
      localStorage.setItem('refresh_token', '');
      //setAccountStage('login')

      console.log('handleAccountsChanged = ' + accounts[0])
      let _bc = bc;
      _bc.connectedWalletBool = true;
      _bc.account = accounts[0];
      _bc.accountShort = accounts[0].substr(0, 5) + '...' + accounts[0].substr(39, 3);
      setBc(_bc);
      console.log('_bc handleAccountsChanged', _bc)

      
    }
    connectWallet()
  }

  function handleChainChanged (chainId) {
    
      console.log('handleChainChanged = ' + chainId.substr(2))
      if (Number(bc.networkId) !== Number(chainId.substr(2))) {

        localStorage.setItem('access_token', '');
        localStorage.setItem('refresh_token', '');
    
        console.log('handleChainChanged different')
        let _bc = bc;
        _bc.networkId = Number(chainId.substr(2));
        setBc(_bc);
        console.log('_bc handleChainChanged', _bc)

        connectWallet()
      }
  }

  /////////////////////////////////////////load NFT contract and stuff
  const loadSmartContract = async (_bc) => {

    setContract({
      'name': '',
      'owner': '',
      'contract_connection' : '',
      'user_service': {},
      'user_subscriptions': {},
      'expire_date': 0
    });

    console.log(Subscribejson)
    console.log('bc.networkId', _bc.networkId)
      
    const networkData = Subscribejson.networks[_bc.networkId];

    console.log('networkData = ', networkData)
    if (networkData) {

      const NFT_par = new _bc.web3.eth.Contract(
        Subscribejson.abi,
        networkData.address
      );
      
      let _name = await NFT_par.methods.name().call();
      let _owner = await NFT_par.methods.owner().call();
      
      console.log(_name);
      Promise.all(
        [ 
          _name,
          _owner
        ])
        .then((values) => {

          

          let _nft_temp = _contract;

          _nft_temp.contract_connection = NFT_par;
          _nft_temp.name = _name.toString();
          _nft_temp.owner = _owner.toString();
          setContract(_nft_temp);

          loadSmartContract_bool = true

          console.log('_nft_temp = ', _nft_temp);

          getInfluencerPromosFnc(_bc.account);

        }
      );
    } else {
      //this.setState({ contractDetected: false });
    }
  }

  const getInfluencerPromosFnc = async (_account) => {

    console.log('_account getInfluencerPromosFnc = ', _account)
    await _contract.contract_connection.methods
        .getInfluencerPromos(_account)
        .call({from:_account})
        .then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('result getInfluencerPromos = ', result)
            //_user_subscriptions = result;
            setInfluencerPromos(result)

            /////////////////////////load user services
            //getUserServiceFnc()
            getInfluencerInvoicesInfoFnc(_account)
          }
        )
        .catch(
          err => {
            console.log("An error occured getInfluencerPromosFnc", err)
            //setBalanceOf_res(String(err))
          }
        )
  }

  const getInfluencerInvoicesInfoFnc = async (_account) => {

    console.log('_account getInfluencerPromosFnc = ', _account)
    await _contract.contract_connection.methods
        .getInfluencerInvoicesInfo(_account)
        .call({from:_account})
        .then(
          result => {
            
            //setBalanceOf_res(result)
            setInfluencerInvoicesInfo(result)
            console.log('result getInfluencerInvoicesInfo = ', result)
            //_user_subscriptions = result;
            //setInfluencerPromos(result)

            /////////////////////////load user services
            //getUserServiceFnc()
            //getInfluencerInvoicesInfoFnc(_account)

            getInfluencerWithdrawsFnc(_account);
          }
        )
        .catch(
          err => {
            console.log("An error occured getInfluencerPromosFnc", err)
            //setBalanceOf_res(String(err))
          }
        )
  }

  ////

  ///////////////////////////////////<Withdraw>

  const getInfluencerWithdrawsFnc = async (_account) => {

    console.log('_account getInfluencerWithdrawsFnc = ', _account)
    await _contract.contract_connection.methods
        .getInfluencerWithdraws(_account)
        .call({from:_account})
        .then(
          result => {
            
            //setBalanceOf_res(result)
            console.log('result getInfluencerWithdraws = ', result)
            //_user_subscriptions = result;
            setInfluencerWithdraws(result)
          }
        )
        .catch(
          err => {
            console.log("An error occured getInfluencerWithdrawsFnc", err)
            //setBalanceOf_res(String(err))
          }
        )
  }

  const _handleWithdraw = () => {

    if(!withdrawLoading) {

      //_connectWallet();
      console.log('_handleWithdraw')

      if(!userWithdrawAgree) {
        setErrorMessageWithdraw(staticText.agree);
      }  else {
        console.log('xuy chgitem')
      }

      console.log('_handleWithdraw bheeee = ' + userWithdrawAgree + ', staticText.agree = ' + staticText.agree)
      if(userWithdrawAgree) {

          if(accountStage == 'login') {
            setErrorMessageWithdraw("");
            setOp_cl_withdraw(true)
            //withdrawDo()
            
          } else {

            connectWallet()
            console.log('_handleWithdraw Booooooooooooooooooooooooooo not login yet????')
          }
      }

      //console.log('userEmail = ' + userEmail + ', userPassword = ' + userPassword);
    }
    
  }
  
  const withdrawDo = async () => {

    let _transHash = '';

    setWithdrawLoading(true)
    await _contract.contract_connection.methods
    .withdrawByInfluencer()
    .send({ from: bc.account }).on('transactionHash', (hash) => {
        _transHash = hash;
        //setLoadingAnimation(true);
        console.log('withdrawByInfluencer transactionHash hash = ' + hash);
        setLoadingNFTWithdrawTransactionHash(true);
      }).once('error', (err) =>{
        //window.alert('Error')
        //setLoading(false);
        //handleLoadingClose();
        setErrorMessageWithdraw(staticText.withdraw_denied);
        setWithdrawLoading(false)
        console.log('subscribeDeposit error' + err.message);
        setLoadingNFTWithdrawTransactionHash(false);
        //setMint_res(String(err))
      }).once('receipt', () => { 
        //setLoading(false);
        console.log('subscribeDeposit receipt');
        setLoadingNFTWithdrawTransactionHash(false);
      }).once("confirmation", () => {

        getInfluencerInvoicesInfoFnc(bc.account)

        setWithdrawLoading(false)
        
        console.log('withdrawByInfluencer confirmation');
        //props._api_get_project(props.projectId)
        setLoadingNFTWithdrawTransactionHash(false);
      }).then(tx => {
        //do whatever you want with tx
        setWithdrawLoading(false)
        setLoadingNFTWithdrawTransactionHash(false);
      })
      .catch(e => {
          if (e.code === 4001){
            setWithdrawLoading(false)
            setLoadingNFTWithdrawTransactionHash(false);
              //user rejected the transaction
          } 
      });
  }

  const setCopyToClipboardStrTimeout = (txt) => {

    navigator.clipboard.writeText(txt)
    setCopyToClipboardStr('Copied!')

    const timer = setTimeout(() => {
      setCopyToClipboardStr('Copy to clipboard')
    }, 2000);
    return () => clearTimeout(timer);
  }

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', margin: 0}} >
      <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      <div style={{alignItems: 'center', padding:20}}>
        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
            
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{padding:20}}>
              <div style={{padding:20, textAlign:'center', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <div style={{border:`1px solid ${theme.foreground}`, padding:10, justifyContent:'center', alignItems:'center'}}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:30}} align="center" justify="center">
                    <Typography variant="h6" gutterBottom component="div">
                      My Revenue Center <span onClick={() => changeHistory('/EarnMoney', '/EarnMoneyHelp')} style={{cursor:'pointer', textDecoration: 'underline'}}>(explanation of columns)</span>
                    </Typography>
                    {bc.web3 != '' && bc.web3 != null ?
                      <div style={{color:'#FCCF3E'}}>(connected: {bc.account.substr(0, 5) + '...' + bc.account.substr(39, 3)})</div> : <div>(not connected)</div>
                    }
                  </Grid>
                  {influencerPromos.length > 0 && bc.web3 != '' && bc.web3 != null ? 
                    <>
                      
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:10, overflowX:'auto', width:'100%', whiteSpace: 'nowrap'}} align="center" justify="center">
                        
                        <div style={{paddingBottom:20, width:'100%'}}>
                          <div style={{width:'25%', minWidth:150, maxWidth:200, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>My Link</div>
                          <div style={{width:'10%', minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Copy Link</div>
                          <div style={{width:'10%', minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Promo Code</div>
                          <div style={{width:'10%', minWidth:50, maxWidth:80, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>User Discount %</div>
                          <div style={{width:'10%', minWidth:80, maxWidth:110, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>My (Influencer) Commission %</div>
                          <div style={{width:'10%', minWidth:150, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Number of times the link was used</div>
                          <div style={{width:'15%', minWidth:150, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Total Remaining Balance (Vested + non-Vested) ETH</div>
                          <div style={{width:'10%', minWidth:70, maxWidth:100, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Link Status</div>
                        </div>
                        {influencerPromos.map((val, key) =>  
                          <div key={`promos_${key}`} style={{paddingTop:10, width:'100%'}}>
                            <div style={{backgroundColor:theme.infoPanelBackground, paddingTop:10, paddingBottom:10}}>
                              <div style={{width:'25%', minWidth:150, maxWidth:200, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left', whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{`${staticText.path}/SignUp/${val["name"]}`}</div>
                              <div style={{width:'10%', minWidth:50, maxWidth:80, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left', cursor:'pointer'}}>
                                <Tooltip title={copyToClipboardStr} arrow>
                                  <ContentCopyIcon onClick={() => {setCopyToClipboardStrTimeout(staticText.path + '/SignUp/' + val["name"])}} />
                                </Tooltip>
                              </div>
                              <div style={{width:'10%', minWidth:50, maxWidth:80, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["name"]}</div>
                              <div style={{width:'10%', minWidth:50, maxWidth:80, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["percent"]}%</div>
                              <div style={{width:'10%', minWidth:80, maxWidth:110, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["influencer_percent"]}%</div>
                              <div style={{width:'10%', minWidth:150, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["used_count"]}</div>
                              <div style={{width:'15%', minWidth:150, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{bc.web3.utils.fromWei(String(Math.floor(Number(val["total_eth_sent"]) * Number(val["influencer_percent"]) / 100)))} ETH</div>
                              <div style={{width:'10%', minWidth:70, maxWidth:100, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["deleted"] ? <div style={{color:'#cccccc'}}>Deleted</div> : 'Active'}</div>
                            </div>
                              
                          </div>
                        )
                        }
                      </Grid>
                      {influencerInvoicesInfo[0] != null ? 
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:30}} align="center" justify="center">
                            <Typography variant="h6" gutterBottom component="div">
                              Total Remaining Vested Balance: {influencerInvoicesInfo[1] != 0 ? bc.web3.utils.fromWei(String(Math.floor(Number(influencerInvoicesInfo[1])))) : 0} ETH
                            </Typography>
                            <Typography variant="h6" gutterBottom component="div">
                              Refundable balance: {influencerInvoicesInfo[2] != 0 ? Number(influencerInvoicesInfo[2]) == 0 ? 0 : bc.web3.utils.fromWei(String(Math.floor(Number(influencerInvoicesInfo[2])))) : 0} ETH
                            </Typography>
                          </Grid>
                          {influencerInvoicesInfo[1] != 0 ?
                          <>
                            <Grid item xs={12} sm={12} md={12} style={{paddingTop:20, textAlign:'center'}} align="center" justify="center">
                                <Grid container direction={'row'} spacing={2} align="center" justify="center">
                                  <Grid item xl={12} md={12} sm={12} xs={12}>
                                    <div style={{display: 'flex', alignItems: 'center', width:'100%', textAlign:'center'}}>
                                      <div style={{fontSize: 13, paddingLeft:6, alignItems: 'center', width:'100%', textAlign:'center'}}>
                                        <FormControlLabel 
                                          control={
                                            <Checkbox 
                                              onChange={e => {
                                                setUserWithdrawAgree(e.target.checked)
                                              }} 
                                              style={{color:theme.foreground}}
                                            />
                                          } 
                                          label={
                                            <Typography style={{fontSize:13}}>
                                              Agree to <a style={{textDecoration: 'underline', color:theme.foreground}} href="/Terms" target="_blank">Terms and Conditions</a>
                                            </Typography>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                            </Grid>
                            {errorMessageWithdraw != '' ? 
                              <Grid item xs={12}>
                                <Alert severity="error">{errorMessageWithdraw}</Alert>
                              </Grid> 
                            : 
                              null
                            }
                            {withdrawLoading 
                            ?
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20}} align="center" justify="center">
                                <div>
                                  <CircularProgress />
                                </div>
                                <div style={{paddingTop:5}}>
                                  <Alert severity="info">{loadingNFTWithdrawTransactionHash ? staticText.withdraw_processing : staticText.withdraw_confirm}</Alert>
                                </div>
                              </Grid>
                            : 
                              null
                            }
                            <Grid item xs={12} sm={12} md={12} style={{paddingTop:20, textAlign:'center'}} align="center" justify="center">
                                
                                  <div>
                                    <ButtonCustomStyle
                                      changeHistory={null}
                                      doFnc={_handleWithdraw}
                                      changeHistoryFrom=''
                                      changeHistoryTo=''
                                      btnName={'WITHDRAW ' + bc.web3.utils.fromWei(String(Math.floor(Number(influencerInvoicesInfo[1])))) + ' ETH'}
                                      variant='outlined'                         
                                    />
                                    <AreYouSureModal type="withdraw" op_cl={op_cl_withdraw} setOp_cl={setOp_cl_withdraw} doAfterAreYouSure={withdrawDo} />
                                  </div>
                            </Grid>
                          </> 
                          : 
                          null
                          }
                          {influencerWithdraws.length > 0 && bc.web3 != '' && bc.web3 != null ? 
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:30}} align="center" justify="center">
                                <Typography variant="h6" gutterBottom component="div">
                                  Withdraw History
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:10, overflowX:'auto', width:'100%', whiteSpace: 'nowrap'}} align="center" justify="center">
                                
                                <div style={{paddingBottom:20, width:'100%'}}>
                                  <div style={{width:'50%', minWidth:150, maxWidth:200, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Withdrawed by</div>
                                  <div style={{width:'25%', minWidth:100, maxWidth:150, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Amount</div>
                                  <div style={{width:'25%', minWidth:100, maxWidth:150, display: 'inline-block', verticalAlign: 'top', textAlign:'left', paddingLeft:15, whiteSpace: 'normal'}}>Date</div>
                                </div>
                                {influencerWithdraws.map((val, key) =>  
                                  <div key={`withdraws_${key}`} style={{paddingTop:10, width:'100%'}}>
                                    <div style={{backgroundColor:theme.infoPanelBackground, paddingTop:10, paddingBottom:10}}>
                                      <div style={{width:'50%', minWidth:150, maxWidth:200, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{val["caller_address"].substr(0, 5) + '...' + val["caller_address"].substr(39, 3)} ({val["caller_address"] == bc.account ? 'Me' : 'Admin'})</div>
                                      <div style={{width:'25%', minWidth:100, maxWidth:150, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{bc.web3.utils.fromWei(String(Number(val["amount"])))} ETH</div>
                                      <div style={{width:'25%', minWidth:100, maxWidth:150, paddingLeft:15, display: 'inline-block', verticalAlign: 'top', textAlign:'left'}}>{moment.unix(Number(val["date"])).format('MMMM DD, YYYY hh:mm A')}</div>
                                    </div>
                                  </div>
                                )
                                }
                              </Grid>
                            </>
                          :
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20}} align="center" justify="center">
                              <div style={{fontSize:16, color:'#FCCF3E'}}>
                                You have no withdrawals yet
                              </div>
                            </Grid>
                          }
                        </>
                      : 
                        null
                      }
                    </>
                  :
                  influencerInvoicesInfo[0] == null || influencerInvoicesInfo[0] == 0 ? 
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:20}} align="center" justify="center">
                        <div style={{fontSize:16, color:'#FCCF3E'}}>
                          You have no approved Promo Code yet
                        </div>
                      </Grid>
                    </>
                  : 
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:90}} align="center" justify="center">
                      <Typography variant="h6" gutterBottom component="div">
                          Loading Promos...
                      </Typography>
                      <CircularProgress />
                    </Grid>
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{paddingTop:20}}>
                <div style={{fontSize:24, paddingTop:20}}>
                    <div style={{fontSize:25, fontStyle: 'italic'}}>
                      Request a Custom Link to Earn Money
                    </div>
                </div>
                <div style={{paddingTop:20, maxWidth:700}}>
                To influencers, salespeople and enthusiasts - we are proud about our product and stand behind it. Contact us if you want to earn money with us by helping us increase our sales. Each sale (new registration)will pay you a very juicy commission. Once your application is approved (takes 24 hours) we will generate a link for you with your information and a user discount built in. You will then be able to share this link with your network. We can also provide you with helpful marketing materials or you can use your own. Apply now and join our success story!
                </div>                
                <div style={{paddingTop:20}}>
                    <FormEarnMoney userDetails={props.userDetails} _apiLogOff={props._apiLogOff} _apiConnect={props._apiConnect} _path={props._path} />
                </div>
            </Grid>
        </Grid>
      </div>
    </Grid>
  );
}