import React, { useContext } from 'react';
import { ThemeContext } from "../index";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import ButtonCustomStyle from "./ButtonCustomStyle";


import TwitterIcon from '@mui/icons-material/Twitter';
//telegram
import TelegramIcon from '@mui/icons-material/Telegram';

import InstagramIcon from '@mui/icons-material/Instagram';
//discord
import DiscordIconDark from "../assets/img/social/discord_dark.png";
import DiscordIconLight from "../assets/img/social/discord_light.png";


import YouTubeIcon from '@mui/icons-material/YouTube';



export default function SocialLinks(props) {

    
    const [theme, toggleTheme, activeTheme] = useContext(ThemeContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };
  
  return (
    <>
        <span>
            <a href="https://twitter.com/CryptoMrktDarts" target="_blank"><TwitterIcon style={{width:30, height:30, color:activeTheme == 'dark' ? '#FFFFFF' : '#000000', cursor:'pointer'}} /></a>
        </span>
        <span style={{paddingLeft:3}}>
            <a href="https://t.me/CryptoMarketDarts" target="_blank"><TelegramIcon style={{width:30, height:30, color:activeTheme == 'dark' ? '#FFFFFF' : '#000000', cursor:'pointer'}} /></a>
        </span>
        <span style={{paddingLeft:5}}>
            <a href="https://www.instagram.com/cryptomarketdarts" target="_blank"><InstagramIcon style={{width:30, height:30, color:activeTheme == 'dark' ? '#FFFFFF' : '#000000', cursor:'pointer'}} /></a>
        </span>
        <span style={{paddingLeft:5}}>
            <a href="https://discord.com/channels/933846621702221855/933846622243278910" target="_blank"><img src={activeTheme == 'dark' ? DiscordIconDark : DiscordIconLight} style={{width:30, height:30, cursor:'pointer'}} /></a>
        </span>
        <span style={{paddingLeft:5}}>
            <a href="https://www.youtube.com/channel/UCanmE8t_RtiKPIWHP8S_yKA" target="_blank"><YouTubeIcon style={{width:30, height:30, color:activeTheme == 'dark' ? '#FFFFFF' : '#000000', cursor:'pointer'}} /></a>
        </span>
    </>
  );
}