import React, { useContext } from 'react';
import { ThemeContext } from "../index";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';


const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

export default function DartLowHighAverage(props) {

  const [theme] = useContext(ThemeContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (

    <Grid container spacing={2} columns={12} rowSpacing={1} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', maxWidth:450, fontSize:16, padding:8}} >
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <div style={{fontWeight:'bold'}}>Price Estimate</div>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
            Low(USD)
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
            High(USD)
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
            Range
        </Grid>
        {
        Number(props.low1W) > 0 && Number(props.high1W) > 0 ? 
          <>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              1W
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <TextField
                sx={theme.inputTextSx}
                id="outlined-textarea"
                placeholder="e.g. your email"
                size="small"
                style={{width:'100%'}}
                inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}, readOnly: true}}
                value={numberToAmericanFormat(props.low1W, 4)}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <TextField
                sx={theme.inputTextSx}
                id="outlined-textarea"
                placeholder="e.g. your email"
                size="small"
                style={{width:'100%', align:'center'}}
                inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}, readOnly: true}}
                value={numberToAmericanFormat(props.high1W, 4)}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <div style={{alignItems: 'center', backgroundColor:theme.infoPanelBackground, padding:3, borderRadius:5, width:'100%'}}>
                {numberToAmericanFormat((100 * (props.high1W - props.low1W) / props.low1W), 2)}%
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              1D
            </Grid>
          </>
        :
          null
        }
        {
        Number(props.low1D) > 0 && Number(props.high1D) > 0 ? 
          <>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <TextField
                sx={theme.inputTextSx}
                id="outlined-textarea"
                placeholder="e.g. your email"
                size="small"
                style={{width:'100%'}}
                inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}, readOnly: true}}
                value={numberToAmericanFormat(props.low1D, 4)}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <TextField
                sx={theme.inputTextSx}
                id="outlined-textarea"
                placeholder="e.g. your email"
                size="small"
                style={{width:'100%', align:'center'}}

                inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}, readOnly: true}}
                value={numberToAmericanFormat(props.high1D, 4)}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <div style={{alignItems: 'center', backgroundColor:theme.infoPanelBackground, padding:3, borderRadius:5, width:'100%'}}>
                {numberToAmericanFormat((100 * (props.high1D - props.low1D) / props.low1D), 2)}%
              </div>
            </Grid>
          </>
        :
          null
        }
        {
        Number(props.low) > 0 && Number(props.high) > 0 ? 
          <>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              1H/4H
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <TextField
                sx={theme.inputTextSx}
                id="outlined-textarea"
                placeholder="e.g. your email"
                size="small"
                style={{width:'100%'}}
                inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}, readOnly: true}}
                value={numberToAmericanFormat(props.low, 4)}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <TextField
                sx={theme.inputTextSx}
                id="outlined-textarea"
                placeholder="e.g. your email"
                size="small"
                style={{width:'100%', align:'center'}}

                inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}, readOnly: true}}
                value={numberToAmericanFormat(props.high, 4)}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <div style={{alignItems: 'center', backgroundColor:theme.infoPanelBackground, padding:3, borderRadius:5, width:'100%'}}>
                {numberToAmericanFormat((100 * (props.high - props.low) / props.low), 2)}%
              </div>
            </Grid>
          </>
        :
          null
        }
    </Grid>
  );
}

