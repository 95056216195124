import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import DashboardTopMenu from "./DashboardTopMenu";


export default function RoutePrivacy(props) {

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
  <Grid container spacing={2} columns={12} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', margin: 0}} >
    <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
    <div style={{alignItems: 'center', padding:20}}>
      <div style={{textAlign:'center'}}>
        <Typography variant="h3" gutterBottom component="div">
          Privacy Policy
        </Typography>
      </div>
      <div style={{maxWidth:900, margin:'auto'}}>
      <div style={{fontWeight:'bold'}}>TERMS OF SERVICE</div>
      <div style={{height:20}} />
      <div style={{fontWeight:'bold'}}>Privacy Policy</div>
      <div style={{height:20}} />
At Utalkia Corporation (“Utalkia”), which owns CryptoMarketDarts platform, we value 
our customers' privacy. This privacy policy was developed to provide you with the 
assurance that we won't use your personal information in ways that you may object to. 
The personal information you provide to us is respected and protected.
Utalkia collects user-submitted information such as email address to authenticate users 
and to send notifications to those users relating to the Utalkia services. Utalkia collects 
nickname and links to social network links and this information is shared with public and
is associated with you on our platform. Utalkia may also collect other profile data for 
use in connection with the Website including professional certifications, additional links 
and references to third party platforms.
<div style={{height:40}} />
<div style={{textDecoration:'underline'}}>General Use and Security</div>
<div style={{height:20}} />
Your personal information will not be shared with third parties unless you give that third
party "opt-in" permission to contact you independent of us. Utalkia has technological 
and operational security procedures in place to protect your personal information from 
loss, misuse, alteration or unintentional destruction. At some places on the Website, we 
use "cookies" to enhance your online experience. Cookies allow our database to 
recognize any information associated with one of your inputs and they allow you to 
navigate through our Website easily. Some of our cookies expire when you close your 
PC system while others may expire after a prolonged period of time, such as credential 
information to facilitate seamless authentication. Most PC browsers now recognize when
a "cookie" is offered, and permit you to refuse or accept it. Our system may not work 
fully if you turn off cookies. Personal information collected by Utalkia is stored and 
maintained in secure operating environments that are not available to the public. Other 
than the uses specified above, we do not share personal information with any third 
parties unless we believe that such action is necessary to conform to legal 
requirements, protect the rights of Utalkia, or enforce the Utalkia General Terms of 
Service or other supplemental terms and conditions. 	Please  be aware that our Website 
may have advertisements that are links to the web sites of our advertisers. If you click 
on these links you are leaving our Website. While we do not share any personal 
information about you when you click on these links, once you leave the Utalkia 
environment for an external site, whether advertiser, or corporate sponsor, the privacy 
policy of the external site may differ from Utalkia’s Privacy Policy. If your computer is 
available to others, you should remember to logout and close your browser window 
when you have finished your work to ensure that others cannot access your personal 
information. If you have questions or comments about the administration of your 
personal information, please contact	Support	. You may also use this address to 
communicate any concerns you may have regarding compliance with our Privacy Policy.
We reserve the right to change our Privacy Policy to adapt to our changing business, 
technological innovations, and the needs and expectations of our users. If we make any 
material change to our Privacy Policy concerning how your personal information is used 
or disclosed, then, in addition to including such revision in this Privacy Policy, we will 
post a notice on the Website that such changes to the Privacy Policy are going into 
effect and send you an email notification. You are expected to review this Privacy Policy 
periodically to ensure familiarity with the most current version.
      </div>
    </div>
  </Grid>
  );
}