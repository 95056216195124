import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from "../index";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import StyleIcon from '@mui/icons-material/Style';
import Grid from '@mui/material/Grid';
import ButtonCustomStyle from "./ButtonCustomStyle";


  

export default function AreYouSureModal(props) {

  const [theme, toggleTheme, activeTheme] = useContext(ThemeContext);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 500,
    bgcolor: theme.background,
    color: theme.foreground,
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    border: '8px solid #FFFFFF',
  };

  const [open, setOpen] = React.useState(props.op_cl);
  const handleOpen = () => props.setOp_cl(true);
  const handleClose = () => {

    props.setOp_cl(false)
  }

  const doContinue = () => {

    props.doAfterAreYouSure();
    handleClose();
  }

  return (
    <div>
      <Modal
        open={props.op_cl}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center" style={{width:'100%'}}>
                <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center" style={{paddingTop:20}}>
                    <Typography variant="h4">
                      GAS FEE ALERT
                    </Typography>
                </Grid>
                <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center" style={{paddingTop:20}}>
                  DEPENDING ON HOW BUSY THE ETHEREUM NETWORK IS YOUR GAS FEES WILL VARY AND MAY BE EXTREMELY HIGH. PLEASE CHECK YOUR GAS FEES IN METAMASK BEFORE ACCEPTING TRANSACTION FEES. {props.type != "withdraw" ? 'PURCHASING SEMI-ANNUAL OR ANNUAL PLANS SHOULD BE CONSIDERED TO MINIMIZE YOUR GAS FEES IN A LONG RUN.' : null}
                </Grid>
                <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center" style={{paddingTop:20}}>
                    <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                        <Grid container item xs={6} sm={6} justifyContent="center" alignItems="center">
                            <ButtonCustomStyle
                                changeHistory={null}
                                doFnc={handleClose}
                                changeHistoryFrom=''
                                changeHistoryTo=''
                                btnName='Close'         
                            />
                        </Grid>
                        <Grid container item xs={6} sm={6} justifyContent="center" alignItems="center">
                            <ButtonCustomStyle
                                variant="outlined"
                                changeHistory={null}
                                doFnc={doContinue}
                                changeHistoryFrom=''
                                changeHistoryTo=''
                                btnName='CONTINUE'
                                borderRadius={4}
                                fontWeight={700}
                                padding={10}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
      </Modal>
    </div>
  );
}
