import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import XDartImage from '../assets/img/x.png';

//let loadDartIndex = 0;
export default function DartsDesign2Historic(props) {

  let intervalId = 0; 
  const [loadDartIndex, setLoadDartIndex] = React.useState(0);

  const [isLoadDart, setIsLoadDart] = useState(true)

  const colorsArr = {"-100": "#FFFFFF", "2": "#00B050", "1": "#25FF88", "0": "#808080", "-1": "#FFA6A0", "-2": "#FF0000"};


  const [h1, setH1] = React.useState(-100);
  const [h4, setH4] = React.useState(-100);
  const [d1, setD1] = React.useState(-100);
  const [w1, setW1] = React.useState(-100);
  const [m1, setM1] = React.useState(-100);

  useEffect(() => {

    if(props._values != undefined && props._values != null) {
      console.log('nulllllll')
        loadDart(false)
    } else {
      console.log('not nulllllll')
      console.log('props._values = ', props._values)
        loadDart(true)
    }

    
    //loadDart(true)
  }, []);

  const loadDart = (_bool) => {

    let _index = 4;

    setIsLoadDart(_bool)
    //console.log('loadDart = ' + _bool)
    if(_bool) {
      intervalId = setInterval(() => {
        if(_index > 0) {

          _index--;
          //console.log('loadDartIndex = ', _index)
        } else {
          _index = 4;
          //console.log('loadDartIndex = ', _index)
        }
        setLoadDartIndex(_index)
      }, 100);

      return () => clearInterval(intervalId);

    } else {
      clearInterval(intervalId);
    }
    
  }

  //////////////////////////////////////



  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <div style={{margin:'auto', width:200, height:300}}>
                              {
                                props._values != undefined && props._values != null ? 
                                  props._values[0].coin_pair_name == "" ? 
                                    null 
                                  : 
                                    <div style={{cursor:'pointer', fontWeight: 'bold'}}>
                                      {props._values[0].coin_pair_name}
                                    </div>
                                :
                                  null
                              }
                              {!props.hideDart && props._values != undefined && props._values != null ?
                                <div style={{paddingTop:40}}>
                                
                                  <div style={{cursor:'pointer', display: 'flex', width: 200, height: 200, borderRadius: '50%', position:'relative', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{margin:'auto', position:'absolute'}}>
                                      <div style={{
                                          display: 'flex', 
                                          width: 200, 
                                          height: 200, 
                                          borderRadius: '50%', 
                                          position:'relative', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 
                                          background: `radial-gradient(white 40%, transparent 41%), 
                                                      conic-gradient(
                                                        ${!isLoadDart ? colorsArr[props._values[0] != undefined ? props._values[0]._1m : "0"] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff'} 0% 25%, 
                                                        ${!isLoadDart ? colorsArr[props._values[3] != undefined ? props._values[3]._1m : "0"] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff'} 25% 50%, 
                                                        ${!isLoadDart ? colorsArr[props._values[2] != undefined ? props._values[2]._1m : "0"] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff'} 50% 75%, 
                                                        ${!isLoadDart ? colorsArr[props._values[1] != undefined ? props._values[1]._1m : "0"] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff'} 75% 100%
                                                      )`,
                                          transform: `rotate(-45deg)`,
                                      }}>
                                        &nbsp;
                                      </div>
                                    </div>
                                    <div style={{margin:'auto', position:'absolute'}}>
                                      <div style={{
                                          display: 'flex', 
                                          width: 160, 
                                          height: 160, 
                                          border: '2px solid #CCCCCC', 
                                          borderRadius: '50%', 
                                          position:'relative', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 
                                          background: `radial-gradient(white 40%, transparent 41%), 
                                                      conic-gradient(
                                                        ${!isLoadDart ? colorsArr[props._values[0] != undefined ? props._values[0]._1w : "0"] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff'} 0% 25%, 
                                                        ${!isLoadDart ? colorsArr[props._values[3] != undefined ? props._values[3]._1w : "0"] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff'} 25% 50%, 
                                                        ${!isLoadDart ? colorsArr[props._values[2] != undefined ? props._values[2]._1w : "0"] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff'} 50% 75%, 
                                                        ${!isLoadDart ? colorsArr[props._values[1] != undefined ? props._values[1]._1w : "0"] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff'} 75% 100%
                                                      )`,
                                          transform: `rotate(-45deg)`,
                                      }}>
                                        &nbsp;
                                      </div>
                                    </div>
                                    <div style={{margin:'auto', position:'absolute'}}>
                                      <div style={{
                                          display: 'flex', 
                                          width: 120, 
                                          height: 120, 
                                          border: '2px solid #CCCCCC', 
                                          borderRadius: '50%', 
                                          position:'relative', 
                                          justifyContent: 'center', 
                                          alignItems: 'center',
                                          background: `radial-gradient(white 40%, transparent 41%), 
                                                      conic-gradient(
                                                        ${!isLoadDart ? colorsArr[props._values[0] != undefined ? props._values[0]._1d : "0"] : loadDartIndex == 2 ? '#F2F2F2' : '#ffffff'} 0% 25%, 
                                                        ${!isLoadDart ? colorsArr[props._values[3] != undefined ? props._values[3]._1d : "0"] : loadDartIndex == 2 ? '#F2F2F2' : '#ffffff'} 25% 50%, 
                                                        ${!isLoadDart ? colorsArr[props._values[2] != undefined ? props._values[2]._1d : "0"] : loadDartIndex == 2 ? '#F2F2F2' : '#ffffff'} 50% 75%, 
                                                        ${!isLoadDart ? colorsArr[props._values[1] != undefined ? props._values[1]._1d : "0"] : loadDartIndex == 2 ? '#F2F2F2' : '#ffffff'} 75% 100%
                                                      )`,
                                          transform: `rotate(-45deg)`,
                                      }}>
                                        &nbsp;
                                      </div>
                                    </div>
                                    <div style={{margin:'auto', position:'absolute'}}>
                                      <div style={{
                                          display: 'flex', 
                                          width: 80, 
                                          height: 80, 
                                          border: '2px solid #CCCCCC', 
                                          borderRadius: '50%', 
                                          position:'relative', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 
                                          background: `radial-gradient(white 40%, transparent 41%), 
                                                      conic-gradient(
                                                        ${!isLoadDart ? colorsArr[props._values[0] != undefined ? props._values[0]._4h : "0"] : loadDartIndex == 3 ? '#F2F2F2' : '#ffffff'} 0% 25%, 
                                                        ${!isLoadDart ? colorsArr[props._values[3] != undefined ? props._values[3]._4h : "0"] : loadDartIndex == 3 ? '#F2F2F2' : '#ffffff'} 25% 50%, 
                                                        ${!isLoadDart ? colorsArr[props._values[2] != undefined ? props._values[2]._4h : "0"] : loadDartIndex == 3 ? '#F2F2F2' : '#ffffff'} 50% 75%, 
                                                        ${!isLoadDart ? colorsArr[props._values[1] != undefined ? props._values[1]._4h : "0"] : loadDartIndex == 3 ? '#F2F2F2' : '#ffffff'} 75% 100%
                                                      )`,
                                          transform: `rotate(-45deg)`,
                                      }}>
                                        &nbsp;
                                      </div>
                                    </div>
                                    <div style={{margin:'auto', position:'absolute'}}>
                                      <div style={{
                                          display: 'flex', 
                                          width: 40, 
                                          height: 40, 
                                          border: '2px solid #CCCCCC', 
                                          borderRadius: '50%', 
                                          position:'relative', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 
                                          background: `radial-gradient(white 2%, transparent 1%), 
                                                      conic-gradient(
                                                        ${!isLoadDart ? colorsArr[props._values[0] != undefined && props._values[0] != null ? props._values[0]._1h : "0"] : loadDartIndex == 4 ? '#F2F2F2' : '#ffffff'} 0% 25%, 
                                                        ${!isLoadDart ? colorsArr[props._values[3] != undefined ? props._values[3]._1h : "0"] : loadDartIndex == 4 ? '#F2F2F2' : '#ffffff'} 25% 50%, 
                                                        ${!isLoadDart ? colorsArr[props._values[2] != undefined ? props._values[2]._1h : "0"] : loadDartIndex == 4 ? '#F2F2F2' : '#ffffff'} 50% 75%, 
                                                        ${!isLoadDart ? colorsArr[props._values[1] != undefined ? props._values[1]._1h : "0"] : loadDartIndex == 4 ? '#F2F2F2' : '#ffffff'} 75% 100%
                                                      )`,
                                          transform: `rotate(-45deg)`,
                                      }}>
                                        &nbsp;
                                      </div>
                                    </div>
                                    <div style={{margin:'auto', position:'absolute'}}>
                                      <div style={{
                                          display: 'flex', 
                                          width: 200, 
                                          height: 200, 
                                          borderRadius: '50%', 
                                          position:'relative', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 

                                      }}>
                                        <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:3, position:'absolute'}}>1M</p>
                                        <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:23, position:'absolute'}}>1W</p>
                                        <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:43, position:'absolute'}}>1D</p>
                                        <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:63, position:'absolute'}}>4H</p>
                                        <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:83, position:'absolute'}}>1H</p>
                                        <div style={{
                                          width:'100%',
                                          height: `1px #000000 solid`,
                                          backgroundColor: '#CCCCCC',
                                          padding: 1,
                                          transform: `rotate(-45deg)`,
                                          position:'absolute'
                                        }}>
                                          
                                        </div>
                                        <div style={{
                                          width:'100%',
                                          height: `1px #000000 solid`,
                                          backgroundColor: '#CCCCCC',
                                          padding: 1,
                                          transform: `rotate(45deg)`,
                                          position:'absolute'
                                        }}>
                                          
                                        </div>
                                        <p style={{padding:1, fontSize:12, margin:'auto', top:-30, position:'absolute'}}>Today</p>
                                        <p style={{padding:1, fontSize:12, margin:'auto', top:90, left:-43, transform: `rotate(-90deg)`, position:'absolute'}}>Yesterday</p>
                                        <p style={{padding:1, fontSize:12, margin:'auto', top:210, position:'absolute'}}>2 Days Ago</p>
                                        <p style={{padding:1, fontSize:12, margin:'auto', top:90, left:185, whiteSpace: 'nowrap', transform: `rotate(-90deg)`, position:'absolute'}}>3 Days Ago</p>

                                      </div>
                                    </div>
                                    
                                    {/*<div style={{margin:'auto', position:'absolute'}}>
                                      <div style={{
                                          display: 'flex', 
                                          width: 200, 
                                          height: 200,
                                      }}>
                                        <img src={XDartImage} />
                                      </div>
                                    </div>*/}
                                  </div>                 
                                </div>
                              :
                                null
                              }
                </div>

  );
}
