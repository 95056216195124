import React, { useState, useEffect } from 'react';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import GridView from "./GridView";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

export default function RouteAdminInviteAFriendStats(props) {

  

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/AdminInviteAFriendStats', '/')
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')
    }
  }, []);
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const columns = [
    { field: 'user', headerName: 'User', width: 250, editable: false },
    { field: 'role', headerName: 'Role', width: 110, editable: false },
    {
      field: 'totalReferred',
      headerName: 'Total Referred',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: 'currentMonth',
      headerName: 'Current Month',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: 'lastMonth',
      headerName: 'Last Month',
      type:'number',
      width: 130,
      editable: false,
    },
  ];
  
  const rows = [
    {
      id: 1,
      user: 'name@outlook.com',
      role: 'User',
      totalReferred: 4,
      currentMonth: 72,
      lastMonth: 11,
    },
    {
      id: 2,
      user: 'name2@outlook.com',
      role: 'Contributor',
      totalReferred: 37,
      currentMonth: 4,
      lastMonth: 20,
    },
    {
      id: 3,
      user: 'user3@gmail.com',
      role: 'User',
      totalReferred: 38,
      currentMonth: 3,
      lastMonth: 45,
    },
    {
      id: 4,
      user: 'cryptoking@gmail.com',
      role: 'User',
      totalReferred: 21,
      currentMonth: 11,
      lastMonth: 34,
    },
    {
      id: 5,
      user: 'godofcrypto@gmail.com',
      role: 'User',
      totalReferred: 33,
      currentMonth: 20,
      lastMonth: 12,
    },
  ];

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <GridView columns={columns} rows={rows} />
        </Grid>
    </Grid>
  );
}