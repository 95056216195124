import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import {firstLetterValidation, numberWithCommas, roundDecimals, numberToAmericanFormat, roundNumberIfNeeded, checkIfMore8Decimals} from './functions';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import { InputAdornment } from '@mui/material';
import ModalAreYouSureCoinEdit from "./ModalAreYouSureCoinEdit";
import SelectTrend from "./SelectTrend";
import PortfolioWalletCollapsible from "./PortfolioWalletCollapsible";
import PortfolioWalletTotal from "./PortfolioWalletTotal";
import PortfolioTrades from "./PortfolioTrades";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';

import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import ConsumerPost from "./ConsumerPost";
import ButtonCustomStyle from "./ButtonCustomStyle";


import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory, useParams } from "react-router-dom";
import { Divider } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import CoingeckoCurrentCoinPrice from "./CoingeckoCurrentCoinPrice";
import CoingeckoCurrentCoinPriceWarning from "./CoingeckoCurrentCoinPriceWarning";
import QuantityWarning from "./QuantityWarning";
import TxnPriceWarning from "./TxnPriceWarning";

import CoinChart from "./CoinChart";
import ModalOrderConditions from './ModalOrderConditions';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        '& .cold': {
            backgroundColor: '#cccccc',
            color: '#1a3e72',
            height: 200,
        },
        '& .hot': {
            backgroundColor: '#333333',
            color: '#1a3e72',
            height: 200,
        },
    },
    currencyok: {
      color: '#cc0000',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

//let open = false;

export default function RouteDashboardCoinEdit(props) {


  const [openOrderConditions, setOpenOrderConditions] = useState(false);
  
  const [orderConditionOrderId, setOrderConditionOrderId] = useState(0);
  const [orderConditionOrderKey, setOrderConditionOrderKey] = useState('');
  
  const [openOrderConditionsAddDelete, setOpenOrderConditionsAddDelete] = useState('add');
  const [orderConditionInputAction, setOrderConditionInputAction] = useState('buy_limit');
  const [orderConditionInputPrice, setOrderConditionInputPrice] = useState(0);
  const [orderConditionInputLimitPrice, setOrderConditionInputLimitPrice] = useState(0);

  const intervalId = useRef(null);

  const [staticText] = useContext(ConstantsContext);

  let { _id } = useParams();

  if(_id == undefined) {
    _id = 0;
  }
  //const [myPortfolioListArr, setMyPortfolioListArr] = useState(['ADA-USD', 'BCH-USD', 'BTC-USD', 'DOGE-USD', 'ETH-USD', 'LTC-USD', 'SHIB-USD', 'XRP-USD'])

  const classes = useStyles();
  const [theme] = useContext(ThemeContext);

  ///////////////////////////////////////////////////////
  const percMore = 0.1;

  /////////////////////////////////////////////////PORTFOLIO SET VARIABLES

  const [get_virtual_million_usdPressed, setGet_virtual_million_usdPressed] = useState(false);

  //////////////////////////order  
  const [priceHasBeenChangedByUser, setPriceHasBeenChangedByUser] = useState(false);
  const [currentCoinCurrentPrice, setCurrentCoinCurrentPrice] = useState(0);
  const [usd_24h_change, setUsd_24h_change] = useState(0);
  const [currentCoingeckoLeftSideName, setCurrentCoingeckoLeftSideName] = useState('');
  const [currentQuantity, setCurrentQuantity] = useState(0);
  

  const [inputCoin, setInputCoin] = useState(0);
  const [inputQuantity, setInputQuantity] = useState(0);
  const [inputPrice, setInputPrice] = useState(0);
  const [inputLimitPrice, setInputLimitPrice] = useState(0);
  const [inputLimitPricePercent, setInputLimitPricePercent] = useState(1);
  const [inputAction, setInputAction] = useState('buy_limit');
  const [inputTxnPrice, setInputTxnPrice] = useState(0);

  const [filterByMonth, setFilterByMonth] = useState(0);
  const [filterMonthsArr, setFilterMonthsArr] = useState([]);

  const priceHasBeenChangedByUserRef = useRef(priceHasBeenChangedByUser);
  const inputCoinRef = useRef(inputCoin); // Update the reference whenever count changes
  const inputActionRef = useRef(inputAction); // Update the reference whenever count changes
  const inputLimitPricePercentRef = useRef(inputLimitPricePercent);
  const inputQuantityRef = useRef(inputQuantity);
  const currentCoinCurrentPriceRef = useRef(currentCoinCurrentPrice);
  const usd_24h_changeRef = useRef(usd_24h_change);
  const currentCoingeckoLeftSideNameRef = useRef(currentCoingeckoLeftSideName);
  const currentQuantityRef = useRef(currentQuantity);

  
  

  const [pandL, setPandL] = useState(0);
  const [burned, setBurned] = useState(0);
  
  const [shortSellTotal, setShortSellTotal] = useState(0);
  
  const [cash_total_balance_available, setCash_total_balance_available] = useState(0);
  
  
  const [chartVal, setChartVal] = useState('1d')
  
  
  
  /////////////////portfolio order success & errors
  const [portfolioOrderCreateSuccessMessage, setPortfolioOrderCreateSuccessMessage] = useState('');
  const [portfolioOrderCreateValidationMessage, setPortfolioOrderCreateValidationMessage] = useState('');

  const [myPortfolioOrderArr, setMyPortfolioOrderArr] = useState([]);
  const [myPortfolioOrderArrLength, setMyPortfolioOrderArrLength] = useState(myPortfolioOrderArr.length)

  /////////////////////////wallet
  const [walletOrderId, setWalletOrderId] = useState(0);

  
  const [portfolioWalletBalances, setPortfolioWalletBalances] = useState([]);

  const [myPortfolioWalletArr, setMyPortfolioWalletArr] = useState([]);

  
  
  

  /////////////////portfolio wallet success & errors
  const [portfolioWalletCreateSuccessMessage, setPortfolioWalletCreateSuccessMessage] = useState('');
  const [portfolioWalletCreateValidationMessage, setPortfolioWalletCreateValidationMessage] = useState('');

  const [filterByStatus, setFilterByStatus] = useState('all')
  const [filterByCoin, setFilterByCoin] = useState('all')
  const [filterByAction, setFilterByAction] = useState('all')
  
  
  
  

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const d = new Date()
  const nextMonthName = monthNames[d.getMonth() + 1]
  
  
  let gggg;

  const [currentPriceCoingecko, setCurrentPriceCoingecko] = useState(0);
  //fake price of coingecko
  const [fakePriceCoingecko, setFakePriceCoingecko] = useState(0);
  const [fakePriceCoingeckoDiraction, setFakePriceCoingeckoDiraction] = useState('plus');
  let fakePriceCoingeckoOplya = 0;

  const [value, setValue] = useState(0.369);
  const [increase, setIncrease] = useState(true);

  const handleIncrementDecrease = () => {
    if (increase) {
      setValue(prevValue => {
        const increasedValue = prevValue + (prevValue * 0.01);
        if (increasedValue >= (0.369 * 1.1)) {
          setIncrease(false);
        }
        return increasedValue;
      });
    } else {
      setValue(prevValue => {
        const decreasedValue = prevValue - (prevValue * 0.01);
        if (decreasedValue <= (0.369 - 0.369 * 0.1)) {
          setIncrease(true);
        }
        return decreasedValue;
      });
    }
  };
  
  
  const [leftVal, setLeftVal] = React.useState('');
  const [rightVal, setRightVal] = React.useState('');
  const [coinPairNameLeftPartOnly, setCoinPairNameLeftPartOnly] = React.useState('');
  

  /////////////////////////////////////////////param _id
  const [coinPairId, setCoinPairId] = useState(_id);
  const [coinPairName, setCoinPairName] = useState('');
  const [changeBool, setChangeBool] = useState(false);

  ///////////////////////////////////Modal Stuff
    const [open, setOpen] = useState(props.open != undefined ? props.open : false);

    const handleOpen = () => {
        //console.log(setOpen);
        setOpen(true);
        //open = true;
    };

    const handleClose = () => {
        setOpen(false);
        //open = false;
    };

    const handleCloseAgree = () => {

      handleClose();
      if(tempCoinPairId != 0) {

        setChangeBool(false);
        setCoinPairId(tempCoinPairId);
        setCoinPairName(tempCoinPairName);
        changeHistory('/DashboardCoinEdit/' + _id, '/DashboardCoinEdit/' + tempCoinPairId)
      }
      
      //props.doFnc();
    };

    const setChangeBoolFnc = (bool) => {

      setChangeBool(bool)
      //props.setChangeBool(bool);
    }

    const [tempCoinPairId, setTempCoinPairId] = useState(0);
    const [tempCoinPairName, setTempCoinPairName] = useState('');

    /////////////////////////////////////////////

  

  const [myPortfolioListArr, setMyPortfolioListArr] = useState([]);
  
  const [myPortfolioListArrLength, setMyPortfolioListArrLength] = useState(myPortfolioListArr.length)

  const simulateCoinGeckoPrice = () => {

    let _price = fakePriceCoingecko;
    if(fakePriceCoingeckoDiraction == 'plus') {
      if(_price < currentPriceCoingecko + currentPriceCoingecko * 0.1) {

        _price = _price + currentPriceCoingecko * 0.01;
      } else {
  
        setFakePriceCoingeckoDiraction('minus')
      }
    } else {

      if(_price > currentPriceCoingecko - currentPriceCoingecko * 0.1) {

        _price = _price - currentPriceCoingecko * 0.01;
      } else {
  
        setFakePriceCoingeckoDiraction('plus')
      }
    }

    //console.log('_price = ', _price)
    
    setFakePriceCoingecko(_price)
    fakePriceCoingeckoOplya = _price;
  }

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_portfolio_callback(_data) {

    if(_data.status_code != 2) {

    } else {

      let _inputCoin;

      let coin_pair_idsArr_int = [];
      _data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      _data.data.map((value, key) => {

        if(parseInt(coinPairId) == parseInt(value["id"])) {
            setCoinPairNameLeftPartOnly(value["left_side"])
            setCoinPairName(value["left_side"]+'-'+value["right_side"])
        }

        if(key == 0) {
            _inputCoin = parseInt(value["id"])
            setInputCoin(parseInt(value["id"])) 
            getCurrentCoinPairPrice(value["left_side_name"], value["right_side"])
            setCoinPairNameLeftPartOnly(value["left_side"])
        }
      })

      

    
      setMyPortfolioListArr(_data.data)
      setMyPortfolioListArrLength(_data.data.length)

      /*if(inputCoinRef.current != 0) {
        // Access the current state using the countRef.current
        console.log('Current inputCoin:', inputCoinRef.current);
        console.log('Current inputAction:', inputActionRef.current);
        _api_get_crypto_prices()
      }*/

      _api_get_orders_months();
      
      //_api_get_portfolio_wallet()
    }    
  }

  const _api_get_portfolio = () => {

    let data = [
      {'key':'action', 'value': 'get_portfolio'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_portfolio_callback)
  }
  //////////////////////////////////////



  ///////////////////////////////////////////////////////////////////////////////coin pairs buttons
  
  const chooseCoinPairFnc = (id, name) => {
    
    if(changeBool) {

      setTempCoinPairId(id);
      setTempCoinPairName(name);
      
      handleOpen();
      //onClick={() => chooseCoinPairFnc(value["id"])}
    } else {
      setCoinPairId(id);
      setCoinPairName(name);
      changeHistory('/DashboardCoinEdit/' + _id, '/DashboardCoinEdit/' + id)
    }
    //_api_get_analyse(id);
  }
  

  ///////////////////////////from coingecko

  const getCurrentCoinPairPrice = (left_side_id, right_side) => {
    //console.log('left_side_id, right_side = ', left_side_id, right_side)
    const request_str = 'https://api.coingecko.com/api/v3/simple/price?ids=' + left_side_id + '&vs_currencies=' + right_side.toLowerCase();

    axios.get(request_str)
    .then(res => {
      
      let inputTypeCoinGecko = res.data[left_side_id][right_side.toLowerCase()]
      setCurrentPriceCoingecko(inputTypeCoinGecko);
      setFakePriceCoingecko(inputTypeCoinGecko);
      fakePriceCoingeckoOplya = inputTypeCoinGecko;

      setInputPrice(inputTypeCoinGecko)

      if(inputAction == 'buy_stop_limit') {

            let buy_stop_limitVal = Number(inputTypeCoinGecko) + Number(inputTypeCoinGecko) * inputLimitPricePercent / 100
            setInputLimitPrice(buy_stop_limitVal)
        } else if(inputAction == 'sell_stop_limit') {

            let sell_stop_limitVal = Number(inputTypeCoinGecko) - Number(inputTypeCoinGecko) * inputLimitPricePercent / 100
            setInputLimitPrice(sell_stop_limitVal)
        }
    })
    .catch(error => setCurrentPriceCoingecko(0));
  }


  ////////////////////////////////////////_api_get_coin_pair_by_id
function _api_get_coin_pair_by_id_callback(_data) {

    //console.log('_api_get_coin_pair_by_id_callback _data', _data)

    if(_data.status_code != 2) {
      
    } else {
      
        //console.log('_data.data[0] = ', _data.data[0])
        setCoinPairNameLeftPartOnly(_data.data[0].left_side)
        setLeftVal(_data.data[0].left_side_name)
        setRightVal(_data.data[0].right_side)

        if(inputCoinRef.current != 0) {
          // Access the current state using the countRef.current
          //console.log('Current inputCoin:', inputCoinRef.current);
          //console.log('Current inputAction:', inputActionRef.current);
          _api_get_crypto_prices()
        }
    }
  }
  
  const _api_get_coin_pair_by_id = (_coinPairId) => {
  
    //(true);
    let data = [
      {'key':'action', 'value': 'get_coin_pair_by_id'},
      {'key':'coin_pair_id', 'value': _coinPairId},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]
  
    props._apiConnect(staticText.path_darts, data, _api_get_coin_pair_by_id_callback)
  }


  //////////////////////////////////////

    const chooseCoinPairFncSelect = (e) => {

        
      
        setInputCoin(e.target.value)  
        setPriceHasBeenChangedByUser(false)
          
        setInputTxnPrice(0);
        setInputQuantity(0);
        setInputAction('buy_limit')

        //console.log('chooseCoinPairFncSelect filterByMonth = ', filterByMonth)
        if(filterByMonth == 0) {
          ///////////////////start _api_get_crypto_prices
          _api_get_crypto_prices()
        } else {

          _api_get_crypto_prices_month(filterMonthsArr[filterByMonth])
        }
    }

    const chooseMonthFncSelect = (e) => {

      //console.log('chooseMonthFncSelect = ', e.target.value)
      setFilterByMonth(e.target.value)
      if(e.target.value == 0) {
        setChartVal('1d')
        ///////////////////start _api_get_crypto_prices
        _api_get_crypto_prices()
        clearInterval(intervalId.current);
        intervalId.current = setInterval(() => {

          if(inputCoinRef.current != 0) {
            // Access the current state using the countRef.current
            //console.log('Current inputCoin:', inputCoinRef.current);
            //console.log('Current inputAction:', inputActionRef.current);
            _api_get_crypto_prices()
          }
        }, 30000);
      } else {
        
      
        ///////////////////stop _api_get_crypto_prices
        clearInterval(intervalId.current);

        //////////////////////////////////////start _api_get_crypto_prices_month once
        
        _api_get_crypto_prices_month(filterMonthsArr[e.target.value])
        setChartVal('30d')
      }

      setFilterByStatus('all')
      setFilterByCoin('all')
      setFilterByAction('all')

      _api_get_portfolio_order('all', 'all', 'all', filterMonthsArr[e.target.value])
      
      //setSelectedMonth(e.target.value)   
  }

    
    

    const chooseActionFncSelect = useCallback((e) => {

        setInputAction(e.target.value) 
        
        let _priceALittleLess;            

        if(e.target.value == 'buy_limit' || e.target.value == 'sell_stop_limit') {

          _priceALittleLess = Number(currentCoinCurrentPriceRef.current) - Number(currentCoinCurrentPriceRef.current) * percMore / 100
        } else if(e.target.value == 'sell_limit' || e.target.value == 'buy_stop_limit' || e.target.value == 'sell_short') {

          _priceALittleLess = Number(currentCoinCurrentPriceRef.current) + Number(currentCoinCurrentPriceRef.current) * percMore / 100
        } else {

          _priceALittleLess = currentCoinCurrentPriceRef.current;
        }

        setInputPrice(_priceALittleLess)

        if(e.target.value == 'buy_stop_limit') {

            let buy_stop_limitVal = Number(_priceALittleLess) + Number(_priceALittleLess) * inputLimitPricePercent / 100
            setInputLimitPrice(buy_stop_limitVal)
            setInputTxnPrice(buy_stop_limitVal * inputQuantity);
            if(inputLimitPricePercent == 0) {

              setInputLimitPricePercent(1)
            }
        } else if(e.target.value == 'sell_stop_limit') {

            let sell_stop_limitVal = Number(_priceALittleLess) - Number(_priceALittleLess) * inputLimitPricePercent / 100
            setInputLimitPrice(sell_stop_limitVal)
            setInputTxnPrice(sell_stop_limitVal * inputQuantity);
            if(inputLimitPricePercent == 0) {

              setInputLimitPricePercent(1)
            }
        } else {
            setInputTxnPrice(_priceALittleLess * inputQuantity);
        }

        _api_get_crypto_prices()
    }, [inputAction]);

    /////////////////////////////////////////////////////////////////////////

  const setInputQuantityFnc = (val) => {

        setInputQuantity(val);

        if(inputAction == 'buy_stop_limit') {

            let buy_stop_limitVal = Number(inputPrice) + Number(inputPrice) * inputLimitPricePercent / 100
            setInputLimitPrice(buy_stop_limitVal)
            setInputTxnPrice(buy_stop_limitVal * val);
        } else if(inputAction == 'sell_stop_limit') {

            let sell_stop_limitVal = Number(inputPrice) - Number(inputPrice) * inputLimitPricePercent / 100
            setInputLimitPrice(sell_stop_limitVal)
            setInputTxnPrice(sell_stop_limitVal * val);
        } else {

            setInputTxnPrice(inputPrice * val);
        }
  }

  const setInputPriceFnc = (val) => {

        if(inputAction == 'buy_stop_limit') {

            let buy_stop_limitVal = Number(val) + Number(val) * inputLimitPricePercent / 100
            setInputLimitPrice(buy_stop_limitVal)
            setInputTxnPrice(inputQuantity * buy_stop_limitVal);
        } else if(inputAction == 'sell_stop_limit') {

            let sell_stop_limitVal = Number(val) - Number(val) * inputLimitPricePercent / 100
            setInputLimitPrice(sell_stop_limitVal)
            setInputTxnPrice(inputQuantity * sell_stop_limitVal);
        } else {

            setInputTxnPrice(inputQuantity * val);
        }
        setInputPrice(val);
        setPriceHasBeenChangedByUser(true)
  }

  const setInputLimitPriceFnc = (val) => {


        setPriceHasBeenChangedByUser(true)
        setInputLimitPrice(val);

        setInputTxnPrice(inputQuantity * val);

        if(inputAction == 'buy_stop_limit') {

            if(val == Number(inputPrice) + Number(inputPrice) * 1 / 100) {

              setInputLimitPricePercent(1)
            } else if(val == Number(inputPrice) + Number(inputPrice) * 2 / 100) {

              setInputLimitPricePercent(2)
            } else if(val == Number(inputPrice) + Number(inputPrice) * 5 / 100) {

              setInputLimitPricePercent(5)
            } else if(val == Number(inputPrice) + Number(inputPrice) * 10 / 100) {

              setInputLimitPricePercent(10)
            } else {

              setInputLimitPricePercent(0)
            }
        } else if(inputAction == 'sell_stop_limit') {

            if(val == Number(inputPrice) - Number(inputPrice) * 1 / 100) {

              setInputLimitPricePercent(1)
            } else if(val == Number(inputPrice) - Number(inputPrice) * 2 / 100) {

              setInputLimitPricePercent(2)
            } else if(val == Number(inputPrice) - Number(inputPrice) * 5 / 100) {

              setInputLimitPricePercent(5)
            } else if(val == Number(inputPrice) - Number(inputPrice) * 10 / 100) {

              setInputLimitPricePercent(10)
            } else {

              setInputLimitPricePercent(0)
            }
        }
  }
  
  const setInputTxnPriceFnc = (val) => {


        setPriceHasBeenChangedByUser(true)
        setInputTxnPrice(val);
        setInputQuantity(val / inputPrice);
  }

  const setInputLimitPricePercentFnc = (val) => {

    setInputLimitPricePercent(val)

    if(inputAction == 'buy_stop_limit') {

        let buy_stop_limitVal = Number(inputPrice) + Number(inputPrice) * val / 100
        setInputLimitPrice(buy_stop_limitVal)
        setInputTxnPrice(inputQuantity * buy_stop_limitVal);
    } else if(inputAction == 'sell_stop_limit') {

        let sell_stop_limitVal = Number(inputPrice) - Number(inputPrice) * val / 100
        setInputLimitPrice(sell_stop_limitVal)
        setInputTxnPrice(inputQuantity * sell_stop_limitVal);
    } else {

        setInputTxnPrice(inputQuantity * inputPrice);
    }
  }

  ///////////////////PORTFOLIO SET ORDER//////////////////////////////////////////////////////////
  const portfolioSetOrder = () => {

    let isValid = true;

    let _priceALittleLess;
    if(inputAction == 'buy_limit') {

      _priceALittleLess = Number(currentCoinCurrentPriceRef.current) - Number(currentCoinCurrentPriceRef.current) * percMore / 100
    }
    else if(inputAction == 'sell_limit') {

      _priceALittleLess = Number(currentCoinCurrentPriceRef.current) + Number(currentCoinCurrentPriceRef.current) * percMore / 100
    } 
    else if(inputAction == 'sell_short') {

      _priceALittleLess = Number(currentCoinCurrentPriceRef.current) + Number(currentCoinCurrentPriceRef.current) * percMore / 100
    }
    else if(inputAction == 'buy_stop_limit') {

      _priceALittleLess = Number(currentCoinCurrentPriceRef.current) + Number(currentCoinCurrentPriceRef.current) * percMore / 100
    } 
    else if(inputAction == 'sell_stop_limit') {

      _priceALittleLess = Number(currentCoinCurrentPriceRef.current) - Number(currentCoinCurrentPriceRef.current) * percMore / 100
    }


    console.log('inputQuantity = ', inputQuantity)
    if(inputCoin == 0) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Please select some "Coin"')
    } else if(inputQuantity == 0 || inputQuantity == '' || inputQuantity == undefined || inputQuantity == null) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Please provide some "Quantity"')
    }
    else if(inputPrice == 0 || inputPrice == '' || inputPrice == undefined || inputPrice == null) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Please provide some "Price"')
    }
    else if(inputPrice < Number(currentCoinCurrentPriceRef.current) / 10) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage(`Your price is ${Number(Number(currentCoinCurrentPriceRef.current) / inputPrice)} times less than the current price`)    
    }
    else if(inputAction == 'sell_stop_limit' && inputLimitPrice < Number(currentCoinCurrentPriceRef.current) / 10) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage(`Your limit price is ${Number(Number(currentCoinCurrentPriceRef.current) / inputLimitPrice)} times less than the current price`)    
    }
    else if(inputAction == 'buy_limit' && inputPrice > _priceALittleLess) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Your Limit price must be lower than ' + coinPairNameLeftPartOnly + '-USD current market price by at least ' + percMore + '%')    
    }
    else if(inputAction == 'sell_limit' && inputPrice < _priceALittleLess) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Your Limit price must be higher than ' + coinPairNameLeftPartOnly + '-USD current market price by at least ' + percMore + '%')
    }
    else if(inputAction == 'sell_short' && inputPrice < _priceALittleLess) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Your Sell Short Limit price must be higher than ' + coinPairNameLeftPartOnly + '-USD current market price by at least ' + percMore + '%')
    }
    else if(inputAction == 'buy_stop_limit' && (inputLimitPrice == 0 || inputLimitPrice == '' || inputLimitPrice == undefined || inputLimitPrice == null)) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Please provide some "Limit Price" when Action "Buy Stop Limit" selected')
    }
    else if(inputAction == 'buy_stop_limit' && inputLimitPrice < inputPrice) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('"Limit Price" must be higher or equal to "Stop Price"')
    }
    else if(inputAction == 'buy_stop_limit' && inputPrice < _priceALittleLess) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Your Stop price must be higher than ' + coinPairNameLeftPartOnly + '-USD current market price by at least ' + percMore + '%')
    }
    else if(inputAction == 'sell_stop_limit' && (inputLimitPrice == 0 || inputLimitPrice == '' || inputLimitPrice == undefined || inputLimitPrice == null)) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Please provide some "Limit Price" when Action "Sell Stop Limit" selected')
    } 
    else if(inputAction == 'sell_stop_limit' && inputLimitPrice > inputPrice) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('"Limit Price" must be less or equal to "Stop Price"')
    }
    else if(inputAction == 'sell_stop_limit' && inputPrice > _priceALittleLess) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Your Stop price must be lower than ' + coinPairNameLeftPartOnly + '-USD current market price by at least ' + percMore + '%')
    }
    else if(inputTxnPrice == 0 || inputTxnPrice == '' || inputTxnPrice == undefined || inputTxnPrice == null) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Please provide some "Txn Price"')
    }
    else if(pandL < 0) {

      isValid = false;
      setPortfolioOrderCreateValidationMessage('Your portfolio has become illiquid for the month due to negative balance. Please wait till next month to request Next Cash Loan')
    }
    else {

      //console.log('inputAction = ', inputAction)
      if(inputAction == 'buy_stop_limit' || inputAction == 'buy_limit') {

        //console.log('portfolioWalletBalances = ', portfolioWalletBalances)
        if(inputTxnPrice > portfolioWalletBalances[0].quantity_available) {

          isValid = false;
          //setPortfolioOrderCreateValidationMessage('"Txn Price" must be less or equal to CASH Tradeable Value')
          setPortfolioOrderCreateValidationMessage('Trade cannot be submitted due to insufficient balance')
        }

        if(inputTxnPrice > cash_total_balance_available + shortSellTotal) {

          isValid = false;
          //setPortfolioOrderCreateValidationMessage('"Txn Price" must be less or equal to CASH Tradeable Value')
          setPortfolioOrderCreateValidationMessage('Trade cannot be submitted due to insufficient balance')
        }
      }
      else if(inputAction == 'sell_short') {

        
        if(inputTxnPrice > cash_total_balance_available + shortSellTotal) {

          isValid = false;
          //setPortfolioOrderCreateValidationMessage('"Txn Price" must be less or equal to CASH Tradeable Value')
          setPortfolioOrderCreateValidationMessage('Trade cannot be submitted due to insufficient balance. For Sell Short Limit your transaction price must be lower than "Available Cash to create Sell Short Limit"')
        }
      } else {

        portfolioWalletBalances.map((___value, ___key) => {

          //console.log('___value.coin_pair_id = ', ___value.coin_pair_id, ', inputCoin = ', inputCoin)
          if(___value.coin_pair_id == inputCoin) {
  
            if(inputQuantity > ___value.quantity_available) {
  
              isValid = false;
              //setPortfolioOrderCreateValidationMessage('"Txn Price" must be less or equal to ' + ___value.coin_pair_name + ' Tradeable Qty')
              setPortfolioOrderCreateValidationMessage('Trade cannot be submitted due to insufficient balance')
            }
          }
        }) 
      }
      
           
    }

    //console.log('fffffff')

    if(isValid) {

      /*if(inputAction == 'buy_stop_limit' || inputAction == 'buy_limit') {

        console.log('open modal')
        setOpenOrderConditions(true);
      } else {*/

        setPortfolioOrderCreateValidationMessage('')
        setPortfolioOrderCreateSuccessMessage('Order request sent. Please wait a few seconds...')
        _api_set_portfolio_order();
      //}
    } else {

      setPortfolioOrderCreateSuccessMessage('')
    }
    
    /////////////////////////////////////////////////
  }
  

  ////////////////////////////////////////_api_set_portfolio_order
  function _api_set_portfolio_order_callback(_data) {
    //console.log('_api_set_portfolio_order_callback _data', _data)
    if(_data.status_code != 2) {
      
      setPortfolioOrderCreateSuccessMessage('');
      setPortfolioOrderCreateValidationMessage(_data.msg);
    } else {

      setPortfolioOrderCreateSuccessMessage('Your order has been submitted');
      setFilterByStatus('all')
      setFilterByCoin('all')
      setFilterByAction('all')
      _api_get_portfolio_order('all', 'all', 'all', filterMonthsArr[filterByMonth])
      //_api_get_portfolio_wallet()
      
      _api_get_crypto_prices()

      if(Object.keys(_data.protfolioLastOrderResArr).length !== 0) {

        //openOrderOptionsFnc(_data.protfolioLastOrderResArr, 'add')
      }
      
      /*if(inputAction == 'buy_stop_limit' || inputAction == 'buy_limit') {

        console.log('open modal')
        setOpenOrderConditions(true);
      }*/
    }    
  }

  const _api_set_portfolio_order = () => {

    
    let data = [
      {'key':'action', 'value': 'set_portfolio_order'},
      {'key':'coin_pair_id', 'value': inputCoin},
      {'key':'coin_pair_name', 'value': `${coinPairNameLeftPartOnly}-USD`},
      {'key':'coingecko_coin_name', 'value': currentCoingeckoLeftSideNameRef.current},
      {'key':'coingecko_current_price', 'value': currentCoinCurrentPriceRef.current},
      {'key':'order_quantity', 'value': inputQuantity},
      {'key':'order_price', 'value': roundNumberIfNeeded(inputPrice, 8)},
      {'key':'order_price_limit', 'value': roundNumberIfNeeded(inputLimitPrice, 8)},
      {'key':'order_price_limit_percent', 'value': inputLimitPricePercent},
      {'key':'order_action', 'value': inputAction},
      {'key':'order_txn_price', 'value': inputTxnPrice},
      {'key':'order_status', 'value': 'open'},
      {'key':'order_trigger_time', 'value': 0},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    //console.log('_api_set_portfolio_order = ', data)

    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_set_portfolio_order_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_get_portfolio_order
  function _api_get_portfolio_order_callback(_data) {
    //console.log('_api_get_portfolio_order_callback _data', _data)
    if(_data.status_code != 2) {
      
      //setPortfolioOrderCreateSuccessMessage('');
      //setPortfolioWalletCreateValidationMessage(_data.msg);
    } else {

      setMyPortfolioOrderArr(_data.data)
      setMyPortfolioOrderArrLength(_data.data.length)
    }    
  }

  const _api_get_portfolio_order = (_filterByStatus, _filterByCoin, _filterByAction, _year_month) => {

    
    let data = [
      {'key':'action', 'value': 'get_portfolio_order'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'filter_by_status', 'value': _filterByStatus},
      {'key':'filter_by_coin', 'value': _filterByCoin},
      {'key':'filter_by_action', 'value': _filterByAction},
      {'key':'filterByMonthValue', 'value': _year_month},
    ]

    //console.log('_api_get_portfolio_order _year_month = ' + _year_month)
    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_get_portfolio_order_callback)
  }
  //////////////////////////////////////////////

  ////////////////////////////////////////_api_get_orders_months
  const _api_get_orders_months_callback = (_data) => {
    //console.log('_api_get_orders_months_callback _data', _data)
    if(_data.status_code != 2) {

      //setPortfolioOrderCreateSuccessMessage('');
      //setPortfolioWalletCreateValidationMessage(_data.msg);
    } else {

      //console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!_api_get_orders_months_callback = ',_data.data);
      setFilterMonthsArr(_data.data)
      _api_get_portfolio_order(filterByStatus, filterByCoin, filterByAction, _data.data[0])

      if(inputCoinRef.current != 0) {
        // Access the current state using the countRef.current
        //console.log('Current inputCoin:', inputCoinRef.current);
        //console.log('Current inputAction:', inputActionRef.current);
        _api_get_crypto_prices()
      }
    }    
  }

  const _api_get_orders_months = () => {

    
    let data = [
      {'key':'action', 'value': 'get_orders_months'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}   
    ]

    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_get_orders_months_callback)
  }
  //////////////////////////////////////////////
  ///////////////////////PORTFOLIO SET WALLET/////////////////////////////////////////////////////////////////////////////////////

  //////////////////set 1,000,000 monthly reward

  const get_virtual_million_usd = () => {

    setGet_virtual_million_usdPressed(true)

    portfolioSetWallet()
  }

  const portfolioSetWallet = () => {

    let isOkToGetVirtualMillionUSDThisMonth = true;
    

    if(isOkToGetVirtualMillionUSDThisMonth) {

      setPortfolioWalletCreateValidationMessage('')
      setPortfolioWalletCreateSuccessMessage('Wallet request sent. Please wait a few seconds...')
      _api_set_portfolio_wallet();

    } else {

      setPortfolioWalletCreateSuccessMessage('')
    }
    
    /////////////////////////////////////////////////
  }
  

  ////////////////////////////////////////_api_set_portfolio_wallet
  function _api_set_portfolio_wallet_callback(_data) {
    //console.log('_api_set_portfolio_wallet_callback _data', _data)
    if(_data.status_code != 2) {
      
      setPortfolioWalletCreateSuccessMessage('');
      setPortfolioWalletCreateValidationMessage(_data.msg);
    } else {

      setPortfolioWalletCreateSuccessMessage('Your Portfolio now has $1,000,000 cash available');
      //_api_get_portfolio_wallet()
      _api_get_crypto_prices()
    }    
  }

  const _api_set_portfolio_wallet = () => {

    
    let data = [
      {'key':'action', 'value': 'set_portfolio_wallet'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_set_portfolio_wallet_callback)
  }

  ////////////////////////////////////////_api_get_portfolio_wallet
  function _api_get_portfolio_wallet_callback(_data) {
    //console.log('_api_get_portfolio_wallet_callback _data', _data)
    if(_data.status_code != 2) {
      
    } else {

      setMyPortfolioWalletArr(_data.data)
      setGet_virtual_million_usdPressed(_data.data.length > 0)
    }    
  }

  const _api_get_portfolio_wallet = () => {

    
    
    let data = [
      {'key':'action', 'value': 'get_portfolio_wallet'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_get_portfolio_wallet_callback)
  }
  //////////////////////////////////////////////

  
  ////////////////////////////////////////_api_get_crypto_prices
  const _api_get_crypto_prices_callback = useCallback((_data) => {

    //if(filterByMonth == 0) {

                  //console.log('_api_get_crypto_prices_callback _data', _data)
                  if(_data.status_code != 2) {
                    
                  } else {

                    if(_data.dataCoingecko.status) {

                      if(_data.dataCoingecko.status.error_code) {

                        console.log('error_code = ', _data.dataCoingecko.status.error_code)
                        console.log('error_message = ', _data.dataCoingecko.status.error_message)
                      }
                    } else {

                      let _portfolioWalletBalances = [];
                      _portfolioWalletBalances.push({coin_pair_id: 0, coin_pair_name: 'CASH', quantity: _data.cash_total_balance, last_price: 1, usd_24h_change: '', history: _data.cash_history, quantity_available: _data.cash_total_balance + _data.cash_total_balance_cancelable, total_balance_hedge_sell_stop_limit: 0, total_balance_hedge_buy_stop_limit: 0, total_balance_filled_short_sell: 0})
  
                      _data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);
  
                      let _inputTypeCoinGecko;
                      let _usd_24h_change;
                      let _currentCoingeckoLeftSideName;
                      
                      _data.data.map((value, key) => {
  
                        _portfolioWalletBalances.push({coin_pair_id: parseInt(value["id"]), coin_pair_name: value["left_side"]+'-'+value["right_side"], quantity: value["total_balance"], last_price: value["price"], usd_24h_change: value["usd_24h_change"], history: value["history"], quantity_available: value["total_balance"] + value["total_balance_cancelable"] >= 0 ? value["total_balance"] + value["total_balance_cancelable"] : 0, total_balance_hedge_sell_stop_limit: value["total_balance_hedge_sell_stop_limit"], total_balance_hedge_buy_stop_limit: value["total_balance_hedge_buy_stop_limit"], total_balance_filled_short_sell: value["total_balance_filled_short_sell"]})
  
                        if(inputCoinRef.current != 0) {
  
                          if(parseInt(inputCoinRef.current) == parseInt(value["id"])) {
                              _inputTypeCoinGecko = value["price"]
                              _usd_24h_change = value["usd_24h_change"]
                              _currentCoingeckoLeftSideName = value["left_side_name"]
                              setCoinPairNameLeftPartOnly(value["left_side"])
                              setCurrentQuantity(value["total_balance"] + value["total_balance_cancelable"] >= 0 ? value["total_balance"] + value["total_balance_cancelable"] : 0)
                              
                              //console.log(`${value["left_side"]+'-'+value["right_side"]} Current Price (Coingecko) $${numberToAmericanFormat(value["price"])}, value["usd_24h_change"] = ${value["usd_24h_change"]}`)
                          }
  
                          setCurrentCoinCurrentPrice(_inputTypeCoinGecko)
                          setUsd_24h_change(_usd_24h_change)
                          setCurrentCoingeckoLeftSideName(_currentCoingeckoLeftSideName)
  
                          if(priceHasBeenChangedByUserRef.current === false) {
  
                            let _priceALittleLess;            
  
                            //console.log('inputActionRef.current = ',inputActionRef.current);
                            if(inputActionRef.current == 'buy_limit' || inputActionRef.current == 'sell_stop_limit') {
  
                              _priceALittleLess = Number(_inputTypeCoinGecko) - Number(_inputTypeCoinGecko) * percMore / 100
                            } else if(inputActionRef.current == 'sell_limit' || inputActionRef.current == 'buy_stop_limit' || inputActionRef.current == 'sell_short') {
  
                              _priceALittleLess = Number(_inputTypeCoinGecko) + Number(_inputTypeCoinGecko) * percMore / 100
                            } else {
  
                              _priceALittleLess = _inputTypeCoinGecko;
                            }
  
                            setInputPrice(_priceALittleLess)
  
                            if(inputActionRef.current == 'buy_stop_limit') {
  
                                let buy_stop_limitVal = Number(_priceALittleLess) + Number(_priceALittleLess) * inputLimitPricePercentRef.current / 100
                                setInputLimitPrice(buy_stop_limitVal)
                                setInputTxnPrice(buy_stop_limitVal * inputQuantityRef.current)
                            } else if(inputActionRef.current == 'sell_stop_limit') {
  
                                let sell_stop_limitVal = Number(_priceALittleLess) - Number(_priceALittleLess) * inputLimitPricePercentRef.current / 100
                                setInputLimitPrice(sell_stop_limitVal)
                                setInputTxnPrice(sell_stop_limitVal * inputQuantityRef.current)
                            } else {
  
                              setInputTxnPrice(_priceALittleLess * inputQuantityRef.current)
                            }
  
                            
                          }
                        }
                        
                      })
  
                      //console.log('_portfolioWalletBalances = ', _portfolioWalletBalances)
                      setPortfolioWalletBalances(_portfolioWalletBalances)
                      setPandL(_data.p_and_l);
                      setBurned(_data.burned);
                      setShortSellTotal(_data.short_sell_total);
                      
                      //changed 2023-12-17 Yerevan
                      //setCash_total_balance_available(_data.cash_total_balance + _data.cash_total_balance_cancelable + _data.new_short_sell_filled);
                      setCash_total_balance_available(_data.cash_available)
  
                      if(_data.filledNotViewedOrdersResArr.length > 0) {
  
                        setFilterByStatus('all')
                        setFilterByCoin('all')
                        setFilterByAction('all')
                        _api_get_portfolio_order('all', 'all', 'all', filterMonthsArr[filterByMonth])
                        //_api_get_portfolio_wallet()
                        setPortfolioWalletCreateValidationMessage('')
                        setPortfolioWalletCreateSuccessMessage(_data.filledNotViewedOrdersResArr.length + ' order' + (_data.filledNotViewedOrdersResArr.length > 1 ? 's': '') + ' has been filled')
                      }
  
                      setGet_virtual_million_usdPressed(_data.cash_history.length > 0)
                    }
                  }    

                  //txnPricAlerts(inputActionRef.current)
    //}
    
  }, [inputCoin, inputAction, currentCoinCurrentPrice, usd_24h_change, currentCoingeckoLeftSideName, currentQuantity]);

  const _api_get_crypto_prices = useCallback(() => {

    //console.log('!!!!!!!!!!!!!!!!!!!! _api_get_crypto_prices = !!!!!!!!!!!!!!!!!!! filterByMonth = ', filterByMonth)
    //if(filterByMonth == 0) {

              
              let data = [
                {'key':'action', 'value': 'get_crypto_prices'},
                {'key':'loggined_token', 'value': props.userDetails.loggined_token}
              ]

              //console.log('_api_get_crypto_prices_month data = ', data)

              props._apiConnect(staticText.path_darts, data, _api_get_crypto_prices_callback)
    //}
    /* else {

              let data = [
                {'key':'action', 'value': 'get_crypto_prices_month'},
                {'key':'filterByMonthValue', 'value': filterMonthsArr[filterByMonth]},
                {'key':'loggined_token', 'value': props.userDetails.loggined_token}
              ]

              props._apiConnect(staticText.path_darts, data, _api_get_crypto_prices_month_callback)
    }*/
  }, [inputCoin, inputAction, currentCoinCurrentPrice, usd_24h_change, currentCoingeckoLeftSideName]);

  /*const myFunction = useCallback(() => {
    // Access and use the updated state variables
    //console.log('Executing myFunction with count:', inputCoin, inputAction);
  }, [inputCoin, inputAction]);*/
  //////////////////////////////////////////////

  ////////////////////////////////////////_api_get_crypto_prices
  const _api_get_crypto_prices_month_callback = (_data) => {

    //if(filterByMonth > 0) {

                  //console.log('_api_get_crypto_prices_month_callback _data', _data)
                  if(_data.status_code != 2) {
                    
                  } else {

                    let _portfolioWalletBalances = [];
                    _portfolioWalletBalances.push({coin_pair_id: 0, coin_pair_name: 'CASH', quantity: _data.cash_total_balance, last_price: 1, usd_24h_change: '', history: _data.cash_history, quantity_available: _data.cash_total_balance + _data.cash_total_balance_cancelable, total_balance_hedge_sell_stop_limit: 0, total_balance_hedge_buy_stop_limit: 0, total_balance_filled_short_sell: 0})

                    _data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

                    let _inputTypeCoinGecko;
                    let _usd_24h_change;
                    let _currentCoingeckoLeftSideName;
                    
                    _data.data.map((value, key) => {

                      _portfolioWalletBalances.push({coin_pair_id: parseInt(value["id"]), coin_pair_name: value["left_side"]+'-'+value["right_side"], quantity: value["total_balance"], last_price: value["price"], usd_24h_change: value["usd_24h_change"], history: value["history"], quantity_available: value["total_balance"] + value["total_balance_cancelable"] >= 0 ? value["total_balance"] + value["total_balance_cancelable"] : 0, total_balance_hedge_sell_stop_limit: value["total_balance_hedge_sell_stop_limit"], total_balance_hedge_buy_stop_limit: value["total_balance_hedge_buy_stop_limit"], total_balance_filled_short_sell: value["total_balance_filled_short_sell"]})

                      if(inputCoinRef.current != 0) {

                        if(parseInt(inputCoinRef.current) == parseInt(value["id"])) {
                            _inputTypeCoinGecko = value["price"]
                            _usd_24h_change = value["usd_24h_change"]
                            _currentCoingeckoLeftSideName = value["left_side_name"]
                            setCoinPairNameLeftPartOnly(value["left_side"])
                            setCurrentQuantity(value["total_balance"] + value["total_balance_cancelable"] >= 0 ? value["total_balance"] + value["total_balance_cancelable"] : 0)
                            
                            //console.log(`${value["left_side"]+'-'+value["right_side"]} Current Price (Coingecko) $${numberToAmericanFormat(value["price"])}, value["usd_24h_change"] = ${value["usd_24h_change"]}`)
                        }

                        setCurrentCoinCurrentPrice(_inputTypeCoinGecko)
                        setUsd_24h_change(_usd_24h_change)
                        setCurrentCoingeckoLeftSideName(_currentCoingeckoLeftSideName)

                        if(priceHasBeenChangedByUserRef.current === false) {

                          let _priceALittleLess;            

                          //console.log('inputActionRef.current = ',inputActionRef.current);
                          if(inputActionRef.current == 'buy_limit' || inputActionRef.current == 'sell_stop_limit') {

                            _priceALittleLess = Number(_inputTypeCoinGecko) - Number(_inputTypeCoinGecko) * percMore / 100
                          } else if(inputActionRef.current == 'sell_limit' || inputActionRef.current == 'buy_stop_limit' || inputActionRef.current == 'sell_short') {

                            _priceALittleLess = Number(_inputTypeCoinGecko) + Number(_inputTypeCoinGecko) * percMore / 100
                          } else {

                            _priceALittleLess = _inputTypeCoinGecko;
                          }

                          setInputPrice(_priceALittleLess)

                          if(inputActionRef.current == 'buy_stop_limit') {

                              let buy_stop_limitVal = Number(_priceALittleLess) + Number(_priceALittleLess) * inputLimitPricePercentRef.current / 100
                              setInputLimitPrice(buy_stop_limitVal)
                              setInputTxnPrice(buy_stop_limitVal * inputQuantityRef.current)
                          } else if(inputActionRef.current == 'sell_stop_limit') {

                              let sell_stop_limitVal = Number(_priceALittleLess) - Number(_priceALittleLess) * inputLimitPricePercentRef.current / 100
                              setInputLimitPrice(sell_stop_limitVal)
                              setInputTxnPrice(sell_stop_limitVal * inputQuantityRef.current)
                          } else {

                            setInputTxnPrice(_priceALittleLess * inputQuantityRef.current)
                          }

                          
                        }
                      }
                      
                    })

                    //console.log('_portfolioWalletBalances = ', _portfolioWalletBalances)
                    setPortfolioWalletBalances(_portfolioWalletBalances)
                    setPandL(_data.p_and_l);
                    setShortSellTotal(_data.short_sell_total);
                    
                    //setCash_total_balance_available(_data.cash_total_balance + _data.cash_total_balance_cancelable);
                    setCash_total_balance_available(_data.cash_available)

                    /*if(_data.filledNotViewedOrdersResArr.length > 0) {

                      setFilterByStatus('all')
                      setFilterByCoin('all')
                      setFilterByAction('all')
                      _api_get_portfolio_order('all', 'all', 'all', filterMonthsArr[filterByMonth])
                      //_api_get_portfolio_wallet()
                      setPortfolioWalletCreateValidationMessage('')
                      setPortfolioWalletCreateSuccessMessage(_data.filledNotViewedOrdersResArr.length + ' order' + (_data.filledNotViewedOrdersResArr.length > 1 ? 's': '') + ' has been filled')
                      
                    }*/

              
                    setGet_virtual_million_usdPressed(_data.cash_history.length > 0)
                  }

                  //txnPricAlerts(inputActionRef.current)
    //}
    
  };

  const _api_get_crypto_prices_month = (_year_month) => {

    //if(filterByMonth > 0) {

              
              
              let data = [
                {'key':'action', 'value': 'get_crypto_prices_month'},
                {'key':'filterByMonthValue', 'value': _year_month},
                {'key':'loggined_token', 'value': props.userDetails.loggined_token}
              ]

              //console.log('_api_get_crypto_prices_month data = ', data)

              props._apiConnect(staticText.path_darts, data, _api_get_crypto_prices_month_callback)
    //}
  };
  //////////////////////////////////////////////////////////////////////////////////////////

  const doFillOrder = (_orderId) => {

    //console.log('_orderId = ', _orderId)
  }

  const updatePriceDynamicallyAgain = () => {
    setPriceHasBeenChangedByUser(false)
    _api_get_crypto_prices()
  }

  ////////////////////////////////////////_api_cancel_portfolio_order
  function _api_cancel_portfolio_order_callback(_data) {
    //console.log('_api_cancel_portfolio_order_callback _data', _data)
    if(_data.status_code != 2) {
      
      setPortfolioOrderCreateValidationMessage(_data.msg);
      setPortfolioOrderCreateSuccessMessage('');
      _api_get_portfolio_order(filterByStatus, filterByCoin, filterByAction, filterMonthsArr[filterByMonth])
      //_api_get_portfolio_wallet()
      _api_get_crypto_prices()
    } else {

      //
      setPortfolioOrderCreateValidationMessage('');
      setPortfolioOrderCreateSuccessMessage('Your order has been successfully canceled');
      _api_get_portfolio_order(filterByStatus, filterByCoin, filterByAction, filterMonthsArr[filterByMonth])
      //_api_get_portfolio_wallet()
      _api_get_crypto_prices()
    }    
  }

  const _api_cancel_portfolio_order = (_order_id) => {

    
    
    let data = [
      {'key':'action', 'value': 'cancel_portfolio_order'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'order_id', 'value': _order_id}
    ]

    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_cancel_portfolio_order_callback)
  }
  //////////////////////////////////////////////

  const cancelOrder = (_order_id) => {

    //console.log('_order_id = ', _order_id)
    _api_cancel_portfolio_order(_order_id)
  }

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const changeFilterStatus = (_filter) => {

    setFilterByStatus(_filter)
    _api_get_portfolio_order(_filter, filterByCoin, filterByAction, filterMonthsArr[filterByMonth])
  }

  const changeFilterCoin = (_filter) => {

    setFilterByCoin(_filter)
    _api_get_portfolio_order(filterByStatus, _filter, filterByAction, filterMonthsArr[filterByMonth])
  }

  const changeFilterAction = (_filter) => {

    setFilterByAction(_filter)
    _api_get_portfolio_order(filterByStatus, filterByCoin, _filter, filterMonthsArr[filterByMonth])
  }

  const chooseCoinPairFncSelectFilter = (e) => {

    changeFilterCoin(e.target.value) 
  }

  const chooseActionFncSelectFilter = (e) => {

    changeFilterAction(e.target.value)
  }

  const repeatOrder = useCallback((_orderId) => {
    //console.log('repeatOrder = order id = ', _orderId)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    let _coin_pair_id = -100;

    myPortfolioOrderArr.map((value, key) => {

      if(value.id == _orderId) {

        _coin_pair_id = parseInt(value.coin_pair_id)
        //console.log(value)
        //console.log('parseInt(value.coin_pair_id) = ', parseInt(value.coin_pair_id))
        ///////////////////////////////////////////////////////////////////////////
        setPriceHasBeenChangedByUser(true)
        setInputCoin(parseInt(value.coin_pair_id))   
        setCoinPairNameLeftPartOnly(value.coin_pair_name.split("-")[0])
        setInputAction(value.action)
        //_api_get_coin_pair_by_id(value.coin_pair_id)
        ////////////////////////////////////////////////////////////////////////////

        setInputQuantity(value.quantity)
        setInputPrice(value.price)
        setInputLimitPrice(value.price_limit)
        setInputLimitPricePercent(Number(value.price_limit_percent))
        
        setInputTxnPrice(value.txn_price)
        

        _api_get_crypto_prices()
      }
    })
  }, [inputCoin, inputAction, currentCoinCurrentPrice, usd_24h_change, currentCoingeckoLeftSideName])

  const hedgeOrder = useCallback((_orderId) => {
    //console.log('hedgeOrder = order id = ', _orderId)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    let _coin_pair_id = -100;

    

    myPortfolioOrderArr.map((value, key) => {

      if(value.id == _orderId) {

        /////////detect current coingecko price
        let _coingecko_market_price
        portfolioWalletBalances.map((___value, ___key) => {

          if(___value.coin_pair_id == value.coin_pair_id) {
    
            _coingecko_market_price = ___value.last_price
          }
        }) 

        _coin_pair_id = parseInt(value.coin_pair_id)
        //console.log(value)
        //console.log('parseInt(value.coin_pair_id) = ', parseInt(value.coin_pair_id))
        ///////////////////////////////////////////////////////////////////////////
        setPriceHasBeenChangedByUser(true)
        setInputCoin(parseInt(value.coin_pair_id))   
        setCoinPairNameLeftPartOnly(value.coin_pair_name.split("-")[0])
        setInputQuantity(value.quantity)
        let _stop_limitVal;
        if(value.action == 'buy_limit' || value.action == 'buy_stop_limit') {

          setInputAction('sell_stop_limit')

          let _limitVal = Number(_coingecko_market_price) - Number(_coingecko_market_price) * percMore / 100
          setInputPrice(_limitVal)
          _stop_limitVal = _limitVal - _limitVal * 1 / 100
          setInputLimitPrice(_stop_limitVal)
        } else if(value.action == 'sell_limit' || value.action == 'sell_stop_limit') {

          setInputAction('buy_stop_limit')

          let _limitVal = Number(_coingecko_market_price) + Number(_coingecko_market_price) * percMore / 100
          setInputPrice(_limitVal)
          _stop_limitVal = _limitVal + _limitVal * 1 / 100
          setInputLimitPrice(_stop_limitVal)
        } else if(value.action == 'sell_short') {

          setInputAction('buy_stop_limit')

          let _limitVal = Number(_coingecko_market_price) + Number(_coingecko_market_price) * percMore / 100
          setInputPrice(_limitVal)
          _stop_limitVal = _limitVal + _limitVal * 1 / 100
          setInputLimitPrice(_stop_limitVal)
        }
        
        //_api_get_coin_pair_by_id(value.coin_pair_id)
        ////////////////////////////////////////////////////////////////////////////

        
        
        setInputLimitPricePercent(1)
        
        setInputTxnPrice(value.quantity * _stop_limitVal)

        _api_get_crypto_prices()
      }
    })
  }, [inputCoin, inputAction, currentCoinCurrentPrice, usd_24h_change, currentCoingeckoLeftSideName])

  const handleStopLossExitOrderOpen = useCallback((_orderId, add_delete) => {
    console.log('ZZhandleStopLossExitOrderOpen _orderId = ', _orderId)

    let _coin_pair_id = -100;

    myPortfolioOrderArr.map((value, key) => {

      if(value.id == _orderId) {

        /////////detect current coingecko price
        /*let _coingecko_market_price
        portfolioWalletBalances.map((___value, ___key) => {

          if(___value.coin_pair_id == value.coin_pair_id) {
    
            _coingecko_market_price = ___value.last_price
          }
        })
        _coin_pair_id = parseInt(value.coin_pair_id)*/

        console.log('value.action = ', value.action)
        console.log('value.price = ', value.price)
        console.log('value.price_limit = ', value.price_limit)
        
        //openOrderOptionsFnc(value, add_delete)
      }
    })
  }, [inputCoin, inputAction, currentCoinCurrentPrice, usd_24h_change, currentCoingeckoLeftSideName])

  const openOrderOptionsFnc = (value, add_delete) => {

    setOrderConditionInputAction(value.action)
    setOrderConditionInputPrice(Number(value.price))
    if(value.action == 'buy_stop_limit') {
      setOrderConditionInputLimitPrice(Number(value.price_limit))
    } else {
      setOrderConditionInputLimitPrice(0)
    }

    setOrderConditionOrderId(value.id)
    setOrderConditionOrderKey(value.order_key)        
    setOpenOrderConditionsAddDelete(add_delete);
    setOpenOrderConditions(true);
  }

  const changeChart = (_val) => {

    setChartVal(_val)
  }

  const updateOrdersList = () => {

    _api_get_portfolio_order('all', 'all', 'all', filterMonthsArr[filterByMonth])
  }

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
        changeHistory('/DashboardCoinEdit', '/')
       
    } else {
        if(props._path != undefined) {
          _api_get_portfolio()
          setCoinPairId(_id);
        }
    }
  }, []);

  useEffect(() => {
    
    priceHasBeenChangedByUserRef.current = priceHasBeenChangedByUser;
    inputCoinRef.current = inputCoin; // Update the reference whenever count changes
    inputActionRef.current = inputAction; // Update the reference whenever count changes
    inputLimitPricePercentRef.current = inputLimitPricePercent;
    inputQuantityRef.current = inputQuantity;
    currentCoinCurrentPriceRef.current = currentCoinCurrentPrice;
    usd_24h_changeRef.current = usd_24h_change;
    currentCoingeckoLeftSideNameRef.current = currentCoingeckoLeftSideName
    currentQuantityRef.current = currentQuantity
    
  }, [inputCoin, inputAction, priceHasBeenChangedByUser, inputLimitPricePercent, inputQuantity, currentCoinCurrentPrice, usd_24h_change, currentCoingeckoLeftSideName, currentQuantity]);

  useEffect(() => {
    
      intervalId.current = setInterval(() => {

        if(inputCoinRef.current != 0) {
          // Access the current state using the countRef.current
          //console.log('Current inputCoin:', inputCoinRef.current);
          //console.log('Current inputAction:', inputActionRef.current);
          _api_get_crypto_prices()
        }
      }, 30000);
    

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
      
      <DashboardTopMenu changeBool={changeBool} userProfileType={props.userDetails.usertype} _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{textAlign:'center', maxWidth:600, fontSize:18, fontWeight:'bold', paddingBottom:5}}>Welcome to Analyst Virtual Investment Portfolio</div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{textAlign:'center', maxWidth:1000, fontSize:13, fontStyle: 'italic', paddingBottom:10}}>You are not using real money, your orders are not sent for true execution. This virtual portfolio is a simulation but the prices are fetched from Exchanges. You will not loose or gain actual dollars as a result of this portfolio performance.</div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%'}} >
                          <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                              Filter by Month <Tooltip title="Coins' prices will be changed to the prices of the last day of the selected month, unless the current month is selected." placement="right"><HelpOutlineIcon style={{width:15, height:15, cursor:'pointer'}} /></Tooltip>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                              {filterMonthsArr.length > 0 ? 
                                  <NativeSelect
                                    value={filterByMonth}
                                    inputProps={{
                                        name: 'month_name',
                                        id: 'monthselect',
                                    }}
                                    style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                                    onChange={chooseMonthFncSelect}
                                  >
                                    {
                                        filterMonthsArr.map((value, key) => {
                                            return (
                                                <option key={`myMonth_${key}`} value={key}>{key == 0 ? 'Current Month' : key == 1 ? 'Last Month' : value}</option>
                                            );
                                        })
                                    }
                                  </NativeSelect>
                              : 
                                  null
                              }
                          </Grid>
                          {/*<Grid>
                            Selected month: {filterMonthsArr[filterByMonth]}
                          </Grid>
                          <Grid>
                            Selected month key: {filterByMonth}
                          </Grid>*/}
                        </Grid> 
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
            <Grid container spacing={1} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', paddingBottom:10, backgroundColor:theme.infoPanelBackground, borderRadius: 10, paddingRight:15}} >
                
                <Grid item xs={12} sm={12} md={12} lg={6} align="center" justify="center">
                  <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%'}} >
                          <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                              Select a Coin <Tooltip title="You can only see coins that exist in your analyst portfolio for which you provide research." placement="right"><HelpOutlineIcon style={{width:15, height:15, cursor:'pointer'}} /></Tooltip>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                              {myPortfolioListArr.length > 0 ? 
                                  <NativeSelect
                                  value={inputCoin}
                                  inputProps={{
                                      name: 'coinpairselect_name',
                                      id: 'coinpairselect',
                                  }}
                                  style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                                  onChange={chooseCoinPairFncSelect}
                                  >
                                  {
                                      myPortfolioListArr.map((value, key) => {
                                          return (
                                              <option key={`myPortfolioListArr_${key}`} value={parseInt(value["id"])}>{(value["left_side"]+'-'+value["right_side"])}</option>
                                          );
                                      })
                                  }
                                  </NativeSelect>
                                  : null
                              }
                          </Grid>
                          {
                          inputCoin != 0 && coinPairNameLeftPartOnly!= undefined && coinPairNameLeftPartOnly!= '' && currentCoinCurrentPriceRef.current != undefined ? 
                              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{paddingTop:10, paddingBottom:10}}>
                                <CoingeckoCurrentCoinPrice filterByMonth={filterByMonth} filterByMonthValue={filterMonthsArr[filterByMonth]} coinPairNameLeftPartOnly={coinPairNameLeftPartOnly} currentCoinCurrentPrice={currentCoinCurrentPriceRef.current} usd_24h_change={usd_24h_changeRef.current} />
                              </Grid>
                          : 
                              null
                          }
                      </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} align="center" justify="center">
                  {currentCoingeckoLeftSideNameRef.current != undefined && currentCoingeckoLeftSideNameRef.current != '' ? 
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{width:'100%'}}>
                        <CoinChart filterByMonth={filterByMonth} filterByMonthValue={filterMonthsArr[filterByMonth]} val={chartVal} currentCoingeckoLeftSideName={currentCoingeckoLeftSideNameRef.current} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <Grid container spacing={2} columns={10} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%'}} >
                          <Grid item xs={2} sm={2} md={2} lg={2} align="center" justify="center">
                            {filterByMonth == 0 ?
                              <div 
                                style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: chartVal == '1h' ? `1px solid ${theme.btn_color_border}` : ''}}
                                onClick={() => {changeChart('1h')}}
                              >
                                1h
                              </div>
                            :
                              <div 
                                style={{cursor:'default', color:theme.btn_disabled, padding:10, width:23, borderRadius:5, border: chartVal == '1h' ? `1px solid ${theme.btn_color_border}` : ''}}
                              >
                                1h
                              </div>
                            }
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} align="center" justify="center">
                            {filterByMonth == 0 ?
                              <div 
                                style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: chartVal == '4h' ? `1px solid ${theme.btn_color_border}` : ''}}
                                onClick={() => {changeChart('4h')}}
                              >
                                4h
                              </div>
                            :
                              <div 
                                style={{cursor:'default', color:theme.btn_disabled, padding:10, width:23, borderRadius:5, border: chartVal == '4h' ? `1px solid ${theme.btn_color_border}` : ''}}
                              >
                                4h
                              </div>
                            }
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} align="center" justify="center">
                            {filterByMonth == 0 ?
                              <div 
                                style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: chartVal == '1d' ? `1px solid ${theme.btn_color_border}` : ''}}
                                onClick={() => {changeChart('1d')}}
                              >
                                1d
                              </div>
                            :
                              <div 
                                style={{cursor:'default', color:theme.btn_disabled, padding:10, width:23, borderRadius:5, border: chartVal == '1d' ? `1px solid ${theme.btn_color_border}` : ''}}
                              >
                                1d
                              </div>
                            }
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} align="center" justify="center">
                            {filterByMonth == 0 ?
                              <div 
                                style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: chartVal == '7d' ? `1px solid ${theme.btn_color_border}` : ''}}
                                onClick={() => {changeChart('7d')}}
                              >
                                7d
                              </div>
                            :
                              <div 
                                style={{cursor:'default', color:theme.btn_disabled, padding:10, width:23, borderRadius:5, border: chartVal == '7d' ? `1px solid ${theme.btn_color_border}` : ''}}
                              >
                                7d
                              </div>
                            }
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} align="center" justify="center">
                            {filterByMonth == 0 ?
                              <div 
                                style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: chartVal == '30d' ? `1px solid ${theme.btn_color_border}` : ''}}
                                onClick={() => {changeChart('30d')}}
                              >
                                30d
                              </div>
                            :
                              <div 
                                style={{cursor:'default', padding:10, width:23, borderRadius:5, border: chartVal == '30d' ? `1px solid ${theme.btn_color_border}` : ''}}
                              >
                                30d
                              </div>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  :
                    null
                  }
                </Grid>
                {filterByMonth == 0 ?
                <>
                    <Grid item xs={12} sm={12} md={12} lg={4} align="center" justify="center" style={{paddingRight:10}}>
                        
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%'}} >
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{height:50}}>
                                Quantity
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                <NumericFormat
                                  className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
                                  displayType="input"
                                  onValueChange={values => {
                                    setInputQuantityFnc(values.floatValue); setChangeBoolFnc(true)
                                  }} 
                                  value={inputQuantity} 
                                  thousandSeparator="," 
                                  allowNegative={false} 
                                  decimalScale={8} 
                                  prefix={`${coinPairNameLeftPartOnly} `} 
                                />
                            </Grid>
                            {
                            inputActionRef.current == 'sell_limit' || inputActionRef.current == 'sell_stop_limit' ? 
                              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                  <QuantityWarning currentQuantity={currentQuantityRef.current} action={inputActionRef.current} />                          
                              </Grid>
                            :
                              null
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3} align="center" justify="center" style={{paddingRight:10}}>
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%'}} >
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{height:50}}>
                                {inputAction == 'buy_stop_limit' || inputAction == 'sell_stop_limit' ? 'Stop ' : null}Price
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                <NumericFormat
                                  className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
                                  displayType="input"
                                  onValueChange={values => {
                                    setInputPriceFnc(values.floatValue); setChangeBoolFnc(true)
                                  }} 
                                  value={inputPrice} 
                                  thousandSeparator="," 
                                  allowNegative={false} 
                                  decimalScale={8} 
                                  prefix={'$'} 
                                />
                            </Grid>
                            {
                            cash_total_balance_available > 0 ?
                              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                  <CoingeckoCurrentCoinPriceWarning percMore={percMore} action={inputActionRef.current} coinPairNameLeftPartOnly={coinPairNameLeftPartOnly} currentCoinCurrentPrice={currentCoinCurrentPriceRef.current} usd_24h_change={usd_24h_changeRef.current} />                           
                              </Grid>
                            :
                              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                <div style={{fontSize:10}}>insufficient balance</div>
                              </Grid>
                            }
                            {
                            checkIfMore8Decimals(inputPrice, 8) ? 
                              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{fontSize:11}}>
                                  The Price <b style={{color:'#E25C4C'}}>{inputPrice}</b> will be rounded to the 8th decimal position: <b style={{color:'#4EAF0A'}}>{roundNumberIfNeeded(inputPrice, 8)}</b>
                              </Grid>
                            :
                              null
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                {!priceHasBeenChangedByUserRef.current ? 
                                  null
                                :
                                  <>
                                    <div
                                        style={{cursor:'pointer', fontSize:12, width:'100%', align:'center', padding:10, borderRadius:5, border: `1px solid ${theme.btn_color_border}`}}
                                        onClick={() => updatePriceDynamicallyAgain()}                         
                                    >
                                      Show Market Price Again
                                    </div>
                                  </>
                                }
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3} align="center" justify="center" style={{paddingRight:10}}>
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%'}} >
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{height:50}}>
                                Action
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">

                                    <NativeSelect
                                        value={inputAction}
                                        inputProps={{
                                            name: 'actionselect_name',
                                            id: 'actionselect',
                                        }}
                                        style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                                        onChange={chooseActionFncSelect}
                                    >
                                                <option key={`buy_limit`} value={`buy_limit`}>Buy Limit</option>
                                                <option key={`sell_limit`} value={`sell_limit`}>Sell Limit</option>
                                                <option key={`buy_stop_limit`} value={`buy_stop_limit`}>Buy Stop Limit</option>
                                                <option key={`sell_stop_limit`} value={`sell_stop_limit`}>Sell Stop Limit</option>
                                                <option key={`sell_short`} value={`sell_short`}>Sell Short Limit</option>
                                    </NativeSelect>
                            </Grid>
                    {inputAction == 'buy_stop_limit' || inputAction == 'sell_stop_limit' ? 
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                Limit Price {inputLimitPricePercent == 1 || inputLimitPricePercent == 2 || inputLimitPricePercent == 5 || inputLimitPricePercent == 10 ? `(${inputLimitPricePercent}%)` : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                <NumericFormat
                                  className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
                                  displayType="input"
                                  onValueChange={values => {
                                    setInputLimitPriceFnc(values.floatValue); setChangeBoolFnc(true)
                                  }} 
                                  value={inputLimitPrice} 
                                  thousandSeparator="," 
                                  allowNegative={false} 
                                  decimalScale={8} 
                                  prefix={'$'} 
                                />
                            </Grid>
                            {checkIfMore8Decimals(inputLimitPrice, 8) ? 
                              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{fontSize:11}}>
                                  The Limit Price <b style={{color:'#E25C4C'}}>{inputLimitPrice}</b> will be rounded to the 8th decimal position: <b style={{color:'#4EAF0A'}}>{roundNumberIfNeeded(inputLimitPrice, 8)}</b>
                              </Grid>
                            :
                              null
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                              <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%'}} >
                                <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
                                    <div 
                                      style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: inputLimitPricePercent == 1 ? `1px solid ${theme.btn_color_border}` : ''}}
                                      onClick={() => {setInputLimitPricePercentFnc(1)}}
                                    >
                                      1%
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
                                    <div 
                                      style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: inputLimitPricePercent == 2 ? `1px solid ${theme.btn_color_border}` : ''}}
                                      onClick={() => {setInputLimitPricePercentFnc(2)}}
                                    >
                                      2%
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
                                    <div 
                                      style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: inputLimitPricePercent == 5 ? `1px solid ${theme.btn_color_border}` : ''}}
                                      onClick={() => {setInputLimitPricePercentFnc(5)}}
                                    >
                                      5%
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
                                    <div 
                                      style={{cursor:'pointer', padding:10, width:23, borderRadius:5, border: inputLimitPricePercent == 10 ? `1px solid ${theme.btn_color_border}` : ''}}
                                      onClick={() => {setInputLimitPricePercentFnc(10)}}
                                    >
                                      10%
                                    </div>
                                </Grid>
                              </Grid>
                            </Grid>
                        </>
                    :
                        null
                    }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2} align="center" justify="center" style={{paddingRight:10}}>
                        
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%'}} >
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{height:50}}>
                                Txn Price
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                <NumericFormat
                                  className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
                                  displayType="input"
                                  onValueChange={values => {
                                    setInputTxnPriceFnc(values.floatValue); setChangeBoolFnc(true)
                                  }} 
                                  value={inputTxnPrice} 
                                  thousandSeparator="," 
                                  allowNegative={false} 
                                  decimalScale={8} 
                                  prefix={'$'} 
                                />
                            </Grid>
                            {
                            inputActionRef.current == 'buy_limit' || inputActionRef.current == 'buy_stop_limit' || inputActionRef.current == 'sell_short' ? 
                              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                  <TxnPriceWarning shortSellTotal={shortSellTotal} cash_total_balance_available={cash_total_balance_available} action={inputActionRef.current} />                          
                              </Grid>
                            :
                              null
                            }
                            {currentCoingeckoLeftSideNameRef.current != undefined && currentCoingeckoLeftSideNameRef.current != '' ? 
                              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                  <div
                                      style={{cursor:'pointer', width:'100%', align:'center', padding:10, borderRadius:5, border: `1px solid ${theme.btn_color_border}`}}
                                      onClick={() => portfolioSetOrder()}                         
                                  >
                                    Submit
                                  </div>
                              </Grid>
                            :
                              null        
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <Collapse in={portfolioOrderCreateValidationMessage != ''}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setPortfolioOrderCreateValidationMessage('');
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            sx={{ mb: 2 }}
                          >
                            {portfolioOrderCreateValidationMessage}
                          </Alert>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <Collapse in={portfolioOrderCreateSuccessMessage != ''}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setPortfolioOrderCreateSuccessMessage('');
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            sx={{ mb: 2 }}
                          >
                            {portfolioOrderCreateSuccessMessage}
                          </Alert>
                        </Collapse>
                    </Grid>
                    </>
                :
                    null
                }
                <Grid item xs={12} sm={12} md={6} lg={6} container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid columns={12} container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{fontSize:12}}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{paddingBottom:10}}
                    >
                      Filter by Status (select a tab below)
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                      <Grid spacing={2} columns={12} container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center" style={{fontSize:12}}>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
                          <div onClick={() => changeFilterStatus('all')}>
                            <div 
                              style={{cursor:'pointer', padding:10, borderRadius:5, border: filterByStatus == 'all' ? `1px solid ${theme.btn_color_border}` : ''}}
                            >
                              All
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
                          <div onClick={() => changeFilterStatus('open')}>
                            <div 
                              style={{cursor:'pointer', padding:10, borderRadius:5, border: filterByStatus == 'open' ? `1px solid ${theme.btn_color_border}` : ''}}
                            >
                              Open
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
                          <div onClick={() => changeFilterStatus('filled')}>
                            <div 
                              style={{cursor:'pointer', padding:10, borderRadius:5, border: filterByStatus == 'filled' ? `1px solid ${theme.btn_color_border}` : ''}}
                            >
                              Filled
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
                          <div onClick={() => changeFilterStatus('canceled')}>
                            <div 
                              style={{cursor:'pointer', padding:10, borderRadius:5, border: filterByStatus == 'canceled' ? `1px solid ${theme.btn_color_border}` : ''}}
                            >
                              Canceled
                            </div>
                          </div>
                        </Grid>                
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid spacing={2} columns={12} container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{fontSize:12}}
                  >
                    <Grid item xs={6} sm={6} md={6} lg={6} container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid columns={12} container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center" style={{fontSize:12}}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{paddingBottom:10}}
                        >
                          Filter by Coin (select a coin) <Tooltip title="You can only see coins that exist in your analyst portfolio for which you provide research." placement="right"><HelpOutlineIcon style={{width:15, height:15, cursor:'pointer'}} /></Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                  {myPortfolioListArr.length > 0 ? 
                                      <NativeSelect
                                      value={filterByCoin}
                                      inputProps={{
                                          name: 'coinpairselect_name',
                                          id: 'coinpairselect',
                                      }}
                                      style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                                      onChange={chooseCoinPairFncSelectFilter}
                                      >
                                        <option key={`myPortfolioListArrFilter_all`} value="all">All</option>
                                      {
                                          myPortfolioListArr.map((value, key) => {
                                              return (
                                                  <option key={`myPortfolioListArrFilter_${key}`} value={parseInt(value["id"])}>{(value["left_side"]+'-'+value["right_side"])}</option>
                                              );
                                          })
                                      }
                                      </NativeSelect>
                                      : null
                                  }
                        </Grid>
                      </Grid>
                    
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid columns={12} container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center" style={{fontSize:12}}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{paddingBottom:10}}
                        >
                          Filter by Action (select txn type)
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                                <NativeSelect
                                    value={filterByAction}
                                    inputProps={{
                                        name: 'actionselect_name',
                                        id: 'actionselect',
                                    }}
                                    style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                                    onChange={chooseActionFncSelectFilter}
                                >
                                            <option key={`filter_all`} value={`all`}>All</option>
                                            <option key={`filter_buy_limit`} value={`buy_limit`}>Buy Limit</option>
                                            <option key={`filter_sell_limit`} value={`sell_limit`}>Sell Limit</option>
                                            <option key={`filter_buy_stop_limit`} value={`buy_stop_limit`}>Buy Stop Limit</option>
                                            <option key={`filter_sell_stop_limit`} value={`sell_stop_limit`}>Sell Stop Limit</option>
                                            <option key={`filter_sell_short`} value={`sell_short`}>Sell Short Limit</option>
                                            
                                </NativeSelect>
                        </Grid>
                      </Grid>
                    
                    </Grid>
                  </Grid>

                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                    <PortfolioTrades viewMode={null} filterByMonth={filterByMonth} filterByMonthValue={filterMonthsArr[filterByMonth]} hedgeOrder={hedgeOrder} repeatOrder={repeatOrder} cancelOrder={cancelOrder} myPortfolioOrderArr={myPortfolioOrderArr} doFillOrder={doFillOrder} userDetails={props.userDetails} handleStopLossExitOrderOpen={handleStopLossExitOrderOpen} />
                </Grid>
            </Grid>
            
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
            
          <Grid container spacing={1} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', maxWidth:900, backgroundColor:theme.infoPanelBackground, borderRadius: 10, paddingRight:15, paddingBottom:10}} >

                {pandL < 0 ? 
                  <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                      <div
                          style={{fontSize:12, cursor:get_virtual_million_usdPressed ? 'default': 'pointer', width:190, align:'center', padding:10, borderRadius:5, border: `1px solid #cc0000`}}                         
                      >
                        Your portfolio has become illiquid for the month due to negative balance. Please wait till next month to request Next Cash Loan
                      </div>
                  </Grid>
                :
                  null
                }
                {
                filterByMonth == 0 ? 
                  <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                      <div
                          style={{fontSize:12, cursor:get_virtual_million_usdPressed ? 'default': 'pointer', width:190, align:'center', padding:10, borderRadius:5, border: `1px solid ${get_virtual_million_usdPressed ? theme.btn_disabled : theme.btn_color_border}`, color: `${get_virtual_million_usdPressed ? theme.btn_disabled : theme.btn_color_border}`}}
                          onClick={() => get_virtual_million_usd()}                         
                      >
                        {get_virtual_million_usdPressed ? `Next Cash Loan available on ${nextMonthName} 1st` : 'GET VIRTUAL $1,000,000'}
                      </div>
                      <div style={{paddingTop:10, fontSize:10}}>
                      By clicking "GET VIRTUAL $1,000,000" button, you agree to provide research at least once every 3 days for each coin in your portfolio. If you surpass 3 days with no contribution for any coin(s) in your portfolio, the coin(s) will be removed from your portfolio for the month and any positions or open orders for the coin(s) will be closed or canceled immediately.
                      </div>
                  </Grid>
                :
                  null
                }
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                  <Collapse in={portfolioWalletCreateValidationMessage != ''}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setPortfolioWalletCreateValidationMessage('');
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {portfolioWalletCreateValidationMessage}
                    </Alert>
                  </Collapse>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                  <Collapse in={portfolioWalletCreateSuccessMessage != ''}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setPortfolioWalletCreateSuccessMessage('');
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {portfolioWalletCreateSuccessMessage}
                    </Alert>
                  </Collapse>
                </Grid>
                {portfolioWalletBalances.length > 0 ?
                  <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                      <PortfolioWalletCollapsible filterByMonth={filterByMonth} filterByMonthValue={filterMonthsArr[filterByMonth]} portfolioWalletBalances={portfolioWalletBalances} selectedCoin={inputCoinRef.current} />
                  </Grid>
                :
                  null
                }
                <Grid item xs={12} sm={12} md={12} lg={12} align="right" justify="right">
                    <PortfolioWalletTotal filterByMonth={filterByMonth} filterByMonthValue={filterMonthsArr[filterByMonth]} get_virtual_million_usdPressed={get_virtual_million_usdPressed} pandL={pandL} burned={burned} shortSellTotal={shortSellTotal} cash_total_balance_available={cash_total_balance_available} />
                </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{height:30}}></div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{fontSize:10, padding:20, textAlign:'left'}}>
                DISCLAIMER: The material presented on CryptoMarketDarts.com platform has no regard to any specific investment objectives, financial situation or needs of 
                any of its users. This information is presented solely for informational purposes and is not to be construed as a recommendation, solicitation or 
                an offer to buy or sell (long or short) any asset classes covered by CryptoMarketDarts.com platform. None of the content on this platform should 
                be taken as investment advice. CryptoMarketDarts.com or its subsidiary or holding entities or any of the contributing analysts or users should not 
                be seen, treated or otherwise assumed to be investment or financial advisors. The views and opinion expressed in the platform are speculative 
                and do not guarantee any specific result or profit. Trading and investing is extremely high risk and can result in the loss of all of your capital. Any 
                opinions expressed in this platform are subject to change without notice. CryptoMarketDarts.com is not under any obligation to update or keep 
                current the information contained herein. CryptoMarketDarts.com or its subsidiary or holding entities may have an interest in the securities, 
                commodities, cryptocurrencies and or derivatives of any entities referred to in this platform. CryptoMarketDarts.com or its subsidiary or holding 
                entities accept no liability whatsoever for any loss or damage of any kind arising out of the use of all or any part of materials presented on 
                CryptoMarketDarts.com. CryptoMarketDarts.com recommends that you consult with a licensed and qualified professional before making any 
                investment or trading decisions.
            </div>
        </Grid>
      </>
      <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle>You have unsaved data</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to leave?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => handleClose()}>No</Button>
          <Button onClick={() => handleCloseAgree()}>I'm sure</Button>
          </DialogActions>
      </Dialog>
      <ModalOrderConditions
        _apiConnect={props._apiConnect}
        userDetails={props.userDetails}
        open={openOrderConditions}
        setOpen={setOpenOrderConditions}
        alertHeader="Stop Loss & Exit Order"
        openOrderConditionsAddDelete={openOrderConditionsAddDelete}
        order_id={orderConditionOrderId}
        order_key={orderConditionOrderKey}
        inputAction={orderConditionInputAction}
        inputPrice={orderConditionInputPrice}
        inputLimitPrice={orderConditionInputLimitPrice}
        updateOrdersList={updateOrdersList}
      />
    </Grid>
  );
}
