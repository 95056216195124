import React, { useEffect, useState, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import Grid from '@mui/material/Grid';
import Chart from 'chart.js/auto';
import axios from 'axios';
import 'chartjs-adapter-moment';

const CoinChart = ({ filterByMonth, filterByMonthValue, currentCoingeckoLeftSideName, val }) => {
    const chartRef = useRef(null);

    const [theme] = useContext(ThemeContext);
    const [chartData, setChartData] = useState({});
    //const [val, setVal] = useState('1d');
    //const [valDays, setValDays] = useState(1);
    //const [valInterval, setValInterval] = useState(1);
    //const [valUnit, setValUnit] = useState('hour')
  function getMonthTimestamps(month) {
    const [year, monthNumber] = month.split('-');
    
    // Timestamp of the first day, 1st hour, 1st minute, 1st second of the month
    const firstDayTimestamp = new Date(year, monthNumber - 1, 1, 0, 0, 0).getTime();
    
    // Get the number of days in the month
    const lastDay = new Date(year, monthNumber, 0).getDate();
    
    // Timestamp of the last day, 23:59:59 of the month
    const lastDayTimestamp = new Date(year, monthNumber - 1, lastDay, 23, 59, 59).getTime();
    
    return {
      firstDayTimestamp: (firstDayTimestamp/1000),
      lastDayTimestamp: (lastDayTimestamp/1000)
    };
  }
    

  useEffect(() => {
    // Fetch data from the CoinGecko API
    const fetchData = async () => {
      //console.log(`https://api.coingecko.com/api/v3/coins/${currentCoingeckoLeftSideName}/market_chart`)
      try {
        let response
        if(filterByMonth == 0) {
          response = await axios.get(
            `https://api.coingecko.com/api/v3/coins/${currentCoingeckoLeftSideName}/market_chart`,
            {
              params: {
                vs_currency: 'usd',
                days: changeChart(val).ValDays,
                //interval: changeChart(val).ValInterval, // 4 hours in seconds
              },
            }
          );
        } else {

          let filterByMonthValueArr = getMonthTimestamps(filterByMonthValue);
          console.log(filterByMonthValueArr);
          response = await axios.get(
            `https://api.coingecko.com/api/v3/coins/${currentCoingeckoLeftSideName}/market_chart/range`,
            {
              params: {
                vs_currency: 'usd',
                from: filterByMonthValueArr.firstDayTimestamp,
                to: filterByMonthValueArr.lastDayTimestamp, // 4 hours in seconds
              },
            }
          );
        }
        const data = response.data;
        // Process the data and extract necessary values
        const prices = data.prices.map((entry) => entry[1]);
        const dates = data.prices.map((entry) => new Date(entry[0]));

        // Set up the chart data
        setChartData({
          labels: dates,
          datasets: [
            {
              label: 'Price (USD)',
              data: prices,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              pointRadius: 0, // Hide data points
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentCoingeckoLeftSideName, val, filterByMonth]);

  useEffect(() => {
    if (chartRef.current) {
        chartRef.current.destroy(); // Destroy previous chart instance
      }
    // Create and update the chart
    const createChart = () => {
      const ctx = document.getElementById(`chart_${currentCoingeckoLeftSideName}`).getContext('2d');
      const newChart = new Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          responsive: true,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: changeChart(val).ValUnit,
              },
            },
            y: {
                beginAtZero: false, // Start y-axis from the minimum price
                ticks: {
                  stepSize: 1000, // Adjust the y-axis step size as needed
                },
                grid: {
                  drawBorder: true, // Show the y-axis line
                  borderDash: [1], // Solid line style
                  borderWidth: 1, // 1-pixel border width
                },
              },
          },
        },
      });

      chartRef.current = newChart; // Store the new chart instance
    };

    if (chartData.labels) {
      createChart();
    }
  }, [chartData]);

  const changeChart = (_type) => {

    let ValDays = 1;
    let ValInterval = 1;
    let ValUnit = 'hour';
    
    if(_type == '1h') {

      //setVal('1h')
      ValDays = 1/24;
      ValInterval = 1;
      ValUnit = 'minute';
    } 
    else if(_type == '4h') {

      //setVal('4h')
      ValDays = 4/24;
      ValInterval = 1;
      ValUnit = 'hour';
    }
    else if(_type == '1d') {

      //setVal('1d')
      ValDays = 1;
      ValInterval = 1;
      ValUnit = 'hour';
    }
    else if(_type == '7d') {

      //setVal('7d')
      ValDays = 7;
      ValInterval = 1;
      ValUnit = 'day';
    }
    else if(_type == '30d') {

      //setVal('30d')
      ValDays = 30;
      ValInterval = 1;
      ValUnit = 'day';
    }

    return {ValDays: ValDays, ValInterval: ValInterval, ValUnit: ValUnit};
  }

  return (
    <div>
      <div style={{ width:'100%', maxWidth:400, height:200}}>
        <canvas id={`chart_${currentCoingeckoLeftSideName}`} style={{ width: '100%'}}></canvas>
      </div>
    </div>
  );
};

export default CoinChart;
