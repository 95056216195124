import React, { useState, useContext } from 'react';
import { ThemeContext } from "../index";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NumericFormat } from 'react-number-format';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 'bold'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  name: string,
  calories: string,
  fat: string,
  carbs: object,
) {
  return { name, calories, fat, carbs };
}



export default function PortfolioWalletTotal(props) {

  const [theme] = useContext(ThemeContext);

  const rows = [
    createData(
      '', 
      '', 
      'Total Portfolio Value:', 
      <NumericFormat
        className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
        displayType="text"
        value={props.pandL} 
        thousandSeparator="," 
        allowNegative={true} 
        decimalScale={8} 
        prefix={`$`} 
        renderText={(value) => <>{value}</>}
      />
    ),
    createData('', '', 'Available Cash:', <NumericFormat
    className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
    displayType="text"
    value={props.cash_total_balance_available} 
    thousandSeparator="," 
    allowNegative={true} 
    decimalScale={8} 
    prefix={`$`} 
    renderText={(value) => <>{value}</>}
  />)/*,
    createData('', '', 'Total "Short Sell Limit" Open Order Market Price:', <NumericFormat
    className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
    displayType="text"
    value={props.shortSellTotal} 
    thousandSeparator="," 
    allowNegative={false} 
    decimalScale={8} 
    prefix={`$`} 
    renderText={(value) => <>{value}</>}
  />),
  createData('', '', 'Available Balance to create "Short Sell":', <NumericFormat
  className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
  displayType="text"
  value={Math.abs(props.cash_total_balance_available) - Math.abs(props.shortSellTotal)} 
  thousandSeparator="," 
  allowNegative={true} 
  decimalScale={8} 
  prefix={`$`} 
  renderText={(value) => <>{value}</>}
/>)*/,
    createData('', '', 'P&L $:', <NumericFormat
    className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
    displayType="text"
    value={props.get_virtual_million_usdPressed ? (props.pandL - 1000000) : 0} 
    thousandSeparator="," 
    allowNegative={true} 
    decimalScale={4} 
    prefix={`$`} 
    renderText={(value) => <>{value}</>}
  />),
    createData('', '', 'P&L %:', <NumericFormat
    className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
    displayType="text"
    value={props.get_virtual_million_usdPressed ? ( props.burned == 0 ? (props.pandL - 1000000) * 100 / 1000000 : -99.9999 ) : 0} 
    thousandSeparator="," 
    allowNegative={true} 
    decimalScale={4} 
    suffix={`%`} 
    renderText={(value) => <>{value}</>}
  />),
  ];
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, key) => (
            <StyledTableRow key={`CustomizedTablesWalletTotal_${key}`}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
