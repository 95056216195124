import React, { useState, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NumericFormat } from 'react-number-format';
import ModalAreYouSureWithParam from "./ModalAreYouSureWithParam";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {convertTimestampToAmerican, convertTimestampToAmericanAmPm, capitalizeFirstLetter} from './functions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
    OrderId: number,
    OrderKeyReal: number,
    OrderKey: string,
    InsertDate: string,
    TriggerDate: string,
    Coin: string,
    Qty: object,
    Price: object,
    PriceLimit: object,
    Action: string,
    TxnPrice: object,
    Status: string,
    CoingeckoCurrentPrice: object,
    ScenarioId: number,
    DeRiskOpened: number,
    ExitOpened: number,
    CancelTradeConditionRight: String,
    OrderOptions: String,
) {
  return { OrderId, OrderKeyReal, OrderKey, InsertDate, TriggerDate, Coin, Qty, Price, PriceLimit, Action, TxnPrice, Status, CoingeckoCurrentPrice, ScenarioId, DeRiskOpened, ExitOpened, CancelTradeConditionRight, OrderOptions };
}

/*const rows = [
  createData('3/1/23 12:00 AM', 'ETHUSD', '0.58651026', '$ 1,770.00', 'Buy Limit', '$ 1,038.12', 'Filled', '-'),
  createData('3/1/24 04:00 AM', 'ETHUSD', '0.56000000', '$ 2,100.00', 'Sell Limit', '$ 1,176.00', 'Completed', '3/1/24 04:15 AM'),
  createData('3/1/25 07:00 AM', 'ETHUSD', '0.25000000', '$ 2,000.00', 'Buy Limit', '$ 500.00', 'Canceled', '3/1/25 07:02 AM'),
  createData('3/1/26 09:00 AM', 'ETHUSD', '0.32100000', '$ 2,100.00', 'Sell Limit', '$ 674.10', 'Filled', '-'),
  createData('3/1/27 10:00 AM', 'ETHUSD', '0.20000000', '$ 2,500.00', 'Sell Limit', '$ 500.00', 'Filled', '-'),
  createData('3/1/28 11:00 AM', 'ETHUSD', '0.39250000', '$ 900.00', 'Sell Stop Limit', '$ 353.25', 'Filled', '-'),
  createData('3/1/29 04:00 AM', 'BTCUSD', '0.04500000', '$ 18,705.00', 'Buy Limit', '$ 841.75', 'Filled', '-'),
];*/

export default function PortfolioTrades(props) {

  console.log('PortfolioTrades = ', props.myPortfolioOrderArr)

  const [theme] = useContext(ThemeContext);
  const [staticText, portfolioAction, portfolioStatus] = useContext(ConstantsContext);

  const [openBtnPressed, setOpenBtnPressed] = useState(false);

  const myPortfolioOrderArr = props.myPortfolioOrderArr;

  let rows = [];

  let order_key_val = '';

  myPortfolioOrderArr.map((value, key) => {
    
    order_key_val = value.order_key;
    if(props.viewMode != null) {
      order_key_val = order_key_val + ' Trader: ' + value.username;
    }
    rows.push(createData(
        value.id, 
        value.order_key,
        <div><div>{value.order_key}</div>{props.viewMode != null ? <div style={{color:'#cc0000'}}>{value.username}</div> : ''}</div>, 
        convertTimestampToAmericanAmPm(value.insert_time), 
        convertTimestampToAmericanAmPm(value.trigger_time), 
        value.coin_pair_name, 
        <NumericFormat
          className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
          displayType="text"
          value={value.quantity} 
          thousandSeparator="," 
          allowNegative={false} 
          decimalScale={8} 
          renderText={(value) => <>{value}</>}
        />, 
        <NumericFormat
          className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
          displayType="text"
          value={value.action == 'buy_stop_limit' || value.action == 'sell_stop_limit' ? value.price_limit : value.price} 
          thousandSeparator="," 
          allowNegative={false} 
          decimalScale={8} 
          prefix={`$`} 
          renderText={(value) => <>{value}</>}
        />,
        <NumericFormat
          className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
          displayType="text"
          value={value.price} 
          thousandSeparator="," 
          allowNegative={false} 
          decimalScale={8} 
          prefix={`$`} 
          renderText={(value) => <>{value}</>}
        />, 
        `${portfolioAction[value.action]}`, 
        <NumericFormat
          className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
          displayType="text"
          value={value.txn_price} 
          thousandSeparator="," 
          allowNegative={false} 
          decimalScale={8} 
          prefix={`$`} 
          renderText={(value) => <>{value}</>}
        />, 
        value.status,
        <NumericFormat
          className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
          displayType="text"
          value={value.coingecko_current_price} 
          thousandSeparator="," 
          allowNegative={false} 
          decimalScale={8} 
          prefix={`$`} 
          renderText={(value) => <>{value}</>}
        />,
        value.scenario_id,
        value.de_risk_opened,
        value.exit_opened,
        value.cancel_trade_condition_right,
        Object.keys(value.order_options).length !== 0 ? <div style={{fontSize:10, paddingTop:10}}><span style={{color:'green'}}>Stop Loss:{value.order_options.stop_loss}%</span>, <span style={{color:'orange'}}>Exit:{value.order_options.take_profit}%</span>{value.order_options.trailing_stop == 1 ? <>, <span style={{color:'blue'}}>Trailing Stop Activation:{value.order_options.trailing_stop_activation}%</span>, <span style={{color:'gray'}}>Trailing Stop Delta:{value.order_options.trailing_stop_delta}%</span></> : null}</div> : null
      )
    )
  })

  const _cancelBtnPressed = () => {
    setOpenBtnPressed(false);
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Order{props.viewMode == null ? '' : '/Trader'}</StyledTableCell>
            <StyledTableCell align="right">Coin</StyledTableCell>
            <StyledTableCell align="right">Qty</StyledTableCell>
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
            <StyledTableCell align="right">Txn Price</StyledTableCell>
            <StyledTableCell align="center">Status/Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, key) => (
            <StyledTableRow id={`order_${row.OrderKey}`} key={`PortfolioTrades_${key}`}>
              <StyledTableCell component="th" scope="row">
                <div>
                  <div>
                    <a href={`#wallet_${row.OrderKey}`} style={{fontSize:10}}>
                      {row.OrderKey} {row.InsertDate} 
                      {
                      row.ScenarioId != 0 ? 
                        <div style={{paddingTop:10}}>
                          {
                          row.ExitOpened == 1 ?
                            <div>
                              <div style={{color:'#666666'}}>De-Risk Canceled</div>
                              <div style={{color:'#00B050'}}>Exit Opened</div>
                            </div>
                          :
                            row.ExitOpened < 0 ? 
                              <div>
                                <div style={{color:'#00B050'}}>Exit Order({row.ExitOpened})</div>
                              </div>
                            : 
                              row.DeRiskOpened == 1 ? 
                                <div>
                                  {row.CancelTradeConditionRight !== '' ? 
                                    <div>
                                      <div style={{color:'#4472C4'}}>New Order</div>
                                      <div style={{color:'#7030A0'}}>De-Risk Opened</div>
                                    </div>
                                  :
                                    <div>
                                      <div style={{color:'#666666'}}>De-Risk Canceled</div>
                                      <div style={{color:'#7030A0'}}>Trailing Opened</div>
                                    </div>
                                  }
                                  
                                </div>
                              : 
                                row.DeRiskOpened < 0 ? 
                                  <div>
                                    <div style={{color:'#7030A0'}}>De-Risk Order({row.DeRiskOpened})</div>
                                  </div>
                                : 
                                  <div>
                                    <div style={{color:'#4472C4'}}>New Order</div>
                                  </div>
                          }
                        </div>
                      : 
                        ''
                      }
                    </a>
                  </div>
                  {/*props.viewMode == null && props.filterByMonth == 0 && (row.Action == "Buy Limit" || row.Action == "Buy Stop Limit") && row.Status == 'open' ? 
                      <div style={{paddingTop:10}}>
                        {row.OrderOptions != null ? 
                          <div
                              style={{cursor:'pointer', fontSize:10, align:'center', padding:10, borderRadius:5, border: `1px solid #cccccc`}}
                              onClick={() => props.handleStopLossExitOrderOpen(row.OrderId, 'delete')}                      
                          >
                              Delete Stop Loss & Exit
                          </div>
                        :
                          <div
                              style={{cursor:'pointer', fontSize:10, align:'center', padding:10, borderRadius:5, border: `1px solid #cccccc`}}
                              onClick={() => props.handleStopLossExitOrderOpen(row.OrderId, 'add')}                      
                          >
                              Add Stop Loss & Exit
                          </div>
                        }
                      </div>
                    :
                      null
                  */}
                  {/*props.viewMode == null && row.OrderOptions != null ? 
                      row.OrderOptions
                    :
                      null
                  */}
                </div>
              </StyledTableCell>
              <StyledTableCell align="right">{row.Coin}</StyledTableCell>
              <StyledTableCell align="right">{row.Qty}</StyledTableCell>
              <StyledTableCell align="right">{row.Price}</StyledTableCell>
              <StyledTableCell align="right">{row.Action}</StyledTableCell>
              <StyledTableCell align="right">{row.TxnPrice}</StyledTableCell>
              <StyledTableCell align="center">
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {portfolioStatus[row.Status]}
                    </AccordionSummary>
                    <AccordionDetails>
                    {props.viewMode == null && props.filterByMonth == 0 && row.Status == 'open' ? 
                        <div style={{paddingBottom:10}}>
                          <ModalAreYouSureWithParam btnTitle="Cancel" open={openBtnPressed} alertHeader={`Cancel Order`} alertMessage={`Are you sure you want to cancel order #${row.OrderKeyReal}?`} rowval={row} cancelFnc={() => _cancelBtnPressed()} doFnc={props.cancelOrder} _param={row.OrderId} />
                        </div>
                      :
                        null
                    }
                    {props.viewMode == null && props.filterByMonth == 0 && row.Status == 'canceled' ? 
                        <div style={{paddingBottom:10}}>
                          <div
                              style={{cursor:'pointer', fontSize:12, width:80, align:'center', padding:10, borderRadius:5, border: `1px solid #cccccc`}}
                              onClick={() => props.repeatOrder(row.OrderId)}                      
                          >
                              Re-Enter Trade
                          </div>
                        </div>
                      :
                        null
                    }
                    {props.viewMode == null && props.filterByMonth == 0 && row.Status == 'filled' ? 
                        <div style={{paddingBottom:10}}>
                          {row.Action == "Buy Limit" || row.Action == "Buy Stop Limit" ?
                            <div
                                style={{cursor:'pointer', fontSize:12, width:80, align:'center', padding:10, borderRadius:5, border: `1px solid #cccccc`}}
                                onClick={() => props.hedgeOrder(row.OrderId)}                      
                            >
                                Create Sell Stop Limit Order
                            </div>
                          :
                            null
                          }
                          {row.Action == "Sell Limit" || row.Action == "Sell Stop Limit" || row.Action == "Short Sell Limit" || row.Action == "Sell Short Limit" ?
                            <div
                                style={{cursor:'pointer', fontSize:12, width:80, align:'center', padding:10, borderRadius:5, border: `1px solid #cccccc`}}
                                onClick={() => props.hedgeOrder(row.OrderId)}                      
                            >
                                Create Buy Stop Limit Order
                            </div>
                          :
                            null
                          }
                        </div>
                      :
                        null
                    }
                    
                    <div style={{fontSize:11, fontWeight:'bold'}}>
                      Creation date
                    </div>
                    <div style={{fontSize:10}}>
                      {row.InsertDate}
                    </div>
                    <div style={{fontSize:11, fontWeight:'bold'}}>
                      Price at Creation date
                    </div>
                    <div style={{fontSize:10}}>
                      {row.CoingeckoCurrentPrice}
                    </div>
                    {row.Status == 'canceled' || row.Status == 'filled' ?
                      props.viewMode == null && props.filterByMonth == 0 ? 
                        <>
                          <div style={{fontSize:11, fontWeight:'bold'}}>
                            {row.Status == 'canceled' ? 'Canceled' : row.Status == 'filled' ? 'Filled' : ''} date
                          </div>
                          <div style={{fontSize:10}}>
                            {row.TriggerDate}
                          </div>
                        </>
                      :
                        null
                    :
                      null
                    } 
                    {row.Action == 'Buy Stop Limit' || row.Action == 'Sell Stop Limit' ?
                      <>
                        <div style={{fontSize:11, fontWeight:'bold'}}>
                          Stop Price 
                        </div>
                        <div style={{fontSize:10}}>
                          {row.CoingeckoCurrentPrice}
                        </div>
                      </>
                    :
                      null
                    }                 
                    </AccordionDetails>
                  </Accordion>
                </>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
