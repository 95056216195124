import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import axios from "axios";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";
import DartsDesign2 from "./DartsDesign2";
import Skeleton from '@mui/material/Skeleton';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import NativeSelect from '@mui/material/NativeSelect';
import SnackbarContent from '@material-ui/core/SnackbarContent';



import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import ConsumerPost from "./ConsumerPost";

import DartLowHighAverage from "./DartLowHighAverage";


import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory, useParams } from "react-router-dom";
import { Divider } from '@mui/material';

import DartsDesign2Historic from "./DartsDesign2Historic";
import DartLowHighAverageHistoric from "./DartLowHighAverageHistoric";

import moment from 'moment'  

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import dayjs from 'dayjs';
import ButtonCustomStyle from "./ButtonCustomStyle";
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
require('dotenv').config()


const useStyles = makeStyles((theme3: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme3.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme3.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

let myDartAverageInfoArr = [];



export default function RouteDashboardCoin(props) {

  const [theme, toggleTheme, activeTheme, dartCurrentHistoricalDate, setDartCurrentHistoricalDateFnc, currentDartDate, setCurrentDartDateFnc] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  let { _id, _cryptopairname, _current_historical } = useParams();

  console.log('_cryptopairname = ', _cryptopairname)
  if(_id == undefined) {
    _id = 0;
  }

  let local_currentHistoricData = dartCurrentHistoricalDate

  const classes = useStyles();
  const theme3 = useTheme();

  const [coinPairId, setCoinPairId] = useState(_id);

  const [cryptopairname, setCryptopairname] = useState(_cryptopairname);

  const [currentPriceCoingecko, setCurrentPriceCoingecko] = useState(0);

  const [myPortfolioListArr, setMyPortfolioListArr] = useState([]);
  //new addings
  const [randomKeyUpdate, setRandomKeyUpdate] = useState(0);

  const [getAnalyseAll, setGetAnalyseAll] = useState([]);
  const [getAnalyseAllCount, setGetAnalyseAllCount] = useState(0);
  

  const [low_average, setLow_average] = useState(0);
  const [high_average, setHigh_average] = useState(0);

  const [low1D_average, setLow1D_average] = useState(0);
  const [high1D_average, setHigh1D_average] = useState(0);

  const [low1W_average, setLow1W_average] = useState(0);
  const [high1W_average, setHigh1W_average] = useState(0);

  const [loading, setLoading] = useState(false);

  const [currentHistoricData, setCurrentHistoricData] = useState(dartCurrentHistoricalDate);
  

  const [hideDart, setHideDart] = useState(true)

  const [dateValue, setDateValue] = React.useState(currentDartDate);

  const [copyToClipboardStr, setCopyToClipboardStr] = useState('Copy to clipboard');

  const [comment, setComment] = React.useState('');
  const [correctText, setCorrectText] = React.useState('');
  const [correctTextForTwitt, setCorrectTextForTwitt] = React.useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [socialPostLoading, setSocialPostLoading] = useState(false);
  const [leftSideName, setLeftSideName] = useState(_cryptopairname.substring(0, _cryptopairname.indexOf("-")));
  

  const handleDateChange = (newValue) => {
    setSocialPostLoading(false)
    setCorrectText('')
    setCorrectTextForTwitt('')
    setDateValue(dayjs(newValue).format('YYYY-MM-DD'));
    setCurrentDartDateFnc(dayjs(newValue).format('YYYY-MM-DD'))
    console.log('newValue = ', newValue, 'handleDateChange newValue = ', dayjs(newValue).format('YYYY-MM-DD'))
    
  };


////////////////////////////////////////_api_get_portfolio
function _api_get_coin_pair_by_id_callback(_data) {

  setLoading(false)
  if(_data.status_code != 2) {
    
    //console.log('_api_get_coin_pair_by_id error = ' + _data.msg);
    //console.log('_api_get_coin_pair_by_id code = ' + _data.status_code);
    
  } else {
    
    console.log('_api_get_coin_pair_by_id_callback _data = ', _data)
    //console.log('_api_get_coin_pair_by_id was successfully sent = ' + _data.data[0].left_side_name + '/' + _data.data[0].right_side);

    _api_get_analyse_average_for_coin_ids(_data.data[0].id, currentHistoricData)

    setLeftSideName(_data.data[0].left_side);
    setCurrentPriceCoingecko(_data.coinPriceArr[0]["close_usd"])
    
    _api_get_analyse_all(_data.data[0].id, _data.data[0].left_side + '-' + _data.data[0].right_side, currentHistoricData);
    ///////////////////////////////////////////////////////////////////////
  }
  
}

const _api_get_coin_pair_by_id = (_coinPairId) => {

  setLoading(true);
  let data = [
    {'key':'action', 'value': 'get_coin_pair_by_id'},
    {'key':'coin_pair_id', 'value': _coinPairId},
    {'key':'loggined_token', 'value': props.userDetails.loggined_token}
  ]

  props._apiConnect(staticText.path_darts, data, _api_get_coin_pair_by_id_callback)
}
//////////////////////////////////////

////////////////////////////////////////_api_get_analyse_all_callback
function _api_get_analyse_all_callback(_data) {

  if(_data.status_code != 2) {
    
    //console.log('get_analyse_all error = ' + _data.msg);
    //console.log('get_analyse_all code = ' + _data.status_code);
    
  } else {
    
    //console.log('get_analyse_all was successfully sent');
    console.log('_api_get_analyse_all_callback _data.data[0]["user_id"] =', _data.data)

    setGetAnalyseAll(_data.data);
    setGetAnalyseAllCount(_data.data.length);
    //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

    //setMyPortfolioListArr(_data.data)
    //make mixed analyse/////////////////////////////////////////////////////////////////////
    if((props.userDetails.user_id == 2056 || props.userDetails.user_id == 84) && _data.data.length > 1 && (currentHistoricData == 'current' || currentHistoricData == 'current_date')) {
 
      let analyseDateForChatGPT;
      if(currentHistoricData == 'current') {

        const current = new Date();
        var tree_days_before = current - 1000 * 60 * 60 * 24 * 3;
        const tree_days_beforeVal = new Date(tree_days_before);
        console.log('_api_get_analyse_all_callback gggggggggggggggggggggggggggggggg current date= ' + current)
        setDateValue(dayjs(current).format('YYYY-MM-DD'));
        analyseDateForChatGPT = dayjs(tree_days_beforeVal).format('YYYY-MM-DD') + ' from ' + dayjs(current).format('YYYY-MM-DD')
      } else {

        analyseDateForChatGPT = dayjs(dateValue).format('YYYY-MM-DD')
      }





      const current = new Date();
      const currentDate = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;


      let commentsPrompt = `Analise date: ${analyseDateForChatGPT}. You are a financial commentator on Bloomberg News. Below are your thoughts about the ${leftSideName} price trend direction. Summarize the output in no more than 5 sentences. Conclude if the price trend direction of ${leftSideName} is bearish, neutral or bullish. Speak from first person and start with "It appears that". Do not mention anything about the news from traditional world. Here are ${_data.data.length} Data points one by one. `;
      let commentsMixed = '';
      _data.data.map((value, key) => {

        commentsMixed = commentsMixed + ' Data point ' + (key + 1) + ':' + value.comment;
        
      })
      commentsPrompt = commentsPrompt + commentsMixed;
      setComment(commentsPrompt)
    }
  }
}

////////////////////////////////////////_api_get_analyse_all_callback
function _api_get_historical_analyse_all_callback(_data) {

  if(_data.status_code != 2) {
    
    //console.log('get_analyse_all error = ' + _data.msg);
    //console.log('get_analyse_all code = ' + _data.status_code);
    
  } else {
    
    //console.log('_api_get_historical_analyse_all was successfully sent', JSON.stringify(_data.data));
    console.log('_api_get_historical_analyse_all was successfully sent');


    setGetAnalyseAll(_data.data);
    console.log('_api_get_analyse_all_callback _data.data[0]["user_id"] =', _data.data)
    setGetAnalyseAllCount(41);
    ///////////////////////////////////////////////////////////////////////
  }
}

const _api_get_analyse_all = (_coinPairId, _coinPairName, _currentHistoricData) => {

  if(_currentHistoricData == 'current') {

    let data = [
      {'key':'action', 'value': 'get_analyse_all'},
      {'key':'coin_pair_id', 'value': _coinPairId},
      {'key':'coin_pair_name', 'value': _coinPairName},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]
  
    props._apiConnect(props._path, data, _api_get_analyse_all_callback)
  } 
  else if(_currentHistoricData == 'current_date') {

    let data = [
      {'key':'action', 'value': 'get_analyse_all'},
      {'key':'coin_pair_id', 'value': _coinPairId},
      {'key':'coin_pair_name', 'value': _coinPairName},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'as_of_day', 'value': currentDartDate}
    ]
  
    props._apiConnect(props._path, data, _api_get_analyse_all_callback)
  } 
  else {

    let data = [
      {'key':'action', 'value': 'get_historical_analyse_all'},
      {'key':'coin_pair_id', 'value': _coinPairId},
      {'key':'coin_pair_name', 'value': _coinPairName},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]
  
    props._apiConnect(props._path, data, _api_get_historical_analyse_all_callback)
  }
  
}
//////////////////////////////////////

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_portfolio_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_portfolio error = ' + _data.msg);
      //console.log('get_portfolio code = ' + _data.status_code);

      
      
    } else {
      
      //console.log('get_portfolio email was successfully sent');

      _data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      setMyPortfolioListArr(_data.data)
      _api_get_coin_pair_by_id(_id);
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_get_portfolio = () => {

    let data = [
      {'key':'action', 'value': 'get_portfolio'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    setHideDart(true)
    props._apiConnect(staticText.path_darts, data, _api_get_portfolio_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_get_analyse_average_for_coin_ids
  function _api_get_analyse_average_for_coin_ids_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_analyse_average_for_coin_ids error = ' + _data.msg);
      //console.log('get_analyse_average_for_coin_ids code = ' + _data.status_code);      
    } else {
      
      //console.log('get_analyse_average_for_coin_ids email was successfully sent');

      _data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);
      //setMyPortfolioListArr(_data.data)

      //console.log('get_analyse_average_for_coin_ids = ' + _data.data)

      myDartAverageInfoArr = _data.data
      setLow_average(_data.data[0]["low_average"])
      setHigh_average(_data.data[0]["high_average"])
      setLow1D_average(_data.data[0]["low1D_average"])
      setHigh1D_average(_data.data[0]["high1D_average"])
      setLow1W_average(_data.data[0]["low1W_average"])
      setHigh1W_average(_data.data[0]["high1W_average"])
      
      setHideDart(false)
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  

  ////////////////////////////////////////_api_get_historical_analyse_average_for_coin_ids
  function _api_get_historical_analyse_average_for_coin_ids_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_analyse_average_for_coin_ids error = ' + _data.msg);
      //console.log('get_analyse_average_for_coin_ids code = ' + _data.status_code);      
    } else {
      
      //_data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);

      myDartAverageInfoArr = _data.data

      setHideDart(false)
      
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_get_analyse_average_for_coin_ids = (_coin_pair_ids, _currentHistoricData) => {

    setRandomKeyUpdate(Math.random())

    if(_currentHistoricData == 'current' || _currentHistoricData == 'watchlist') {

      let data = [
        {'key':'action', 'value': 'get_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids}
      ]
  
      props._apiConnect(staticText.path_darts, data, _api_get_analyse_average_for_coin_ids_callback)
    }
    else if(_currentHistoricData == 'current_date') {

      let data = [
        {'key':'action', 'value': 'get_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids},
        {'key':'as_of_day', 'value': currentDartDate}
      ]
  
      props._apiConnect(staticText.path_darts, data, _api_get_analyse_average_for_coin_ids_callback)
    }
    else {

      let data = [
        {'key':'action', 'value': 'get_historical_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids}
      ]
  
      props._apiConnect(staticText.path_darts, data, _api_get_historical_analyse_average_for_coin_ids_callback)
    }
    
  }
  //////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////coin pairs buttons

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const chooseCoinPairFncSelect = (e) => {

    setSocialPostLoading(false)
    setCorrectText('')
    setCorrectTextForTwitt('')
    setHideDart(true)
    setRandomKeyUpdate(randomIntFromInterval(1,10000))
    chooseCoinPairFnc(e.target.value)
    
  }

  const chooseCurrentHistoryFncSelect = (e) => {

    console.log(e.target.value)
    setRandomKeyUpdate(randomIntFromInterval(1,10000))

    setCurrentHistoricData(e.target.value);
    if(e.target.value == 'current') {

      const current = new Date();
      console.log('gggggggggggggggggggggggggggggggg current date= ' + current)
      setDateValue(dayjs(current).format('YYYY-MM-DD'));
      _api_get_analyse_average_for_coin_ids(coinPairId, e.target.value)
      _api_get_analyse_all(coinPairId, cryptopairname, e.target.value);
    } else {

      _api_get_analyse_average_for_coin_ids(coinPairId, e.target.value)
      _api_get_analyse_all(coinPairId, cryptopairname, e.target.value);
    }

    local_currentHistoricData = e.target.value;
    setDartCurrentHistoricalDateFnc(e.target.value)

  }
    
  const chooseCoinPairFnc = (id) => {
      
    let cryptopairname_temp = '';
    //console.log('chooseCoinPairFnc = ' + parseInt(id))
    myPortfolioListArr.map((value, key) => {

      if(parseInt(value["id"]) == parseInt(id)) {

        cryptopairname_temp = value["left_side"]+'-'+value["right_side"];
        setLeftSideName(value["left_side"]);
        setCryptopairname(value["left_side"]+'-'+value["right_side"]);
        //console.log('chooseCoinPairFnc = ' + (value["left_side"]+'-'+value["right_side"]))
        
      }
    })
    setCoinPairId(id);
    
    _api_get_coin_pair_by_id(id);
    changeHistory('/DashboardCoin/' + _id + '/' + _cryptopairname, '/DashboardCoin/' + id + '/' + cryptopairname_temp)
  }

  /////////////////////////////////////////////////////////////////////////


  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  function Action(props) {
      return (<Button style={{color:"#FFFFFF"}} size="small" onClick={() => {navigator.clipboard.writeText(props.text)}}>
        Copy
      </Button>)
  }

  const doCopyFnc = (_textPath) => {

    navigator.clipboard.writeText(_textPath)
  }

  const setCopyToClipboardStrTimeout = (txt) => {

    navigator.clipboard.writeText(txt)
    setCopyToClipboardStr('Copied!')

    const timer = setTimeout(() => {
      setCopyToClipboardStr('Copy to clipboard')
    }, 2000);
    return () => clearTimeout(timer);
  }

  const handleCorrectClick = async () => {
    console.log('dddd')
    let API_KEY = process.env.REACT_APP_OPENAI_API_KEY_MIXED;
    
    const response = await axios.post(
        "https://api.openai.com/v1/completions",
        {
          prompt: comment,
          model: 'gpt-3.5-turbo-instruct',
          max_tokens: 1200,
          n: 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      //setOpenConfirmModal(true)
      setCorrectText(response.data.choices[0].text.trim());
      
      console.log(response.data.choices[0].text.trim())

      ////TWITTER/////////////////////////////////////////////////////////////////////
    
      console.log('twitt = ', `Write an engaging tweet with a maximum character count of 180 using this text: ${response.data.choices[0].text.trim()}`)
    const responseForTwitt = await axios.post(
        "https://api.openai.com/v1/completions",
        {
          prompt: `Write an engaging tweet on: ${response.data.choices[0].text.trim()}`,
          model: 'gpt-3.5-turbo-instruct',
          max_tokens: 300,
          n: 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      //setOpenConfirmModal(true)
      setCorrectTextForTwitt(responseForTwitt.data.choices[0].text.trim());
      
      console.log(responseForTwitt.data.choices[0].text.trim())
  };

  ////////////////////////////////////////_api_get_portfolio
  function _api_set_analyse_mixed_callback(_data) {
    setSocialPostLoading(false)
    setCorrectText('')
    setCorrectTextForTwitt('')
    console.log('_data', _data)
    if(_data.status_code != 2) {

      setSuccessMessage('');
    } else {

      let coin_pair_idsArr_int = [];

      setSuccessMessage('Successfully Posted to all Socials');
    }
  }

  const _api_set_analyse_mixed = () => {

    setSocialPostLoading(true)
    console.log(myDartAverageInfoArr[0], correctText)
    let data = [
      {'key':'action', 'value': 'set_analyse_mixed'},
      {'key':'coin_pair_name', 'value': myDartAverageInfoArr[0].coin_pair_name},
      {'key':'_1h_average', 'value': myDartAverageInfoArr[0]._1h_average},
      {'key':'_4h_average', 'value': myDartAverageInfoArr[0]._4h_average},
      {'key':'_1w_average', 'value': myDartAverageInfoArr[0]._1w_average},
      {'key':'_1d_average', 'value': myDartAverageInfoArr[0]._1d_average},
      {'key':'_1m_average', 'value': myDartAverageInfoArr[0]._1m_average},
      {'key':'low_average', 'value': numberToAmericanFormat(myDartAverageInfoArr[0].low_average, 4)},
      {'key':'high_average', 'value': numberToAmericanFormat(myDartAverageInfoArr[0].high_average, 4)},
      {'key':'comment', 'value': encodeURIComponent(correctText)},
      {'key':'commentForTwitt', 'value': encodeURIComponent(correctTextForTwitt)},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('data = ', data)

    props._apiConnect(props._path, data, _api_set_analyse_mixed_callback)
  }
  //////////////////////////////////////

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/DashboardCoin', '/')
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')

      _api_get_portfolio();
      console.log('again')
      
    }
  }, [_id, _cryptopairname, dartCurrentHistoricalDate]);

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
      {props.isIframe === false ?
        <DashboardTopMenu selectedDart={_cryptopairname} userProfileType={props.userDetails.usertype} _apiLogOff={props._apiLogOff} userDetails={props.userDetails} backBtn={`${coinPairId}/${cryptopairname}`} />
      :
        null
      }
      <>
        <Grid item xs={12} sm={5} md={5} lg={5} align="center" justify="center">
          <div style={{alignItems: 'center', width:'100%'}}>
          <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%'}} >
            <Grid item xs={4} sm={4} md={4} lg={4} align="center" justify="center">
              {myPortfolioListArr.length > 0 ? 
                <NativeSelect
                  defaultValue={coinPairId}
                  inputProps={{
                    name: 'coinpairselect_name',
                    id: 'coinpairselect',
                  }}
                  style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                  onChange={chooseCoinPairFncSelect}
                >
                  {
                    myPortfolioListArr.map((value, key) => {
                        return (
                            <option key={`myPortfolioListArr_${key}`} value={parseInt(value["id"])}>{(value["left_side"]+'-'+value["right_side"])}</option>
                        );
                    })
                  }
                </NativeSelect>
                : null
              }
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} align="center" justify="center">
              <div style={{width:'100%', paddingLeft:10, backgroundColor:theme.infoPanelBackground, height:24, fontSize:13, paddingTop:8}}>Current Price (Coingecko)</div>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} align="center" justify="center">
              <div style={{width:'100%', paddingLeft:10, backgroundColor:theme.infoPanelBackground, height:26, fontSize:14, paddingTop:6}}>{currentPriceCoingecko == 0 ? 'no result' : numberToAmericanFormat(currentPriceCoingecko, 5)}</div>
            </Grid>
          </Grid>
            
          </div>
          <div style={{paddingTop:20}}></div>
          <div style={{borderRadius: 10, padding:10, border: '1px solid #F2F2F2'}}>
            <div style={{alignItems: 'center', maxWidth:800, backgroundColor:theme.infoPanelBackground, paddingTop:2}}>
                <NativeSelect
                  defaultValue={dartCurrentHistoricalDate}
                  inputProps={{
                    name: 'current_history_select_name',
                    id: 'current_history_select',
                  }}
                  style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                  onChange={chooseCurrentHistoryFncSelect}
                >
                  <option key="current" value="current">Current</option>
                  <option key="current_historic_data" value="current_historic_data">Current + Historic Data</option>
                  <option key="current_date" value="current_date">As of Date</option>
                </NativeSelect>
            </div>
            {dartCurrentHistoricalDate == 'current_date' ? 
              <div style={{alignItems: 'center', maxWidth:800, backgroundColor:'#FFFFFF', paddingTop:10}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        minDate={dayjs(new Date()).subtract(12, 'month')}
                        maxDate={dayjs(new Date()).subtract(3, 'day')}
                        label="Date"
                        inputFormat="YYYY-MM-DD"
                        value={dateValue}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
              </div>
            :
              null
            }
            <div style={{alignItems: 'center', maxWidth:800, backgroundColor:theme.infoPanelBackground, paddingTop:10, paddingBottom:10}}>
                {dartCurrentHistoricalDate == 'current' ? 'Averaged Trend and 1-3 Day Price Outlook (Estimated)' : dartCurrentHistoricalDate == 'current_historic_data' ? 'Sentiment Price Trend Indicator' : 'Averaged Trend Price Outlook for selected date (Estimated)'}
            </div>
            
              {
                myDartAverageInfoArr.length > 0 ?
                  currentHistoricData == 'watchlist' || currentHistoricData == 'current' || currentHistoricData == 'current_date' ? 
                  
                    <Grid container spacing={0} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', paddingTop:20}} >
                      <Grid item xs={12} sm={12} md={12} lg={5} align="center" justify="center">
                        <div style={{alignItems: 'center', maxWidth:800, paddingTop:20}}>
                            <DartsDesign2 
                                key={coinPairId + '_' + randomKeyUpdate} 
                                coinPairId={coinPairId} 
                                name={cryptopairname} 
                                userDetails={props.userDetails}
                                _values={myDartAverageInfoArr[0]}
                            />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={7} align="center" justify="center">
                        <div style={{paddingLeft:10, alignItems: 'center', width:'100%', maxWidth:450}}>
                            <DartLowHighAverage 
                              low={low_average} 
                              high={high_average} 
                              low1D={low1D_average} 
                              high1D={high1D_average} 
                              low1W={low1W_average} 
                              high1W={high1W_average} 
                            />
                        </div>
                      </Grid>
                    </Grid>
                  :
                    myDartAverageInfoArr != undefined && myDartAverageInfoArr !=  null ? 
                      <Grid container spacing={0} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', paddingTop:20}} >
                        <Grid item xs={12} sm={12} md={12} lg={6} align="center" justify="center">
                          <div style={{alignItems: 'center', width:200, maxWidth:800, paddingTop:20}}>
                              <DartsDesign2Historic 
                                  key={coinPairId + '_' + randomKeyUpdate} 
                                  coinPairId={coinPairId} 
                                  name={cryptopairname} 
                                  userDetails={props.userDetails}
                                  _values={myDartAverageInfoArr}
                                  rando={randomKeyUpdate}
                                  hideDart={hideDart}
                              />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} align="center" justify="center">
                          <div style={{paddingLeft:10, alignItems: 'center', width:300, maxWidth:400, paddingTop:20}}>
                              <DartLowHighAverageHistoric 
                                _values={myDartAverageInfoArr}
                              />
                          </div>
                        </Grid>
                      </Grid>
                    :
                      null
                :
                    null
              }
          </div>
          {/*<div style={{borderRadius: 10, padding:10}}>
            <Typography variant="overline" display="block" gutterBottom>
                Embed
            </Typography>
            <SnackbarContent
            style={{textAlign:'left'}}
                message={
                  `<iframe width="100%" height="100%" src="https://cryptomarketdarts.com/DashboardCoin/${coinPairId}/${cryptopairname}/${currentHistoricData}/" frameborder="0" scrolling="no"></iframe>`
                }
                action={<Action text={`<iframe width="100%" height="100%" src="https://cryptomarketdarts.com/DashboardCoin/${coinPairId}/${cryptopairname}/${currentHistoricData}/" frameborder="0" scrolling="no"></iframe>`} />}
            />
          </div>*/}
          <div style={{borderRadius: 10, padding:10, textAlign:'left'}}>
            <Typography variant="overline" display="block" gutterBottom>
                OPEN API
            </Typography>
            <SnackbarContent
            style={{textAlign:'left', width:'80%', overflow: 'auto', whiteSpace:'nowrap'}}
            message={
                  `${staticText.path}/api/darts/index.php?action=${currentHistoricData == 'current' || currentHistoricData == 'current_date' ? 'get_analyse_average_for_coin_ids' : 'get_historical_analyse_average_for_coin_ids'}&coin_pair_ids=${coinPairId}${currentHistoricData == 'current_date' ? '&as_of_day=' + currentDartDate : ''}&api_key=red07home`
                }
            />
            <div style={{paddingTop:20, textAlign:'left', cursor:'pointer'}}>
              <Tooltip title={copyToClipboardStr} arrow>
                <div style={{width:100}} onClick={() => {setCopyToClipboardStrTimeout(`${staticText.path}/api/darts/index.php?action=${currentHistoricData == 'current' || currentHistoricData == 'current_date' ? 'get_analyse_average_for_coin_ids' : 'get_historical_analyse_average_for_coin_ids'}&coin_pair_ids=${coinPairId}${currentHistoricData == 'current_date' ? '&as_of_day=' + currentDartDate : ''}&api_key=red07home`)}}>
                  Copy
                </div>
              </Tooltip>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={7} md={7} lg={7} align="center" justify="center">
          <Grid container spacing={2} columns={12} rowSpacing={3} direction="row" justifyContent="flex-start" alignItems="left" style={{width:'100%', maxWidth:800, fontSize:16}} >

            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
              <div style={{fontSize:15, backgroundColor:theme.infoPanelBackground, padding:6}}>Individual Contributions</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="center" style={{ overflowY: 'scroll', height: 600 }}>
              {
              getAnalyseAll.length > 0 ?
                currentHistoricData == 'current' || currentHistoricData == 'current_date' ? 
                  <>
                    <div style={{textAlign:'left', fontSize:16, fontWeight: 'bold', paddingTop:5, paddingBottom:25}}>
                      Total Reviews: {getAnalyseAllCount}
                    </div>
                    {
                      !loading ? 
                        getAnalyseAll.map((value, key) => {
                            return (
                              <div key={`ConsumerPost_${key}`}>
                                <ConsumerPost 
                                  ddate={value.date}
                                  isForTodayIndex={0}
                                  key={`ConsumerPost_${key}_inside`}
                                  coinPairId={coinPairId} userDetails={props.userDetails} _apiConnect={props._apiConnect} _path={props._path}
                                  id={value.id}
                                  totalCount={getAnalyseAllCount}
                                  reviewNum={key + 1} 
                                  updatedTime={value.date_ago} 
                                  user_id={value.user_id}
                                  username={value.username}
                                  cryptopairname={cryptopairname}
                                  totalReviews={5} 
                                  highEst={value.high}
                                  lowEst={value.low}
                                  high1DEst={value.high1D}
                                  low1DEst={value.low1D}
                                  high1WEst={value.high1W}
                                  low1WEst={value.low1W}
                                  comment={value.comment}
                                  chartLink={value.chart_link}
                                  videoLink={value.video_link}
                                  telegram={value.telegram}
                                  youtube={value.youtube}
                                  twitter={value.twitter}
                                  likeCount={value.like}
                                  dislikeCount={value.dislike}
                                  _1h={value._1h}
                                  _4h={value._4h}
                                  _1d={value._1d}
                                  _1w={value._1w}
                                  _1m={value._1m}
                                  pnl={value.pnl}
                                  burned={value.burned}
                                />
                                <Divider style={{padding:15}} />
                              </div>
                            );
                        })
                      :
                        <>
                          <Box sx={{ width: 500, align:'left' }}>
                            <Skeleton />
                            <Skeleton animation="wave" width="90%" />
                            <Skeleton animation="wave" width="90%" />
                            <Skeleton animation="wave" width="50%" />
                            <Skeleton animation="wave" width="30%" />
                            <Skeleton variant="rectangular" width="90%" height={200} />
                            <Skeleton animation="wave" width="50%" />
                          </Box>
                          <div style={{paddingTop:30}}></div>
                          <Box sx={{ width: 500, align:'left' }}>
                            <Skeleton />
                            <Skeleton animation="wave" width="90%" />
                            <Skeleton animation="wave" width="90%" />
                            <Skeleton animation="wave" width="50%" />
                            <Skeleton animation="wave" width="30%" />
                            <Skeleton variant="rectangular" width="90%" height={200} />
                            <Skeleton animation="wave" width="50%" />
                          </Box>
                          <div style={{paddingTop:30}}></div>
                          <Box sx={{ width: 500, align:'left' }}>
                            <Skeleton />
                            <Skeleton animation="wave" width="90%" />
                            <Skeleton animation="wave" width="90%" />
                            <Skeleton animation="wave" width="50%" />
                            <Skeleton animation="wave" width="30%" />
                            <Skeleton variant="rectangular" width="90%" height={200} />
                            <Skeleton animation="wave" width="50%" />
                          </Box>
                        </>
                    }
                  </>
                :
                  <>
                  {
                  getAnalyseAll.map((_value, _key) => {
                    return (
                      _value.length > 0 ? 
                    <div key={`history_${_key}`}>
                      <div style={{textAlign:'left', fontSize:16, paddingTop:5, paddingBottom:25}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <div style={{fontSize:16, fontWeight: 'bold'}}>
                            Total Reviews: {_value.length}
                          </div>
                          <div style={{paddingLeft:10, color:'#959595'}}>{staticText.historicDateLabels[_key]}</div>
                        </div>
                      </div>
                      {
                        !loading ? 
                          _value.map((value, key) => {
                              return (
                                <div key={`ConsumerPost_${key}`}>
                                  <ConsumerPost 
                                    ddate={value.date}
                                    isForTodayIndex={_key}
                                    key={`ConsumerPost_${key}_inside`}
                                    coinPairId={coinPairId} userDetails={props.userDetails} _apiConnect={props._apiConnect} _path={props._path}
                                    id={value.id}
                                    totalCount={_value.length}
                                    reviewNum={key + 1} 
                                    updatedTime={value.date_ago} 
                                    user_id={value.user_id}
                                    username={value.username}
                                    cryptopairname={cryptopairname}
                                    totalReviews={5} 
                                    highEst={value.high}
                                    lowEst={value.low}
                                    high1DEst={value.high1D}
                                    low1DEst={value.low1D}
                                    high1WEst={value.high1W}
                                    low1WEst={value.low1W}
                                    comment={value.comment}
                                    chartLink={value.chart_link}
                                    videoLink={value.video_link}
                                    telegram={value.telegram}
                                    youtube={value.youtube}
                                    twitter={value.twitter}
                                    likeCount={value.like}
                                    dislikeCount={value.dislike}
                                    _1h={value._1h}
                                    _4h={value._4h}
                                    _1d={value._1d}
                                    _1w={value._1w}
                                    _1m={value._1m}
                                    pnl={value.pnl}
                                    burned={value.burned}
                                  />
                                  <Divider style={{padding:15}} />
                                </div>
                              );
                          })
                        :
                          <>
                            <Box sx={{ width: 500, align:'left' }}>
                              <Skeleton />
                              <Skeleton animation="wave" width="90%" />
                              <Skeleton animation="wave" width="90%" />
                              <Skeleton animation="wave" width="50%" />
                              <Skeleton animation="wave" width="30%" />
                              <Skeleton variant="rectangular" width="90%" height={200} />
                              <Skeleton animation="wave" width="50%" />
                            </Box>
                            <div style={{paddingTop:30}}></div>
                            <Box sx={{ width: 500, align:'left' }}>
                              <Skeleton />
                              <Skeleton animation="wave" width="90%" />
                              <Skeleton animation="wave" width="90%" />
                              <Skeleton animation="wave" width="50%" />
                              <Skeleton animation="wave" width="30%" />
                              <Skeleton variant="rectangular" width="90%" height={200} />
                              <Skeleton animation="wave" width="50%" />
                            </Box>
                            <div style={{paddingTop:30}}></div>
                            <Box sx={{ width: 500, align:'left' }}>
                              <Skeleton />
                              <Skeleton animation="wave" width="90%" />
                              <Skeleton animation="wave" width="90%" />
                              <Skeleton animation="wave" width="50%" />
                              <Skeleton animation="wave" width="30%" />
                              <Skeleton variant="rectangular" width="90%" height={200} />
                              <Skeleton animation="wave" width="50%" />
                            </Box>
                          </>
                          
                      }
                    </div>
                    :
                    null
                    )
                  })
                  }
                  </>
              :
                null
              }             
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={12} rowSpacing={0} direction="row" justifyContent="flex-start" alignItems="left" style={{paddingTop:30, width:'100%', maxWidth:800, fontSize:16}} >
            <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="center">
              <div style={{width:'100%'}}>
                <Typography variant="overline" display="block" gutterBottom>
                    OPEN API
                </Typography>
                <SnackbarContent
                  style={{textAlign:'left', width:'80%', overflow: 'auto', whiteSpace:'nowrap'}}
                  message={
                    `${staticText.path}/api/darts/index.php?action=${currentHistoricData == 'current' || currentHistoricData == 'current_date' ? 'get_analyse_all' : 'get_historical_analyse_all'}&coin_pair_id=${coinPairId}&coin_pair_name=${cryptopairname}${currentHistoricData == 'current_date' ? '&as_of_day=' + currentDartDate : ''}&api_key=red07home`
                  }
                />
                <div style={{paddingTop:20, textAlign:'left', cursor:'pointer'}}>
                  <Tooltip title={copyToClipboardStr} arrow>
                    <div style={{width:100}} onClick={() => {setCopyToClipboardStrTimeout(`${staticText.path}/api/darts/index.php?action=${currentHistoricData == 'current' || currentHistoricData == 'current_date' ? 'get_analyse_all' : 'get_historical_analyse_all'}&coin_pair_id=${coinPairId}&coin_pair_name=${cryptopairname}${currentHistoricData == 'current_date' ? '&as_of_day=' + currentDartDate : ''}&api_key=red07home`)}}>
                      Copy
                    </div>
                  </Tooltip>
                </div>
                
              </div>
            </Grid>
          </Grid>
          {(props.userDetails.user_id == 2056 || props.userDetails.user_id == 84) && getAnalyseAllCount > 1 && (currentHistoricData == 'current' || currentHistoricData == 'current_date') ? 
            <Grid container spacing={2} columns={12} rowSpacing={0} direction="row" justifyContent="flex-start" alignItems="left" style={{paddingTop:30, paddingLeft:30, width:'100%', maxWidth:800, fontSize:16}} >
              <Grid container spacing={0} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', paddingTop:20}} >
              
                <Grid container spacing={2} columns={12} rowSpacing={0} direction="row" justifyContent="flex-start" alignItems="left" style={{paddingTop:30, paddingBottom:30, width:'100%', maxWidth:800, fontSize:16}} >
                    Only for Admin
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                    <TextField
                        sx={theme.inputTextSx}
                        id="outlined-multiline-static"
                        multiline
                        rows={10}
                        placeholder="Your Comments here..."
                        size="small"
                        style={{width:'100%'}}
                        onChange={event => {setComment(event.target.value);}}
                        inputProps={{style: {fontSize: 13, color:theme.foreground}}}
                        defaultValue={comment}
                        value={comment}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                    <Button
                        disabled={comment == ''}
                        onClick={handleCorrectClick}
                    >
                        GENERATE Chat GPT Mixed Version
                    </Button>
                </Grid>
                {correctText == '' ? null : 
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', borderRadius: 10 }}>
                            {getAnalyseAllCount} Data Points' Mixed version
                        </div>
                    </Grid>
                    <Grid container spacing={2} columns={12} rowSpacing={0} direction="row" justifyContent="flex-start" alignItems="left" style={{paddingTop:30, paddingBottom:30, width:'100%', maxWidth:800, fontSize:16}} >
                        Text for Telegram & Discord
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <TextField
                            sx={theme.inputTextSx}
                            id="outlined-multiline-static-telegram-discord"
                            multiline
                            rows={10}
                            placeholder="Comments to post to Telegram & Discord..."
                            size="small"
                            style={{width:'100%'}}
                            onChange={event => {setCorrectText(event.target.value);}}
                            inputProps={{style: {fontSize: 13, color:theme.foreground}}}
                            defaultValue={correctText}
                            value={correctText}
                        />
                    </Grid>
                    <Grid container spacing={2} columns={12} rowSpacing={0} direction="row" justifyContent="flex-start" alignItems="left" style={{paddingTop:30, paddingBottom:30, width:'100%', maxWidth:800, fontSize:16}} >
                        Text for Twitter
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <TextField
                            sx={theme.inputTextSx}
                            id="outlined-multiline-static-twitter"
                            multiline
                            rows={7}
                            placeholder="Comments to post to Twitter..."
                            size="small"
                            style={{width:'100%'}}
                            onChange={event => {setCorrectTextForTwitt(event.target.value);}}
                            inputProps={{style: {fontSize: 13, color:theme.foreground}}}
                            defaultValue={correctTextForTwitt}
                            value={correctTextForTwitt}
                        />
                    </Grid>
                    <Grid container spacing={2} columns={12} rowSpacing={0} direction="row" justifyContent="flex-start" alignItems="left" style={{paddingTop:30, paddingBottom:30, width:'100%', maxWidth:800, fontSize:16}} >
                        <span style={{color:correctTextForTwitt.length > 280 ? '#cc0000' : '#FFFFFF'}}>{correctTextForTwitt.length}</span>/280
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end" style={{paddingRight:20, paddingTop:20}}>
                        <div style={{width:350, textAlign:'right'}}>
                          {correctText != '' ?
                            <ButtonCustomStyle
                                changeHistory={null}
                                doFnc={_api_set_analyse_mixed}
                                changeHistoryFrom=''
                                changeHistoryTo=''
                                btnName='Post to Socials'                      
                            />
                          :
                            <div style={{fontSize:16, color: '#cccccc' }}>
                                Post to Socials
                            </div>
                          }
                        </div>
                    </Grid>
                    {socialPostLoading ?
                      <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                          Posting to Socials... Please wait a second
                      </Grid>
                    :
                      null
                    }
                    {successMessage != '' ? 
                      <div style={{width:230, textAlign:'center'}}>
                          <Alert severity="success">{successMessage}</Alert>
                      </div>
                    : 
                      null
                    }
                  </>
                }
                
              </Grid>
            </Grid>
          :
              null
          }
        </Grid>
        
        {props.isIframe === false ?
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{height:30}}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{fontSize:10, padding:20, textAlign:'left'}}>
                    DISCLAIMER: The material presented on CryptoMarketDarts.com platform has no regard to any specific investment objectives, financial situation or needs of 
                    any of its users. This information is presented solely for informational purposes and is not to be construed as a recommendation, solicitation or 
                    an offer to buy or sell (long or short) any asset classes covered by CryptoMarketDarts.com platform. None of the content on this platform should 
                    be taken as investment advice. CryptoMarketDarts.com or its subsidiary or holding entities or any of the contributing analysts or users should not 
                    be seen, treated or otherwise assumed to be investment or financial advisors. The views and opinion expressed in the platform are speculative 
                    and do not guarantee any specific result or profit. Trading and investing is extremely high risk and can result in the loss of all of your capital. Any 
                    opinions expressed in this platform are subject to change without notice. CryptoMarketDarts.com is not under any obligation to update or keep 
                    current the information contained herein. CryptoMarketDarts.com or its subsidiary or holding entities may have an interest in the securities, 
                    commodities, cryptocurrencies and or derivatives of any entities referred to in this platform. CryptoMarketDarts.com or its subsidiary or holding 
                    entities accept no liability whatsoever for any loss or damage of any kind arising out of the use of all or any part of materials presented on 
                    CryptoMarketDarts.com. CryptoMarketDarts.com recommends that you consult with a licensed and qualified professional before making any 
                    investment or trading decisions.
                </div>
            </Grid>
          </>
        :
          null
        }
      </>
    </Grid>
  );
}