import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import ButtonCustomStyle from "./ButtonCustomStyle";

import Alert from '@mui/material/Alert';

const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding:20,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1976D2',
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPasswordConfirm(props) {

  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();

  const [userConfirm, setUserConfirm] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPasswordConfirm, setUserPasswordConfirm] = useState('');
  const [forgotPasswordConfirmChangePassword, setForgotPasswordConfirmChangePassword] = useState(false);

  const userEmailRef = useRef(null);
  const userPasswordRef = useRef(null);
  const userPasswordConfirmRef = useRef(null);
  
  const [errorMessage, setErrorMessage] = useState(props.forgotPasswordResetConfirmErrorMessage != null ? props.forgotPasswordResetConfirmErrorMessage : '');

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const _apiForgotPasswordConfirm = () => {

    //let data = {};
    //data['userConfirm'] = userConfirm;
    //data['userPassword'] = userPassword;
    //data['userPasswordConfirm'] = userPasswordConfirm;

    //props._apiForgotPasswordConfirm();

    _api_forgotpassword_newpassword()
  }


  ////////////////////////////////////////forgotpassword_newpassword 
  function _api_forgotpassword_newpassword_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('_api_forgotpassword_newpassword error = ' + _data.msg);
      //console.log('_api_forgotpassword_newpassword code = ' + _data.status_code);
      if(_data.status_code == 5) {
        
        setErrorMessage(_data.msg);
      } else {
        setErrorMessage('Technical error');
      }

    } else {
      setErrorMessage('');
      setForgotPasswordConfirmChangePassword(true);
      //console.log('_api_forgotpassword_newpassword was successfull');


    }
    
  }

  const _api_forgotpassword_newpassword = (_data) => {

    let data = [
      {'key':'action', 'value': 'forgotpassword_newpassword'},
      {'key':'email', 'value': props.forgotPasswordEmail},
      {'key':'activation_code', 'value': userConfirm},
      {'key':'password', 'value': userPassword},
      {'key':'confirm_password', 'value': userPasswordConfirm}
    ]

    props._apiConnect(staticText.path_user, data, _api_forgotpassword_newpassword_callback);
  }
  ////////////////////////////////////////

  const handleSubmit = (e) => {
    e.preventDefault();

    //if(errorMessage == '') {

      if(userConfirm == '') {
        setErrorMessage("Confirmation code can't be empty");
      } else if(userPassword == '') {
        setErrorMessage("Password can't be empty");
      } else if(userPassword.length < 6) {
        setErrorMessage("Password must be minimum 6 symbols");
      } else if(userPasswordConfirm != userPassword) {
        setErrorMessage("Confirm New Password must be ugual to New Password");
      }
    //}
    

    if(userConfirm != '' && userPassword != '' && userPassword.length >= 6 && userPasswordConfirm == userPassword) {

      //sendContactRequest();
      _apiForgotPasswordConfirm();
    }
    

    
    //console.log('userConfirm = ' + userConfirm + ',userPassword = ' + userPassword + ',userPasswordConfirm = ' + userPasswordConfirm);
  };

  return (
        <form className={classes.form}>
        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:400}}>
                    {!forgotPasswordConfirmChangePassword ? 
                    <>
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item>Code</Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          sx={theme.inputTextSx}
                          id="userConfirm_id"
                          name="userConfirm"
                          ref={userEmailRef}
                          placeholder="write your confirmation code"
                          onChange={event => setUserConfirm(event.target.value)}
                          size="small"
                          style={{width:'100%'}}
                          inputProps={{style: {color:theme.foreground}}}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item>New Password</Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          sx={theme.inputTextSx}
                          id="userPassword_id"
                          name="userPassword"
                          ref={userPasswordRef}
                          size="small"
                          style={{width:'100%'}}
                          type="password"
                          onChange={event => setUserPassword(event.target.value)}
                          inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item>Confirm New Password</Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          sx={theme.inputTextSx}
                          id="userPasswordConfirm_id"
                          name="userPasswordConfirm"
                          ref={userPasswordConfirmRef}
                          size="small"
                          style={{width:'100%'}}
                          type="password"
                          onChange={event => setUserPasswordConfirm(event.target.value)}
                          inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                        />
                    </Grid>
                    {errorMessage != '' ? <Grid item xs={12}>
                      <Alert severity="error">{errorMessage}</Alert>
                    </Grid> : (<div></div>)}
                    
                    
                    <Grid item xs={4} sm={4} md={4}>

                    </Grid>
                    <Grid container item xs={8} sm={8} md={8} justifyContent="flex-end">
                      <div>
                        <ButtonCustomStyle
                            changeHistory={null}
                            doFnc={handleSubmit}
                            changeHistoryFrom=''
                            changeHistoryTo=''
                            btnName='Confirm'                          
                        />
                      </div>
                    </Grid>
                    </> : null}
                    {forgotPasswordConfirmChangePassword ? <Grid item xs={12}>
                      <Alert severity="success">Password has been successfully changed</Alert>
                        <ButtonCustomStyle
                            changeHistory={true}
                            doFnc={null}
                            changeHistoryFrom='/ForgotPasswordConfirm'
                            changeHistoryTo='/'
                            btnName='Sign In'                          
                        />
                    </Grid> : (<div></div>)}
        </Grid>
        </form>
  );
}