import React, { useEffect, useState, useContext}  from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { NumericFormat } from 'react-number-format';
import {numberToAmericanFormat, roundDecimals} from './functions';

export default function CoingeckoCurrentCoinPriceWarning(props) {

  const [theme] = useContext(ThemeContext);

  //const [priceWarning, setPriceWarning] = useState('bbb');
  let priceWarning = '';
  ///const txnPricAlerts = (tar) => {

    //console.log('tar = ', tar)
    //let tar = props.action;
    let _priceALittleLess;
    if(props.action == 'buy_limit') {

      _priceALittleLess = Number(props.currentCoinCurrentPrice) - Number(props.currentCoinCurrentPrice) * props.percMore / 100
    }
    else if(props.action == 'sell_limit') {

      _priceALittleLess = Number(props.currentCoinCurrentPrice) + Number(props.currentCoinCurrentPrice) * props.percMore / 100
    } 
    else if(props.action == 'sell_short') {

      _priceALittleLess = Number(props.currentCoinCurrentPrice) + Number(props.currentCoinCurrentPrice) * props.percMore / 100
    }
    else if(props.action == 'buy_stop_limit') {

      _priceALittleLess = Number(props.currentCoinCurrentPrice) + Number(props.currentCoinCurrentPrice) * props.percMore / 100
    } 
    else if(props.action == 'sell_stop_limit') {

      _priceALittleLess = Number(props.currentCoinCurrentPrice) - Number(props.currentCoinCurrentPrice) * props.percMore / 100
    }

    ///////////////price and stop price warning
    if(props.action == 'buy_limit' && props.currentCoinCurrentPrice > _priceALittleLess) {

      //setPriceWarning('0 < PRICE < ' + _priceALittleLess) 
      priceWarning = 'max value: '
    }
    else if(props.action == 'sell_limit' && props.currentCoinCurrentPrice < _priceALittleLess) {

      //setPriceWarning('PRICE > ' + _priceALittleLess)
      priceWarning = 'min value: '
    }
    else if(props.action == 'sell_short' && props.currentCoinCurrentPrice < _priceALittleLess) {

      //setPriceWarning('PRICE > ' + _priceALittleLess)
      priceWarning = 'min value: '
    }
    else if(props.action == 'buy_stop_limit' && props.currentCoinCurrentPrice < _priceALittleLess) {

      //setPriceWarning('PRICE > ' + _priceALittleLess)
      priceWarning = 'min value: '
    }
    else if(props.action == 'sell_stop_limit' && props.currentCoinCurrentPrice > _priceALittleLess) {

      //setPriceWarning('0 < PRICE < ' + _priceALittleLess)
      priceWarning = 'max value: '
    }
  //}

  /*useEffect(() => {

    txnPricAlerts(props.action)
  }, []);*/

  return (
    <>
      <div style={{fontSize:10}}>
        <span>{priceWarning}</span>
        <span>
          <NumericFormat
            className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
            displayType="text"
            value={_priceALittleLess} 
            thousandSeparator="," 
            allowNegative={false} 
            decimalScale={8} 
            prefix={`$`} 
            renderText={(value) => <>{value}</>}
          />
        </span>
      </div>
    </>
  );
}