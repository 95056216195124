import React, { useState, useEffect, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';


import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];

const MultiSelect = (props) => {
    
    let _selectedItems = []
    if(props.selected_ids != '') {

        _selectedItems = props.selected_ids.split(",");
    }
    
    const [selectedItems, setSelectedItems] = useState(_selectedItems);
  
    const handleCheckboxChange = (userId) => {
      const newSelectedUsers = [...selectedItems];
      if (newSelectedUsers.includes(userId)) {
        newSelectedUsers.splice(newSelectedUsers.indexOf(userId), 1);
      } else {
        newSelectedUsers.push(userId);
      }
      setSelectedItems(newSelectedUsers);
      props.setSelectedItems(newSelectedUsers.join(','))
  
      console.log(newSelectedUsers.join(','));
    };
  
  
    return (
      <div>
        {props.Items.map((user) => (
          <div key={user.user_id}>
            <label>
              <input
                type="checkbox"
                value={user.user_id}
                checked={selectedItems.includes(user.user_id)}
                onChange={() => handleCheckboxChange(user.user_id)}
              />
              {user.username}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const MultiSelectCoins = (props) => {
    let _selectedItems = []
    if(props.selected_ids != '') {

        _selectedItems = props.selected_ids.split(",");
    }
    
    const [selectedItems, setSelectedItems] = useState(_selectedItems);
  
    const handleCheckboxChange = (userId) => {
      const newSelectedUsers = [...selectedItems];
      if (newSelectedUsers.includes(userId)) {
        newSelectedUsers.splice(newSelectedUsers.indexOf(userId), 1);
      } else {
        newSelectedUsers.push(userId);
      }
      setSelectedItems(newSelectedUsers);
      props.setSelectedItems(newSelectedUsers.join(','))
  
      console.log(newSelectedUsers.join(','));
    };
  
  
    return (
      <div>
        {props.Items.map((user) => (
          <div key={user.coin_pair_id}>
            <label>
              <input
                type="checkbox"
                value={user.coin_pair_id}
                checked={selectedItems.includes(user.coin_pair_id)}
                onChange={() => handleCheckboxChange(user.coin_pair_id)}
              />
              {user.left_side}
            </label>
          </div>
        ))}
      </div>
    );
  };

export default function ScenarioSettingsLine(props) {

    
    //////////////////////</Selects>

    const [scenarioName, setScenarioName] = useState(props.name);

    const handleChangeScenarioName = (event) => {
        const value = event.target.value;
        setScenarioName(value);
    };

    
    const [selectedUsers, setSelectedUsers] = useState('');
    const [selectedCoins, setSelectedCoins] = useState('');

    const doSaveSettings = () => {

        console.log('props.id = ', props.id)
        console.log('selectedUsers = ', selectedUsers)
        console.log('selectedCoins = ', selectedCoins)
        props.doSaveSettings(props.id, selectedUsers, selectedCoins)
    }

    
    const doSaveScenarioName = () => {

      console.log('props.id = ', props.id)
      console.log('selectedUsers = ', scenarioName)
      props.doSaveScenarioName(props.id, scenarioName)
  }


  return (
    <>
        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <Paper elevation={3} style={{ maxWidth: 400, padding: 16, marginBottom: 16, backgroundColor: '#4472C4'}}>
                    <Typography style={{fontSize:20, color: '#FFFFFF'}}>Edit Name</Typography>
                    <div style={{backgroundColor:'#C7D2E6', padding:10}}>
                        <div>
                          <TextField
                              label="Scenario Name"
                              id="outlined-size-small"
                              defaultValue={scenarioName}
                              size="small"
                              value={scenarioName}
                              onChange={handleChangeScenarioName}
                              style={{width:300}}
                          />
                        </div>
                        <div style={{paddingTop:10}}>
                            <Button variant="contained" size="small" style={{width:300}} onClick={() => doSaveScenarioName()}>
                              Save Name
                          </Button>
                        </div>
                    </div>
                    
                    {scenarioName == '' ? 
                      <div>
                        <Alert severity="warning">Scenario Name can't be empty</Alert>
                      </div>
                    :
                      null
                    }
                    
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <Button variant="contained" size="small" style={{width:'70%'}} onClick={() => doSaveSettings()}>
                    Save {props.title}
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                <Paper elevation={3} style={{ padding: 16, marginBottom: 16, backgroundColor: '#4472C4'}}>
                    <Typography style={{fontSize:20, color: '#FFFFFF', textAlign: 'left'}}>Choose Analytics</Typography>
                    <div style={{backgroundColor:'#C7D2E6', padding:10}}>
                    
                        <div>IDs ({selectedUsers}) Choose Analytic</div>
                        {props.analyseUsersArr.length > 0 ? 
                            <MultiSelect selected_ids={props.user_ids} setSelectedItems={setSelectedUsers} Items={props.analyseUsersArr} /> 
                        : 
                            null
                        }
                    </div>
                    <div>
                        <Alert severity="info">If no checkbox is selected, the scenario will include all analytics.</Alert>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                <Paper elevation={3} style={{ padding: 16, marginBottom: 16, backgroundColor: '#4472C4'}}>
                    <Typography style={{fontSize:20, color: '#FFFFFF', textAlign: 'left'}}>Choose Coins</Typography>
                    <div style={{backgroundColor:'#C7D2E6', padding:10}}>
                    
                        <div>IDs ({selectedCoins}) Choose Analytic</div>
                        {props.currentMonthCoinsArr.length > 0 ? 
                            <MultiSelectCoins selected_ids={props.coin_ids} setSelectedItems={setSelectedCoins} Items={props.currentMonthCoinsArr} /> 
                        : 
                            null
                        }
                    </div>
                    <div>
                        <Alert severity="info">If no checkbox is selected, the scenario will include all coins.</Alert>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <Button variant="contained" size="small" style={{width:'70%'}} onClick={() => doSaveSettings()}>
                    Save {props.title}
                </Button>
            </Grid>
        </Grid>
        
    </>
  );
}