import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import DartAnatomy from '../assets/img/DartAnatomy.png';
import DashboardTopMenu from "./DashboardTopMenu";
import { makeStyles } from '@material-ui/core/styles';
import "./youtubeStyles.css";
import YoutubeEmbed from "./YoutubeEmbed";

/*const useStyles = makeStyles((theme) => ({
  videoResponsive: {

    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0
  },

  videoResponsiveIframe: {

    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute'
  },
}));*/

export default function RouteSiteTutorial(props) {

  const history = useHistory();

  //const classes = useStyles();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
  <Grid container spacing={2} columns={12} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', margin: 0}} >
    <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
    <div style={{alignItems: 'center', padding:20}}>
      <div style={{textAlign:'center'}}>
        <Typography variant="h3" gutterBottom component="div">
          Site Tutorial
        </Typography>
      </div>
      
      <div style={{textAlign:'center'}} className="videoResponsive">
        <YoutubeEmbed embedId="iyySDOMl9Ls" />
      </div>
      <div style={{height:30}} />
      <div style={{maxWidth:900, margin:'auto'}}>
        Welcome to CryptoMarketDarts!
        <div style={{height:40}} />
        Below are quick and simple steps you need to follow to get access to analysts trend recommendations, 
        price estimates, commentaries and custom charts and videos:
        <div style={{height:40}} />
        1. Click “Register” to create an account
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        a. Establish your credentials
        <div style={{height:10}} />
        b. Use “Regular User” option if you are only interested to use information provided by 
        contributors
        <div style={{height:10}} />
        c. Use “Contributor” option if you are interested to provide information, such as trend 
        recommendation, price estimate, your commentaries and custom price charts and 
        videos. If your role is Contributor, you will need to pick a Nickname – this is the name 
        that will be shown to all site users. Go ahead and add your Telegram, YouTube and 
        Twitter links – we are happy to help you market your talent.
        <div style={{height:10}} />
        d. Press Register button. No emails, no text to validate – you are in!
        </div>
        <div style={{height:10}} />
        2. Depending on your profile type (i.e. Regular User vs. Contributor) your dashboard will show 
        different information.
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
            <div style={{fontWeight:'bold'}}>a. Regular User scenario:</div>
            <div style={{height:10}} />
            <div style={{paddingLeft:40}}>
                i. As soon as you register, you will be taken to “Build Portfolio” page to add crypto
                pairs to your portfolio. On this page you will see all crypto pairs for which we 
                have research collected from individual contributors. Once selected, press the 
                Save button. This will load your Dashboard.
                <div style={{height:10}} />
                ii. Your dashboard will show darts for each crypto pair combination you added to 
                your portfolio. These darts represent aggregate summary of individual 
                contributions provided by the site contributors. Here is a Dart anatomy
                <div style={{height:10}} />
                <img src={DartAnatomy} width="100%" height="auto" />
                <div style={{height:10}} />
                iii. Click on the dart to see the underlying information provided by analysts. You 
                can flip from one crypto pair to another right from the details screen. In addition
                to Darts, there is averaged low and high estimates information along with a list 
                of contributions and their valuable commentaries and charts.
            </div>
            <div style={{fontWeight:'bold'}}>b. Contributor Scenario:</div>
            <div style={{height:10}} />
            <div style={{paddingLeft:40}}>
                    i. As soon as you register, you will be taken to “Build Portfolio” page to add crypto
                    pairs to your portfolio. On this page you will see all crypto pairs covered by 
                    CryptoMarketDarts. Select only the ones for which you want to start providing 
                    contribution
                <div style={{height:10}} />
                    ii. Once selection of crypto pairs are made and saved, Dashboard screen will load. 
                    Initially all darts will be blank – this is because you haven’t started providing 
                    research yet. 
                <div style={{height:10}} />
                    iii. Click a dart to go to the Analyst Input page to start providing your opinion. 
                    Provide the following information:

                    <div style={{paddingLeft:40}}>
                            1. One Hour trend direction
                        <div style={{height:10}} />
                            2. Four Hour trend direction
                        <div style={{height:10}} />
                            3. One Day trend direction
                        <div style={{height:10}} />
                        4. One Week trend direction
                        <div style={{height:10}} />
                        5. One Month Trend direction
                        <div style={{height:10}} />
                        6. High and Low price estimate – where you think the price will be within 
one to three calendar days
                        <div style={{height:10}} />
                        7. Commentary – please keep in mind, rich, concise and informative 
comments will make you look better than your fellow contributors. This 
is important during analyst ranking process that is conducted 
automatically by the system. A good commentary will include a 
reference to a few indicators or articles used determining trend 
direction, high and low estimate as well as what you will depict on the 
price chart
                        <div style={{height:10}} />
                        8. A link to Price Chart (optional) – most contributors already use 
TradingView that makes it very simple to get a link to your custom chart 
for the crypto. Paste this link and press the Save button
                        <div style={{height:10}} />
                        9. A link to YouTube video (optional) – this would be a very good 
complimentary information especially if you already have this video 
made for your YouTube channels. We are happy to have you show it to 
more people
                        <div style={{height:10}} />
                        10. Don’t forget to Save your information
                        <div style={{height:10}} />
                        11. Once information is saved, go ahead and review Dashboard. A dart 
representing the crypto you just provided information for has just been 
dressed in colors corresponding to your trend direction indications. 
                        <div style={{height:10}} />
                        12. Repeat this step for each crypto dart.
                        <div style={{height:10}} />
                        13. Crypto markets are very dynamic and volatile. We recommend that as a 
contributor you provide updates as often as possible. Most our ​
contributors do it daily. If you haven’t provided or updated information 
in 3 days, it will be marked as expired and not taken into consideration 
when showing aggregate details to the site users.
                    </div>
            </div>
        </div>
        
      </div>
    </div>
  </Grid>
  );
}