import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';

const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
  color: theme.palette.text.secondary,
}));

export default function SelectLabels(props) {

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (

    <div style={{backgroundColor:props.color, fontSize:10, paddingLeft:10, paddingRight:10, paddingTop:4, paddingBottom:0, height:'100%', color:'#000000'}}>{props.name}</div>
  );
}