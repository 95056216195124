import * as React from 'react';
import Grid from '@mui/material/Grid';
import ForgotPassword from "./ForgotPassword";
import Typography from '@mui/material/Typography';
import homepage_image from '../assets/img/homepage_image.png';


export default function RouteForgotPassword(props) {
  return (
    <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%'}} direction="row" justifyContent="center" alignItems="center" >
      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:90}} align="center" justify="center">
        <Typography variant="h4" gutterBottom component="div">
          Forgot Password
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop:60}}>
        <ForgotPassword _apiForgotPassword={props._apiForgotPassword} forgotPasswordResetErrorMessage={props.forgotPasswordResetErrorMessage} />
      </Grid>
    </Grid>
  );
}