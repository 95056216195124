import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import DashboardTopMenu from "./DashboardTopMenu";


export default function RouteTerms(props) {

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <div style={{alignItems: 'center', padding:20}}>
          <div style={{maxWidth:900, margin:'auto'}}>
          <div style={{fontWeight:'bold'}}>TERMS OF SERVICE</div><div style={{height:20}} />
          <div style={{fontWeight:'bold'}}>General</div><div style={{height:20}} />
          <div style={{fontWeight:'bold'}}>Acceptance of Terms</div><div style={{height:20}} />
    These General Terms of Service (“TOS”) are a contract between you and Utalkia Corporation (“we,” “us,”
    “our,” and “Utalkia”) which owns CryptoMarketDarts.com platform. They govern your use of Utalkia’s 
    Services (as defined in Section 2 below).	 These <span style={{fontWeight:'bold'}}>TOS</span> apply to anyone using our Services (as defined in 
    Section 2 below), including the general public, Members (as defined below), Critics (as defined below), 
    and Corporate Sponsors (as defined below). You may also be subject to supplemental terms to the TOS 
    that you may separately enter into with us and such supplemental TOS terms are incorporated by 
    reference and made part of this TOS and will apply to your use, as applicable, of our Services and our 
    website at 	www.CryptoMarketDarts.com	(“ <span style={{fontWeight:'bold'}}>Website</span>	”). We reserve the right to amend, at our sole 
    discretion, these TOS, and any supplemental terms, to comply with applicable laws, to address new 
    services or features, or for any other reasons we determine at our sole discretion. We may supplement 
    these TOS by posting guidelines or rules applicable to specific Services (as defined below) provided on 
    the Website, including our Privacy Policy. By accepting these TOS, you agree to abide by all guidelines, 
    policies, and rules related to the Services (as defined below) provided on the Website.
    <div style={{height:20}} />
    BY CHECKING THE “I AGREE” BOX, OR BY CONTINUING TO USE THE SERVICES (AS DEFINED BELOW) 
    FOLLOWING OUR POSTING OF REVISED TOS ON THE WEBSITE, YOU (A) AGREE TO BE BOUND BY THIS 
    TOS; (B) ACKNOWLEDGE AND AGREE THAT YOU HAVE INDEPENDENTLY EVALUATED THE FITNESS OF 
    USING THE SERVICES DESCRIBED IN THE TOS AND ARE NOT RELYING ON ANY REPRESENTATION, 
    GUARANTEE, OR STATEMENT OTHER THAN AS EXPRESSLY SET FORTH IN THIS TOS; AND (C) HEREBY 
    REPRESENT AND WARRANT THAT YOU ARE LAWFULLY ABLE TO ENTER INTO CONTRACTS (E.G., YOU ARE 
    NOT A MINOR) AND THAT YOU ARE AND WILL REMAIN IN COMPLIANCE WITH THIS TOS. IN ADDITION, IF
    THIS TOS IS BEING AGREED TO BY A COMPANY OR OTHER LEGAL ENTITY, THEN THE PERSON AGREEING 
    TO THIS TOS ON BEHALF OF THAT COMPANY OR ENTITY HEREBY REPRESENTS AND WARRANTS THAT HE 
    OR SHE IS AUTHORIZED AND LAWFULLY ABLE TO BIND THAT COMPANY OR ENTITY TO THIS TOS.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>2. Services</div>
    <div style={{height:20}} />
    If you wish to submit your information and content as a Member-Analyst (as defined below), including 
    your financial prognosis on price trend and price estimate along with any commentary, price charts or 
    links to price charts, videos or links to videos or other work created by you (collectively the “Work”), we 
    grant you the right, pursuant to this TOS, to access the Website to use the provided information therein 
    to help promote your brand as Analyst.
    <div style={{height:20}} />
    In order to submit your Work, you must provide such information as defined in the Privacy Policy to 
    register with Utalkia via the Website in order to become a Member-Analyst.	 As a Member-Analyst, you 
    are able to build a coverage portfolio, provide your opinion on the price trend and target estimates for 
    individual coins or a group of coins or any other asset class represented on the Website. Your input will 
    be reviewed by the Website’s users and your individual contributions may be liked or disliked by the 
    Website users. 
    <div style={{height:20}} />
    A Member’s access to any services via the Website may be changed at any time and without prior notice
    by Utalkia.​
    <div style={{height:20}} />
    You agree to use the Services solely for the purpose of posting, promoting, and distributing your Work 
    to other Members or as otherwise expressly authorized by us. You assume sole, full responsibility and 
    liability for any Work you submit to the Website.	 In addition to offering paid membership to the 
    Website users, our Services provide a means to promote the Work to viewers of the Website including 
    Members and Corporate Sponsors.
    <div style={{height:20}} />
    Utalkia does not acquire any right or ownership in your Intellectual Property when you use our Services.
    Members retain all Intellectual Property rights in any work submitted to the Website.	 You grant us a 
    nonexclusive, royalty-free, worldwide, perpetual, and irrevocable right to copy, transmit, modify, adapt, 
    publish, sell, translate, create derivative works from, distribute, and display any information, data, Work,
    or any other information associated with your Work (collectively the “Submitted Materials”) submitted 
    to us via the Services in any media or format.	 Utalkia may act as a platform for any Member whose 
    Work has been submitted to the Website.
    <div style={{height:20}} />
    We do not validate the accuracy or timeliness of the Work posted on the Website. Accordingly, we do 
    not warrant the accuracy or validity of the Work. You must evaluate and bear all risks associated with 
    your use of any of the Work, including any reliance on its accuracy, completeness, or usefulness. We 
    reserve the right, at our sole discretion, to delete or remove any content from the Website if we deem 
    such content to be offensive, inappropriate, inaccurate or inconsistent with our business. You may 
    contact us  if you wish to notify us that any content displayed on the Website is inaccurate, offensive or 
    inappropriate.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>3. Your Registration Obligations</div><div style={{height:20}} />
    You agree to provide accurate, current and complete information about yourself and your Work (as 
    applicable) as prompted by us via our registration process and you will maintain and promptly update 
    this information to keep it accurate, current and complete. If you provide information about yourself or 
    your Work that is inaccurate, not current, or incomplete (or we have reasonable grounds to suspect that
    such information is inaccurate, not current or incomplete or does not belong to you), we may suspend 
    or terminate your account and deny you further access to the Website or Services.​
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    4. Member Account Access</div><div style={{height:20}} />
    You will create a password and receive an account designation upon completing our registration process
    for the Services. You are responsible for maintaining the confidentiality of the password and account, 
    and are responsible for all activities that occur using your password or account. You agree to notify us 
    immediately of any unauthorized use of your password or account or any other breach of security, and 
    you will exit from your account at the end of each session so that a subsequent user on your computer 
    will not have access to your account. We will not be liable for any loss or damage arising from your 
    failure to comply with these requirements.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    5. International Use</div><div style={{height:20}} />
    You may be able to access the Services outside the United States. You agree to comply with all local, 
    national, or international laws regarding online conduct and acceptable works. You also agree to comply 
    with all applicable laws regarding the transmission of technical data exported from the United States or 
    to or from the country in which you reside.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    6. Termination or Modification
    </div><div style={{height:20}} />
    We reserve the right at any time to refuse to provide, modify or discontinue, temporarily or 
    permanently, our Services (or any part thereof) to you with or without notice. We may terminate or 
    limit your access to our Services and remove and discard any information or Work you provide to us at 
    any time for any reason or without a reason. You agree that you will not hold us liable or any third party 
    for any termination, modification, suspension, or unavailability of our Services. Upon any termination or 
    expiration of this TOS, all rights and obligations of the parties will be extinguished. No termination or 
    expiration of this Agreement will relieve either party for any liability for any breach of, or liability 
    accruing under, this Agreement prior to termination or expiration.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    7. Proprietary rights, Use Rights and Limitations, Representations and 
    Warranties
    </div><div style={{height:20}} />
    Nothing in this TOS provides you any rights or ownership in our or any third parties' copyrights, 
    trademarks, patents or trade secrets ("Intellectual Property"). Similarly, we do not acquire any right or 
    ownership in your Intellectual Property when you use our Services. You represent and warrant that you 
    have all the necessary rights, title, interest, or permission and authority from third parties (portals, 
    market data, similar services as us) to submit any information or materials to us via the Services 
    regarding your Work.<div style={{height:20}} />
    You grant us a nonexclusive, royalty-free, worldwide, perpetual, and irrevocable right to copy, transmit, 
    sell, modify, adapt, publish, translate, create derivative works from, distribute, and display any 
    information, data, Work, or any other information associated with your Work (collectively the 
    “Submitted Materials”) you submit to us via the Services in any media or format. Your use of the 
    Services does not grant us ownership of your Work. As between you and us, you retain ownership of 
    your Work.<div style={{height:20}} />
    You agree that Submitted Materials will remain on the Website for a minimum term of six (6) months as 
    submission. After the minimum six (6) month period, you can request us to delete any of your posted 
    Submitted Materials at your discretion. Processing the deletions may take a little time, but we will 
    process as quickly as possible.
    <div style={{height:20}} />
    You represent and warrant that (a) you own or otherwise control all of the rights to the Submitted 
    Materials you submit to us, (b) such Submitted Materials are accurate, (c) our use of the Submitted 
    Materials will not violate any terms of use in connection with the Website or Services or applicable laws,
    (d) the Submitted Materials will not cause injury to any person or entity, and (e) you will not provide any
    Submitted Materials to us that is defamatory, libelous, obscene or might otherwise be considered 
    unlawful.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    8. Disclaimer of Warranties and Limitations of Liability.
    </div><div style={{height:20}} />
    YOUR USE OF THE WEBSITE OR SERVICES IS AT YOUR SOLE RISK. THE WEBSITE AND SERVICES IS 
    PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. WE EXPRESSLY DISCLAIM ALL WARRANTIES, 
    EXPRESS, IMPLIED OR STATUTORY, INCLUDING ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
    FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING FROM 
    ANY COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE, WITH REGARD TO ANY 
    INFORMATION, DATA, CONTENT, PRODUCTS, SERVICES OR OTHER ITEMS PROVIDED OR MADE 
    AVAILABLE HEREUNDER.
    <div style={{height:20}} />
    WE ARE NOT LIABLE, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, PRODUCT 
    LIABILITY OR OTHER THEORY) OR OTHERWISE, TO YOU OR ANY OTHER PERSON FOR COST OF COVER, 
    RECOVERY OR RECOUPMENT OF ANY INVESTMENT MADE BY EITHER PARTY OR ITS AFFILIATES IN 
    CONNECTION WITH THIS AGREEMENT, OR FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, 
    CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, 
    USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF 
    SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE WEBSITE OR SUBMITTER
    SERVICES; (ii) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE OR SUBMITTER 
    SERVICES; OR (iii) ANY OTHER MATTER RELATING TO THE WEBSITE OR SUBMITTER SERVICES.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    9. Notice
    </div><div style={{height:20}} />
    We may provide you with notice regarding the Website or Services via either email or regular mail. 
    Accordingly, you agree to provide us with an email address and agree to update such information 
    current within your use of the Website or Services. You agree that notice via email is adequate for any 
    purpose under this TOS.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    10. Disputes
    </div><div style={{height:20}} />
    Any dispute or claim relating in any way to your use of the Website or 
    Services will be resolved by binding arbitration, rather than in court, except 
    that you may assert claims in small claims court if your claims qualify. The 
    Federal Arbitration Act and federal arbitration law apply to this agreement.
    <div style={{height:20}} />
    There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an 
    arbitrator can award on an individual basis the same damages and relief as a court (including injunctive 
    and declaratory relief or statutory damages), and must follow the terms of these Terms of Use as a court
    would.
    <div style={{height:20}} />
    To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your 
    claim to our registered agent info@utalkia.com. The arbitration will be conducted by the American 
    Arbitration Association (AAA) under its rules, including the AAA’s Supplementary Procedures for
    Consumer-Related Disputes. The AAA’s rules are available at www.adr.org or by calling 1-800-778-7879. 
    Payment of all filing, administration and arbitrator fees will be governed by the AAA’s rules. We will 
    reimburse those fees for claims totaling less than $1,000 unless the arbitrator determines the claims are 
    frivolous. Likewise, we will not seek attorneys’ fees and costs in arbitration unless the arbitrator 
    determines the claims are frivolous. You may choose to have the arbitration conducted by telephone, 
    based on written submissions, or in person in the county where you live or at another mutually agreed 
    location.<div style={{height:20}} />
    You and Utalkia each agree that any dispute resolution proceedings will be conducted only on an 
    individual basis and not in a class, consolidated or representative action. If for any reason a claim 
    proceeds in court rather than in arbitration you and Utalkia each waive any right to a jury trial. You and 
    Utalkia also both agree that you or we may bring suit in court to enjoin infringement or other misuse of 
    intellectual property rights.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    11. Miscellaneous; Governing Law
    </div><div style={{height:20}} />
    Unless otherwise agreed to in writing by you and Utalkia, this TOS (and any supplemental terms to the 
    TOS that you separately agree to enter with us) constitute the entire agreement between you and us 
    regarding the Website and the Services, superseding any prior agreements between you and us. You 
    also may be subject to additional terms and conditions agreed upon in writing between you and Utalkia 
    that may apply. The TOS and the relationship between you and Utalkia shall be governed by the laws of 
    the State of New York without regard to its conflict of law provisions. You and Utalkia agree to submit to
    the personal and exclusive jurisdiction of the courts located within the county of New York. Our failure 
    to exercise or enforce any right or provision of the TOS shall not constitute a waiver of such right or 
    provision. If any provision of the TOS is found by a court of competent jurisdiction to be invalid, the 
    parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as 
    reflected in the provision, and the other provisions of the TOS remain in full force and effect. You agree 
    that regardless of any law to the contrary, any claim or cause of action arising out of or related to use of 
    the Services or the TOS must be filed within one (1) year after such claim or cause of action arose or be 
    forever barred. The section titles in the TOS are for convenience only and have no legal or contractual 
    effect. Whenever used in the TOS or any supplemental terms to the TOS, unless otherwise specified, the 
    terms “includes,” “including,” “e.g.,” “for example”, “such as”, and other similar terms are deemed to 
    include the term “without limitation” immediately thereafter.
    <div style={{height:20}} />
    <div style={{fontWeight:'bold'}}>
    12. Incorporated Rules and Policies
    </div><div style={{height:20}} />
    By using the Services, you agree to let us collect and use information as detailed in our	Privacy Policy	. 
    If you’re outside the United States, you consent to letting us transfer, store, and process your 
    information (including your personal information and content) in and out of the United States. 
          </div>
        </div>
    </Grid>
  );
}