import React, { useState, useEffect } from 'react';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

export default function RouteMyInfo(props) {

  

  const classes = useStyles();
  const theme = useTheme();

  const [portfolioListArr, setPortfolioListArr] = useState(['ADA-USD', 'BCH-USD', 'BTC-USD', 'DOGE-USD', 'ETH-USD', 'LINK-USD', 'LTC-USD', 'SHIB-USD', 'XLM-USD', 'XRP-USD'])
  const [myPortfolioListArr, setMyPortfolioListArr] = useState(['BTC-USD', 'ETH-USD'])
  const [buildYourPortfolioPressed, setBuildYourPortfolioPressed] = useState(false);
  const [referAFriendPressed, setReferAFriendPressed] = useState(false);
  const [myInfoPressed, setMyInfoPressed] = useState(false);
  
  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/MyInfo', '/')
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')
    }
  }, []);

  const portfolioChange = (event) => {
    //console.log('|' + event.target.checked);
  };

  
  const setMyInfoSaveFnc = () => {
    
    //do some save My Info Stuff stuf
    changeHistory('/', props.userDetails.usertype == 0 ? '/DashboardUser' : '/DashboardConsumer')
  };

  const setMyInfoDeleteAccountFnc = () => {
    
    //do some delete account stuff
    //changeHistory('/', '/')
    props.setMyInfoDeleteAccountFnc();
  };

  const setReferAFriendPressedFnc = (event) => {
    if(event == false) {
      setBuildYourPortfolioPressed(false);
      setReferAFriendPressed(false);
      setMyInfoPressed(false);
    } else {
      setBuildYourPortfolioPressed(!event);
      setMyInfoPressed(!event);
      setReferAFriendPressed(event);
      
    }
    
  };
  const setBuildYourPortfolioPressedFnc = (event) => {
    if(event == false) {
      setBuildYourPortfolioPressed(false);
      setReferAFriendPressed(false);
      setMyInfoPressed(false);
    } else {
      setReferAFriendPressed(!event);
      setMyInfoPressed(!event);
      setBuildYourPortfolioPressed(event);
    }
  };

  const setmyInfoPressedFnc = (event) => {
    if(event == false) {
      setBuildYourPortfolioPressed(false);
      setReferAFriendPressed(false);
      setMyInfoPressed(false);
    } else {
      //setOpen(false);
      setMyInfoPressed(event);
      setReferAFriendPressed(!event);
      setBuildYourPortfolioPressed(!event);
    }
  };

  const setMyInfoPressedFnc1 = () => {
    setmyInfoPressedFnc(false);
  }

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{fontSize:24, paddingTop:20, maxWidth:400}}>
                <div style={{fontSize:25, fontStyle: 'italic', textAlign:'left', paddingLeft:15, paddingBottom:15}}>
                    My Info
                </div>
            </div>
            <div>
                <SignUp myInfoSuccessMessage={props.myInfoSuccessMessage} _api_get_my_userFnc={props._api_get_my_userFnc} userDetails={props.userDetails} userProfileType={props.userDetails.usertype} setMyInfoSaveFnc={setMyInfoSaveFnc} setMyInfoDeleteAccountFnc={setMyInfoDeleteAccountFnc} myInfo={true} _apiConnect={props._apiConnect} _path={props._path} setUserDetails={props.setUserDetails} setLoggined_token={props.setLoggined_token} _api_chech_user_token={props._api_chech_user_token} />
            </div>
        </Grid>
    </Grid>
    

  );
}