import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from '@mui/x-data-grid-generator';

const useStyles = makeStyles({
    root: {
      '& .cold': {
        backgroundColor: '#cccccc',
        color: '#1a3e72',
        height: 200,
      },
      '& .hot': {
        backgroundColor: '#333333',
        color: '#1a3e72',
        height: 200,
      },
    },
  });

export default function GridView(props) {
    const classes = useStyles();

  return (
    <div style={{ height: 700, width: '100%' }}>
      <DataGrid 
        wrap="wrap"
        rows={props.rows} 
        columns={props.columns}
        rowsPerPageOptions={[]}
        defaultCurrentPage={0}
        defaultPageSize={0}
        disableSelectionOnClick
        getCellClassName={(params) => {
            if (params.field === 'age') {
            return '';
            }
            return params.value >= 30 ? 'hot' : 'cold';
        }} 
      />
    </div>
  );
}