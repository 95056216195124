import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import DashboardTopMenu from "./DashboardTopMenu";
import ButtonCustomStyle from "./ButtonCustomStyle";



export default function RouteAbout(props) {

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (

    <Grid container spacing={2} columns={12} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', margin: 0}} >
      <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      <div style={{alignItems: 'center', padding:20}}>
        <div style={{textAlign:'center'}}>
          <Typography variant="h3" gutterBottom component="div">
            Career
          </Typography>
        </div>
        <div style={{maxWidth:900, alignItems: 'center', margin:'auto'}}>
          <div style={{height:30}} />
          Great minds don' think alike! That's why CryptoMarketDarts reflects the incredible diversity of the minds that contribute to our platform.
  <div style={{height:20}} />
  <b>Who we are:</b>
  <div style={{height:10}} />
We are a community crypto research platform that provides consolidated time-sensitive critical daily analysis for the top 16 crypto coins using proprietary Darts indicator.
<div style={{height:20}} />
  <b>What we do:</b>
  <div style={{height:10}} />
  We collect individual contributions from crypto analysts located around the globe and use our proprietary method to build a sentiment price trend indicator using the Dart object.
  <div style={{height:20}} />
  <b>Why join us:</b>
  <div style={{height:10}} />
  Gain exposure - we are showing the platform to serious players in the financial industry, so analysts' work will be visible
  <div style={{height:20}} />
  Knowledge/Education/Reward - each analyst will get free access to the platform to see the Dart indicator and other contributors' analysis. The annual access fee to the platform is currently estimated to be around $500.
  <div style={{height:20}} />
  Award - upon completion of the first month and considering an analyst contributed at least 15 times, our NFT will be granted to an analyst.
  <div style={{height:20}} />
  Future compensation - after the first completed round of funding (meeting the threshold) is secured, the top 10% of analysts will be compensated based on the accuracy of their analysis (using our analyst ranking formula). This compensation will be awarded on a monthly or quarterly basis.
  <div style={{height:20}} />
  Equity purchase plan - after the first completed round of funding is secured, the analyst will be allowed to participate in the friends and family fund to purchase equity in the company at a discounted rate
  <div style={{height:20}} />
  Analyst monetization - each analyst will receive a personal promo link to the site that could be shared with information consumers (regular users). When a regular user signs up for the site and pays the monthly membership fee, the Analyst will automatically receive 50% commissions from the first month/quarter/year (depending on the option selected by the regular user upon sign-up)
  <div style={{height:20}} />
  <ButtonCustomStyle
      changeHistory={true}
      doFnc={null}
      changeHistoryFrom='/'
      changeHistoryTo='/Contact'
      btnName='Contact Us'
  />
  <div style={{height:10}} />
  <ButtonCustomStyle
      changeHistory={true}
      doFnc={null}
      changeHistoryFrom='/'
      changeHistoryTo='/SignUp'
      btnName='Sign Up'
  />
      </div>
      </div>
    </Grid>
  );
}