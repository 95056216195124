import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import ButtonCustomStyle from "./ButtonCustomStyle";
import Tooltip from '@mui/material/Tooltip';


const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

export default function FormEarnMoney(props) {

  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };
  
  const [emailAddress, setEmailAddress] = useState('');
  const [emailSubject, setEmailSubject] = useState('Request to Get Custom Link');
  const [emailWalletAddress, setEmailWalletAddress] = useState('');
  
  const [emailMessage, setEmailMessage] = useState('Hello, please send me a custom link I could share with my network to have them register with your platform');

  const [twitter, setTwitter] = useState('');
  const [telegram, setTelegram] = useState('');
  const [instagram, setInstagram] = useState('');
  const [discord, setDiscord] = useState('');
  const [youtube, setYoutube] = useState('');
  const [other, setOther] = useState('');
  

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const setContactUsFnc = () => {
    
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validation_email = true;
    
    if(emailAddress == '') {

      validation_email = false;
      setErrorMessage("Email from field can't be empty");
    } else if(!re.test(emailAddress) ) {
      
      validation_email = false
      setErrorMessage("Email is invalid");
      // invalid email, maybe show an error to the user.
    } else if(emailSubject == '') {
      validation_email = false;
      setErrorMessage("Subject can't be empty");
    } else if(emailWalletAddress == '') {
        validation_email = false;
        setErrorMessage("Ethereum Wallet Address can't be empty");
    } else if(emailMessage == '') {
      validation_email = false;
      setErrorMessage("Message can't be empty");
    }
    if(validation_email) {
      setErrorMessage("");
      
      _api_send_contact();
    }
  };

  ////////////////////////////////////////forgotpassword_newpassword 
  function _api_send_contact_callback(_data) {

    if(_data.status_code != 2) {
      
     // console.log('_api_send_contact error = ' + _data.msg);
      //console.log('_api_send_contact code = ' + _data.status_code);
      if(_data.status_code == 5) {
        
        setErrorMessage(_data.msg);
      } else {
        setErrorMessage('Technical error');
      }
    } else {
      setErrorMessage('');
      setSuccessMessage('Successfully Sent');
      //console.log('_api_send_contact was successfull');
    }
  }

  const _api_send_contact = () => {

    let data = [
      {'key':'action', 'value': 'earn_money'},
      {'key':'email_address', 'value': emailAddress},
      {'key':'email_subject', 'value': emailSubject},
      {'key':'email_wallet_address', 'value': emailWalletAddress},
      {'key':'email_message', 'value': emailMessage},
      {'key':'twitter', 'value': twitter},
      {'key':'telegram', 'value': telegram},
      {'key':'instagram', 'value': instagram},
      {'key':'discord', 'value': discord},
      {'key':'youtube', 'value': youtube},
      {'key':'other', 'value': other},
    ]

    props._apiConnect(staticText.path_user, data, _api_send_contact_callback);
  }
  ////////////////////////////////////////
  

  return (

        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:800}}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:350}}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Email from
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Your email here"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setEmailAddress(event.target.value)}
                                />
                            </Grid>                  
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Subject
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Subject here"
                                    defaultValue="Request to Get Custom Link"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setEmailSubject(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Ethereum Wallet <Tooltip title="You must have Metamask wallet to set up an account with us. If you have it, put your Metamask wallet info here. If you don't have Metamask, download it from HERE and then provide us your wallet info" arrow>
                                        <span>(Explanation)</span>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Ethereum Wallet Address here (ex. 0x000...000)"
                                    defaultValue=""
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setEmailWalletAddress(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Custom Message
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    multiline
                                    maxRows={3}
                                    rows={3}
                                    placeholder="Message here"
                                    defaultValue="Hello, please send me a custom link I could share with my network to have them register with your platform"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, color:theme.foreground}}}
                                    onChange={event => setEmailMessage(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:350}}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Twitter (optional)
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Your twitter address here"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setTwitter(event.target.value)}
                                />
                            </Grid>                  
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Telegram (optional)
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Your telegram address here"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setTelegram(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Instagram (optional)
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Your instagram address here"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setInstagram(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Discord (optional)
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Your discord address here"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setDiscord(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Youtube (optional)
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Your youtube address here"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setYoutube(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                                    Other (optional)
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Other URL here"
                                    size="small"
                                    style={{width:'100%'}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                                    onChange={event => setOther(event.target.value)}
                                />
                            </Grid>
                            {errorMessage != '' ? <Grid item xs={12}>
                            <Alert severity="error">{errorMessage}</Alert>
                            </Grid> : (<div></div>)}
                            {successMessage != '' ? <Grid item xs={12}>
                            <Alert severity="success">Thank you for submitting a Custom Link request to start earning money with us. We will review your request within 24 hours and, if approved, will send you your personal custom link to the email address you provided.</Alert>
                            </Grid> : (<div></div>)}
                            <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
                            <div style={{width:350, textAlign:'right'}}>
                                <ButtonCustomStyle
                                    changeHistory={null}
                                    doFnc={setContactUsFnc}
                                    changeHistoryFrom=''
                                    changeHistoryTo=''
                                    btnName='Send'                          
                                />
                            </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    
        </Grid>
  );
}