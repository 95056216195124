import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";
import ConsumerHistoryGrid2 from "./ConsumerHistoryGrid2";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import LinearProgress from '@mui/material/LinearProgress';



import Conditions_image from '../assets/img/conditions.jpeg';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import GridView from "./GridView";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ModalAreYouSureWithParam from "./ModalAreYouSureWithParam";
import ControlledSwitches from './ControlledSwitches';
import ConditionLine from './ConditionLine';
import ScenarioSettingsLine from './ScenarioSettingsLine';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
  }),
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

let currentCommentToEdit = '';
let currentCommentId = -1;



export default function RouteAdminScenarioBuilder(props) {

    const [theme] = useContext(ThemeContext);

    const [scenarioName, setScenarioName] = useState('');
    const [scenarioList, setScenarioList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    
    
    const [openBtnPressed, setOpenBtnPressed] = useState(false);
    const [openBtnDuplicatePressed, setOpenBtnDuplicatePressed] = useState(false);

    

  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const colorsArr = {"-100": "#FFFFFF", "2": "#00B050", "1": "#25FF88", "0": "#808080", "-1": "#FFA6A0", "-2": "#FF0000"};
  const colorsNameArr = {"-100": "#FFFFFF", "2": "Bullish - Strong", "1": "Bullish", "0": "Neutral", "-1": "Bearish", "-2": "Bearish - Strong"};

  //const [currentCommentToEdit, setCurrentCommentToEdit] = useState('');

  const [loading, setLoading] = useState(false);

  const [loadStartPager, setLoadStartPager] = useState(0);

  const [analyseUsersArr, setAnalyseUsersArr] = useState([]);
  const [currentMonthCoinsArr, setCurrentMonthCoinsArr] = useState([]);
  

  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_edit_live_scenario_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_edit_live_scenario error = ' + _data.msg);
      console.log('admin_edit_live_scenario code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      setSuccessMessage('')
      
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Scenario Live/Offline Saved Successfully')
      console.log('admin_edit_live_scenario was successfully sent');
      _api_admin_get_scenario()
    }
    
  }

  const _api_admin_edit_live_scenario = (id, live) => {

    let data = [
      {'key':'action', 'value': 'admin_edit_live_scenario'},
      {'key':'id', 'value': id},
      {'key':'live', 'value': live},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('LIVE: ', data)

    props._apiConnect(staticText.path_darts, data, _api_admin_edit_live_scenario_callback)
  }
  //////////////////////////////////////

  
  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_edit_notification_scenario_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_edit_notification_scenario error = ' + _data.msg);
      console.log('admin_edit_notification_scenario code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      setSuccessMessage('')
      
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Scenario Notification Saved Successfully')
      console.log('_api_admin_edit_notification_scenario was successfully sent');
      _api_admin_get_scenario()
    }
    
  }

  const _api_admin_edit_notification_scenario = (id, notification) => {

    let data = [
      {'key':'action', 'value': 'admin_edit_notification_scenario'},
      {'key':'id', 'value': id},
      {'key':'notification', 'value': notification},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('notification: ', data)

    props._apiConnect(staticText.path_darts, data, _api_admin_edit_notification_scenario_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////<Delete Scenario>
  function _api_admin_delete_scenario_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_delete_scenario error = ' + _data.msg);
      console.log('admin_delete_scenario code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      setSuccessMessage('')
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Deleted')
      console.log('admin_delete_scenario was successfully sent');
      _api_admin_get_scenario()
    }
    
  }

  const _api_admin_delete_scenario = (id) => {

    let data = [
      {'key':'action', 'value': 'admin_delete_scenario'},
      {'key':'id', 'value': id},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_admin_delete_scenario_callback)
  }
  ////////////////////////////////////////</Delete Scenario>
  //////////////////////////////////////

  
  ////////////////////////////////////////<Duplicate Scenario>
  function _api_admin_duplicate_scenario_callback(_data) {

    console.log('_api_admin_duplicate_scenario_callback SQL = ', _data.sql)
    if(_data.status_code != 2) {
      
      console.log('_api_admin_duplicate_scenario error = ' + _data.msg);
      console.log('_api_admin_duplicate_scenario code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      setSuccessMessage('')
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Duplicated Successfully')
      console.log('_api_admin_duplicate_scenario was successfully sent');
      _api_admin_get_scenario()
    }
    
  }

  const _api_admin_duplicate_scenario = (id) => {

    let data = [
      {'key':'action', 'value': 'admin_duplicate_scenario'},
      {'key':'id', 'value': id},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_admin_duplicate_scenario_callback)
  }
  ////////////////////////////////////////</Duplicate Scenario>

  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_get_scenario_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_get_scenario error = ' + _data.msg);
      console.log('admin_get_scenario code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      
    } else {
      
        setScenarioList(_data.data)
        console.log('admin_get_scenario was successfully sent = ', _data.data);
        _api_analyse_users();
        _api_get_current_month_coins();
      //console.log('_data = ' + _data.data[0]);
      //setPortfolioListArr(_data.data)
      //_api_admin_get_coin_pair();
    }
    
  }

  const _api_admin_get_scenario = () => {

    let data = [
      {'key':'action', 'value': 'admin_get_scenario'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_admin_get_scenario_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_set_scenario_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_set_scenario error = ' + _data.msg);
      console.log('admin_set_scenario code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      setSuccessMessage('')
      
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Scenario added successfully')
      console.log('admin_set_scenario was successfully sent');
      _api_admin_get_scenario()
      //console.log('_data = ' + _data.data[0]);
      //setPortfolioListArr(_data.data)
      //_api_admin_get_coin_pair();
    }
    
  }

  const _api_admin_set_scenario = () => {

    let data = [
      {'key':'action', 'value': 'admin_set_scenario'},
      {'key':'scenario_name', 'value': scenarioName},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_admin_set_scenario_callback)
  }
  //////////////////////////////////////

  
  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_edit_condition_scenario_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('_api_admin_edit_condition_scenario error = ' + _data.msg);
      console.log('_api_admin_edit_condition_scenario code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Condition Saved Successfully')
      console.log('admin_edit_live_scenario was successfully sent');
      _api_admin_get_scenario()
    }
    
  }

  const _api_admin_edit_condition_scenario = (id, _data) => {

    let data = [
      {'key':'action', 'value': 'admin_edit_condition_scenario'},
      {'key':'id', 'value': id},
      {'key':'data', 'value': btoa(JSON.stringify(_data))},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('LIVE: ', data)

    props._apiConnect(staticText.path_darts, data, _api_admin_edit_condition_scenario_callback)
  }
  //////////////////////////////////////

  const doSaveCondition = (id, _data) => {

    console.log('doSaveCondition _data = ', _data)
    _api_admin_edit_condition_scenario(id, _data)
  }

  /////////////////////<Settings>
  function _api_admin_edit_settings_scenario_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('_api_admin_edit_settings_scenario_callback error = ' + _data.msg);
      console.log('_api_admin_edit_settings_scenario_callback code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Settings Saved Successfully')
      console.log('_api_admin_edit_settings_scenario was successfully sent');
      _api_admin_get_scenario()
    }
    
  }

  const _api_admin_edit_settings_scenario = (id, _selectedUsers, _selectedCoins) => {

    let data = [
      {'key':'action', 'value': 'admin_edit_settings_scenario'},
      {'key':'id', 'value': id},
      {'key':'user_ids', 'value': _selectedUsers},
      {'key':'coin_ids', 'value': _selectedCoins},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('_api_admin_edit_settings_scenario: ', data)

    props._apiConnect(staticText.path_darts, data, _api_admin_edit_settings_scenario_callback)
  }

  const doSaveSettings = (id, _selectedUsers, _selectedCoins) => {

    console.log('id = ', id)
    console.log('_selectedUsers = ', _selectedUsers)
    console.log('_selectedCoins = ', _selectedCoins)
    _api_admin_edit_settings_scenario(id, _selectedUsers, _selectedCoins)
  }
  /////////////////////</Settings>

  /////////////////////<Scenario Name>
function _api_admin_edit_name_scenario_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('_api_admin_edit_name_scenario_callback error = ' + _data.msg);
      console.log('_api_admin_edit_name_scenario_callback code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      
    } else {
      
      setErrorMessage('')
      setSuccessMessage('Settings Saved Successfully')
      console.log('_api_admin_edit_settings_scenario was successfully sent');
      _api_admin_get_scenario()
    }
    
  }

  const _api_admin_edit_name_scenario = (id, _name) => {

    let data = [
      {'key':'action', 'value': 'admin_edit_name_scenario'},
      {'key':'id', 'value': id},
      {'key':'name', 'value': _name},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    console.log('_api_admin_edit_name_scenario: ', data)

    props._apiConnect(staticText.path_darts, data, _api_admin_edit_name_scenario_callback)
  }

  const doSaveScenarioName = (id, _name) => {

    console.log('id = ', id)
    console.log('_name = ', _name)
    _api_admin_edit_name_scenario(id, _name)
  }
  /////////////////////</Scenario Name>

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/AdminGridView', '/')
    } else {
      
        _api_admin_get_scenario()
    }
  }, []);

  const addNewScenario = () => {

    console.log('Add New Scenario')
    if(scenarioName == '') {

        setErrorMessage('Please fill Scenario Name')
    } else {

        _api_admin_set_scenario()
    }
  }

  const deleteScenario = (id) => {

    console.log('Delete Scenario id: ' + id)
    _api_admin_delete_scenario(id)
  }

  const duplicateScenario = (id) => {

    console.log('Duplicate Scenario id: ' + id)
    _api_admin_duplicate_scenario(id)
  }

  
  
  const editScenario = (id) => {

    console.log('Edit Scenario id: ' + id)
    //_api_admin_delete_scenario()
  }

  const _cancelBtnPressed = () => {
    setOpenBtnPressed(false);
  }

  const _cancelBtnDuplicatePressed = () => {
    setOpenBtnDuplicatePressed(false);
  }
  

  
  const _liveChanged = (_id, _live) => {
    _api_admin_edit_live_scenario(_id, _live ? 1 : 0);
  }

  const _notificationChanged = (_id, _notification) => {
    _api_admin_edit_notification_scenario(_id, _notification ? 1 : 0);
  }
  

  // <get Analyse Users>
  function _api_analyse_users_callback(_data) {

    if(_data.status_code != 2) {
      
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      }     
    } else {
      
      setAnalyseUsersArr(_data.data)
      console.log('_api_analyse_users_callback _data.data = ' + _data.data)
      ///////////////////////////////////////////////////////////////////////
    }
  }

  function _api_analyse_users() {

    let data = [
      {'key':'action', 'value': 'analyse_users'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_analyse_users_callback)
  }
  // </get Analyse Users>

  // <get Coins>
  function _api_get_current_month_coins_callback(_data) {

    if(_data.status_code != 2) {
      
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      }     
    } else {
      
      setCurrentMonthCoinsArr(_data.data)
      console.log('_api_analyse_users_callback _data.data = ' + _data.data)
      ///////////////////////////////////////////////////////////////////////
    }
  }

  function _api_get_current_month_coins() {

    let data = [
      {'key':'action', 'value': 'get_current_month_coins'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_current_month_coins_callback)
  }
  // </get Coins>
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };


  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />

        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
          <div>Scenario Builder</div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
            <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0, padding: 10}} >
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
                    <TextField
                        sx={theme.inputTextSx}
                        id="scenario_name"
                        placeholder="ex. Agressive Scenario"
                        size="small"
                        style={{width:300}}
                        onChange={event => setScenarioName(event.target.value)}
                        inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto', paddingBottom:10}}>
                    <Button
                        style={{
                            cursor:'pointer',
                            textTransform: 'none',
                            backgroundColor: '#F2F2F2'
                        }}
                        onClick={
                            () => addNewScenario()
                        }
                    >
                        Add New Scenario
                    </Button>
                </Grid>
                {errorMessage != '' ? 
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto', paddingBottom:20}}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                :
                    null
                }
                {successMessage != '' ? 
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto', paddingBottom:20}}>
                        <Alert severity="success">{successMessage}</Alert>
                    </Grid>
                :
                    null
                }
                {
                scenarioList.length > 0 ?
                        
                    
                        
                            scenarioList.map((value, key) => (
                              <React.Fragment key={`scenarioListFragment${key}_${value.id}`}>
                                <div style={{paddingBottom:20}}>
                                <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
                                  <Table sx={{ width:'100%' }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell align="right">Subscribers</StyledTableCell>
                                            <StyledTableCell align="right">Notification</StyledTableCell>
                                            <StyledTableCell align="right">Live/Offline</StyledTableCell>
                                            <StyledTableCell align="right" style={{width:40}}>Duplicate</StyledTableCell>
                                            <StyledTableCell align="right" style={{width:40}}>Delete</StyledTableCell>
                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        
                                            <StyledTableRow 
                                                key={`scenarioList${key}`}
                                            >
                                                    <StyledTableCell align="left">{value.name}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        Subscribers({value.subscribers})
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <ControlledSwitches checked={value.notification == 1 ? true : false} id={value.id} onSwitchChange={_notificationChanged} />
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <ControlledSwitches checked={value.live == 1 ? true : false} id={value.id} onSwitchChange={_liveChanged} />
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" style={{width:40}}>
                                                        <ModalAreYouSureWithParam btnTitle="Duplicate" open={openBtnDuplicatePressed} alertHeader={`Duplicate Scenario`} alertMessage={`Are you sure you want to duplicate Scenario #${value.name}?`} cancelFnc={() => _cancelBtnDuplicatePressed()} doFnc={duplicateScenario} _param={value.id} />
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" style={{width:40, color:'#cc0000'}}>
                                                        <ModalAreYouSureWithParam btnTitle="Delete" open={openBtnPressed} alertHeader={`Delete Scenario`} alertMessage={`Are you sure you want to delete Scenario #${value.name}?`} cancelFnc={() => _cancelBtnPressed()} doFnc={deleteScenario} _param={value.id} />
                                                    </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={6} align="left">
                                                    <Accordion>
                                                          <AccordionSummary
                                                              expandIcon={<ExpandMoreIcon />}
                                                              aria-controls="settings-content"
                                                              id="settings-header"
                                                          >
                                                              <Typography>Settings</Typography>
                                                          </AccordionSummary>
                                                          <AccordionDetails>
                                                              <ScenarioSettingsLine 
                                                                  title="Scenario Settings" 
                                                                  name={value.name}
                                                                  id={value.id}
                                                                  doSaveSettings={doSaveSettings}
                                                                  doSaveScenarioName={doSaveScenarioName}                                                          
                                                                  user_ids={value.user_ids}
                                                                  coin_ids={value.coin_ids}
                                                                  analyseUsersArr={analyseUsersArr}
                                                                  currentMonthCoinsArr={currentMonthCoinsArr}
                                                              />
                                                          </AccordionDetails>
                                                      </Accordion>
                                                    {value.conditions[0] ? 
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Typography>Condition 1 - Buy Resistance</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <ConditionLine 
                                                                    title="Condition 1 - Buy Resistance" 
                                                                    id={value.id}
                                                                    data={value.conditions[0]}
                                                                    doSaveCondition={doSaveCondition} 
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    :
                                                        null
                                                    }
                                                    {value.conditions[1] ? 
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel2a-content"
                                                                id="panel2a-header"
                                                            >
                                                                <Typography>Condition 2 - Buy Support</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <ConditionLine 
                                                                    title="Condition 2 - Buy Support" 
                                                                    id={value.id}
                                                                    data={value.conditions[1]} 
                                                                    doSaveCondition={doSaveCondition}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    :
                                                        null
                                                    }
                                                    {value.conditions[2] ? 
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel3a-content"
                                                                id="panel3a-header"
                                                            >
                                                                <Typography>Condition 3 - Sell Resistance</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <ConditionLine 
                                                                    title="Condition 3 - Sell Resistance" 
                                                                    id={value.id}
                                                                    data={value.conditions[2]} 
                                                                    doSaveCondition={doSaveCondition}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    :
                                                        null
                                                    }
                                                    {value.conditions[3] ? 
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel4a-content"
                                                                id="panel4a-header"
                                                            >
                                                                <Typography>Condition 4 - Sell Support</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <ConditionLine 
                                                                    title="Condition 4 - Sell Support" 
                                                                    id={value.id}
                                                                    data={value.conditions[3]} 
                                                                    doSaveCondition={doSaveCondition}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    :
                                                        null
                                                    }
                                                    {value.conditions[4] ? 
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel5a-content"
                                                                id="panel5a-header"
                                                            >
                                                                <Typography>Condition 5 - Neutral</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <ConditionLine 
                                                                    title="Condition 5 - Neutral" 
                                                                    id={value.id}
                                                                    data={value.conditions[4]}
                                                                    doSaveCondition={doSaveCondition}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    :
                                                        null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                </div>
                              </React.Fragment>
                            ))
                            
                        
                            
                :
                    null
                }
            </Grid>
        </Grid>

    </Grid>
  );
}