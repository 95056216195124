import React, { useContext } from 'react';
import { ThemeContext } from "../index";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';


export default function ButtonCustomStyle(props) {

    const [theme] = useContext(ThemeContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
      <>
      {props.changeHistory != null ? 
        <Button
            variant={props.variant == null ? 'text' : props.variant}
            onClick={() => changeHistory(props.changeHistoryFrom, props.changeHistoryTo)}
            endIcon={props.endIcon == null ? null : props.endIcon}
            style={{
                textTransform: props.textTransform == null ? 'none' : props.textTransform, 
                fontSize:props.fontSize == null ? 14 : props.fontSize, 
                color:theme.btn_color
            }}
        >
            {props.btnName}
        </Button>
        :
        props.doFnc != null ?
        <Button
            variant={props.variant == null ? 'text' : props.variant}
            onClick={props.param == null ? props.doFnc : () => props.doFnc(props.param)}
            endIcon={props.endIcon == null ? null : props.endIcon}
            style={{
                textTransform: props.textTransform == null ? 'none' : props.textTransform, 
                fontSize:props.fontSize == null ? 14 : props.fontSize, 
                color:theme.btn_color
            }}

        >
            {props.btnName}
        </Button>
        : 
        <Button
            variant={props.variant == null ? 'text' : props.variant}
            endIcon={props.endIcon == null ? null : props.endIcon}
            style={{
                textTransform: props.textTransform == null ? 'none' : props.textTransform, 
                fontSize:props.fontSize == null ? 14 : props.fontSize, 
                color:theme.btn_color
            }}
        >
            {props.btnName}
        </Button>
      }    
      </>
  );
}