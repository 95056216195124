import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import DartAnatomy from '../assets/img/DartAnatomy.png';
import DashboardTopMenu from "./DashboardTopMenu";
import { makeStyles } from '@material-ui/core/styles';
import "./youtubeStyles.css";
import YoutubeEmbed from "./YoutubeEmbed";

/*const useStyles = makeStyles((theme) => ({
  videoResponsive: {

    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0
  },

  videoResponsiveIframe: {

    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute'
  },
}));*/

export default function RouteColumnsHelp(props) {

  const history = useHistory();

  //const classes = useStyles();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
  <Grid container spacing={2} columns={12} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', margin: 0}} >
    <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
    <div style={{alignItems: 'center', padding:20}}>
      <div style={{textAlign:'center'}}>
        <Typography variant="h3" gutterBottom component="div">
          My Revenue Center Help
        </Typography>
      </div>
      <div style={{height:30}} />
      <div style={{maxWidth:900, margin:'auto'}}>
        <div style={{fontWeight:'bold'}}>My Link</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        This is a link that is generated by CryptoMarketDarts team specifically for you after you requested it by providing your details, such as name, wallet information, social network links. Discount code and percent of YOUR sales commission is already built into the code. When you share the link with your network and after a user clicks it to create a new registration on CryptoMarketDarts.com a discount will be automatically applied to a plan the user selects and YOU will automatically receive percent commission from this transaction. Please note that your commission amount will be locked in Smart Contract for the duration of 7 days due to our refund policy. Upon expiration of the refund period and only if the user did not ask for a refund, your sales commission becomes vested and you can withdraw it to your wallet that you provided to us during link request process. You can have one or many links depending how you want to use them. For example, you may want to use greater percent discount link and lesser percent sales commission for larger follower networks (get in volume) and lesser percent discount and greater percent sales commission for a smaller network. This will be up to your judgement.
        </div>
        <div style={{height:10}} />
        <div style={{fontWeight:'bold'}}>Promo Code</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        Is the code that CryptoMarketDarts team assigned to YOU. There are two things that are permanently associated with a promo code: percent discount for end user (that is applied once a user purchases a registration plan) and YOUR sales commission percent that was agreed upon with you during link set up process. Each promo code will likely have different discount percent and sales commission percent associated with it.
        </div>
        <div style={{height:10}} />
        <div style={{fontWeight:'bold'}}>User Discount %</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        User discount is percent discount from the regular price on any of CryptoMarketDarts membership plans. It is mostly determined by the CryptoMarketDarts team, however should you require a custom percent discount for your users, we normally accommodate the request.
        </div>
        <div style={{height:10}} />
        <div style={{fontWeight:'bold'}}>My (Influencer) Commission %</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        This is percent of sales commission YOU will receive from each transaction (each user registration). Please note that our refund policy is 7 days, so your sales commission will be locked in the smart contract for 7 days. Once the refund period ends and provided the user did not ask for a refund, your sales commisison becomes vested and you can transfer it to the wallet that you previously provided us with. You will need to have Metamask installed and you need to be connected to the site with that wallet address. Email us at info@CryptoMarketDarts.com if you have any questions.
        </div>
        <div style={{height:10}} />
        <div style={{fontWeight:'bold'}}>Number of Times the Link was Used</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        This number shows how many times the link was used TO COMPLETE the registration process. If 100 users clicked the link but only 15 actually registred on CryptoMarketDarts platform, then this number will be 15
        </div>
        <div style={{height:10}} />
        <div style={{fontWeight:'bold'}}>Total Earned (Vested + non-Vested) (ETH)</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        This is a total balance left (vested and unvested). Only vested portion of this balance is available for immediate withdrawal. The unvested portion of this balance has to wait until the end of the refund period in order to be eligible for immediate withdrawal.
        </div>
        <div style={{height:10}} />
        <div style={{fontWeight:'bold'}}>Link Status</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        Identified by CryptoMarketDarts team. Most of the time the link status is Active, but in certain cases when deactivated for reasons, such as no use within 30 days or when requested by the Influencer, the status will be Deactivated.
        </div>
        <div style={{height:10}} />
        <div style={{fontWeight:'bold'}}>Remaining Vested Balance From the Link (ETH)</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        This is the vested amount left available for immediate withdrawal after you withdrew portions or all of the vested amount
        </div>
        <div style={{height:10}} />
        <div style={{fontWeight:'bold'}}>Refundable Balance</div>
        <div style={{height:10}} />
        <div style={{paddingLeft:40}}>
        This number represents your sales commission that is not available for immediate withdrawal by you until the refund period ends. Once the refund period ends, this balance will move to vested portion of your sales commission and will be immediately available for withdrawal, provided it was not claimed by the users during refund period.
        </div>
      </div>
    </div>
  </Grid>
  );
}