import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext } from "../index";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import ButtonCustomStyle from "./ButtonCustomStyle";

const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding:20,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1976D2',
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword(props) {

  const [theme] = useContext(ThemeContext);

  const classes = useStyles();

  const [userEmail, setUserEmail] = useState('');

  const userEmailRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState(props.forgotPasswordResetErrorMessage != null ? props.forgotPasswordResetErrorMessage : '');

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const _apiForgotPassword = () => {

    let data = {};
    data['userEmail'] = userEmail;

    props._apiForgotPassword(data);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

      // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(userEmail == '') {
      setErrorMessage("Email can't be empty");
    } else if ( !re.test(userEmail) ) {
      
      setErrorMessage("Email is invalid");
        // invalid email, maybe show an error to the user.
    }

    if(userEmail != '' && re.test(userEmail)) {

      //sendContactRequest();
      _apiForgotPassword();
    }
    

    
    //console.log('userEmail = ' + userEmail);
  };

  return (
        <form className={classes.form}>
        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:400}}>
                    <Grid item xs={4} sm={4} md={4}>
                      <div style={{paddingTop:2}}>
                        <Item>Email</Item>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          sx={theme.inputTextSx}
                          id="userEmail_id"
                          name="userEmail"
                          ref={userEmailRef}
                          placeholder="your account email"
                          onChange={event => setUserEmail(event.target.value)}
                          size="small"
                          style={{width:'100%'}}
                          inputProps={{style: {color:theme.foreground}}}
                        />
                    </Grid>
                    {errorMessage != '' ? <Grid item xs={12}>
                      <Alert severity="error">{errorMessage}</Alert>
                    </Grid> : (<div></div>)}
                    <Grid item xs={4} sm={4} md={4}>

                    </Grid>
                    <Grid container item xs={8} sm={8} md={8} justifyContent="flex-end">
                      <div>
                        <ButtonCustomStyle
                            changeHistory={null}
                            doFnc={handleSubmit}
                            changeHistoryFrom=''
                            changeHistoryTo=''
                            btnName='Reset'                          
                        />
                      </div>
                    </Grid>
        </Grid>
        </form>
  );
}