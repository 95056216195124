import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';

//let loadDartIndex = 0;
export default function DartsDesign2(props) {

  let intervalId = 0; 
  const [loadDartIndex, setLoadDartIndex] = React.useState(0);

  const [isLoadDart, setIsLoadDart] = useState(true)

  const colorsArr = {"-100": "#FFFFFF", "2": "#00B050", "1": "#25FF88", "0": "#808080", "-1": "#FFA6A0", "-2": "#FF0000"};


  const [h1, setH1] = React.useState(-100);
  const [h4, setH4] = React.useState(-100);
  const [d1, setD1] = React.useState(-100);
  const [w1, setW1] = React.useState(-100);
  const [m1, setM1] = React.useState(-100);

  useEffect(() => {

    if(props._values != null) {
        loadDart(false)
    } else {
        loadDart(true)
    }
  }, []);

  const loadDart = (_bool) => {

    let _index = 4;

    setIsLoadDart(_bool)
    //console.log('loadDart = ' + _bool)
    if(_bool) {
      intervalId = setInterval(() => {
        if(_index > 0) {

          _index--;
          //console.log('loadDartIndex = ', _index)
        } else {
          _index = 4;
          //console.log('loadDartIndex = ', _index)
        }
        setLoadDartIndex(_index)
      }, 100);

      return () => clearInterval(intervalId);

    } else {
      clearInterval(intervalId);
    }
    
  }

  //////////////////////////////////////



  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (

    <div style={{margin:'auto', width:200, height:300}}>
                              {
                              props._values.coin_pair_name == "" ? null : 
                                <div style={{cursor:'pointer', fontWeight: 'bold'}}>
                                  {props._values.coin_pair_name}
                                </div>
                              }
                              <div style={{paddingTop:10}}>
                                <div style={{cursor:'pointer', display: 'flex', width: 200, height: 200, backgroundColor: '#00cc00', borderRadius: '50%', position:'relative', justifyContent: 'center', alignItems: 'center'}}>
                                  <div style={{margin:'auto', position:'absolute'}}>
                                    <div style={{display: 'flex', width: 200, height: 200, backgroundColor: !isLoadDart ? colorsArr[String(props._values._1m)] : loadDartIndex == 0 ? '#F2F2F2' : '#ffffff', borderRadius: '50%', position:'relative', justifyContent: 'center', alignItems: 'center'}}>
                                      
                                    </div>
                                  </div>
                                  <div style={{margin:'auto', position:'absolute'}}>
                                    <div style={{display: 'flex', width: 160, height: 160, backgroundColor: !isLoadDart ? colorsArr[props._values._1w] : loadDartIndex == 1 ? '#F2F2F2' : '#ffffff', border: '2px solid #CCCCCC', borderRadius: '50%', position:'relative', justifyContent: 'center', alignItems: 'center', }}>
                                      
                                    </div>
                                  </div>
                                  <div style={{margin:'auto', position:'absolute'}}>
                                    <div style={{display: 'flex', width: 120, height: 120, backgroundColor: !isLoadDart ? colorsArr[props._values._1d] : loadDartIndex == 2 ? '#F2F2F2' : '#ffffff', border: '2px solid #CCCCCC', borderRadius: '50%', position:'relative', justifyContent: 'center', alignItems: 'center', }}>
                                      
                                    </div>
                                  </div>
                                  <div style={{margin:'auto', position:'absolute'}}>
                                    <div style={{display: 'flex', width: 80, height: 80, backgroundColor: !isLoadDart ? colorsArr[props._values._4h] : loadDartIndex == 3 ? '#F2F2F2' : '#ffffff', border: '2px solid #CCCCCC', borderRadius: '50%', position:'relative', justifyContent: 'center', alignItems: 'center', }}>
                                      
                                    </div>
                                  </div>
                                  <div style={{margin:'auto', position:'absolute'}}>
                                    <div style={{display: 'flex', width: 40, height: 40, backgroundColor: !isLoadDart ? colorsArr[props._values._1h] : loadDartIndex == 4 ? '#F2F2F2' : '#ffffff', border: '2px solid #CCCCCC', borderRadius: '50%', position:'relative', justifyContent: 'center', alignItems: 'center', }}>
                                      
                                    </div>
                                  </div>
                                  <div style={{margin:'auto', position:'absolute'}}>
                                    <div style={{
                                        display: 'flex', 
                                        width: 200, 
                                        height: 200, 
                                        borderRadius: '50%', 
                                        position:'relative', 
                                        justifyContent: 'center', 
                                        alignItems: 'center', 

                                    }}>
                                      <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:3, position:'absolute'}}>1M</p>
                                      <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:23, position:'absolute'}}>1W</p>
                                      <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:43, position:'absolute'}}>1D</p>
                                      <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:63, position:'absolute'}}>4H</p>
                                      <p style={{backgroundColor: '#666666', width:10, padding:1, fontSize:7, color:'#FFFFFF', margin:'auto', top:83, position:'absolute'}}>1H</p>
                                    </div>
                                  </div>
                                </div>                 
                              </div>
                </div>
  );
}
