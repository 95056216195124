import React, { useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from 'react-router-dom';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Tooltip from '@mui/material/Tooltip';
import { NumericFormat } from 'react-number-format';

import {convertTimestampToAmerican, convertTimestampToAmericanAmPm, capitalizeFirstLetter, roundDecimals} from './functions';

function createData(hedge_quantity, hedge_quantity_buy, total_balance_filled_short_sell, quantity_number, coin_pair_id, coin, quantity, quantity_available, lastprice, marketvalue, marketvalue_available, history) {
  return {
    hedge_quantity,
    hedge_quantity_buy,
    total_balance_filled_short_sell,
    quantity_number,
    coin_pair_id,
    coin,
    quantity,
    quantity_available,
    lastprice,
    marketvalue,
    marketvalue_available,
    history,
  };
}

function Row(props) {
  const { row } = props;
  const theme = props.theme
  const [open, setOpen] = React.useState(false);

  function convertToUSD(pair) {
    const [coin, currency] = pair.split('-');
    return `USD-${coin}`;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', color:row.coin_pair_id == props.selectedCoin ? '#093A58' : '#333333', fontWeight:row.coin_pair_id == props.selectedCoin ? 'bold' : 'none' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon style={{color:row.coin_pair_id == props.selectedCoin ? '#093A58' : '#333333'}} /> : <KeyboardArrowDownIcon style={{color:row.coin_pair_id == props.selectedCoin ? '#093A58' : '#333333'}} />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <div>{row.coin}</div>
          {/*<div>Quantity:{Number(row.quantity_number)}</div>
          <div>totfilled_short:{Math.abs(row.total_balance_filled_short_sell)}</div>
          <div>hedge_quantity_buy:{Math.abs(row.hedge_quantity_buy)}</div>
          <div>diff:{Math.abs(row.total_balance_filled_short_sell) - Math.abs(row.hedge_quantity_buy)}</div>
          <div>hedge_quantity:{row.hedge_quantity}</div>*/}
          {Number(row.coin_pair_id) == 0 ? 
            null 
          : 
            (Number(row.quantity_number) < 0 && Math.abs(row.quantity_number) - Math.abs(row.hedge_quantity_buy)) > 0 ? 
              <Tooltip title={`Position does not appear to be fully hedged. Consider limiting your risks and entering stop orders, as applicable. Recommended quantity to create Buy stop Limit order: ${Math.abs(row.quantity_number) - Math.abs(row.hedge_quantity_buy)}`} placement="right"><ReportProblemIcon style={{width:15, height:15, cursor:'pointer', color: '#cc0000'}} /></Tooltip>
            :
              Number(row.hedge_quantity) > 0 ? 
                <Tooltip title={`Position does not appear to be fully hedged. Consider limiting your risks and entering stop orders, as applicable. Recommended quantity to create Sell stop Limit order: ${row.hedge_quantity}`} placement="right"><ReportProblemIcon style={{width:15, height:15, cursor:'pointer', color: '#cc0000'}} /></Tooltip>
              :
                null
          }
        </TableCell>
        <TableCell align="right">{row.quantity}</TableCell>
        <TableCell align="right">{row.quantity_available}</TableCell>        
        <TableCell align="right"><div>{row.lastprice}</div></TableCell>
        <TableCell align="right">{row.marketvalue}</TableCell>
        <TableCell align="right">{row.marketvalue_available}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {row.history.length == 0 ? 
              <>
                <Typography variant="h6" gutterBottom component="div">
                    History
                </Typography>
                <Table size="small" aria-label="purchases">
                    <TableHead>
                    <TableRow>
                        <TableCell>Order</TableCell>
                        <TableCell>Txn Type</TableCell>
                        <TableCell align="right">Coin</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                    </TableHead>
                </Table>
              </>
              :
              <>
                <Typography variant="h6" gutterBottom component="div">
                    History
                </Typography>
                <Table size="small" aria-label="purchases">
                    <TableHead>
                    <TableRow>
                        <TableCell>Order</TableCell>
                        <TableCell>Txn Type</TableCell>
                        <TableCell align="right">Coin</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Op Coin</TableCell>
                        <TableCell align="right">Op Quantity</TableCell>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {row.history.map((historyRow, key) => (
                        <TableRow id={`#wallet_${historyRow.order}`} key={`${historyRow.date}_${key}`}>
                            
                            <TableCell component="th" scope="row">
                                <a href={`#order_${historyRow.order}`} style={{fontSize:10}}>{historyRow.order}</a>
                            </TableCell>
                            <TableCell>{historyRow.txnType}</TableCell>
                            <TableCell align="right">{props.ind == 0 ? convertToUSD(historyRow.coin) : historyRow.coin}</TableCell>
                            <TableCell align="right">
                                <NumericFormat
                                    className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
                                    displayType="text"
                                    value={historyRow.quantity} 
                                    thousandSeparator="," 
                                    allowNegative={true} 
                                    decimalScale={8} 
                                    renderText={(value) => <>{value}</>}
                                />
                            </TableCell>
                            <TableCell align="right">{historyRow.log_coin_pair_name}</TableCell>
                            <TableCell align="right">
                              <NumericFormat
                                className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
                                displayType="text"
                                value={historyRow.log_txn_price}
                                thousandSeparator="," 
                                allowNegative={false} 
                                decimalScale={8}
                                renderText={(value) => <>{value}</>}
                              />
                            </TableCell>
                            <TableCell align="right">{historyRow.status}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    hedge_quantity: PropTypes.number.isRequired,
    hedge_quantity_buy: PropTypes.number.isRequired,
    total_balance_filled_short_sell: PropTypes.number.isRequired,
    quantity_number: PropTypes.number.isRequired,    
    coin_pair_id: PropTypes.number.isRequired,
    coin: PropTypes.string.isRequired,
    quantity_available: PropTypes.object.isRequired,
    quantity: PropTypes.object.isRequired,
    lastprice: PropTypes.object.isRequired,
    marketvalue: PropTypes.object.isRequired,    
    history: PropTypes.arrayOf(
      PropTypes.shape({
        order: PropTypes.string.isRequired,
        txnType: PropTypes.string.isRequired,
        coin: PropTypes.string.isRequired,
        quantity: PropTypes.string.isRequired,
        log_coin_pair_name: PropTypes.string.isRequired,
        log_txn_price: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};



export default function CollapsibleTable(props) {

    const [theme] = useContext(ThemeContext);
    const [staticText, portfolioAction] = useContext(ConstantsContext);

    const portfolioWalletBalances = props.portfolioWalletBalances;
    

    let rows = [];

    let history = []
    
    portfolioWalletBalances.map((value, key) => {

      ///////////HISTORY
      history = []
      if(value["history"] != undefined) {

        if(value["history"].length > 0) {
          value["history"].map((__value, __key) => {
              history.push({
                  order: __value.order_key,
                  txnType: portfolioAction[__value.action],
                  coin: __value.coin_pair_name,
                  quantity: __value.quantity,
                  log_coin_pair_name: __value.log_coin_pair_name,
                  log_txn_price: __value.log_txn_price,
                  status: __value.status,
              })
          })
        }
      }

        rows.push(createData(
            Number(value["quantity"]) + Number(value["total_balance_hedge_sell_stop_limit"]),
            Number(value["total_balance_hedge_buy_stop_limit"]),
            Number(value["total_balance_filled_short_sell"]),
            value["quantity"],
            Number(value["coin_pair_id"]), 
            value["coin_pair_name"], 
            <NumericFormat
              className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
              displayType="text"
              value={value["quantity"]} 
              thousandSeparator="," 
              allowNegative={true} 
              decimalScale={8} 
              renderText={(value) => <>{value}</>}
            />, 
            <NumericFormat
              className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
              displayType="text"
              value={value["quantity_available"]} 
              thousandSeparator="," 
              allowNegative={true} 
              decimalScale={8} 
              renderText={(value) => <>{value}</>}
            />,
            <NumericFormat
              className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
              displayType="text"
              value={value["last_price"]} 
              thousandSeparator="," 
              allowNegative={false} 
              decimalScale={8} 
              prefix={`$`} 
              renderText={(value) => <>{value}</>}
            />, 
            <NumericFormat
              className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
              displayType="text"
              value={value["last_price"] * value["quantity"]} 
              thousandSeparator="," 
              allowNegative={true} 
              decimalScale={2} 
              prefix={`$`} 
              renderText={(value) => <b>{value}</b>}
            />,
            <NumericFormat
              className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
              displayType="text"
              value={value["last_price"] * value["quantity_available"]} 
              thousandSeparator="," 
              allowNegative={false} 
              decimalScale={2} 
              prefix={`$`} 
              renderText={(value) => <b>{value}</b>}
            />,
            history
        ))

        
                            
    })

    function formatDate(inputDate) {
      const [year, month] = inputDate.split('-');
      const date = new Date(year, month - 1);
      const monthName = date.toLocaleString('default', { month: 'short' });
      const formattedDate = `${monthName} ${year}`;
    
      return formattedDate;
    }

  return (
    <>
      {props.filterByMonth == 0 ? null : <div style={{paddingBottom:10}}>{formatDate(props.filterByMonthValue)}</div>}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Coin</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Tradeable Qty</TableCell>
              <TableCell align="right">{props.filterByMonth == 0 ? 'Last' : formatDate(props.filterByMonthValue) + ' closing'} Price</TableCell>
              <TableCell align="right">Market Value</TableCell>
              <TableCell align="right">Tradeable Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => (
              <Row key={`collapsible_table_${key}`} ind={key} row={row} theme={theme} selectedCoin={props.selectedCoin} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
    
  );
}