import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAreYouSureWithParam(props) {
  const [open, setOpen] = React.useState(props.open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.cancelFnc();
  };

  const handleCloseAgree = () => {

    handleClose();
    props.doFnc(props._param);
  };

  return (
    <div>
        <div
            style={{cursor:'pointer', fontSize:12, width:50, align:'center', padding:10, borderRadius:5, border: `1px solid #cccccc`}}
            onClick={() => handleClickOpen(true)}                      
        >
            {props.btnTitle}
        </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{props.alertHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>No</Button>
          <Button onClick={() => handleCloseAgree()}>I'm sure</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}