import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from "../index";
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SignIn from "./SignIn";
import signinlogo from '../assets/img/signinlogo.png';
import home_cryptos from '../assets/img/home_cryptos.png';
import ParticlesBg from 'particles-bg';
import "./youtubeStyles.css";
import YoutubeEmbed from "./YoutubeEmbed";
import ButtonCustomStyle from "./ButtonCustomStyle";
import EthereumGold from '../assets/img/ethereum-gold.png';
import PnlListView from "./PnlListView";




const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding:20,
  },
  root_iframe: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0),
    },
    padding: theme.spacing(0,0),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1976D2',
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RouteSignIn(props) {

  const [theme] = useContext(ThemeContext);
  console.log("RouteSignIn theme", theme);


  const classes = useStyles();

  const IframeComponent = () => {

    return <iframe width="100%" height={930} src="https://embed.dappportfol.io/market/v1/usd/1/10/currency_off/pager_off/market_cap_rank,current_price,price_change_percentage_1h_in_currency,price_change_percentage_24h_in_currency,price_change_percentage_7d_in_currency" title="Market Widget" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  };

  let config = {
    color: ["random", "#ff0000"],
  };

  const EthereumGoldIcon = () => {
    return(
      <img src={EthereumGold} style={{width:20, height:20}} />
    )
  }

  

  return (
    <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%'}} direction="row" justifyContent="center" alignItems="flex-start" >
      <ParticlesBg style={{zIndex:-1}} num={100} 
        type="cobweb" 
        config={config} 
        color='#8FD0F8'
        bg={{
          position: "absolute",
          zIndex: 1,
          height: 850,
          top: 0,
          left: 0,
          color:'#FFFFFF',
        }}
      />
      {/*<Grid style={{zIndex:2}} item xs={12} sm={12} md={12} lg={6} align="center" justify="center" order={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
        <div className={classes.root_iframe} style={{paddingLeft:10}}>
            <IframeComponent />
        </div>
      </Grid>*/}
      <Grid style={{zIndex:10}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center" order={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
        <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', fontSize:16}} direction="row" justifyContent="center" alignItems="flex-start" >
          <Grid item xs={12} sm={12} md={12} lg={4} align="center" justify="center">
            <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%', maxWidth:400}} direction="row" justifyContent="center" alignItems="center" >
              <Grid style={{zIndex:1}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div>
                  <PnlListView noSignedIn={true} userDetails={props.userDetails} _apiConnect={props._apiConnect} />
                </div>
              </Grid>
              <Grid style={{zIndex:1}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{paddingTop:20, textAlign:'left'}}>
                      <div style={{height:20}} />
                      <b>Virtual Portfolio Competition Announcement</b>
                      <br />
                      We are thrilled to invite you to participate in our monthly Virtual Portfolio Crypto Trading Competition. As a passionate analyst or trader like yourself, we believe you have the skills and analytical prowess to excel in this thrilling challenge.
                      <div style={{height:20}} />
                      <b>About the Competition:</b>
                      <br />
                      The competition kicks off on the first of each month and runs until the last day of the same month. As a participant, you have the flexibility to start trading your virtual portfolio at any time after the competition begins. The goal is to showcase your exceptional analytical and trading skills while maneuvering the dynamic world of cryptocurrency markets.
                      <div style={{height:20}} />
                      <b>Rewards and Recognition:</b>
                      <br />
                      To make things even more exciting, we have enticing rewards for the top-performing analysts. On the last day of the month, at midnight (EST time zone), the top ten analysts will be awarded an impressive <b>cash prize of $200 each (paid in BTC or SOL equivalents)</b> provided they meet one of the following conditions: 
                      <br />
                      1. Your portfolio outperformed BTC price <b>by at least 10%, when BTC is greater than negative 9% MTD</b> (for example, BTC price MTD is down 4% MTD but your portfolio is up by 6% or more MTD)
                      <br />
                      2. Your portfolio is at least <b>1% up when BTC price is less than negative 10% MTD</b> (for example, BTC price MTD is down 15% but your portfolio is up 1% or more MTD)
                      <br /><br />
                      But, of course, the real triumph lies in the recognition and respect you'll earn within our vibrant analyst community
                      <div style={{height:20}} />
                      <b>How to Participate:</b>
                      <br />
                      Participating in the competition is a breeze. Here's a simple step-by-step guide:
                      <br />
                      <br />
                      Create your Analyst Account on <a href="https://cryptomarketdarts.com" style={{color:theme.btn_color}}>www.cryptomarketdarts.com</a>.
                      <br />
                      Make sure you start contributing your research at least once every three days (ideally every day). Exceptions apply to automated programming and select proprietary accounts not rewarded during competition regardless of their results.
                      <br />
                      Click the blue portfolio button (top right corner).
                      <br />
                      Access a virtual cash loan of $1,000,000 to kickstart your crypto trading journey.
                      <div style={{height:20}} />
                      <b>Learn, Compete, and Have Fun:</b>
                      <br />
                      While the competition is fierce, it's also an excellent opportunity to learn from fellow enthusiasts, enhance your trading strategies, and enjoy the thrill of the crypto market. Win or lose, every experience gained will contribute to your growth as an analyst and trader.
                      <div style={{height:20}} />
                      <b>Join Us Today!</b>
                      <br />
                      Don't miss out on the excitement and camaraderie of our Virtual Portfolio Crypto Trading Competition.
                  </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} align="center" justify="center">
            <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', fontSize:16}} direction="row" justifyContent="center" alignItems="center" >
              {/*<Grid item xs={12} sm={12} md={12} lg={12} align="flex-end" justify="flex-end" justifyContent="flex-end">
                <div style={{fontSize:20, paddingTop:30, textAlign:'right'}}>
                  <ButtonCustomStyle
                    changeHistory={true}
                    doFnc={null}
                    changeHistoryFrom='/'
                    changeHistoryTo='/EarnMoney'
                    btnName='Earn Money'
                    endIcon={<EthereumGoldIcon />}
                  />
                </div>
              </Grid>*/}
              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{fontSize:20, paddingTop:30}}>
                  Welcome to CryptoMarketDarts
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{fontSize:16}}>
                  Time-sensitive Critical Research and Analysis for Top Crypto Currencies
                </div>
              </Grid>      
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SignIn _apiSignIn={props._apiSignIn} signInErrorMessage={props.signInErrorMessage} userEmail={props.userEmail} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%', maxWidth:600}} direction="row" justifyContent="center" alignItems="center" >
                  <Grid style={{zIndex:1}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                    <div style={{textAlign:'center'}} className="videoResponsive">
                      <YoutubeEmbed embedId="P3Kh8ZVmQ0k" />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>      
    </Grid>
  );
}