import * as React from 'react';
import Switch from '@mui/material/Switch';

export default function ControlledSwitches(props) {
  const [checked, setChecked] = React.useState(props.checked);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.onSwitchChange(props.id, event.target.checked)
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}