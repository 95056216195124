import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];



export default function ConditionLine(props) {

    //const [dataTmp, setDataTmp] = React.useState(props.data)
    const timeIntervals = [
        { value: '1w_he', label: '1W HE' },
        { value: '1w_le', label: '1W LE' },
        { value: '1d_he', label: '1D HE' },
        { value: '1d_le', label: '1D LE' },
        { value: '1_4h_he', label: '1/4H HE' },
        { value: '1_4h_le', label: '1/4H LE' },
    ];
    const txnTypes = [
        { value: 'buy_limit', label: 'Buy Limit' },
        { value: 'buy_stop_limit', label: 'Buy Stop Limit' },
        { value: 'sell_limit', label: 'Sell Limit' },
        { value: 'sell_stop_limit', label: 'Sell Stop Limit' },
        { value: 'sell_short', label: 'Sell Short Limit' },
    ];

    const newOrderTxnTypes = [
        { value: 'buy_limit', label: 'Buy Limit' },
        { value: 'buy_stop_limit', label: 'Buy Stop Limit' },
        { value: 'sell_short', label: 'Sell Short Limit' },
    ];
    
    const findLabelByValue = (arr, value) => {
        const interval = arr.find((interval) => interval.value === value);
        return interval ? interval.label : '';
    };
    function convertNumber(input) {
        return 1 + (input / 100);
    }


    

    //////////////////////<TextFields>
    const [new_order_txn_size, setNew_order_txn_size] = React.useState(props.data.new_order_txn_size);
    const handleChangeNew_order_txn_size = (event) => {
        const value = event.target.value;
        setNew_order_txn_size(value);
    };
    
    const [new_order_trade_condition_percent, setNew_order_trade_condition_percent] = React.useState(props.data.new_order_trade_condition_percent);
    const handleChangeNew_order_trade_condition_percent = (event) => {
        const value = event.target.value;
        setNew_order_trade_condition_percent(value);
    };

    const [new_order_txn_padding, setNew_order_txn_padding] = React.useState(props.data.new_order_txn_padding);
    const handleChangeNew_order_txn_padding = (event) => {
        const value = event.target.value;
        setNew_order_txn_padding(value);
    };

    const [cancel_trade_condition_percent, setCancel_trade_condition_percent] = React.useState(props.data.cancel_trade_condition_percent);
    const handleChangeCancel_trade_condition_percent = (event) => {
        const value = event.target.value;
        setCancel_trade_condition_percent(value);
    };

    const [de_risk_txn_padding, setDe_risk_txn_padding] = React.useState(props.data.de_risk_txn_padding);
    const handleChangeDe_risk_txn_padding = (event) => {
        const value = event.target.value;
        setDe_risk_txn_padding(value);
    };

    const [de_risk_cancel_trade_condition_percent, setDe_risk_ancel_trade_condition_percent] = React.useState(props.data.de_risk_cancel_trade_condition_percent);
    const handleChangeDe_risk_cancel_trade_condition_percent = (event) => {
        const value = event.target.value;
        setDe_risk_ancel_trade_condition_percent(value);
    };

    const [exit_txn_padding, setExit_txn_padding] = React.useState(props.data.exit_txn_padding);
    const handleChangeExit_txn_padding = (event) => {
        const value = event.target.value;
        setExit_txn_padding(value);
    };
    //////////////////////</TextFields>

    //////////////////////<Selects>
    const handleChangeLeft = (event) => {
        const newDataTmp = { ...dataTmp, new_order_trade_condition_left: event.target.value };
        setDataTmp(newDataTmp);
    };
    
    
    const handleChangeCondition = (event) => {
        const newDataTmp = { ...dataTmp, new_order_trade_condition: event.target.value };
        setDataTmp(newDataTmp);
    };
    const handleChangeRight = (event) => {
        const newDataTmp = { ...dataTmp, new_order_trade_condition_right: event.target.value };
        setDataTmp(newDataTmp);
    };
    //Cancel Select More|Less
    const handleChangeCancelCondition = (event) => {
        const newDataTmp = { ...dataTmp, cancel_trade_condition: event.target.value };
        setDataTmp(newDataTmp);
    };
    //De Risk Cancel Select More|Less
    const handleDe_risk_cancel_trade_condition = (event) => {
        const newDataTmp = { ...dataTmp, de_risk_cancel_trade_condition: event.target.value };
        setDataTmp(newDataTmp);
    };

    const handleChangeExitTxnPeriod = (event) => {
        const newDataTmp = { ...dataTmp, exit_txn_period: event.target.value };
        setDataTmp(newDataTmp);
    };
    
    //////////////////////</Selects>

    const [dataTmp, setDataTmp] = React.useState(props.data)
    
    const doSaveCondition = () => {

        const newDataTmp = { 
            ...dataTmp, 
            new_order_txn_size: new_order_txn_size,
            new_order_trade_condition_percent: new_order_trade_condition_percent,
            cancel_trade_condition_percent: cancel_trade_condition_percent,
            new_order_txn_padding: new_order_txn_padding,
            de_risk_txn_padding: de_risk_txn_padding,
            de_risk_cancel_trade_condition_percent: de_risk_cancel_trade_condition_percent,
            exit_txn_padding: exit_txn_padding,
        };
        setDataTmp(newDataTmp);

        console.log('dataTmp = ', newDataTmp)
        props.doSaveCondition(props.id, newDataTmp)
    }



    const NewOrderSelectTimeframesCheckbox = () => {

        const handleChange_1h = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_1h: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        const handleChange_4h = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_4h: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        const handleChange_1d = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_1d: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        const handleChange_1w = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_1w: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        const handleChange_1m = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_1m: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        //2nd timeframes condition
        const handleChange_1h_2nd = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_1h_2nd: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        const handleChange_4h_2nd = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_4h_2nd: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        const handleChange_1d_2nd = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_1d_2nd: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        const handleChange_1w_2nd = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_1w_2nd: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };

        const handleChange_1m_2nd = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_1m_2nd: event.target.checked ? "1" : "0" };
            setDataTmp(newDataTmp);
        };
        // Function to map the numeric values to colors
        return (
            
            <div style={{width:370}}>
                <div>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_1h == "1"}
                                    onChange={handleChange_1h}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="1H"
                            labelPlacement="top"
                        />
                    </span>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_4h == "1"}
                                    onChange={handleChange_4h}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="4H"
                            labelPlacement="top"
                        />
                    </span>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_1d == "1"}
                                    onChange={handleChange_1d}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="1D"
                            labelPlacement="top"
                        />
                    </span>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_1w == "1"}
                                    onChange={handleChange_1w}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="1W"
                            labelPlacement="top"
                        />
                    </span>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_1m == "1"}
                                    onChange={handleChange_1m}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="1M"
                            labelPlacement="top"
                        />
                    </span>
                </div>
                <div>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_1h_2nd == "1"}
                                    onChange={handleChange_1h_2nd}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="1H"
                            labelPlacement="top"
                        />
                    </span>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_4h_2nd == "1"}
                                    onChange={handleChange_4h_2nd}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="4H"
                            labelPlacement="top"
                        />
                    </span>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_1d_2nd == "1"}
                                    onChange={handleChange_1d_2nd}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="1D"
                            labelPlacement="top"
                        />
                    </span>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_1w_2nd == "1"}
                                    onChange={handleChange_1w_2nd}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="1W"
                            labelPlacement="top"
                        />
                    </span>
                    <span>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={dataTmp.timeframe_1m_2nd == "1"}
                                    onChange={handleChange_1m_2nd}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="1M"
                            labelPlacement="top"
                        />
                    </span>
                </div>
            </div>
        );
    };
    const NewOrderSelectedTimeframesOutcome = () => {

        const handleChange = (event) => {
            const newDataTmp = { ...dataTmp, timeframe_outcome: event.target.value };
            setDataTmp(newDataTmp);
        };
        // Function to map the numeric values to colors
        return (
            
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Outcome</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={dataTmp.timeframe_outcome}
                    label="Outcome"
                    onChange={handleChange}
                >
                    <MenuItem value="buy">Buy</MenuItem>
                    <MenuItem value="sell">Sell</MenuItem>
                    <MenuItem value="neutral">Neutral</MenuItem>
                </Select>
            </FormControl>
        );
    };
    const NewOrderResistanceSupport = () => {
        // Function to map the numeric values to colors
        

        const handleChange = (event) => {
            const newDataTmp = { ...dataTmp, resistance_support: event.target.value };
            setDataTmp(newDataTmp);
        };
        // Function to map the numeric values to colors
        return (
            
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Resistance/Support</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={dataTmp.resistance_support}
                    label="Resistance/Support"
                    onChange={handleChange}
                >
                    <MenuItem value="resistance">Resistance</MenuItem>
                    <MenuItem value="support">Support</MenuItem>
                </Select>
            </FormControl>
        );
    };
    
    const NewOrderTxnType = () => {
        // Function to map the numeric values to colors
        const handleChange = (event) => {
            const newDataTmp = { ...dataTmp, new_order_txn_type: event.target.value };
            setDataTmp(newDataTmp);
        };
        // Function to map the numeric values to colors
        return (
            
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Txn Type</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={dataTmp.new_order_txn_type}
                    label="Txn Type"
                    onChange={handleChange}
                >
                    {newOrderTxnTypes.map((interval) => (
                        <MenuItem key={interval.value} value={interval.value}>
                            {interval.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const NewOrderTxnPriceFormula = () => {
        // Function to map the numeric values to colors
        return (
            
            <div>
                {findLabelByValue(timeIntervals, dataTmp.new_order_trade_condition_right)} * {convertNumber(new_order_txn_padding)}
            </div>
        );
    };
    
    ///////////////De-Risk Section Components
    const DeRiskTxnType = () => {
        // Function to map the numeric values to colors
        const handleChange = (event) => {
            const newDataTmp = { ...dataTmp, de_risk_txn_type: event.target.value };
            setDataTmp(newDataTmp);
        };
        // Function to map the numeric values to colors
        return (
            
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Txn Type</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={dataTmp.de_risk_txn_type}
                    label="Txn Type"
                    onChange={handleChange}
                >
                    {txnTypes.map((interval) => (
                        <MenuItem key={interval.value} value={interval.value}>
                            {interval.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const DeRiskTxnPriceFormula = () => {
        // Function to map the numeric values to colors
        return (
            
            <div>
                (New Txn Filled/De-Risk Canceled) Order Price * {convertNumber(de_risk_txn_padding)}
            </div>
        );
    };

    ///////////////Exit Section Components
    const ExitTxnType = () => {
        // Function to map the numeric values to colors
        const handleChange = (event) => {
            const newDataTmp = { ...dataTmp, exit_txn_type: event.target.value };
            setDataTmp(newDataTmp);
        };
        // Function to map the numeric values to colors
        return (
            
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Txn Type</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={dataTmp.exit_txn_type}
                    label="Txn Type"
                    onChange={handleChange}
                >
                    {txnTypes.map((interval) => (
                        <MenuItem key={interval.value} value={interval.value}>
                            {interval.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const ExitTxnPriceFormula = () => {
        // Function to map the numeric values to colors
        return (
            
            <div>
                * {convertNumber(exit_txn_padding)}
            </div>
            
        );
    };

    

  return (
    <>
        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <Button variant="contained" size="small" style={{width:'70%'}} onClick={() => doSaveCondition()}>
                    Save {props.title}
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <Paper elevation={3} style={{ padding: 16, marginBottom: 16, backgroundColor: '#4472C4'}}>
                    <Typography style={{fontSize:20, color: '#FFFFFF', textAlign: 'left'}}>Conditions</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700, backgroundColor: '#CFDBEF' }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Select Timeframes</StyledTableCell>
                                    <StyledTableCell align="left">Selected Timeframes Outcome</StyledTableCell>
                                    <StyledTableCell align="left">Res/Supp</StyledTableCell>
                                    <StyledTableCell align="left">Txn Size (from av. cash)</StyledTableCell>
                                    <StyledTableCell align="left">Trade Condition (IF … = TRUE)</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={`condition_new_order_1`}>
                                    <StyledTableCell component="th" scope="row">
                                        <NewOrderSelectTimeframesCheckbox />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <NewOrderSelectedTimeframesOutcome />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {dataTmp.timeframe_outcome != 'neutral' ? 
                                            <NewOrderResistanceSupport />
                                        : null}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {dataTmp.timeframe_outcome != 'neutral' ? 
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <TextField
                                                    label="Txn Size"
                                                    id="outlined-size-small"
                                                    defaultValue={new_order_txn_size}
                                                    size="small"
                                                    value={new_order_txn_size}
                                                    onChange={handleChangeNew_order_txn_size}
                                                    style={{width:70}}
                                                />
                                                <span style={{ marginLeft: 5 }}>%</span>
                                            </div>
                                        : null}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" style={{minWidth:300}}>
                                        {dataTmp.timeframe_outcome != 'neutral' ? 
                                            <div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span>
                                                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                            <InputLabel id="demo-select-small-label">Left</InputLabel>
                                                            <Select
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small"
                                                                value={dataTmp.new_order_trade_condition_left}
                                                                label="Trade Left"
                                                                onChange={handleChangeLeft}
                                                            >
                                                                {timeIntervals.map((interval) => (
                                                                    <MenuItem key={interval.value} value={interval.value}>
                                                                        {interval.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </span>
                                                    <span style={{ marginLeft: 5 }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <TextField
                                                                label="Trade Condition Percent"
                                                                id="outlined-size-small"
                                                                defaultValue={new_order_trade_condition_percent}
                                                                size="small"
                                                                value={new_order_trade_condition_percent}
                                                                onChange={handleChangeNew_order_trade_condition_percent}
                                                                style={{width:70}}
                                                            />
                                                            <span style={{ marginLeft: 5 }}>%</span>
                                                        </div>
                                                    </span>
                                                    <span style={{ marginLeft: 5 }}>
                                                        <FormControl sx={{ m: 1, minWidth: 50 }} size="small">
                                                            <InputLabel id="demo-select-small-label">{'>/<'}</InputLabel>
                                                            <Select
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small"
                                                                value={dataTmp.new_order_trade_condition}
                                                                label=">/<"
                                                                onChange={handleChangeCondition}
                                                                style={{width:50}}
                                                            >
                                                                <MenuItem value="more">{'>'}</MenuItem>
                                                                <MenuItem value="less">{'<'}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </span>
                                                    <span style={{ marginLeft: 5 }}>
                                                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                            <InputLabel id="demo-select-small-label">Right</InputLabel>
                                                            <Select
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small"
                                                                value={dataTmp.new_order_trade_condition_right}
                                                                label="Trade Right"
                                                                onChange={handleChangeRight}
                                                            >
                                                                {timeIntervals.map((interval) => (
                                                                    <MenuItem key={interval.value} value={interval.value}>
                                                                        {interval.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </span>
                                                </div>
                                                
                                            </div>
                                        : 
                                            null
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            {dataTmp.timeframe_outcome != 'neutral' ? 
            <Grid item xs={12} sm={12} md={4} lg={4} align="center" justify="center">
                <Paper elevation={3} style={{ padding: 16, marginBottom: 16, backgroundColor: '#4472C4'}}>
                    <Typography style={{fontSize:20, color: '#FFFFFF', textAlign: 'left'}}>New Order</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ backgroundColor: '#CFDBEF' }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>New Order Txn Type</StyledTableCell>
                                    <StyledTableCell align="left">New Order Txn Padding</StyledTableCell>
                                    <StyledTableCell align="right">New Order Txn Price Formula</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={`condition_new_order_1`}>
                                    <StyledTableCell align="right">
                                        <NewOrderTxnType />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                label="Txn Padding"
                                                id="outlined-size-small"
                                                defaultValue={new_order_txn_padding}
                                                size="small"
                                                value={new_order_txn_padding}
                                                onChange={handleChangeNew_order_txn_padding}
                                                style={{width:70}}
                                            />
                                            <span style={{ marginLeft: 5 }}>%</span>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <NewOrderTxnPriceFormula />
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key={`condition_new_order_2`}>
                                    <StyledTableCell colSpan={3}>
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', paddingLeft:10 }}>
                                                Cancel Condition
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginLeft: 5, width: 120 }}>
                                                    {findLabelByValue(timeIntervals, dataTmp.new_order_trade_condition_right)} old value
                                                </span>
                                                <span style={{ marginLeft: 5 }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            label="Trade Condition Percent"
                                                            id="outlined-size-small"
                                                            defaultValue={cancel_trade_condition_percent}
                                                            size="small"
                                                            value={cancel_trade_condition_percent}
                                                            onChange={handleChangeCancel_trade_condition_percent}
                                                            style={{width:70}}
                                                        />
                                                        <span style={{ marginLeft: 5 }}>%</span>
                                                    </div>
                                                </span>
                                                <span style={{ marginLeft: 5 }}>
                                                    <FormControl sx={{ m: 1, minWidth: 50 }} size="small">
                                                        <InputLabel id="demo-select-small-label">{'>/<'}</InputLabel>
                                                        <Select
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={dataTmp.cancel_trade_condition}
                                                            label=">/<"
                                                            onChange={handleChangeCancelCondition}
                                                            style={{width:50}}
                                                        >
                                                            <MenuItem value="more">{'>'}</MenuItem>
                                                            <MenuItem value="less">{'<'}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </span>
                                                <span style={{ marginLeft: 5, width: 120 }}>
                                                    {findLabelByValue(timeIntervals, dataTmp.new_order_trade_condition_right)} current value
                                                </span>
                                            </div>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            : null}
            {dataTmp.timeframe_outcome != 'neutral' ? 
            <Grid item xs={12} sm={12} md={4} lg={4} align="center" justify="center">
                <Paper elevation={3} style={{ padding: 16, marginBottom: 16, backgroundColor: '#7030A0'}}>
                    <Typography style={{fontSize:20, color: '#FFFFFF', textAlign: 'left'}}>De-Risk</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ backgroundColor: '#D7BCEB' }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>De-Risk Txn Type</StyledTableCell>
                                    <StyledTableCell align="left">De-Risk Txn Padding</StyledTableCell>
                                    <StyledTableCell align="right">De-Risk Txn Price Formula</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={`condition_de_risk_1`}>
                                    <StyledTableCell component="th" scope="row">
                                        <DeRiskTxnType />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                label="Txn Padding"
                                                id="outlined-size-small"
                                                defaultValue={de_risk_txn_padding}
                                                size="small"
                                                value={de_risk_txn_padding}
                                                onChange={handleChangeDe_risk_txn_padding}
                                                style={{width:70}}
                                            />
                                            <span style={{ marginLeft: 5 }}>%</span>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <DeRiskTxnPriceFormula />
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key={`condition_de_risk_2`}>
                                    <StyledTableCell colSpan={3}>
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', paddingLeft:10 }}>
                                                Cancel Condition (Trailing Order Creation)
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginLeft: 5, width: 120 }}>
                                                    (New Txn Filled/De-Risk Canceled) Order Price
                                                </span>
                                                <span style={{ marginLeft: 5 }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            label="Trade Condition Percent"
                                                            id="outlined-size-small"
                                                            defaultValue={de_risk_cancel_trade_condition_percent}
                                                            size="small"
                                                            value={de_risk_cancel_trade_condition_percent}
                                                            onChange={handleChangeDe_risk_cancel_trade_condition_percent}
                                                            style={{width:70}}
                                                        />
                                                        <span style={{ marginLeft: 5 }}>%</span>
                                                    </div>
                                                </span>
                                                <span style={{ marginLeft: 5 }}>
                                                    <FormControl sx={{ m: 1, minWidth: 50 }} size="small">
                                                        <InputLabel id="demo-select-small-label">{'>/<'}</InputLabel>
                                                        <Select
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={dataTmp.de_risk_cancel_trade_condition}
                                                            label=">/<"
                                                            onChange={handleDe_risk_cancel_trade_condition}
                                                            style={{width:50}}
                                                        >
                                                            <MenuItem value="more">{'>'}</MenuItem>
                                                            <MenuItem value="less">{'<'}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </span>
                                                <span style={{ marginLeft: 5, width: 120 }}>
                                                    Current Price
                                                </span>
                                            </div>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            : null}
            {dataTmp.timeframe_outcome != 'neutral' ? 
            <Grid item xs={12} sm={12} md={4} lg={4} align="center" justify="center">
                <Paper elevation={3} style={{ padding: 16, marginBottom: 16, backgroundColor: '#00B050'}}>
                    <Typography style={{fontSize:20, color: '#FFFFFF', textAlign: 'left'}}>Exit</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ backgroundColor: '#B0FFD3' }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Exit Txn Type</StyledTableCell>
                                    <StyledTableCell align="left">Exit Txn Padding</StyledTableCell>
                                    <StyledTableCell align="right">Exit Txn Price Formula</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={`condition_new_order_1`}>
                                    <StyledTableCell component="th" scope="row">
                                        <ExitTxnType />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                label="Txn Padding"
                                                id="outlined-size-small"
                                                defaultValue={exit_txn_padding}
                                                size="small"
                                                value={exit_txn_padding}
                                                onChange={handleChangeExit_txn_padding}
                                                style={{width:70}}
                                            />
                                            <span style={{ marginLeft: 5 }}>%</span>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <div>
                                            <span>
                                                <FormControl sx={{ m: 1, minWidth: 90 }} size="small">
                                                    <InputLabel id="demo-select-small-label">Left</InputLabel>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={dataTmp.exit_txn_period}
                                                        label="Trade Left"
                                                        onChange={handleChangeExitTxnPeriod}
                                                    >
                                                        {timeIntervals.map((interval) => (
                                                            <MenuItem key={interval.value} value={interval.value}>
                                                                {interval.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </span>
                                            <span>
                                                <ExitTxnPriceFormula />
                                            </span>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            : null}
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <Button variant="contained" size="small" style={{width:'70%'}} onClick={() => doSaveCondition()}>
                    Save {props.title}
                </Button>
            </Grid>
        </Grid>
        
    </>
  );
}