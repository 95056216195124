import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import dayjs from 'dayjs';

const Web3 = require("web3");


////////////////////////////////////////////////////test
//const _domain = 'test.cryptomarketdarts.com';
////////////////////////////////////////////////////production
const _domain = 'cryptomarketdarts.com';

//let Subscribejson = require('./abis/Subscribe.json');

const themes = {
  light: {
    btn_color: "#0075B6",
    btn_color_border: '#073149',
    btn_disabled: '#ADA2A2',
    foreground: "#000000",
    background: "#FFFFFF",
    infoPanelBackground: '#F2F2F2',
    
    inputTextSx: {
      "& .MuiOutlinedInput-root": {
        "& > fieldset": {
          borderColor: '#000000'
        }
      },
      "& .MuiOutlinedInput-root:focused": {
        "& > fieldset": {
          borderColor: '#000000'
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
          borderColor: '#000000'
        }
      }
    },
  },
  dark: {
    btn_color: "#C7E7FA",
    btn_color_border: '#F2F2F2',
    btn_disabled: '#B0A6A6',
    foreground: "#C7E7FA",
    background: "#093A58",
    infoPanelBackground: '#073149',
    inputTextSx: {
      "& .MuiOutlinedInput-root": {
        "& > fieldset": {
          borderColor: '#C7E7FA'
        }
      },
      "& .MuiOutlinedInput-root:focused": {
        "& > fieldset": {
          borderColor: '#C7E7FA'
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
          borderColor: '#C7E7FA'
        }
      }
    },
  },
};

export const ThemeContext = React.createContext();
const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(themes.dark);
  const [activeTheme, setActiveTheme] = useState("dark");

  const [dartCurrentHistoricalDate, setDartCurrentHistoricalDate] = useState('current');
  const [currentDartDate, setCurrentDartDate] = useState(dayjs(new Date()).subtract(3, 'day').format('YYYY-MM-DD'));

  const toggleTheme = () => {
    const nextTheme = activeTheme === "light" ? "dark" : "light";
    setTheme(themes[nextTheme]);
    setActiveTheme(nextTheme);
    console.log("toggleTheme", theme, activeTheme);
  };

  const setDartCurrentHistoricalDateFnc = (_type_name) => {

    setDartCurrentHistoricalDate(_type_name);
  }

  const setCurrentDartDateFnc = (_new) => {

    setCurrentDartDate(_new);
  }

  return (
    <ThemeContext.Provider value={[theme, toggleTheme, activeTheme, dartCurrentHistoricalDate, setDartCurrentHistoricalDateFnc, currentDartDate, setCurrentDartDateFnc]}>
      {children}
    </ThemeContext.Provider>
  );
};

////////////////////////////////////////////////////<ConstantsContextProvider>
const staticText = {
  connect_to_wallet: "You need to login into your Metamask wallet before proceeding. Once logged in, ensure you are connected to Ethereum Mainnet.",
  connect_to_mainnet: "Please connect to Ethereum Mainnet",
  payment_confirm: "Please Confirm Payment Transaction on Metamask",
  payment_processing: "Please don’t leave this page while we are processing your payment. This can take 10-15 seconds.",
  payment_denied: "User denied transaction signature. Press Pay button and confirm the transaction on Metamask",
  refund_confirm: "Please Confirm Refund Transaction on Metamask. You will see gas fee charged for this transaction. However the amount you paid for subscription is not shown BUT it will be refunded to you net of gas fee",
  refund_processing: "Please don’t leave this page while we are processing your refund. This can take 10-15 seconds.",
  refund_denied: "User denied transaction signature. Press Refund button and confirm the transaction on Metamask",
  withdraw_confirm: "Please Confirm Withdraw Transaction on Metamask. You will see gas fee charged for this transaction. However the amount you are going withdraw is not shown BUT it will be withdraw to you net of gas fee",
  withdraw_processing: "Please don’t leave this page while we are processing your withdraw. This can take 10-15 seconds.",
  withdraw_denied: "User denied transaction signature. Press Withdraw button and confirm the transaction on Metamask",
  agree: "You must agree to Terms and Conditions",
  agree_refund: "You must agree to Terms and Conditions below My Subscriptions summary.",
  subscription_expired: "Your subscription has expired. Please select a plan below to purchase a new subscription",
  invalid_promo_code: "Invalid Promo code",
  path: "https://" + _domain,
  path_user: "https://" + _domain + "/api/user/index.php",
  path_darts: "https://" + _domain + "/api/darts/index.php",
  path_like: "https://" + _domain + "/api/like/index.php",
  path_log: "https://" + _domain + "/api/log/index.php",
  rightNetworkId: 1,
  historicDateLabels: ["Today", "Yesterday", "2 Days Ago", "3 Days Ago"]
};


const portfolioAction = {
  start: "Start", 
  buy_limit: "Buy Limit", 
  sell_limit: "Sell Limit", 
  buy_stop_limit: "Buy Stop Limit", 
  sell_stop_limit: "Sell Stop Limit",
  sell_short: "Short Sell Limit",
  buy_long: "Buy Long Limit"
};

const portfolioStatus = {
  open: "Open", 
  filled: "Filled", 
  canceled: "Canceled"
};

export const ConstantsContext = React.createContext();
const ConstantsContextProvider = ({ children }) => {

  return (
    <ConstantsContext.Provider value={[staticText, portfolioAction, portfolioStatus]}>
      {children}
    </ConstantsContext.Provider>
  );
};

////////////////////////////////////////////////////</ConstantsContextProvider>

///////////////////////////////////////connect wallet CONTEXT
/*
export const ConnectWalletContext = React.createContext();

const ConnectWalletContextProvider = ({ children }) => {
  
  //const rightNetworkId = 4;
  const rightNetworkId = 1;
  const [accountStage, setAccountStage] = useState('connect_to_wallet');
  const [connectedChanged, setConnectedChanged] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');
  const [bc, setBc] = useState({
    'account': '',
    'accountShort': '',
    'networkId': 0,
    'connectedWalletBool': false,
    'web3': '',
    'isSigned': false,
    'signature': '',
    'message': ''
  });

  const [_contract, setContract] = useState({
    'name': '',
    'owner': '',
    'contract_connection' : '',
    'user_service': {},
    'user_subscriptions': {},
    'expire_date': 0
  });
  //const [activeTheme, setActiveTheme] = useState("dark");

  const connectWallet = async () => {
    console.log('connectWallet');
    if (window.ethereum) {


        await window.ethereum.send('eth_requestAccounts');

        window.web3 = new Web3(window.ethereum);

        /////////////////////////////////////////////////////
        const accounts = await window.web3.eth.getAccounts()
        // Network ID
        const networkId = await window.web3.eth.net.getId()
        ///let _balance = 0;
        
        if(accounts[0] != undefined) {

          console.log('account = ', accounts[0]);
          console.log('networkId = ', networkId);

          let _bc = {
            'account': accounts[0],
            'accountShort': accounts[0].substr(0, 5) + '...' + accounts[0].substr(39, 3),
            'networkId': networkId,
            'connectedWalletBool': true,
            'web3': window.web3
          }

          

          setBc(_bc);

          if(networkId != rightNetworkId) {

            setAccountStage('connect_to_mainnet')
          } else {

            loadSmartContract(_bc);
            //loadMyNFTsContract();

            //if(accountStage != 'logined') {
            if((localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '')) {

              console.log('set logined')
              setAccountStage('logined')
            } else {

              setAccountStage('login')
            }
            //}
            
            //signMessage();
          }


          window.ethereum.on('accountsChanged', handleAccountsChanged)
          window.ethereum.on('chainChanged', handleChainChanged)
        } else {

          console.log('connect_to_wallet')
          setAccountStage('connect_to_wallet')
        }

      return true;
    } else {
      console.log('Please install MetaMask.')

      let _bc = bc;
      _bc.account = '';
      _bc.accountShort = '';
      _bc.networkId = 0;
      _bc.connectedWalletBool = false;
      _bc.isSigned = false;
      _bc.signature = '';
      _bc.message = '';
      setBc(_bc);
      setAccountStage('connect_to_wallet')

      //setMetmaskError('Please install MetaMask.')
    }
    
    return false;
  }

  const signMessage = () => {

    console.log('signMessage  = ', bc.account)
    //let msg = '0xca5e3f850877ea106134e2a2c8d7c0018a9d412b59abe623b2d1a432f0d163a8'
    let msg = 'I am signing to galileofigaro'
    let from = bc.account
    window.web3.eth.personal.sign(msg, from, function (err, result) {
      if (err) return console.error(err)

      let _bc = bc;
      _bc.isSigned = true;
      _bc.signature = result;
      _bc.message = msg;

      setBc(_bc);
      console.log('_bc signMessage', _bc);
      setAccountStage('signed')
    })
  }

  // For now, 'eth_accounts' will continue to always return an array
  const handleAccountsChanged = (accounts) => {

    if (accounts.length === 0) {

      let _bc = bc;
      _bc.account = '';
      _bc.connectedWalletBool = false;
      setBc(_bc);
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.')
      setAccountStage('connect_to_wallet')
      //setMetmaskError('Please connect to MetaMask.')

    } else if (accounts[0] !== bc.account) {

      localStorage.setItem('access_token', '');
      localStorage.setItem('refresh_token', '');
      //setAccountStage('login')

      console.log('handleAccountsChanged = ' + accounts[0])
      let _bc = bc;
      _bc.connectedWalletBool = true;
      _bc.account = accounts[0];
      _bc.accountShort = accounts[0].substr(0, 5) + '...' + accounts[0].substr(39, 3);
      setBc(_bc);
      console.log('_bc handleAccountsChanged', _bc)

      connectWallet()
    }
  }

  function handleChainChanged (chainId) {
    
      console.log('handleChainChanged = ' + chainId.substr(2))
      if (Number(bc.networkId) !== Number(chainId.substr(2))) {

        localStorage.setItem('access_token', '');
        localStorage.setItem('refresh_token', '');
    
        console.log('handleChainChanged different')
        let _bc = bc;
        _bc.networkId = Number(chainId.substr(2));
        setBc(_bc);
        console.log('_bc handleChainChanged', _bc)

        connectWallet()
      }
    }

    /////////////////////////////////////////load NFT contract and stuff
    const loadSmartContract = async (_bc) => {

      setContract({
        'name': '',
        'owner': '',
        'contract_connection' : '',
        'user_service': {},
        'user_subscriptions': {},
        'expire_date': 0
      });

      console.log(Subscribejson)
      console.log('bc.networkId', _bc.networkId)
        
      const networkData = Subscribejson.networks[_bc.networkId];

      console.log('networkData = ', networkData)
      if (networkData) {

        const NFT_par = new _bc.web3.eth.Contract(
          Subscribejson.abi,
          networkData.address
        );
        
        let _name = await NFT_par.methods.name().call();
        let _owner = await NFT_par.methods.owner().call();
        let _user_service = {};
        let _user_subscriptions = {};
        await NFT_par.methods
          .user_service(_bc.account)
          .call()
          .then(
            result => {
              
              //setBalanceOf_res(result)
              console.log('result = ', result)
              _user_service = result;
            }
          )
          .catch(
            err => {
              //console.log("An error occured", err)
              //setBalanceOf_res(String(err))
            }
          )
        
          await NFT_par.methods
          .getUserSubscribesByAddress(_bc.account)
          .call()
          .then(
            result => {
              
              //setBalanceOf_res(result)
              console.log('result = ', result)
              _user_subscriptions = result;
            }
          )
          .catch(
            err => {
              //console.log("An error occured", err)
              //setBalanceOf_res(String(err))
            }
          )

          
        
        console.log(_name);
        Promise.all(
          [ 
            _name,
            _owner
          ])
          .then((values) => {

            let _nft_temp = _contract;

            _nft_temp.contract_connection = NFT_par;
            _nft_temp.name = _name.toString();
            _nft_temp.owner = _owner.toString();
            _nft_temp.user_service = _user_service;
            _nft_temp.expire_date = _user_service.expire_date;
            _nft_temp.user_subscriptions = _user_subscriptions;
            setContract(_nft_temp);

            console.log('_nft_temp = ', _nft_temp);
          }
        );
      } else {
        //this.setState({ contractDetected: false });
      }
  }

  ////////////////////////////////////////

  const user_serviceFnc = async (__contract) => {

    console.log('newwwwwwwwwwwwwwwwwwwww = ', __contract)
    
  }

  useEffect(() => {

  }, []);

  return (
    <ConnectWalletContext.Provider value={[accountStage, setAccountStage, bc, rightNetworkId, connectWallet, signMessage, currentPath, loadSmartContract, _contract, user_serviceFnc]}>
      {children}
    </ConnectWalletContext.Provider>
  );
};
*/

ReactDOM.render(
  <React.StrictMode>
    <ConstantsContextProvider>
      <ThemeContextProvider>
          <App />
      </ThemeContextProvider>
    </ConstantsContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
