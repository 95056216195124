import React, { useState, useEffect } from 'react';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import GridView from "./GridView";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

export default function RouteAdminSitesVisits(props) {

  

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/AdminSitesVisits', '/')
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')
    }
  }, []);
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const columns = [
    { field: 'date', headerName: 'Date', type: 'date', width: 110, editable: false },
    {
      field: '_1day',
      headerName: '1 Day',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_7days',
      headerName: '7 Days',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_15days',
      headerName: '15 Days',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_30days',
      headerName: '30 Days',
      type:'number',
      width: 130,
      editable: false,
    },
    {
      field: '_30dayaverage',
      headerName: '30 Day Average',
      type:'number',
      width: 130,
      editable: false,
    },
  ];
  
  const rows = [
    {
      id: 1,
      date: '10/31/2021',
      _1day: 2,
      _7days: 29,
      _15days: 48,
      _30days: 105,
      _30dayaverage: 3.43,
    },
    {
      id: 2,
      date: '10/29/2021',
      _1day: 6,
      _7days: 24,
      _15days: 48,
      _30days: 123,
      _30dayaverage: 3.43,
    },
    {
      id: 3,
      date: '9/22/2021',
      _1day: 4,
      _7days: 25,
      _15days: 48,
      _30days: 105,
      _30dayaverage: 3.43,
    },
    {
      id: 4,
      date: '9/20/2021',
      _1day: 8,
      _7days: 17,
      _15days: 48,
      _30days: 154,
      _30dayaverage: 3.43,
    },
    {
      id: 5,
      date: '8/14/2021',
      _1day: 3,
      _7days: 24,
      _15days: 48,
      _30days: 1764,
      _30dayaverage: 3.43,
    },
  ];

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <GridView columns={columns} rows={rows} />
        </Grid>
    </Grid>
  );
}