import React, { useState, useContext}  from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { NumericFormat } from 'react-number-format';
import {numberToAmericanFormat, roundDecimals} from './functions';

export default function CoingeckoCurrentCoinPrice(props) {

  const [theme] = useContext(ThemeContext);

  function formatDate(inputDate) {
    const [year, month] = inputDate.split('-');
    const date = new Date(year, month - 1);
    const monthName = date.toLocaleString('default', { month: 'short' });
    const formattedDate = `${monthName} ${year}`;
  
    return formattedDate;
  }

  return (
    <>
      <div style={{fontSize:12}}>
        {props.coinPairNameLeftPartOnly}-USD {props.filterByMonth == 0 ? 'Current' : formatDate(props.filterByMonthValue) + ' closing'} Price (Coingecko)
      </div>
      <div>
        <NumericFormat
          className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
          displayType="text"
          value={props.currentCoinCurrentPrice} 
          thousandSeparator="," 
          allowNegative={false} 
          decimalScale={8} 
          prefix={`$`} 
          renderText={(value) => <>{value}</>}
        />
      </div>
      <div>
      {props.usd_24h_change != null && props.usd_24h_change != -1000000 ? <div style={{color: props.usd_24h_change > 0 ? '#4EAF0A' : '#E25C4C'}}>{roundDecimals(props.usd_24h_change, 2)}%</div> : null}
      </div>
    </>
  );
}