import React, { useState, useEffect, useContext } from 'react';
import { ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";
import ConsumerHistoryGrid2 from "./ConsumerHistoryGrid2";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import LinearProgress from '@mui/material/LinearProgress';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


import TextField from '@mui/material/TextField';


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import GridView from "./GridView";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
  }),
);

let currentCommentToEdit = '';
let currentCommentId = -1;

export default function RouteAdminGridView(props) {

  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();
  const theme = useTheme();

  const [rows, setRows] = useState([]);
  const colorsArr = {"-100": "#FFFFFF", "2": "#00B050", "1": "#25FF88", "0": "#808080", "-1": "#FFA6A0", "-2": "#FF0000"};
  const colorsNameArr = {"-100": "#FFFFFF", "2": "Bullish - Strong", "1": "Bullish", "0": "Neutral", "-1": "Bearish", "-2": "Bearish - Strong"};

  //const [currentCommentToEdit, setCurrentCommentToEdit] = useState('');

  const [loading, setLoading] = useState(false);

  const [loadStartPager, setLoadStartPager] = useState(0);

  

  const setCurrentCommentToEditFNC = (val, id) => {

    console.log('setCurrentCommentToEditFNC = ' + val)
    console.log('id = ' + id);
    currentCommentToEdit = val;
    currentCommentId = id;
  }


  function saveCurrentComment (id, active) {


    if(currentCommentToEdit != '' && currentCommentId == id) {

      console.log('save comment = ' + currentCommentToEdit + ', id=' + id + ', active = ' + active);
      _api_analyse_comment_edit_admin(id, active, currentCommentToEdit)
    } else {

      console.log('comment is empty');
    }
  }

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/AdminGridView', '/')
    } else {
      _api_get_analyse_all_admin();
      //changeHistory('/ReferAFriend', '/SignUp')
    }
  }, []);

  ////////////////////////////////////////get_analyse_all_admin
  function _api_analyse_comment_edit_admin_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('_api_analyse_comment_edit_admin error = ' + _data.msg);
      console.log('_api_analyse_comment_edit_admin code = ' + _data.status_code);
      
    } else {
      
      
      console.log('_api_analyse_comment_edit_admin was successfully sent');

      
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_analyse_comment_edit_admin = (id, active, comment) => {

    //setLoading(true)
    let data = [
      {'key':'action', 'value': 'analyse_comment_edit_admin'},
      {'key':'id', 'value': id},
      {'key':'active', 'value': active},
      {'key':'comment', 'value': comment},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_analyse_comment_edit_admin_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////get_analyse_all_admin
  function _api_get_analyse_all_admin_callback(_data) {

    setLoading(false)
    if(_data.status_code != 2) {
      
      console.log('_api_get_analyse_all_admin error = ' + _data.msg);
      console.log('_api_get_analyse_all_admin code = ' + _data.status_code);
      
    } else {
      
      
      console.log('_api_get_analyse_all_admin was successfully sent');

          let _rows = [];
          //_data.data.sort((a, b) => a.date > b.date ? -1 : 1);
          _data.data.map((value, key) => {

              _rows.push({

                  id: value.id,
                  contributor: value.username,
                  coin: value.coin_pair_name,
                  dateCreated: <div style={{fontSize:13}}>{value.date}</div>,
                  _1h: <div style={{backgroundColor:colorsArr[value._1h], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._1h]}</div>,
                  _4h: <div style={{backgroundColor:colorsArr[value._4h], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._4h]}</div>,
                  _1d: <div style={{backgroundColor:colorsArr[value._1d], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._1d]}</div>,
                  _1w: <div style={{backgroundColor:colorsArr[value._1w], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._1w]}</div>,
                  _1m: <div style={{backgroundColor:colorsArr[value._1m], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._1m]}</div>, 
                  comments: <div style={{paddingLeft:10, textAlign:'left'}}><TextField
                              id={"outlined-textarea" + value.id}
                              multiline
                              placeholder="can not be empty"
                              size="small"
                              style={{width:220}}
                              inputProps={{style: {fontSize: 13, padding: '4px 7px', textAlign:'left'}}}
                              defaultValue={value.comment}
                              onChange={(event) => setCurrentCommentToEditFNC(event.target.value, value.id)}
                              />
                              <div style={{fontSize:12, paddingTop:10, textAlign:'right', cursor:'pointer'}}
                                onClick={(event) => saveCurrentComment(value.id, value.active)}
                              >Save</div>
                            </div>,
                  high1WEstimate: numberToAmericanFormat(value.high1W, 5), 
                  low1WEstimate: numberToAmericanFormat(value.low1W, 5),
                  high1DEstimate: numberToAmericanFormat(value.high1D, 5), 
                  low1DEstimate: numberToAmericanFormat(value.low1D, 5),  
                  highEstimate: numberToAmericanFormat(value.high, 5), 
                  lowEstimate: numberToAmericanFormat(value.low, 5),  
                  chartLink: value.chart_link != '' ? (<a href={value.chart_link} target="_blank"><ShowChartIcon /></a>) : <ShowChartIcon style={{color:'#cccccc'}} />,
                  videoLink: value.video_link != '' ? (<a href={value.video_link} target="_blank"><OndemandVideoIcon /></a>) : <OndemandVideoIcon style={{color:'#cccccc'}} />,
                  likes: value.like,
                  dislikes: value.dislike,
                  status: value.active == 1 ? <div style={{color:'#00cc00'}}>Active</div> : <div style={{color:'#cc0000'}}>Expired</div>,
                  dateCreatedFormat:value.date
              });
          })

      //setGetAnalyseAll(_data.data);
      let concated_arr = rows.concat(_rows);
      concated_arr.sort((a, b) => a.dateCreatedFormat > b.dateCreatedFormat ? -1 : 1);
      setRows(concated_arr);
      //setGetAnalyseAllCount(_data.count);
      //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      //setMyPortfolioListArr(_data.data)
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_get_analyse_all_admin = () => {

    
    
    let data = [
      {'key':'action', 'value': 'get_analyse_all_admin'},
      {'key':'start', 'value': loadStartPager * 10},
      {'key':'limit', 'value': 10},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]


    let p = loadStartPager + 1;
    setLoadStartPager(p);

    props._apiConnect(staticText.path_darts, data, _api_get_analyse_all_admin_callback)

    setLoading(true)
  }
  //////////////////////////////////////
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const columns = [
    { field: 'contributor', headerName: 'Contr.', width: 140, editable: false },
    { field: 'coin', headerName: 'Coin', width: 110, editable: false },
    {
      field: 'dateCreated',
      headerName: 'Date',
      type: 'date',
      width: 150,
      editable: false,
    },
    {
      field: '_1h',
      headerName: '1H',
      width: 130,
      editable: false,
    },
    {
      field: '_4h',
      headerName: '4H',
      width: 130,
      editable: false,
    },
    {
      field: '_1d',
      headerName: '1D',
      width: 130,
      editable: false,
    },
    {
      field: '_1w',
      headerName: '1W',
      width: 130,
      editable: false,
    },
    {
      field: '_1m',
      headerName: '1M',
      width: 130,
      editable: false,
    },
    
    
    {
      field: 'comments',
      headerName: 'Comments',
      width: 220,
      editable: true,
      renderCell: (params) => (
        <div style={{ flexShrink: 1 }} >
          {params.value}
        </div>
      ),
      
      height:200,
    },
    {
      field: 'high1WEstimate',
      headerName: '1W High Est. (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'low1WEstimate',
      headerName: '1W Low Est. (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'high1DEstimate',
      headerName: '1D High Est. (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'low1DEstimate',
      headerName: '1D Low Est. (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'highEstimate',
      headerName: 'High Est. (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'lowEstimate',
      headerName: 'Low Est. (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'chartLink',
      headerName: 'Chart',
      width: 70,
      editable: false,
    },
    {
      field: 'videoLink',
      headerName: 'Video',
      width: 70,
      editable: false,
    },
    {
      field: 'likes',
      headerName: <ThumbUpIcon />,
      width: 60,
      editable: false,
    },
    {
      field: 'dislikes',
      headerName: <ThumbDownAltIcon />,
      type: 'number',
      width: 60,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
      editable: false,
    },
  ];

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />

        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
          {rows.length} Results
          {/*<GridView columns={columns} rows={rows} />*/}
          {
          loading ? 
          <LinearProgress />
          :
          <ConsumerHistoryGrid2 columns={columns} rows={rows} minWidth={1400} />
          }
          <div style={{cursor:'pointer', color:'#cc0000'}} onClick={() => _api_get_analyse_all_admin()}>Load More</div>
        </Grid>

    </Grid>
  );
}