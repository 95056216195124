import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import clsx from 'clsx';
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';



import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import ConsumerPost from "./ConsumerPost";

import DartLowHighAverage from "./DartLowHighAverage";


import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory, useParams } from "react-router-dom";
import { Divider } from '@mui/material';

import ConsumerHistoryGrid from "./ConsumerHistoryGrid";
import ConsumerHistoryGrid2 from "./ConsumerHistoryGrid2";





export default function RouteDashboardCoin(props) {

  const [theme, toggleTheme, activeTheme, dartCurrentHistoricalDate, setDartCurrentHistoricalDateFnc] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);


  //let rows = [];
  const [rows, setRows] = useState([]);
  let { _id, _user_id, _username, _coinpairname } = useParams();

  if(_id == undefined) {
    _id = 0;
  }


  if(_user_id == undefined) {
    _user_id = 0;
  }


  const [coinPairId, setCoinPairId] = useState(_id);
  const [userId, setUserId] = useState(_user_id);

  const [myPortfolioListArr, setMyPortfolioListArr] = useState([]);

  const [getAnalyseAll, setGetAnalyseAll] = useState([]);
  const [getAnalyseAllCount, setGetAnalyseAllCount] = useState(0);
  

  const [low_average, setLow_average] = useState(0);
  const [high_average, setHigh_average] = useState(0);

  const colorsArr = {"-100": "#FFFFFF", "2": "#00B050", "1": "#25FF88", "0": "#808080", "-1": "#FFA6A0", "-2": "#FF0000"};
  const colorsNameArr = {"-100": "#FFFFFF", "2": "Bullish - Strong", "1": "Bullish", "0": "Neutral", "-1": "Bearish", "-2": "Bearish - Strong"};


  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/DashboardCoin', '/')
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')

     _api_get_analyse_history_by_user_coin(_user_id, _id)
    }
  }, []);

  const Welcome = (name) => {
    return (<div>Hello, {name}</div>);
  }
  
////////////////////////////////////////_api_get_analyse_history_by_user_coin
function _api_get_analyse_history_by_user_coin_callback(_data) {

  if(_data.status_code != 2) {
    

  } else {
    
        let _rows = [];

        _data.data.map((value, key) => {

            _rows.push({

                id: value.id,
                dateCreated: value.date,
                _1h: <div style={{backgroundColor:colorsArr[value._1h], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center', color:'#000000'}}>{colorsNameArr[value._1h]}</div>,
                _4h: <div style={{backgroundColor:colorsArr[value._4h], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center', color:'#000000'}}>{colorsNameArr[value._4h]}</div>,
                _1d: <div style={{backgroundColor:colorsArr[value._1d], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center', color:'#000000'}}>{colorsNameArr[value._1d]}</div>,
                _1w: <div style={{backgroundColor:colorsArr[value._1w], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center', color:'#000000'}}>{colorsNameArr[value._1w]}</div>,
                _1m: <div style={{backgroundColor:colorsArr[value._1m], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center', color:'#000000'}}>{colorsNameArr[value._1m]}</div>, 
                comments: <div style={{paddingLeft:10, textAlign:'left'}}>{value.comment}</div>,
                highEstimate: numberToAmericanFormat(value.high, 5), 
                lowEstimate: numberToAmericanFormat(value.low, 5), 
                high1DEstimate: numberToAmericanFormat(value.high1D, 5), 
                low1DEstimate: numberToAmericanFormat(value.low1D, 5),  
                high1WEstimate: numberToAmericanFormat(value.high1W, 5), 
                low1WEstimate: numberToAmericanFormat(value.low1W, 5),
                chartLink: value.chart_link != '' ? (<a href={value.chart_link} target="_blank" style={{color:theme.foreground}}><div>Click here</div><div><ShowChartIcon style={{color:theme.foreground}} /></div></a>) : <ShowChartIcon style={{color:'#666666'}} />,
                videoLink: value.video_link != '' ? (<a href={value.video_link} target="_blank" style={{color:theme.foreground}}><div>Click here</div><div><OndemandVideoIcon style={{color:theme.foreground}} /></div></a>) : <OndemandVideoIcon style={{color:'#666666'}} />,
                likes: value.like,
                dislikes: value.dislike,
            });
        })

    //setGetAnalyseAll(_data.data);
    setRows(_rows);
    setGetAnalyseAllCount(_data.count);
    //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

    //setMyPortfolioListArr(_data.data)
    ///////////////////////////////////////////////////////////////////////
  }
  
}

const _api_get_analyse_history_by_user_coin = (_userId, _coinPairId) => {

  let data = [
    {'key':'action', 'value': 'get_analyse_history_by_user_coin'},
    {'key':'user_id', 'value': _userId},
    {'key':'coin_pair_id', 'value': _coinPairId},
    {'key':'loggined_token', 'value': props.userDetails.loggined_token}
  ]

  props._apiConnect(staticText.path_darts, data, _api_get_analyse_history_by_user_coin_callback)
}
//////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      type: 'date',
      width: 150,
      editable: false,
    },
    {
      field: '_1h',
      headerName: '1H',
      width: 90,
      editable: false,
      cellClassName: (params) =>
      clsx('super-app', {
        _m2v: params.value == 'Bearish - Strong',
        _m1v: params.value == 'Bearish',
        _0v: params.value == 'Neutral',
        _p1v: params.value == 'Bullish',
        _p2v: params.value == 'Bullish - Strong',
      }),
    },
    {
      field: '_4h',
      headerName: '4H',
      width: 90,
      editable: false,
      cellClassName: (params) =>
      clsx('super-app', {
        _m2v: params.value == 'Bearish - Strong',
        _m1v: params.value == 'Bearish',
        _0v: params.value == 'Neutral',
        _p1v: params.value == 'Bullish',
        _p2v: params.value == 'Bullish - Strong',
      }),
    },
    {
      field: '_1d',
      headerName: '1D',
      width: 90,
      editable: false,
      cellClassName: (params) =>
      clsx('super-app', {
        _m2v: params.value == 'Bearish - Strong',
        _m1v: params.value == 'Bearish',
        _0v: params.value == 'Neutral',
        _p1v: params.value == 'Bullish',
        _p2v: params.value == 'Bullish - Strong',
      }),
    },
    {
      field: '_1w',
      headerName: '1W',
      width: 90,
      editable: false,
      cellClassName: (params) =>
      clsx('super-app', {
        _m2v: params.value == 'Bearish - Strong',
        _m1v: params.value == 'Bearish',
        _0v: params.value == 'Neutral',
        _p1v: params.value == 'Bullish',
        _p2v: params.value == 'Bullish - Strong',
      }),
    },
    {
      field: '_1m',
      headerName: '1M',
      width: 90,
      editable: false,
      cellClassName: (params) =>
      clsx('super-app', {
        _m2v: params.value == 'Bearish - Strong',
        _m1v: params.value == 'Bearish',
        _0v: params.value == 'Neutral',
        _p1v: params.value == 'Bullish',
        _p2v: params.value == 'Bullish - Strong',
      }),
    },
    
    {
      field: 'comments',
      headerName: 'Comments',
      width: 320,
      editable: false,
      cellClassName: (params) =>
      clsx('super-app', {
        _c20v: params.value.length > 20,
        _c10v: params.value.length < 20,
      }),
      
      height:200,
    },
    {
        field: 'high1WEstimate',
        headerName: '1W High Estimate (USD)',
        width: 90,
        editable: false,
    },
    {
      field: 'low1WEstimate',
      headerName: '1W Low Estimate (USD)',
      width: 90,
      editable: false,
    },
    {
        field: 'high1DEstimate',
        headerName: '1D High Estimate (USD)',
        width: 90,
        editable: false,
    },
    {
      field: 'low1DEstimate',
      headerName: '1D Low Estimate (USD)',
      width: 90,
      editable: false,
    },
    {
        field: 'highEstimate',
        headerName: '1H/4H High Estimate (USD)',
        width: 90,
        editable: false,
    },
    {
      field: 'lowEstimate',
      headerName: '1H/4H Low Estimate (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'chartLink',
      headerName: 'Chart Link',
      width: 110,
      editable: false,
    },
    {
      field: 'videoLink',
      headerName: 'Video Link',
      width: 110,
      editable: false,
    },
    {
      field: 'likes',
      headerName: <ThumbUpIcon />,
      width: 60,
      editable: false,
    },
    {
      field: 'dislikes',
      headerName: <ThumbDownAltIcon />,
      type: 'number',
      width: 60,
      editable: false,
    },
  ];
  

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu backBtn={_id + '/' + _coinpairname} _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{color:theme.foreground}}>{_username}'s Historic Updates for {_coinpairname}</div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
          {/*<ConsumerHistoryGrid columns={columns} rows={rows} />*/}
          <ConsumerHistoryGrid2 columns={columns} rows={rows} minWidth={1100} />
        </Grid>
    </Grid>
  );
}