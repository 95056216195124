import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import {firstLetterValidation, numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import SelectTrend from "./SelectTrend";




import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import ConsumerPost from "./ConsumerPost";


import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory, useParams } from "react-router-dom";
import { Divider } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ButtonCustomStyle from "./ButtonCustomStyle";
import SnackbarContent from '@mui/material/SnackbarContent';
import axios from 'axios';
require('dotenv').config()

const useStyles = makeStyles({
    root: {
      '& .cold': {
        backgroundColor: '#cccccc',
        color: '#1a3e72',
        height: 200,
      },
      '& .hot': {
        backgroundColor: '#333333',
        color: '#1a3e72',
        height: 200,
      },
    },
  });

  



export default function AnalyseInput(props) {

    const [theme] = useContext(ThemeContext);
    const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();

  
  useEffect(() => {

    _api_get_analyse(props.coinPairId)
    //
    //_api_get_analyse(props.coinPairId)
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  /////////////////////////////////////////////

  const [errorMessage, setErrorMessage] = useState('');

  const [successMessage, setSuccessMessage] = useState('');

  const [changeBool, setChangeBool] = useState(props.changeBool != undefined ? props.changeBool : false);
  const [mustBeHigherBool, setMustBeHigherBool] = useState(false)
  

  /////////////////////////////////////////////

  const [h1, setH1] = React.useState(-100);
  const [h4, setH4] = React.useState(-100);
  const [d1, setD1] = React.useState(-100);
  const [w1, setW1] = React.useState(-100);
  const [m1, setM1] = React.useState(-100);

  const [low, setLow] = React.useState(0);
  const [high, setHigh] = React.useState(0);

  const [low1D, setLow1D] = React.useState(0);
  const [high1D, setHigh1D] = React.useState(0);

  const [low1W, setLow1W] = React.useState(0);
  const [high1W, setHigh1W] = React.useState(0);

  const [comment, setComment] = React.useState('');

  const [chartLink, setChartLink] = React.useState('');
  const [videoLink, setVideoLink] = React.useState('');

  const [like, setLike] = React.useState(0);
  const [dislike, setDislike] = React.useState(0);
  

  const [dateAgo, setDateAgo] = React.useState('');

  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [correctText, setCorrectText] = React.useState('');
  const [chatgptPredictionText, setChatgptPredictionText] = React.useState('');

  
  const [currentPriceFromCoingecko, setCurrentPriceFromCoingecko] = React.useState('');
  const [leftVal, setLeftVal] = React.useState('');
  const [rightVal, setRightVal] = React.useState('');
  
  
  
  /////////////////////////////////////////////
  /////////////////////////////////////////////



  const handleConfirm = (_comment) => {
    setComment(_comment)
    // do something after confirmation
    console.log("Confirmed!");
  };

  const handleCorrectClick = async () => {
    console.log('dddd')
    let API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
    

    const response = await axios.post(
        "https://api.openai.com/v1/completions",
        {
          prompt: `I want you to act as an English translator, spelling corrector and improver. I will speak to you in any language and you will detect the language, translate it and answer in the corrected and improved version of my text, in English. I want you to replace my simplified A0-level words and sentences with more beautiful and elegant, upper level English words and sentences. Keep the meaning same, but make them more literary. I want you to only reply the correction, the improvements and nothing else, do not write explanations. Act like expert of financial and crypto analysis. write a brief snippet of price trend analysis in Bloomberg style. Choose some words for seo marketing and make them hashtag in the text. My first sentence is "${comment}"`,
          model: 'gpt-3.5-turbo-instruct',
          max_tokens: 400,
          n: 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      //setOpenConfirmModal(true)
      setCorrectText(response.data.choices[0].text.trim());
      
      console.log(response.data.choices[0].text.trim())
      getCurrentCoinPairPrice(leftVal, rightVal)
      
      //_api_get_coin_pair_by_id(props.coinPairId)
  };

  const setChangeBoolFnc = (_bool) => {

    setChangeBool(_bool);
    setSuccessMessage('')
    props.setChangeBoolFnc(_bool);
    setErrorMessage('')
  }

  ////////////////////////////////////////_api_get_analyse
  function _api_get_analyse_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_analyse error = ' + _data.msg);
      //console.log('get_analyse code = ' + _data.status_code);
      
    } else {
      
      //console.log('get_analyse was successfully sent');
      //console.log('_data = ' + _data.data[0]);
      

      let coin_pair_idsArr_int = [];
      //_data.data.map((value, key) => {

        //coin_pair_idsArr_int.push(parseInt(value["id"]));
      //})
        //console.log('data = ', _data.data[0]);

        setH1(_data.data[0]["_1h"])
        setH4(_data.data[0]["_4h"])
        setD1(_data.data[0]["_1d"])
        setW1(_data.data[0]["_1w"])
        setM1(_data.data[0]["_1m"])
        setLow(parseFloat(_data.data[0]["low"]))
        setHigh(parseFloat(_data.data[0]["high"]))
        setLow1D(parseFloat(_data.data[0]["low1D"]))
        setHigh1D(parseFloat(_data.data[0]["high1D"]))
        setLow1W(parseFloat(_data.data[0]["low1W"]))
        setHigh1W(parseFloat(_data.data[0]["high1W"]))
        
        setComment(_data.data[0]["comment"])
        setChartLink(_data.data[0]["chart_link"])
        setVideoLink(_data.data[0]["video_link"])
        setDateAgo(_data.data[0]["date_ago"])
        setLike(_data.data[0]["like"])
        setDislike(_data.data[0]["dislike"])
    }

    _api_get_coin_pair_by_id(props.coinPairId)
    
  }

  const _api_get_analyse = (_coinPairId) => {

    let data = [
      {'key':'action', 'value': 'get_analyse'},
      {'key':'coin_pair_id', 'value': _coinPairId},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_analyse_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_get_portfolio
  function _api_set_analyse_callback(_data) {
    console.log('_data', _data)
    if(_data.status_code != 2) {
      
      //console.log('set_analyse error = ' + _data.msg);
      //console.log('set_analyse code = ' + _data.status_code);
      
      setSuccessMessage('');
    } else {
      
      //console.log('set_analyse was successfully sent');
      //console.log('_data = ' + _data.data[0]);
      

      let coin_pair_idsArr_int = [];

      setSuccessMessage('Changes has been successfully saved');
      setDateAgo('just now')
      setLike(0)
      setDislike(0)
      setChangeBool(false);
      props.setChangeBoolFnc(false);
    }
    
  }

  const _api_set_analyse = () => {

    let data = [
      {'key':'action', 'value': 'set_analyse'},
      {'key':'coin_pair_id', 'value': props.coinPairId},
      {'key':'coin_pair_name', 'value': props.coinPairName},
      {'key':'_1h', 'value': h1},
      {'key':'_4h', 'value': h4},
      {'key':'_1w', 'value': w1},
      {'key':'_1d', 'value': d1},
      {'key':'_1m', 'value': m1},
      {'key':'low', 'value': low},
      {'key':'high', 'value': high},
      {'key':'low1D', 'value': low1D},
      {'key':'high1D', 'value': high1D},
      {'key':'low1W', 'value': low1W},
      {'key':'high1W', 'value': high1W},
      {'key':'comment', 'value': encodeURIComponent(comment)},
      {'key':'chart_link', 'value': chartLink},
      {'key':'video_link', 'value': videoLink},
      {'key':'telegram', 'value': props.userDetails.telegram},
      {'key':'youtube', 'value': props.userDetails.youtube},
      {'key':'twitter', 'value': props.userDetails.twitter},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    //strInputString = strInputString.replace(/'/g, "\\'");
    console.log('props._path = ', props._path)
    console.log('data = ', data)

    props._apiConnect(props._path, data, _api_set_analyse_callback)
  }
  //////////////////////////////////////

  function isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  const saveCoinEditFnc = () => {
    //setChangeBool(false)
    //do some email stuf
    
    //changeHistory('/', props.userDetails.usertype == 0 ? '/DashboardUser' : '/DashboardConsumer')

    let validation = true;

    if(parseInt(h1) == -100) {
        validation = false;
        setErrorMessage('Please select 1 Hour field')
    } else if(parseInt(h4) == -100) {
        validation = false;
        setErrorMessage('Please select 4 Hours field')
    }
    else if(parseInt(d1) == -100) {
        validation = false;
        setErrorMessage('Please select 1 Day field')
    }
    else if(parseInt(w1) == -100) {
        validation = false;
        setErrorMessage('Please select 1 Week field')
    }
    else if(parseInt(m1) == -100) {
        validation = false;
        setErrorMessage('Please select 1 Month field')
    }
    else if(Number(low1W) == 0 || low1W == '') {
        validation = false;
        setErrorMessage('Please provide 1W Low estimate number')
    }
    else if(Number(high1W) == 0 || high1W == '') {
        validation = false;
        setErrorMessage('Please provide 1W High estimate number')
    }
    else if(Number(low1W) >= Number(high1W)) {
        validation = false;
        setErrorMessage('1W Low estimate must be less than 1W High estimate')
    }
    else if(Number(low1D) == 0 || low1D == '') {
        validation = false;
        setErrorMessage('Please provide 1D Low estimate number')
    }
    else if(Number(high1D) == 0 || high1D == '') {
        validation = false;
        setErrorMessage('Please provide 1D High estimate number')
    }
    else if(Number(low1D) >= Number(high1D)) {
        validation = false;
        setErrorMessage('1D Low estimate must be less than 1D High estimate')
    }
    else if(Number(low) == 0 || low == '') {
        validation = false;
        setErrorMessage('Please provide 1H/4H Low estimate number')
    }
    else if(Number(high) == 0 || high == '') {
        validation = false;
        setErrorMessage('Please provide 1H/4H High estimate number')
    }
    else if(Number(low) >= Number(high)) {
        validation = false;
        setErrorMessage('1H/4H Low estimate must be less than 1H/4H High estimate')
    }
    else if(Number(high1D) < Number(high)) {
        validation = false;
        setErrorMessage('1D High estimate should be greater or equal than 1H/4H High estimate')
    }
    else if(Number(low1D) > Number(low)) {
        validation = false;
        setErrorMessage('1D Low estimate should be less or equal than 1H/4H Low estimate')
    }
    else if(Number(high1W) < Number(high1D)) {
        validation = false;
        setErrorMessage('1W High estimate should be greater or equal than 1D High estimate')
    }
    else if(Number(low1W) > Number(low1D)) {
        validation = false;
        setErrorMessage('1W Low estimate should be less or equal than 1D Low estimate')
    }
    else if(Number(low) >= Number(currentPriceFromCoingecko)) {
        validation = false;
        setErrorMessage('1H/4H Low estimate should be lower than the current price')
    }
    else if(Number(high) <= Number(currentPriceFromCoingecko)) {
        validation = false;
        setErrorMessage('1H/4H High estimate should be greater than the current price')
    }
    else if(comment == '') {
        validation = false;
        setErrorMessage('Please fill comment field')
    }
    else if(chartLink != '' && !isValidURL(chartLink)) {

        validation = false;
        setErrorMessage('Price chart link must be valid URL');
    }
    else if(videoLink != '' && !isValidURL(videoLink)) {

        validation = false;
        setErrorMessage('Link to video must be valid URL');
    }

    if(validation) {

        console.log('DashboardCoinEdit')
        //handleCorrectClick();
        _api_set_analyse();
        
    } else {

        //console.log('Validation error')
    }
  };

  const setLowFnc = (val) => {

    if(firstLetterValidation(val)) {

        setLow(0);
    } else {

        setLow(val);
    }
  }

  const setHighFnc = (val) => {

    if(firstLetterValidation(val)) {

        setHigh(0);
    } else {

        setHigh(val);
    }
  }

  const setLow1DFnc = (val) => {

    if(firstLetterValidation(val)) {

        setLow1D(0);
    } else {

        setLow1D(val);
    }
  }

  const setHigh1DFnc = (val) => {

    if(firstLetterValidation(val)) {

        setHigh1D(0);
    } else {

        setHigh1D(val);
    }
  }

  const setLow1WFnc = (val) => {

    if(firstLetterValidation(val)) {

        setLow1W(0);
    } else {

        setLow1W(val);
    }
  }

  const setHigh1WFnc = (val) => {

    if(firstLetterValidation(val)) {

        setHigh1W(0);
    } else {

        setHigh1W(val);
    }
  }

  const getCurrentCoinPairPriceNow = (left_side_id, right_side) => {
    const request_str = 'https://api.coingecko.com/api/v3/simple/price?ids=' + left_side_id + '&vs_currencies=' + right_side.toLowerCase();

    //console.log('request_str = ', request_str);
    axios.get(request_str)
    .then(res => {

      setCurrentPriceFromCoingecko(res.data[left_side_id][right_side.toLowerCase()])
    })
    .catch(error => {
        console.log('getCurrentCoinPairPriceNow error', error)
    });
  }

  const getCurrentCoinPairPrice = (left_side_id, right_side) => {
    let time_now = Math.floor(Date.now() / 1000);
    //var last = Math.floor((Date.now() - (100 * 24 * 60 * 60 * 1000)) / 1000);
    //var day =last.getDate();
    console.log('time_now = ', time_now)
    const request_str = 'https://api.coingecko.com/api/v3/coins/' + left_side_id + '/market_chart/range?vs_currency=' + right_side.toLowerCase() + '&from=' + (time_now - 100 * 24 * 60 * 60) + '&to=' + (time_now);
    console.log('request_str = ', request_str)
    //https://api.coingecko.com/api/v3/coins/bitcoin/market_chart/range?vs_currency=usd&from=1392577232&to=1422577232
    //console.log('request_str = ', request_str);
    axios.get(request_str)
    .then(res => {
      console.log('getCurrentCoinPairPrice res = ', res)

      let pricesOnlyArr = []
      res.data.prices.map((value, key) => {

        pricesOnlyArr.push(value[1])
      })

      console.log('pricesOnly  =', pricesOnlyArr)

      setChatgptPredictionText('')
      chatGPTVersionGenerate(left_side_id, pricesOnlyArr)
    })
    .catch(error => {
        console.log('error', error)
    });
  }

  ////////////////////////////////////////_api_get_coin_pair_by_id
function _api_get_coin_pair_by_id_callback(_data) {

    console.log('_api_get_coin_pair_by_id_callback _data', _data)
    //setLoading(false)
    if(_data.status_code != 2) {
      
        
      //console.log('_api_get_coin_pair_by_id error = ' + _data.msg);
      //console.log('_api_get_coin_pair_by_id code = ' + _data.status_code);
      
    } else {
      
        setLeftVal(_data.data[0].left_side_name)
        setRightVal(_data.data[0].right_side)
        setCurrentPriceFromCoingecko(_data.coinPriceArr[0]["close_usd"])
        //getCurrentCoinPairPriceNow(_data.data[0].left_side_name, _data.data[0].right_side);
      //

    }
    
  }
  
  const _api_get_coin_pair_by_id = (_coinPairId) => {
  
    //(true);
    let data = [
      {'key':'action', 'value': 'get_coin_pair_by_id'},
      {'key':'coin_pair_id', 'value': _coinPairId},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]
  
    props._apiConnect(staticText.path_darts, data, _api_get_coin_pair_by_id_callback)
  }
  //////////////////////////////////////

  const chatGPTVersionGenerate = async (coin_name, pricesOnlyArr) => {
    console.log('dddd = ' , currentPriceFromCoingecko)
    let API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

    let promptStr = `Forget about everything I told you before. You are an expert crypto trader with more than 10 years of experience, 
    I will provide you with a list of ${coin_name} prices for the last 100 days, now is 2023.
    can you provide me with a technical analysis
    of ${coin_name} based on these prices if current price of ${coin_name} is ${currentPriceFromCoingecko}. Do not make investment recommendation. Do not suggest buying, selling or holding the asset. Here is what information I want: 
    Price Overview: 
    Moving Averages:
    Relative Strength Index (RSI):
    Moving Average Convergence Divergence (MACD):
    Please be as detailed as much as you can, and explain in a way any beginner can understand. and make sure to use headings.
    Here is the price list: "${pricesOnlyArr}"`;
    

    const response = await axios.post(
        "https://api.openai.com/v1/completions",
        {
          prompt: promptStr,
          model: 'gpt-3.5-turbo-instruct',
          max_tokens: 500,
          n: 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      //setOpenConfirmModal(true)
      setChatgptPredictionText(response.data.choices[0].text.trim());
      //setCorrectText(response.data.choices[0].text.trim());
      
      //console.log(response.data.choices[0].text.trim())

      //_api_get_coin_pair_by_id(props.coinPairId)
  };

  return (
    
        <>
            <Grid item xs={12} sm={8} md={4} lg={4} align="center" justify="center">
                <Grid container spacing={5} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:800, paddingBottom:10, fontSize:16}} >
                    <Grid item xs={4} sm={4} md={4} lg={4} align="left" justify="left">
                        <div style={{paddingBottom:10, textAlign:'right', height:30}}>Interval</div>
                        <div style={{paddingBottom:10, textAlign:'center'}}>
                            <div style={{paddingTop:6, textAlign:'right'}}>
                                1 Hour
                            </div>
                            <div style={{paddingTop:18, textAlign:'right'}}>
                                4 Hour
                            </div>
                            <div style={{paddingTop:18, textAlign:'right'}}>
                                1 Day
                            </div>
                            <div style={{paddingTop:18, textAlign:'right'}}>
                                1 Week
                            </div>
                            <div style={{paddingTop:18, textAlign:'right'}}>
                                1 Month
                            </div>
                        </div>
                        
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} align="left" justify="left">
                        <div style={{paddingBottom:10, textAlign:'center', height:30}}>Your Vision of the Trend Direction</div>
                        <div style={{paddingBottom:10, textAlign:'center'}}>
                            <div style={{paddingTop:2}}>
                            <SelectTrend setChangeBoolFnc={setChangeBoolFnc} key={h1 + '_' + props.coinPairId} trendName="1 Hour" trendFormName="_1H" defaultValue={h1} setTrend={setH1} />
                            </div>
                            <div style={{paddingTop:7}}>
                            <SelectTrend setChangeBoolFnc={setChangeBoolFnc} key={h4 + '_' + props.coinPairId} trendName="4 Hours" trendFormName="_4H" defaultValue={h4} setTrend={setH4} />
                            </div>
                            <div style={{paddingTop:7}}>
                            <SelectTrend setChangeBoolFnc={setChangeBoolFnc} key={d1 + '_' + props.coinPairId} trendName="1 Day" trendFormName="_1D" defaultValue={d1} setTrend={setD1} />
                            </div>
                            <div style={{paddingTop:7}}>
                            <SelectTrend setChangeBoolFnc={setChangeBoolFnc} key={w1 + '_' + props.coinPairId} trendName="1 Week" trendFormName="_1W" defaultValue={w1} setTrend={setW1} />
                            </div>
                            <div style={{paddingTop:7}}>
                            <SelectTrend setChangeBoolFnc={setChangeBoolFnc} key={m1 + '_' + props.coinPairId} trendName="1 Month" trendFormName="_1M" defaultValue={m1} setTrend={setM1} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, paddingBottom:10, fontSize:16}} >
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{height:40}}>Price Estimate</Grid>
                    {currentPriceFromCoingecko != 0 ? <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{height:40}}>Current Price (Coingecko) {currentPriceFromCoingecko > 1 ? numberToAmericanFormat(currentPriceFromCoingecko) : currentPriceFromCoingecko}</Grid> : null}
                    
                    <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                
                            </Grid>
                        
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <div style={{alignItems: 'center', padding:3, borderRadius:5, width:'100%'}}>
                                Low (USD)
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                
                            </Grid>
                        
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <div style={{alignItems: 'center', padding:3, borderRadius:5, width:'100%'}}>
                                High (USD)
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <div style={{alignItems: 'center', padding:3, borderRadius:5, width:'100%'}}>
                                1W
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    size="small"
                                    style={{width:'100%', align:'center'}}
                                    onChange={event => {setLow1WFnc(event.target.value); setChangeBoolFnc(true)}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}}}
                                    defaultValue={low1W}
                                    value={low1W}
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    size="small"
                                    style={{width:'100%', align:'center'}}
                                    onChange={event => {setHigh1WFnc(event.target.value); setChangeBoolFnc(true)}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}}}
                                    defaultValue={high1W}
                                    value={high1W}
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <div style={{alignItems: 'center', padding:3, borderRadius:5, width:'100%'}}>
                                1D
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    size="small"
                                    style={{width:'100%', align:'center'}}
                                    onChange={event => {setLow1DFnc(event.target.value); setChangeBoolFnc(true)}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}}}
                                    defaultValue={low1D}
                                    value={low1D}
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    size="small"
                                    style={{width:'100%', align:'center'}}
                                    onChange={event => {setHigh1DFnc(event.target.value); setChangeBoolFnc(true)}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}}}
                                    defaultValue={high1D}
                                    value={high1D}
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <div style={{alignItems: 'center', padding:3, borderRadius:5, width:'100%'}}>
                                1H/4H
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    size="small"
                                    style={{width:'100%', align:'center'}}
                                    onChange={event => {setLowFnc(event.target.value); setChangeBoolFnc(true)}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}}}
                                    defaultValue={low}
                                    value={low}
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                        <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    size="small"
                                    style={{width:'100%', align:'center'}}
                                    onChange={event => {setHighFnc(event.target.value); setChangeBoolFnc(true)}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}}}
                                    defaultValue={high}
                                    value={high}
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                    (high1W / low1W) > 2 || (low1W / high1W) > 2 ? 
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                            <Alert severity="warning">Note: your 1W Low and High estimates are 50% apart</Alert>
                        </Grid>
                    : 
                        null
                    }
                    {
                    (high1D / low1D) > 2 || (low1D / high1D) > 2 ? 
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                            <Alert severity="warning">Note: your 1D Low and High estimates are 50% apart</Alert>
                        </Grid>
                    : 
                        null
                    }
                    {
                    (high / low) > 2 || (low / high) > 2 ? 
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                            <Alert severity="warning">Note: your 1H/4H Low and High estimates are 50% apart</Alert>
                        </Grid>
                    : 
                        null
                    }
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', borderRadius: 10 }}>
                            Comments
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <TextField
                            sx={theme.inputTextSx}
                            id="outlined-multiline-static"
                            multiline
                            rows={5}
                            placeholder="Your Comments here..."
                            size="small"
                            style={{width:'100%'}}
                            onChange={event => {setComment(event.target.value); setChangeBoolFnc(true)}}
                            inputProps={{style: {fontSize: 13, color:theme.foreground}}}
                            defaultValue={comment}
                            value={comment}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <Button
                            disabled={comment == ''}
                            onClick={handleCorrectClick}
                        >
                            GENERATE Chat GPT Version
                        </Button>
                    </Grid>
                    {correctText == '' ? null : 
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', borderRadius: 10 }}>
                            Corrected version
                        </div>
                    </Grid>
                    }
                    {correctText == '' ? null : 
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">

                            <SnackbarContent
                                message={correctText}
                            />
                        </Grid>
                    }
                    {correctText == '' ? null : 
                        <>
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <Button
                                    onClick={() => {setCorrectText('')}}
                                >
                                    Don't Use
                                </Button>
                        
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} align="center" justify="center">
                                <Button
                                    onClick={() => {setComment(correctText); setCorrectText(''); setChatgptPredictionText('')}}
                                >
                                    Use
                                </Button>
                        
                            </Grid>
                        </>
                    }
                    {chatgptPredictionText == '' ? null : 
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', borderRadius: 10 }}>
                            Chat GPT Brief Overview
                        </div>
                    </Grid>
                    }
                    {chatgptPredictionText == '' ? null : 
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{textAlign:'left'}}>

                            {chatgptPredictionText}
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                        <Grid container spacing={0} rowSpacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:700, fontSize:16}} >
                            <Grid item xs={4} sm={4} md={4} lg={4} align="right" justify="right" style={{paddingRight:10}}>
                                <div style={{alignItems: 'right', borderRadius:5, width:'100%', paddingTop:5}}>
                                Price Chart Link (opt)
                                </div>
                            </Grid>
                        
                            <Grid item xs={8} sm={8} md={8} lg={8} align="center" justify="center">
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Chart link here"
                                    size="small"
                                    style={{width:'100%', align:'center'}}
                                    onChange={event => {setChartLink(event.target.value); setChangeBoolFnc(true)}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'left', color:theme.foreground}}}
                                    defaultValue={chartLink}
                                    value={chartLink}
                                />
                            </Grid>
                        
                            <Grid item xs={4} sm={4} md={4} lg={4} align="right" justify="right" style={{paddingRight:10}}>
                                <div style={{alignItems: 'right', borderRadius:5, width:'100%', paddingTop:5}}>
                                Link to Video (opt)
                                </div>
                            </Grid>
                        
                            <Grid item xs={8} sm={8} md={8} lg={8} align="center" justify="center">
                                <TextField
                                    sx={theme.inputTextSx}
                                    id="outlined-textarea"
                                    placeholder="Video link here"
                                    size="small"
                                    style={{width:'100%', align:'center'}}
                                    onChange={event => {setVideoLink(event.target.value); setChangeBoolFnc(true)}}
                                    inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'left', color:theme.foreground}}}
                                    defaultValue={videoLink}
                                    value={videoLink}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end" style={{paddingRight:20}}>
                                <div style={{display: 'flex', textAlign:'right', maxWidth:300}}>
                                    <div style={{textAlign:'right'}}>
                                        <Button
                                        disabled
                                        style={{
                                            textTransform: 'none',
                                            color:'#FF1F1F',
                                            fontSize:20
                                        }}
                                        >
                                        <ThumbUpIcon style={{color:'#CCCCCC'}} />
                                        </Button>
                                    </div>
                                    <div style={{paddingLeft:1, paddingTop:5, textAlign:'right'}}>({like})</div>
                                    <div style={{paddingLeft:40, textAlign:'right'}}>
                                        <Button
                                        disabled
                                        style={{
                                            textTransform: 'none',
                                            color:'#FF1F1F',
                                            fontSize:20
                                        }}
                                        >
                                        <ThumbDownAltIcon style={{color:'#CCCCCC'}} />
                                        </Button>
                                    </div>
                                    <div style={{paddingLeft:1, paddingTop:5, textAlign:'right'}}>({dislike})</div>
                                </div>
                            </Grid>
                            {
                            errorMessage != '' ? 
                                <div style={{width:230, textAlign:'center'}}>
                                    <Alert severity="error">{errorMessage}</Alert>
                                </div>
                            : 
                                null
                            }
                            <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end" style={{paddingRight:20}}>
                                <div style={{width:350, textAlign:'right'}}>
                                    <ButtonCustomStyle
                                        changeHistory={null}
                                        doFnc={saveCoinEditFnc}
                                        changeHistoryFrom=''
                                        changeHistoryTo=''
                                        btnName='Save'                          
                                    />
                                </div>
                            </Grid>
                            <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end" style={{paddingRight:20}}>
                                <div style={{width:350, textAlign:'right', fontSize:11}}>
                                    {dateAgo != '' ? 'Last update: ' + dateAgo : null}
                                </div>
                            </Grid>
                            
                            {successMessage != '' ? 
                                <div style={{width:230, textAlign:'center'}}>
                                    <Alert severity="success">{successMessage}</Alert>
                                </div>
                            : 
                                null
                            }
                            {
                            mustBeHigherBool ? 
                                <div style={{width:230, textAlign:'center'}}>
                                    <Alert severity="warning">Higher timeframe estimates should normally be greater than lower timeframe estimates</Alert>
                                </div>
                            : 
                                null
                            }
                            {
                            changeBool ? 
                                <div style={{width:230, textAlign:'center'}}>
                                    <Alert severity="warning">Don’t forget to save your changes</Alert>
                                </div>
                            : 
                                null
                            }                            
                        </Grid>
                    </Grid>

                </Grid>
                
            </Grid>
            
        </>
  );
}