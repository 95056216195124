import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const loadingBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function LoadingAnimation(props) {
  return (
    <Modal
        open={props.loadingAnimation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={loadingBoxStyle}>
            <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Please Wait...
            </Typography>
            <LinearProgress />
            </> 
        </Box>
    </Modal>
  );
}