import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import ButtonCustomStyle from "./ButtonCustomStyle";


const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

export default function FormContactUs(props) {

  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };
  
  const [emailAddress, setEmailAddress] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailMessage, setEmailMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const setContactUsFnc = () => {
    
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validation_email = true;
    
    if(emailAddress == '') {

      validation_email = false;
      setErrorMessage("Email from field can't be empty");
    } else if(!re.test(emailAddress) ) {
      
      validation_email = false
      setErrorMessage("Email is invalid");
      // invalid email, maybe show an error to the user.
    } else if(emailSubject == '') {
      validation_email = false;
      setErrorMessage("Subject can't be empty");
    } else if(emailMessage == '') {
      validation_email = false;
      setErrorMessage("Message can't be empty");
    }

    if(validation_email) {
      setErrorMessage("");
      
      _api_send_contact();
    }
  };

  ////////////////////////////////////////forgotpassword_newpassword 
  function _api_send_contact_callback(_data) {

    if(_data.status_code != 2) {
      
     // console.log('_api_send_contact error = ' + _data.msg);
      //console.log('_api_send_contact code = ' + _data.status_code);
      if(_data.status_code == 5) {
        
        setErrorMessage(_data.msg);
      } else {
        setErrorMessage('Technical error');
      }
    } else {
      setErrorMessage('');
      setSuccessMessage('Successfully Sent');
      //console.log('_api_send_contact was successfull');
    }
  }

  const _api_send_contact = () => {

    let data = [
      {'key':'action', 'value': 'send_contact'},
      {'key':'email_address', 'value': emailAddress},
      {'key':'email_subject', 'value': emailSubject},
      {'key':'email_message', 'value': emailMessage}
    ]

    props._apiConnect(staticText.path_user, data, _api_send_contact_callback);
  }
  ////////////////////////////////////////
  

  return (

        <Grid container spacing={2} rowSpacing={2} columns={12} style={{maxWidth:350}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                            Email from
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            sx={theme.inputTextSx}
                            id="outlined-textarea"
                            placeholder="Your email here"
                            size="small"
                            style={{width:'100%'}}
                            inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                            onChange={event => setEmailAddress(event.target.value)}
                        />
                    </Grid>                  
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                            Subject
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            sx={theme.inputTextSx}
                            id="outlined-textarea"
                            placeholder="Subject here"
                            defaultValue=""
                            size="small"
                            style={{width:'100%'}}
                            inputProps={{style: {fontSize: 13, padding: '8px 14px', color:theme.foreground}}}
                            onChange={event => setEmailSubject(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{fontSize:16, paddingTop:5, paddingBottom:5, width:'100%', backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
                            Custom Message
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            sx={theme.inputTextSx}
                            id="outlined-textarea"
                            multiline
                            maxRows={3}
                            rows={3}
                            placeholder="Message here"
                            defaultValue=""
                            size="small"
                            style={{width:'100%'}}
                            inputProps={{style: {fontSize: 13, color:theme.foreground}}}
                            onChange={event => setEmailMessage(event.target.value)}
                        />
                    </Grid>
                    {errorMessage != '' ? <Grid item xs={12}>
                      <Alert severity="error">{errorMessage}</Alert>
                    </Grid> : (<div></div>)}
                    {successMessage != '' ? <Grid item xs={12}>
                      <Alert severity="success">Thank you for your inquiry/feedback. We will review it within 24 hours and get back to you if the inquiry requires it.</Alert>
                    </Grid> : (<div></div>)}
                    <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
                      <div style={{width:350, textAlign:'right'}}>
                        <ButtonCustomStyle
                            changeHistory={null}
                            doFnc={setContactUsFnc}
                            changeHistoryFrom=''
                            changeHistoryTo=''
                            btnName='Send'                          
                        />
                      </div>
                    </Grid>
        </Grid>
  );
}