import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import NativeSelect from '@mui/material/NativeSelect';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from '@mui/x-data-grid-generator';

const useStyles = makeStyles({
    root: {
      '& .cold': {
        backgroundColor: '#cccccc',
        color: '#1a3e72',
        height: 200,
      },
      '& .hot': {
        backgroundColor: '#333333',
        color: '#1a3e72',
        height: 200,
      },
    },
  });

  



export default function SelectTrend(props) {
  const classes = useStyles();

  const colorsArr = {"-100": "#FFFFFF", "2": "#00B050", "1": "#25FF88", "0": "#808080", "-1": "#FFA6A0", "-2": "#FF0000"};
  const [selectColorId, setSelectColorId] = useState(String(props.defaultValue));

  const changeSelectTrend = (e) => {

    //console.log(String(e.target.value));
    //console.log(colorsArr[String(e.target.value)]);
    setSelectColorId(String(e.target.value));
    props.setTrend(e.target.value);
    props.setChangeBoolFnc(true);
  }

  useEffect(() => {

    //console.log('changed trend id is = ' + String(selectColorId))
    //setSelectColorId(String(selectColorId));
  }, []);

  return (
    
        <div style={{alignItems: 'center', width:'100%', backgroundColor:colorsArr[selectColorId]}}>
            <NativeSelect
                onChange={changeSelectTrend}
                defaultValue={props.defaultValue}
                value={props.defaultValue}
                
                inputProps={{
                    name: props.trendFormName,
                    id: 'uncontrolled-native',
                }}
                style={{width:'100%', paddingLeft:10}}
            >
                <option value={-100}>Select one</option>
                <option value={2}>Bullish - Strong</option>
                <option value={1}>Bullish</option>
                <option value={0}>Neutral</option>
                <option value={-1}>Bearish</option>
                <option value={-2}>Bearish - Strong</option>
            </NativeSelect>
        </div>
  );
}