import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

export default function RouteAdminCreateCoinPair(props) {

  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/RouteAdminCreateCoinPair', '/')
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')

      _api_admin_get_coin_pair()
    }
  }, []);

  const [portfolioListArr, setPortfolioListArr] = useState([])

  const [leftSide, setLeftSide] = useState('');
  const [leftSideId, setLeftSideId] = useState('');
  
  const [rightSide, setRightSide] = useState('USD');
  const [errorMessage, setErrorMessage] = useState('');

  ////////////////////////////////////////_api_admin_get_coin_pair
  function _api_admin_get_coin_pair_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('admin_get_coin_pair error = ' + _data.msg);
      console.log('admin_get_coin_pair code = ' + _data.status_code);
      
    } else {
      
      console.log('_api_admin_get_coin_pair email was successfully sent');
      console.log('_data = ' + _data.data[0]);
      setPortfolioListArr(_data.data)

    }
    
  }

  const _api_admin_get_coin_pair = () => {

    console.log('loggined_token _api_admin_get_coin_pair' + props.userDetails.loggined_token);
    let data = [
      {'key':'action', 'value': 'admin_get_coin_pair'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_admin_get_coin_pair_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_disabel_enable_switch
  function _api_disabel_enable_switch_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('_api_disabel_enable_switch error = ' + _data.msg);
      console.log('_api_disabel_enable_switch code = ' + _data.status_code);
      
    } else {
      
      console.log('_api_disabel_enable_switch was successfully sent');
      //console.log('_data = ' + _data.data[0]);
      //setPortfolioListArr(_data.data)
      _api_admin_get_coin_pair();
    }
    
  }

  const _api_disabel_enable_switch = (_id) => {

    console.log('loggined_token _api_admin_get_coin_pair' + props.userDetails.loggined_token);
    let data = [
      {'key':'action', 'value': 'admin_disable_switch_coin_pair'},
      {'key':'id', 'value': _id},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_disabel_enable_switch_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_admin_set_coin_pair
  function _api_admin_set_coin_pair_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('_api_admin_set_coin_pair error = ' + _data.msg);
      console.log('_api_admin_set_coin_pair code = ' + _data.status_code);

      if(_data.status_code == 4) {

        setErrorMessage(_data.msg)
      } else {

        setErrorMessage('')
      }
      
    } else {
      
      
      console.log('_api_admin_set_coin_pair was successfully sent');
      //console.log('_data = ' + _data.data[0]);
      //setPortfolioListArr(_data.data)
      _api_admin_get_coin_pair();
    }
    
  }

  const _api_admin_set_coin_pair = () => {

    console.log('loggined_token _api_admin_get_coin_pair' + props.userDetails.loggined_token);
    let data = [
      {'key':'action', 'value': 'admin_set_coin_pair'},
      {'key':'left_side', 'value': leftSide},
      {'key':'left_side_id', 'value': leftSideId},
      {'key':'right_side', 'value': rightSide},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_admin_set_coin_pair_callback)
  }
  //////////////////////////////////////
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };
  
  const _disabel_enable_switchFnc = (_id) => {

    _api_disabel_enable_switch(_id);
    //
  }

  const addToList = () => {
    console.log('leftSide = ' + leftSide + ', rightSide = ' + rightSide);
    leftSide == '' || rightSide == '' ? setErrorMessage('Left and Right Sides can\'t be empty') : setErrorMessage('')
    if(leftSide != '' && rightSide != '') {
      _api_admin_set_coin_pair();
    }
  }

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="flex-start" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <Grid item xs={12} sm={12} md={12} lg={12} justifyContent="flex-start" justify="center">
          <div style={{justifyContent:"center"}}>
            <div style={{fontSize:22, fontStyle: 'italic', paddingLeft:40}}>
              Build a list of available coins
            </div>
            <div style={{display:'flex', paddingLeft:50, paddingTop:10}}>
              <div style={{fontSize:14}}>
                <div style={{width:90, textAlign:'center', backgroundColor:'#F2F2F2', borderRadius: 5, paddingTop:5, paddingBottom:5 }}>
                  Left Side
                </div>
              </div>
              <div style={{fontSize:14, paddingLeft:50}}>
                <div style={{width:90, textAlign:'center', backgroundColor:'#F2F2F2', borderRadius: 5, paddingTop:5, paddingBottom:5 }}>
                  Right Side
                </div>
              </div>
            </div>
            
            <div style={{display:'flex', paddingLeft:50, paddingTop:10}}>
              <div style={{fontSize:16}}>
                <div>
                  <TextField
                    id="outlined-textarea"
                    placeholder="ex. BTC"
                    size="small"
                    style={{width:90}}
                    inputProps={{style: {fontSize: 13, padding: '8px 14px'}}}
                    onChange={event => setLeftSide(event.target.value)}
                  />
                </div>
              </div>
              <div style={{width:30, textAlign:'center', paddingLeft:10}}>
                <div style={{backgroundColor:'#F2F2F2', borderRadius: 5, paddingTop:5, paddingBottom:5}}>
                  -
                </div>
              </div>
              <div style={{fontSize:16, paddingLeft:10}}>
                <div>
                  <TextField
                    id="outlined-textarea"
                    placeholder="ex. USD"
                    size="small"
                    style={{width:90}}
                    inputProps={{style: {fontSize: 13, padding: '8px 14px'}}}
                    onChange={event => setRightSide(event.target.value)}
                    defaultValue="USD"
                  />
                </div>
              </div>
              <div style={{fontSize:16, paddingLeft:10}}>
                <div>
                  <Button
                      style={{
                        cursor:'pointer',
                        textTransform: 'none',
                        backgroundColor: '#F2F2F2'
                      }}
                      onClick={
                        () => addToList()
                      }
                  >
                    Add to List
                  </Button>
                </div>
              </div> 
            </div>
            <div style={{display:'flex', paddingLeft:50, paddingTop:10}}>
              <div style={{fontSize:16}}>
                <div>
                  <TextField
                    id="outlined-textarea"
                    placeholder="ex. bitcoin"
                    size="small"
                    style={{width:90}}
                    inputProps={{style: {fontSize: 13, padding: '8px 14px'}}}
                    onChange={event => setLeftSideId(event.target.value)}
                  />
                </div>
              </div>
              
            </div>
            {errorMessage != '' ? <Alert severity="error">{errorMessage}</Alert> : (<div></div>)}
            <div style={{display:'flex', paddingLeft:50, paddingTop:10}}>
              <div style={{fontSize:14}}>
                <div style={{width:230, textAlign:'center', backgroundColor:'#F2F2F2', borderRadius: 5, paddingTop:5, paddingBottom:5 }}>
                  Available Cryptos
                </div>
              </div>
            </div>
            <div style={{display:'flex', paddingLeft:50, paddingTop:30}}>
              <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:245, overflowY: 'scroll', height: 400}}>
                

                {

                portfolioListArr.map((value, key) => {

                  const colorCoinPair = value.disabled == 0 ? '#00cc00' : '#cc0000'
                  return (

                    <>
                      <Grid item xs={6} sm={6} md={6} lg={6} align="flex-start" justify="center">
                        <div style={{color: colorCoinPair}}>{value.left_side}-{value.right_side}</div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} align="flex-start" justify="center" style={{textAlign:'right', paddingRight:10}}>
                        <Button
                            style={{
                              cursor:'pointer',
                              textTransform: 'none',
                              backgroundColor: '#F2F2F2'
                            }}
                            onClick={() => _disabel_enable_switchFnc(value.id)}
                        >
                          {value.disabled == 0 ? 'Disable' : 'Enable'}
                        </Button>
                      </Grid>
                    </>
                  );
                  })
                }
                
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="flex-start" justify="center">
          
        </Grid>
    </Grid>
  );
}