import React, { useContext } from 'react';
import { ThemeContext } from "../index";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';


import LowHighChart from "./LowHighChart";
import LowHighPercentChart from "./LowHighPercentChart";



const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

export default function DartLowHighAverageHistoric(props) {

  const [theme] = useContext(ThemeContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (

    props._values[0] != undefined ? <Grid container spacing={2} columns={12} rowSpacing={1} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', maxWidth:800, fontSize:16}} >
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <LowHighChart _values={props._values} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <LowHighPercentChart _values={props._values} />
        </Grid>
    </Grid>
    : null
  );
}

